import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'

const MultiFileInput = ({ onAdd, accept }) => {
  const onDrop = useCallback(acceptedFiles => {
    onAdd(acceptedFiles)
  }, [])

  const options = { onDrop }

  const { getRootProps, getInputProps, isDragActive } = useDropzone(
    accept ? { ...options, accept } : options
  )

  return (
    <MultiFileInputWrapper {...getRootProps()}>
      <>
        <input {...getInputProps()} />
        <EmptyStateWrapper>
          <IconWrapper>
            <CloudIcon />
          </IconWrapper>
          {isDragActive ? (
            <p>Drop file(s) here...</p>
          ) : (
            <Text>
              <p>
                <span>Click to upload</span> or drag and drop
              </p>
              <p>({accept.replace(/,/g, ', ')})</p>
            </Text>
          )}
        </EmptyStateWrapper>
      </>
    </MultiFileInputWrapper>
  )
}

export default MultiFileInput

const MultiFileInputWrapper = styled.div`
  box-sizing: border-box;
  padding: 20px;
  border: 1px dashed ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
  width: 100%;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.white};
  margin-bottom: 20px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

const EmptyStateWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  p {
    margin-left: 10px;
    text-align: center;
  }
`

const Text = styled.div`
  color: ${({ theme }) => theme.colors.grey[800]};
  span {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
  }
`

const IconWrapper = styled.div`
  background: radial-gradient(#fae4de 50%, #fcf4f2 30%);
  border-radius: 50%;
  padding: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
`

const CloudIcon = styled(CloudUploadOutlinedIcon)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 25px !important;
`
