const emptyBpListState = {
  entries: [],
  totalPages: 1,
  totalFound: 1,
  favourites: {
    entries: [],
    totalPages: 1,
    totalFound: 1
  },
  prices: [],
  searchBpResults: [],
  searchFileResults: [],
  selectedBp: {},
  buildingTypes: [],
  invitations: [],
  bpsCount: 0,
  unitsCount: 0
}

export default emptyBpListState
