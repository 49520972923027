import APIService from './APIService'

const api = APIService()

/**
 * Get current session info
 * @returns {Promise} Promise with session info
 */
export const checkSession = () => api.get('/session/check')

/**
 * Extend the user's session
 * @returns {Promise} Promise with extended session info
 */
export const extendSession = () => api.post('/session/extend')

/**
 * Initialize session tracking on the client side
 */
export const initializeSessionTracking = () => {
  // Set up initial session expiry time (if not already set)
  if (!sessionStorage.getItem('session_expires_at')) {
    const expiryTime = new Date()
    expiryTime.setMinutes(expiryTime.getMinutes() + 30) // Default 30 mins
    sessionStorage.setItem('session_expires_at', expiryTime.toISOString())
  }
}

/**
 * Update the client-side session expiry time
 * @param {string} expiresAt - ISO timestamp when session expires
 */
export const updateSessionExpiry = (expiresAt) => {
  try {
    // Validate the timestamp before storing
    const date = new Date(expiresAt)
    if (!isNaN(date.getTime())) {
      sessionStorage.setItem('session_expires_at', expiresAt)
    }
  } catch (e) {
    console.error('Error updating session expiry:', e)
  }
}

/**
 * Get the client-side session expiry time
 * @returns {Date|null} Date object representing expiry time or null if not set
 */
export const getClientSessionExpiry = () => {
  try {
    const expiryTime = sessionStorage.getItem('session_expires_at')
    if (expiryTime) {
      const date = new Date(expiryTime)
      if (!isNaN(date.getTime())) {
        return date
      }
    }
    return null
  } catch (e) {
    console.error('Error getting client session expiry:', e)
    return null
  }
}
