import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { isNotNil } from 'utils/ramda'
import { CircularProgress } from '@mui/material'
import { getFileViewedLogs } from 'services/fileService'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import { formatDateWithTime } from 'utils/date'
import { USER_ROLES } from 'utils/userRoles'
import { isEmpty } from 'ramda'

const ViewedStatusModal = ({ file, children }) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false)

  const currentUsers = data?.current || []
  const previousUsers = data?.previous || []

  const getStatusIcon = status => {
    switch (status) {
      case 'viewed':
        return <StyledViewedIcon />
      case 'logged_in':
        return <StyledLoggedIcon />
      case 'not_logged_in':
        return <StyledNotLoggedIcon />
      default:
        return <div>default</div>
    }
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  useEffect(() => {
    if (open) {
      setLoading(true)
      getFileViewedLogs({ fileId: file.id })
        .then(resp => {
          setData(resp.data.data)
          setLoading(false)
        })
        .catch(() => {
          setData([])
          setLoading(false)
        })
    }
  }, [file, open])

  const renderTable = usersData => {

    return (
      <>
        {isNotNil(usersData) && (
          <TableWrapper>
            <table>
              <thead>
              <tr>
                <th>Status</th>
                <th>Name</th>
                <th>Role</th>
                <th>Access</th>
                <th>Date/Time Last Opened</th>
                <th>Number of views</th>
                <th>Downloaded?</th>
              </tr>
              </thead>
              <tbody>
              {usersData.map(row => {
                return (
                  <tr key={row.id}>
                    <td>{getStatusIcon(row.status.value)}</td>
                    <td>{row.firstName} {row.lastName}</td>
                    <td>{USER_ROLES[row.relationType] ? USER_ROLES[row.relationType] : '---'}</td>
                    <td>{row.access}</td>
                    <td>{row.viewedAt ? formatDateWithTime(row.viewedAt) : '---'}</td>
                    <td>{row?.viewsCount || '---'}</td>
                    <td>{row.downloadedAt ? formatDateWithTime(row.downloadedAt) : '---'}</td>
                  </tr>
                )
              })}
              </tbody>
            </table>
          </TableWrapper>
        )
        }
        {isEmpty(usersData)
          ? (
            <EmptyState>
              No users to display
            </EmptyState>
          )
          : null}
      </>
    )
  }

  return (
    <Wrapper>
      <TriggerWrapper onClick={handleOpen}>
        {children}
      </TriggerWrapper>
      {
        open && (
          <Backdrop onClick={handleClose}>
            <ModalContainer onClick={e => e.stopPropagation()}>
              <ModalHeader>
                <Title>Views from user with access to &apos;{file.displayName}&apos;</Title>
                <StyledCloseIcon onClick={handleClose} />
              </ModalHeader>
              <ModalContent>
                <NoteWrapper>
                  NOTE: If you wish to control who has access to this file you can click &apos;Manage
                  Permissions&apos; in the
                  breadcrumb menu next to the file listing in the Documents section.
                </NoteWrapper>
                {
                  loading
                    ? (
                      <LoadingWrapper>
                        <CircularProgress />
                      </LoadingWrapper>
                    )
                    : (
                      <>
                        <TableTitle>Current Users</TableTitle>
                        {renderTable(currentUsers)}
                        <TableTitle>Previous Users</TableTitle>
                        {renderTable(previousUsers)}
                        <Legend>
                          <LegendItem>
                            <StyledNotLoggedIcon />
                            <div>User not opened Building Passport since file added</div>
                          </LegendItem>
                          <LegendItem>
                            <StyledLoggedIcon />
                            <div>User has logged in, but not yet viewed file</div>
                          </LegendItem>
                          <LegendItem>
                            <StyledViewedIcon />
                            <div>User has viewed file</div>
                          </LegendItem>
                        </Legend>
                      </>
                    )
                }
              </ModalContent>
            </ModalContainer>
          </Backdrop>
        )
      }
    </Wrapper>
  )
}

export default ViewedStatusModal

const Wrapper = styled.div`

`

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .4);
  z-index: 999999;
`

const ModalContainer = styled.div`
  width: 95vw;
  max-width: 1200px;
  max-height: 80vh;
  overflow-y: auto;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 9px rgba(0, 0, 0, .03);
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  position: relative;
`

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TriggerWrapper = styled.div`
  cursor: pointer;
`

const ModalContent = styled.div`
  width: 100%;
`

const NoteWrapper = styled.div`
  margin-bottom: 20px;
`

const TableWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};

  table {
    width: 100%;
  }

  thead > tr {
    background: ${({ theme }) => theme.colors.backgroundColor};
  }

  th {
    box-sizing: border-box;
    color: ${({ theme }) => theme.colors.haiti};
    font-size: 11px;
    opacity: .5;
    text-transform: uppercase;
    text-align: left;
    height: 40px;
    padding: 0 10px;
    position: relative;
  }

  td {
    box-sizing: border-box;
    padding: 0 10px;
    height: 56px;
    font-size: 14px;
  }

  tbody > tr {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};

    &:hover {
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.lighterGrey};
    }
  }
`

const Legend = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 30px;
`

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
`

const StyledCloseIcon = styled(CloseIcon)`
  transition: all .3s;
  cursor: pointer;
  font-size: 30px !important;
  color: ${({ theme }) => theme.colors.haiti};
`

const StyledNotLoggedIcon = styled(DoneIcon)`
  font-size: 16px !important;
  color: ${({ theme }) => theme.colors.darkGrey};
`

const StyledLoggedIcon = styled(DoneAllIcon)`
  font-size: 16px !important;
  color: ${({ theme }) => theme.colors.darkGrey};
`

const StyledViewedIcon = styled(DoneAllIcon)`
  font-size: 16px !important;
  color: ${({ theme }) => theme.colors.secondary};
`

const TableTitle = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: bold;
  margin: 10px 0;
`

const EmptyState = styled.div`
  margin-top: 10px;
`
