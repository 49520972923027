import { iterable } from 'utils/array'
import numeral from 'numeral'
import { isNil, propOr, reverse } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'

export const FOLDERS = [
  'guru',
  'floorplans',
  'frs',
  'other',
  'files',
  'notes',
  'model',
  'accessControl',
  'analytics',
  'units',
  'noticeBoard'
]

export const getNoteFloorsOptions = bp => () => {
  const groundFloor = bp.groundFloor
    ? [{ value: 'ground', label: 'Ground floor' }]
    : []
  const lowerGroundFloor = bp.lowerGroundFloor
    ? [{ value: 'lower_ground', label: 'Lower ground floor' }]
    : []
  const underground = iterable(bp.undergroundFloorsNumber).map(
    (element, index) => ({
      value: -(index + 1),
      label: `Basement level ${index + 1}`
    })
  )
  const floors = iterable(bp.floorsNumber).map((element, index) => ({
    value: index + 1,
    label: `${numeral(index + 1).format('Oo')} floor`
  }))

  return [
    { value: 'general', label: 'General note' },
    { value: 'notice', label: 'Notice for all units' },
    ...reverse(floors),
    ...groundFloor,
    ...lowerGroundFloor,
    ...underground
  ]
}

export const getFloorOptions = (bp, short) => {
  let floors = []

  if (bp.undergroundFloorsNumber > 0) {
    for (let i = 1; i <= bp.undergroundFloorsNumber; i++) {
      floors.push({
        label: short ? `${-i}` : `Basement level ${i}`,
        value: `${-i}`
      })
    }
  }

  floors.reverse()

  if (bp.lowerGroundFloor) {
    floors.push({
      label: short ? 'LG' : 'Lower Ground Floor',
      value: `lower_ground`
    })
  }

  if (bp.groundFloor) {
    floors.push({ label: short ? 'G' : 'Ground Floor', value: parseInt('0') })
  }

  if (bp.floorsNumber > 0) {
    for (let i = 1; i <= bp.floorsNumber; i++) {
      floors.push({
        label: short ? i : `${numeral(i).format('oO')} floor`,
        value: `${i}`
      })
    }
  }
  return floors
}

export const getFloorName = floor => {
  switch (true) {
    case floor === 'lower_ground':
      return 'Lower ground floor'
    case floor === '0':
    case floor === 'ground':
      return 'Ground floor'
    case floor < 0:
      return `Basement level ${-floor}`
    case floor > 0:
      return `${numeral(floor).format('Oo')} floor`
    default:
      return ''
  }
}

export const getFileTypeName = (typeId, allTypes, isPrivate = false) => {
  if (isPrivate) {
    return 'Private'
  }
  const found = allTypes.find(e => e.id === typeId)
  return propOr('Uncategorised', 'name', found)
}

export const getBuildingTypeValueType = type => {
  switch (type) {
    case 'assembly':
    case 'recreation':
    case 'leisure':
    case 'infrastructure':
    case 'labs':
      return 'Area (sqm)'
    case 'car_parks':
    case 'storage':
    case 'industrial':
    case 'office':
    case 'retail':
      return 'Lettable area (sqm)'
    case 'residential.dwelling':
      return 'Number of units/apartments/flats etc'
    case 'residential.institutional':
    case 'residential.other':
    case 'residential.dwelling.sole_unit':
    case 'residential':
    case 'hotel':
      return 'Units'
    default:
      return 'Area / Units / Flats'
  }
}

export const getAssignedUsers = bp => {
  return isNotNilOrEmpty(bp)
    ? [
        ...propOr([], 'owners', bp).map(user => ({
          ...user,
          relationType: 'owner'
        })),
        ...propOr([], 'agents', bp).map(user => ({
          ...user,
          relationType: 'agent'
        })),
        ...propOr([], 'occupants', bp).map(user => ({
          ...user,
          relationType: 'occupant'
        })),
        ...propOr([], 'principalAccountablePersons', bp).map(user => ({
          ...user,
          relationType: 'principalAccountablePerson'
        })),
        ...propOr([], 'principalDesigners', bp).map(user => ({
          ...user,
          relationType: 'principalDesigner'
        })),
        ...propOr([], 'responsiblePersons', bp).map(user => ({
          ...user,
          relationType: 'responsiblePerson'
        })),
        ...propOr([], 'prospectivePurchasers', bp)
          .filter(pp => !isNil(pp.firstName))
          .map(user => ({ ...user, relationType: 'prospectivePurchaser' }))
      ]
    : []
}

export const getFloorsIntervals = floors => {
  const sorted = floors ? floors.sort((a, b) => Number(a) - Number(b)) : []

  const intervals = []

  const groundIndex = sorted.indexOf(0)
  const lowerGroundIndex = sorted.indexOf(-0.5)

  if (groundIndex > -1) {
    sorted.splice(groundIndex, 1)
    intervals.push('G')
  }

  if (lowerGroundIndex > -1) {
    sorted.splice(lowerGroundIndex, 1)
    intervals.push('LG')
  }

  let currentIndex = intervals.length > 0 ? intervals.length : 0

  sorted.forEach((floor, index) => {
    if (sorted[index - 1]) {
      Math.abs(floor - sorted[index - 1]) !== 1 && currentIndex++
      const currentInterval = intervals[currentIndex] || []
      intervals[currentIndex] = [...currentInterval, floor]
    } else {
      const currentInterval = intervals[currentIndex] || []
      intervals[currentIndex] = [...currentInterval, floor]
    }
  })

  return intervals.map(el => {
    return typeof el === 'object'
      ? el.length > 1
        ? `${Math.min(...el)} to ${Math.max(...el)}`
        : el[0]
      : el
  })
}
