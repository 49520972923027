import React, { useEffect, useMemo, useState } from 'react'
import Modal from 'components/atoms/Modal'
import styled, { css } from 'styled-components'
import Input from 'components/atoms/Input'
import Select from 'components/atoms/Select'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUserRole } from 'features/auth/ducks/selectors'
import { isNotNil, isNotNilOrEmpty } from 'utils/ramda'
import moment from 'moment'
import { propOr, pick, pathOr } from 'ramda'
import { selectFileCategories } from 'ducks/dictionaries/selectors'
import Collapse from '@mui/material/Collapse'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InputDescription from 'features/createBp/components/atoms/InputDescription'
import FileTypesSearch from 'features/bpDetails/Components/files/FileTypesSearch'
import { updateUnitFileRoutine } from 'ducks/units/actions'
import { getFileTypes } from 'ducks/files/selectors'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import { getBpFilesRoutine, updateFileRoutine } from 'ducks/files/actions'
import Checkbox from 'components/atoms/Checkbox'

const emptyFileValues = {
  displayName: null,
  fileTypeId: null,
  floorNumbers: [],
  producedBy: null,
  creationDate: null,
  legalOwner: null,
  expirationDate: null,
  approvedBy: null,
  expirationReminderDate: null,
  segment: null,
  fileCategory: null,
  dateOfIssue: null
}

const EditFileModal = ({
  open,
  floorOptions,
  onClose,
  file,
  isUnit,
  unit
}) => {
  const dispatch = useDispatch()
  const [modalValues, setValues] = useState({})
  const [fileTypeOpen, setFileTypeOpen] = useState(true)
  const fileTypes = useSelector(getFileTypes)
  const fileCategories = useSelector(selectFileCategories)
  const userRole = useSelector(getCurrentUserRole)
  const bp = useSelector(getSelectedBp)
  const fileVersionId = pathOr('', ['versions', 0, 'id'], file)
  const [additionalOpen, setAdditionalOpen] = useState(false)
  const [wantsReminder, setWantsReminder] = useState(false)

  const toggleAdditionalInfo = () => setAdditionalOpen(prev => !prev)

  const handleValueChange = (name, value) => {
    if (name === 'expirationDate') {
      const subtractSixMonths = moment(value).subtract(6, 'month')
      const isBeforeToday = subtractSixMonths.isBefore(moment())
      const expirationReminderDate = isBeforeToday
        ? moment().add(1, 'day').format('YYYY-MM-DD')
        : subtractSixMonths.format('YYYY-MM-DD')

      setValues(prev => ({
        ...prev,
        [name]: value,
        expirationReminderDate
      }))
    } else {
      setValues(prev => ({ ...prev, [name]: value }))
    }
  }

  const handleCreationDateClear = () => {
    handleValueChange('creationDate', '')
  }

  const handleDateOfIssueClear = () => {
    handleValueChange('dateOfIssue', '')
  }

  const handleExpirationDateClear = () => {
    handleValueChange('expirationDate', '')
    handleValueChange('expirationReminderDate', '')
    setWantsReminder(false)
  }

  const handleReminderDateClear = () => {
    handleValueChange('expirationReminderDate', '')
  }

  const categoriesOptions = useMemo(() => {
    if (fileCategories) {
      const categories =  fileCategories
        .map(type => ({
          label: type.name,
          value: type.id
        }))

      return [
        {
          label: 'All file types',
          value: 'all'
        },
        ...categories
      ]
    }
  }, [fileCategories, userRole])

  const typeOptions = useMemo(() => {
    if (fileTypes && modalValues.fileCategory) {
      const currentCategoryTypes = modalValues.fileCategory === 'all'
        ? fileTypes
        : fileTypes.filter(type => type.fileCategoryId === modalValues.fileCategory)

      const options = currentCategoryTypes
        .map(type => {
          const currentCategory = categoriesOptions.find(category => {
            return category.value === type.fileCategoryId
          })
          return {
            label: type.name,
            value: type.id,
            description: type.description,
            categoryName: propOr('', 'label', currentCategory),
            categoryId: propOr('', 'value', currentCategory)
          }
        })

      const properOptions = unit
        ? options.filter(option => option.label !== 'Building plan')
        : options

      return userRole !== 'emergency_service'
        ? properOptions.filter(option => option.label !== 'FRS floor plan')
        : properOptions
    } else {
      return []
    }
  }, [fileTypes, userRole, modalValues.fileCategory, categoriesOptions])

  const isFloorPlan = useMemo(() => {
    if (modalValues.fileTypeId) {
      const currentType = typeOptions.find(type => type.value === modalValues.fileTypeId)
      const label = propOr('', 'label', currentType)
      return label === 'FRS floor plan' || label === 'Building plan'
    }
  }, [modalValues.fileTypeId])

  useEffect(() => {
    const version = pathOr({}, ['versions', 0], file)
    if (file) {
      const expirationReminderDate = propOr('', 'expirationReminderDate', version)
        ? moment(propOr('', 'expirationReminderDate', version)).format('YYYY-MM-DD')
        : null

      setWantsReminder((isNotNilOrEmpty(expirationReminderDate)))

      setValues({
        ...pick([
          'producedBy',
          'legalOwner',
          'approvedBy',
          'segment',
          'dateOfIssue'
        ], version),
        fileTypeId: pathOr('', ['fileType', 'id'], file),
        fileCategory: pathOr('', ['fileType', 'fileCategoryId'], file),
        displayName: pathOr('', ['displayName'], file),
        floorNumbers: pathOr([], ['floorNumbers'], file),
        expirationDate: propOr(null, 'expirationDate', version) ? moment(propOr('', 'expirationDate', version)).format('YYYY-MM-DD') : null,
        creationDate: propOr('', 'creationDate', version) ? moment(propOr(null, 'creationDate', version)).format('YYYY-MM-DD') : null,
        expirationReminderDate,
        dateOfIssue: propOr('', 'dateOfIssue', version) ? moment(propOr('', 'dateOfIssue', version)).format('YYYY-MM-DD') : null,
      })
    }
  }, [file, open])

  const afterSubmitCallback = () => {
    dispatch(getBpFilesRoutine({ id: bp.id }))
    onClose()
  }

  const handleUpload = e => {
    e.preventDefault()
    if (isUnit) {
      dispatch(updateUnitFileRoutine({
        bpId: bp.id,
        unitId: unit.id,
        fileId: file.id,
        fileVersionId,
        body: {
          ...modalValues,
          floorNumbers: isNotNil(modalValues.floorNumbers) ? modalValues.floorNumbers.map(num => num === 'ground' ? '0' : num) : [],
          displayName: modalValues.displayName,
          expirationReminderDate: wantsReminder ? modalValues.expirationReminderDate : null
        },
        clearValues: afterSubmitCallback
      }))
    } else {
      dispatch(updateFileRoutine({
        bpId: bp.id,
        id: file.id,
        fileVersionId,
        body: {
          ...modalValues,
          floorNumbers: isNotNil(modalValues.floorNumbers) ? modalValues.floorNumbers.map(num => num === 'ground' ? '0' : num) : [],
          displayName: modalValues.displayName,
          expirationReminderDate: wantsReminder ? modalValues.expirationReminderDate : null
        },
        clearValues: afterSubmitCallback
      }))
    }
  }

  useEffect(() => {
    if (!open) {
      setValues(emptyFileValues)
    }
  }, [open])

  const isValid = useMemo(() => {
    const { floorNumbers, fileTypeId, fileCategory, displayName } = modalValues
    return isFloorPlan
      ? [floorNumbers, fileTypeId, fileCategory, displayName].every(e => isNotNilOrEmpty(e))
      : [fileTypeId, fileCategory, displayName].every(e => isNotNilOrEmpty(e))
  }, [modalValues])

  const handleFileTypeSelect = (categoryId, typeId) => {
    setValues(prev => ({
      ...prev,
      fileTypeId: typeId,
      fileCategory: categoryId
    }))
    setFileTypeOpen(false)
  }

  const toggleFileTypeInfo = () => setFileTypeOpen(prev => !prev)

  const searchTypeOptions = useMemo(() => {
    if (fileTypes) {
      const options = fileTypes
        .map(type => {
          const currentCategory = categoriesOptions.find(category => {
            return category.value === type.fileCategoryId
          })
          return {
            label: type.name,
            value: type.id,
            description: type.description,
            categoryName: propOr('', 'label', currentCategory),
            categoryId: propOr('', 'value', currentCategory)
          }
        })
      return userRole !== 'emergency_service'
        ? options.filter(option => option.label !== 'FRS floor plan')
        : options
    } else {
      return []
    }
  }, [fileTypes, userRole, categoriesOptions])

  return (
    <Modal
      title='File information'
      open={open}
      onClose={onClose}
    >
      <FormWrapper>
        <CollapseTitle
          onClick={toggleFileTypeInfo}
        >
          <div>
            File type
          </div>
          <StyledExpandIcon open={fileTypeOpen} />
        </CollapseTitle>
        <Collapse in={fileTypeOpen}>
          <FileTypeWrapper>
            <p>Specify file type</p>
            <FileTypesSearch typeOptions={searchTypeOptions} onFileTypeSelect={handleFileTypeSelect} />
            <p>or browse by category</p>
            <Select
              name='fileCategory'
              options={categoriesOptions}
              value={modalValues.fileCategory}
              placeholder='File category'
              label='Category'
              onChange={(name, value) => {
                setValues(prev => ({
                  ...prev,
                  [name]: value,
                  fileTypeId: null
                }))
              }}
            />
            <Select
              name='fileTypeId'
              options={typeOptions}
              value={modalValues.fileTypeId}
              label='File type'
              placeholder='File type'
              disabledValues={['Building plan']}
              onChange={(name, value) => {
                setFileTypeOpen(false)
                setValues(prev => ({
                  ...prev,
                  fileTypeId: value,
                  fileCategory: propOr('', 'categoryId', typeOptions.find(cat => {
                    return cat.value === value
                  }))
                }))
              }}
            />
          </FileTypeWrapper>
        </Collapse>
        {isNotNilOrEmpty(modalValues.fileCategory) && (
          <>
            {
              isFloorPlan && (
                <>
                  <Select
                    name='floorNumbers'
                    placeholder='Floor numbers'
                    options={floorOptions}
                    value={
                      isNotNil(modalValues.floorNumbers)
                        ? modalValues.floorNumbers
                        : []
                    }
                    multiple
                    label='Applicable floors'
                    onChange={handleValueChange}
                    noMargin
                  />
                  <InputDescription margin='5px 0 20px'>
                    Select the floor(s) that this file relates to or
                    <SelectAllFloorsButton
                      onClick={
                        floorOptions
                          ? () => handleValueChange('floorNumbers', floorOptions.map(floor => floor.value))
                          : () => {}
                      }
                    >
                      select all
                    </SelectAllFloorsButton>
                  </InputDescription>
                </>
              )
            }
            <Input
              label='Title of file'
              value={modalValues.displayName}
              name='displayName'
              onChange={handleValueChange}
              placeholder='e.g. Fire Risk Assessment'
            />
            <Input
              label='Expiry date'
              value={modalValues.expirationDate}
              name='expirationDate'
              type='date'
              optional
              onChange={handleValueChange}
              additionalLabel={
                isNotNilOrEmpty(modalValues.expirationDate)
                  ? (
                    <ClearTrigger onClick={handleExpirationDateClear}>
                      clear
                    </ClearTrigger>
                  )
                  : null
              }
            />
            <Checkbox
              label='File expiry reminder'
              name='wantsReminder'
              disabled={!isNotNilOrEmpty(modalValues.expirationDate)}
              value={wantsReminder}
              onChange={() => setWantsReminder(prev => !prev)}
            />
            <InputDescription margin='10px 0 20px'>
              Get reminded via email on a specific date that this file is due to expire.
              Every user with access to the Building Passport will receive this email.
              Any user has the ability to turn off this type of reminder in the settings section of the Building Passport.
            </InputDescription>
            <Input
              label='Expiration reminder date'
              disabled={!wantsReminder}
              value={modalValues.expirationReminderDate}
              name='expirationReminderDate'
              type='date'
              onChange={handleValueChange}
              inputProps={{ min: moment().add(1, 'day').format('YYYY-MM-DD') }}
              additionalLabel={
                isNotNilOrEmpty(modalValues.expirationReminderDate)
                  ? (
                    <ClearTrigger onClick={handleReminderDateClear}>
                      clear
                    </ClearTrigger>
                  )
                  : null
              }
            />
            <AdditionalInfoSection>
              <CollapseTitle
                onClick={toggleAdditionalInfo}
              >
                <div>
                  Additional file information <span>(optional)</span>
                </div>
                <StyledExpandIcon open={additionalOpen} />
              </CollapseTitle>
              <Collapse in={additionalOpen}>
                <AdditionalInfoDescription>
                  In order to achieve the highest quality of Building Passport,
                  the following additional file information can be added.
                </AdditionalInfoDescription>
                <Input
                  label='Legal Owner'
                  value={modalValues.legalOwner}
                  name='legalOwner'
                  onChange={handleValueChange}
                  placeholder='Name of the company'
                />
                <Input
                  label='Creator'
                  placeholder='Person who created the document'
                  value={modalValues.producedBy}
                  name='producedBy'
                  onChange={handleValueChange}
                />
                <Input
                  type='date'
                  label='Date of issue'
                  value={modalValues.dateOfIssue}
                  name='dateOfIssue'
                  onChange={handleValueChange}
                  additionalLabel={
                    isNotNilOrEmpty(modalValues.dateOfIssue)
                      ? (
                        <ClearTrigger onClick={handleDateOfIssueClear}>
                          clear
                        </ClearTrigger>
                      )
                      : null
                  }
                />
                <Input
                  type='date'
                  label='Creation date'
                  value={modalValues.creationDate}
                  name='creationDate'
                  onChange={handleValueChange}
                  inputProps={{ max: moment().format('YYYY-MM-DD') }}
                  additionalLabel={
                    isNotNilOrEmpty(modalValues.creationDate)
                      ? (
                        <ClearTrigger onClick={handleCreationDateClear}>
                          clear
                        </ClearTrigger>
                      )
                      : null
                  }
                />
                <Input
                  label='Approved by'
                  value={modalValues.approvedBy}
                  name='approvedBy'
                  onChange={handleValueChange}
                  placeholder='Person who approved the document'
                />
                <Input
                  label='Segment / sheet'
                  value={modalValues.segment}
                  name='segment'
                  onChange={handleValueChange}
                  placeholder='Enter if applicable'
                />
              </Collapse>
            </AdditionalInfoSection>
            <ButtonWrapper>
              <UploadButton
                onClick={handleUpload}
                disabled={!isValid}
              >
                Save
              </UploadButton>
            </ButtonWrapper>
          </>
        )}
      </FormWrapper>
    </Modal>
  )
}

export default EditFileModal

const FormWrapper = styled.div`
  max-width: 340px;
  margin: 0 auto;

`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`

const UploadButton = styled.button`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.secondary};
  height: 30px;
  width: 80%;
  transition: all .3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
  }

  ${({ disabled, theme }) => disabled && css`
    border-color: ${theme.colors.grey[700]};
    color: ${theme.colors.grey[700]};

    &:hover {
      background-color: transparent;
      color: ${({ theme }) => theme.colors.grey[700]};
    }
  `}
`

const CollapseTitle = styled.div`
  padding: 15px 0;
  margin-bottom: 20px;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[500]};
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    color: ${({ theme }) => theme.colors.grey[700]};
  }
`

const StyledExpandIcon = styled(ExpandMoreIcon)`
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.grey[700]};
  transition: all .3s;
  transform: rotate(${({ open }) => open ? '180deg' : '0'});
`

const FileTypeWrapper = styled.div`
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.lighterGrey};
  border: 1px solid ${({ theme }) => theme.colors.grey[600]};
  margin-bottom: 20px;

  p {
    margin-bottom: 10px;
  }
`

const SelectAllFloorsButton = styled.span`
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.secondary};
`

const AdditionalInfoSection = styled.div`
  margin: 20px 0 10px;
`

const AdditionalInfoDescription = styled.div`
  margin-bottom: 25px;
`

const ClearTrigger = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.secondary};
`
