import React, { useEffect, useState } from 'react'
import { AddButton, MainContentPanel } from 'features/bpDetails/BpDetails'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { isEmpty, pathOr } from 'ramda'
import { getFileTypeName } from 'utils/bpData'
import { getFileTypes, selectBpFilesList } from 'ducks/files/selectors'
import File from 'features/bpDetails/Components/File'
import Select from 'components/atoms/Select'
import { selectFileCategories } from 'ducks/dictionaries/selectors'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import useDebounce from 'utils/hooks/useDebounce'
import { CircularProgress } from '@mui/material'
import FileTagsPanel from 'features/bpDetails/Components/files/FileTagsPanel'
import BackButton from 'components/atoms/BackButton'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import AddMultipleFilesModal from 'features/bpDetails/Components/files/AddMultipleFilesModal'

const FilesFolder = () => {
  const bp = useSelector(getSelectedBp)
  const categories = useSelector(selectFileCategories)
  const files = useSelector(selectBpFilesList)
  const [filteredFiles, setFilteredFiles] = useState([])
  const [tagsFilteringType, setTagsFilteringType] = useState('any')
  const fileTypes = useSelector(getFileTypes)
  const [selectedCategory, setSelectedCategory] = useState('all')
  const [activeTags, setActiveTags] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSearchQuery = useDebounce(searchQuery, 500)
  const [loading] = useState(false)

  const informationExchangeCategoryName = 'FRS Information Exchange'
  const categoriesOptions = categories
    .filter(cat => cat.name !== informationExchangeCategoryName)
    .map(type => ({
      label: type.name,
      value: type.id
    }))

  useEffect(() => {
    const isSearching = debouncedSearchQuery.length >= 3
    const hasCategory = selectedCategory !== 'all'
    const hasActiveTag = isNotNilOrEmpty(activeTags)

    let result = []

    if (isSearching && hasCategory) {
      result = files.filter(file => {
        const categoryId = file?.fileType?.fileCategoryId || ''

        const parsedQuery = debouncedSearchQuery
          .replace(/\s/g, '')
          .toLowerCase()
        const parsedName =
          file.displayName?.replace(/\s/g, '').toLowerCase() || ''

        return (
          parsedName.includes(parsedQuery) && categoryId === selectedCategory
        )
      })
    }

    if (isSearching && !hasCategory) {
      result = files.filter(file => {
        const parsedQuery = debouncedSearchQuery
          .replace(/\s/g, '')
          .toLowerCase()
        const parsedName =
          file.displayName?.replace(/\s/g, '').toLowerCase() || ''

        return parsedName.includes(parsedQuery)
      })
    }

    if (!isSearching && hasCategory) {
      result = files.filter(file => {
        const categoryId = file?.fileType?.fileCategoryId || ''

        return categoryId === selectedCategory
      })
    }

    if (!isSearching && !hasCategory) {
      result = files
    }

    if (hasActiveTag) {
      if (tagsFilteringType === 'any') {
        result = result.filter(file => {
          const fileTags = file?.taggings || []
          const fileVersions = file?.versions || []

          const fileHasTag = fileTags.some(tag =>
            activeTags.includes(tag.tag.id)
          )
          const versionHasTag = fileVersions.some(version => {
            const versionTags = version?.taggings || []
            return versionTags.some(tag => activeTags.includes(tag.tag.id))
          })

          return fileHasTag || versionHasTag
        })
      } else if (tagsFilteringType === 'all') {
        result = result.filter(file => {
          const fileTags = (file?.taggings || []).map(t => t.tag.id)
          const fileVersions = file?.versions || []

          const fileHasTag = activeTags.every(t => fileTags.includes(t))

          const versionHasTag = fileVersions.some(version => {
            const versionTags = (version?.taggings || []).map(t => t.tag.id)

            return activeTags.every(t => versionTags.includes(t))
          })

          return fileHasTag || versionHasTag
        })
      }
    }

    setFilteredFiles(
      result.length > 1
        ? result.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        : result
    )
  }, [
    selectedCategory,
    debouncedSearchQuery,
    files,
    activeTags,
    tagsFilteringType
  ])

  const handleCategoryChange = (_, value) => {
    setSelectedCategory(value)
  }

  const handleSearch = e => {
    setSearchQuery(e.target.value)
  }

  const handleTagsChange = (tags, type) => {
    setActiveTags(tags)
    setTagsFilteringType(type)
  }

  return (
    <FilesFolderWrapper>
      <MainContentPanel>
        <PanelHeaderWrapper>
          <PanelTitle>Building Passport Files</PanelTitle>
          <BackButton to={`/bp-list/${bp.id}/other`}>go back</BackButton>
        </PanelHeaderWrapper>
        <FileTagsPanel onSortingChange={handleTagsChange} />
        <AddFileButtonWrapper>
          <AddMultipleFilesModal>
            <AddButton>+ Add new files</AddButton>
          </AddMultipleFilesModal>
        </AddFileButtonWrapper>
        <FiltersWrapper>
          <SearchInputWrapper>
            <SearchInputIcon>
              <StyledSearchIcon />
            </SearchInputIcon>
            <StyledInput
              placeholder='Search for files...'
              value={searchQuery}
              onChange={handleSearch}
            />
            {!isEmpty(searchQuery) && (
              <StyledClearIcon onClick={() => setSearchQuery('')} />
            )}
          </SearchInputWrapper>
          <Select
            noMargin
            name='category'
            value='all'
            options={[
              { label: 'All categories', value: 'all' },
              ...categoriesOptions
            ]}
            onChange={handleCategoryChange}
          />
        </FiltersWrapper>
        <>
          {loading ? (
            <LoadingWrapper>
              <CircularProgress />
            </LoadingWrapper>
          ) : (
            <>
              <FilesWrapper>
                {isEmpty(filteredFiles) ? (
                  <div>No files found</div>
                ) : (
                  filteredFiles.map(file => (
                    <File
                      key={`file-${Math.random() * 99}`}
                      file={file}
                      type={getFileTypeName(
                        pathOr('', ['fileType', 'id'], file),
                        fileTypes,
                        file.isPrivate
                      )}
                      isSharedWithUnits={file.isSharedWithUnits}
                    />
                  ))
                )}
              </FilesWrapper>
            </>
          )}
        </>
      </MainContentPanel>
    </FilesFolderWrapper>
  )
}

export default FilesFolder

const FilesFolderWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: relative;
`

const FilesWrapper = styled.div`
  box-sizing: border-box;
  max-width: 100%;
`

const PanelHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  text-transform: uppercase;
`

const AddFileButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
`

const PanelTitle = styled.div`
  text-transform: uppercase;
  font-size: 14px;
`

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 30px;
`

const StyledInput = styled.input`
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  line-height: 16px;
  font-size: 16px;
  flex-grow: 2;
  padding-right: 20px;
  height: 100%;
`

const SearchInputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  input {
    padding: 0;
  }
`

const SearchInputIcon = styled.div`
  position: relative;
  top: 2px;
  margin-right: 15px;
  color: rgba(152, 152, 152, 0.61);
`

const StyledClearIcon = styled(ClearIcon)`
  cursor: pointer;
  position: absolute;
  right: 0;
  padding: 10px;
  font-size: 16px;
  color: rgba(152, 152, 152, 0.61);
`

const StyledSearchIcon = styled(SearchIcon)`
  font-size: 25px !important;
`

const LoadingWrapper = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
