import React, { useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { SectionHeader, SectionHeaderWrapper } from 'features/bpDetails/BpDetails'
import { PRIVATE_PATHS, redirect } from 'utils/paths'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import Collapse from '@mui/material/Collapse'
import { isNotNilOrEmpty } from 'utils/ramda'
import AnalyticProgressBar from 'features/analytics/compontents/AnalyticProgressBar'
import { ReactComponent as AnalyticsIcon } from 'assets/images/analytics.svg'

const AnalyticsBox = ({ bp }) => {
  const [open, setOpen] = useState(true)

  const templatesSortedByType = useMemo(() => {
    return isNotNilOrEmpty(bp.analyticsTemplates)
      ? [
        ...bp.analyticsTemplates.filter(template => template.type === 'default'),
        ...bp.analyticsTemplates.filter(template => template.type === 'custom')
      ]
      : []
  }, [bp])

  return (
    <AnalyticsBoxWrapper>
      <SectionHeaderWrapper withBorder={open} noMargin>
        <SectionHeader>
          Analytics
        </SectionHeader>
        <ActionsWrapper>
          <CollapseIcon
            open={open}
            onClick={() => setOpen(prev => !prev)}
          />
        </ActionsWrapper>
      </SectionHeaderWrapper>
      <Collapse in={open}>
        <AnalyticsContent open={open}>
          {
            templatesSortedByType.map((template, index) => (
              <AnalyticProgressBar
                key={`analytic-bar-${template.id}-${index}`}
                template={template}
                bp={bp}
                isDetailsView
              />
            ))
          }
        </AnalyticsContent>
        <RedirectButtonWrapper>
          <RedirectButton
            onClick={() => redirect(PRIVATE_PATHS.analytics)}
          >
            <AnalyticsIcon /> go to analytics overview
          </RedirectButton>
        </RedirectButtonWrapper>
      </Collapse>
    </AnalyticsBoxWrapper>
  )
}

export default AnalyticsBox

const AnalyticsBoxWrapper = styled.div`
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px 25px;
  box-shadow: 0 2px 9px rgba(0, 0, 0, .03);
  border-radius: 4px;
  width: 100%;
  margin-top: 30px;
`

export const RedirectButton = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: .3px;
  display: flex;
  align-items: center;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
    margin-right: 5px;
    padding-bottom: 2px;
  }
`

const CollapseIcon = styled(KeyboardArrowDownOutlinedIcon)`
  transition: all .3s;
  cursor: pointer;
  transform: rotate(${({ open }) => open ? '180deg': '0'});
  margin-left: 10px;
`

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`

const AnalyticsContent = styled.div`
  padding: 0;
  transition: all .3s;

  ${({ open }) => open && css`
    padding-top: 20px;
  `}
`

const RedirectButtonWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
