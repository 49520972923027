import React from 'react'
import { useSelector } from 'react-redux'
import LinearProgress from '@mui/material/LinearProgress'
import styled from 'styled-components'
import { selectGlobalLoader } from 'ducks/loaderSelectors'
import withStyles from '@mui/styles/withStyles';

const ProgressBar = () => {
  const isLoading = useSelector(selectGlobalLoader)

  const StyledLinearProgress = withStyles({
    colorPrimary: {
      backgroundColor: '#ade5eb'
    },
    barColorPrimary: {
      backgroundColor: '#33becc'
    }
  })(LinearProgress)

  return isLoading && (
    <GlobalLoader>
      <StyledLinearProgress color='primary' />
    </GlobalLoader>
  )
}

const GlobalLoader = styled.div`
  position: fixed;
  height: 2px !important;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
`

export default ProgressBar
