import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import Information from 'features/bpDetails/Components/BpInfo/Information'
import Activity from 'features/bpDetails/Components/BpInfo/Activity'
import { useTranslation } from 'react-i18next'
import { isNotNilOrEmpty } from 'utils/ramda'
import Modal from 'components/atoms/Modal'
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined'
import EditAddress from 'features/bpDetails/Components/edit/EditAddress'
import EditGeneralInfo from 'features/bpDetails/Components/edit/EditGeneralInfo'
import EditBuildingTypes from 'features/bpDetails/Components/edit/EditBuildingTypes'
import EditFloors from 'features/bpDetails/Components/edit/EditFloors'
import EditResidents from 'features/bpDetails/Components/edit/EditResidents'
import EditIssues from 'features/bpDetails/Components/edit/EditIssues'
import { useSelector } from 'react-redux'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import AddAssignmentModal from 'features/accessControl/components/AddAssignmentModal'
import { canEditBp } from 'utils/user'
import { getSelectedBp, selectHasAccessToBp } from 'features/bpDetails/ducks/selectors'

const BpInfo = ({ redirectPath }) => {
  const bp = useSelector(getSelectedBp)
  const [activeTab, setActiveTab] = useState('information')
  const [modalOpen, setModalOpen] = useState(false)
  const [editContentName, setEditContentName] = useState('')
  const currentUser = useSelector(getCurrentUser)
  const hasFullAccess = useSelector(selectHasAccessToBp)
  const canEdit = canEditBp(currentUser, bp)

  const setTab = tabName => () => setActiveTab(tabName)
  const { t } = useTranslation()

  const closeEditModal = () => {
    setEditContentName('')
    setModalOpen(false)
  }

  const openEditModal = name => {
    setEditContentName(name)
    setModalOpen(true)
  }

  const renderTabContent = () => {
    switch (activeTab) {
      case 'activity':
        return <Activity />
      case 'information':
      default:
        return (
          <Information openEditModal={openEditModal} redirectPath={redirectPath} />
        )
    }
  }

  const openGeneralInfoEdit = () => {
    openEditModal('general')
  }

  const modalContent = useMemo(() => {
    switch (editContentName) {
      case 'general':
        return <EditGeneralInfo closeModal={closeEditModal} />
      case 'address':
        return <EditAddress closeModal={closeEditModal} />
      case 'buildingTypes':
        return <EditBuildingTypes closeModal={closeEditModal} />
      case 'floors':
        return <EditFloors closeModal={closeEditModal} />
      case 'residents':
        return <EditResidents closeModal={closeEditModal} />
      case 'issues':
        return <EditIssues closeModal={closeEditModal} />
      case 'owner':
        return <AddAssignmentModal initialUserType='owner' closeModal={closeEditModal} />
      case 'responsiblePerson':
        return <AddAssignmentModal initialUserType='responsible_person' closeModal={closeEditModal} />
      case 'principalAccountablePerson':
        return <AddAssignmentModal initialUserType='principal_accountable_person' closeModal={closeEditModal} />
      case 'principalDesigner':
        return <AddAssignmentModal initialUserType='principal_designer' closeModal={closeEditModal} />
      default:
        return <div />
    }
  }, [editContentName])

  return (
    <div>
      <Header>
        <TitleWrapper>
          <Icon>
            <DescriptionOutlinedIcon />
          </Icon>
          <div>
            <BpName>{bp.name}</BpName>
            <Uprn>UPRN: {
                isNotNilOrEmpty(bp.uprn)
                  ? bp.uprn
                  : 'Not yet allocated'
              }
            </Uprn>
          </div>
        </TitleWrapper>
        {canEdit && <EditIconWrapper onClick={openGeneralInfoEdit}>
          <span>edit</span>
          <EditIcon />
        </EditIconWrapper>}
      </Header>
      {
        hasFullAccess && (
          <TabsWrapper>
            <Tab
              onClick={setTab('information')}
              isactive={(activeTab === 'information').toString()}
            >
              {t('bpDetails.tabs.information')}
            </Tab>
            <Tab
              onClick={setTab('activity')}
              isactive={(activeTab === 'activity').toString()}
            >
              {t('bpDetails.tabs.activity')}
            </Tab>
          </TabsWrapper>
        )
      }
      <ContentWrapper>
        {renderTabContent()}
      </ContentWrapper>
      <Modal
        open={modalOpen}
        title='Edit'
        onClose={closeEditModal}
      >
        {modalContent}
      </Modal>
    </div>
  )
}

export default BpInfo

const TabsWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;

  & > div {
    flex: 1;
  }
`

const Tab = styled.div`
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-color: ${({ theme, isactive }) =>
    isactive === "true" ? theme.colors.secondary : theme.colors.lightGrey};
  padding: 5px 0;
  text-align: center;
  cursor: pointer;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`

const BpName = styled.div`
  font-size: 19px;
  padding-right: 20px;
  line-height: 20px;
`

const Uprn = styled.div`
  font-size: 12px;
  margin-top: 3px;
  text-transform: uppercase;
  opacity: .4;
`

const TitleWrapper = styled.div`
  display: flex;
  padding-right: 30px;
`

const Icon = styled.div`
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  background-color: ${({ theme }) => theme.colors.linen};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px !important;
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 15px;
  border-radius: 4px;
`

const ContentWrapper = styled.div`
  padding: 10px 0;
`

const EditIconWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  align-items: center;
  cursor: pointer;

  span {
    transition: all .3s;
    opacity: 0;
  }

  &:hover {
    span {
      opacity: 1;
      background-color: ${({ theme }) => theme.colors.grey[400]};
    }
  }

  span {
    display: inline-block;
    margin-right: 3px;
    font-size: 13px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.secondary};
  }
`

const EditIcon = styled(CreateOutlinedIcon)`
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.secondary};
`
