import {
  fetchAuthUserRoutine,
  loginUserRoutine,
  registerUserRoutine,
  updateUserRoutine
} from 'features/auth/ducks/actions'
import { getBillingListRoutine } from 'features/billingList/ducks/actions'
import {
  updateBpRoutine,
  removeBpRoutine
} from 'features/createBp/ducks/actions'
import { requestAccessToBpRoutine } from 'features/AccessRequest/ducks/actions'
import {
  fetchUserBpsRoutine,
  fetchFavouritesRoutine
} from 'features/bpList/ducks/actions'
import {
  fetchUnseenNotificationsCounterRoutine,
  fetchAllNotificationsRoutine,
  fetchUnreadNotificationsCounterRoutine
} from 'features/notifications/ducks/actions'
import {
  assignUserToBpRoutine,
  revokeAccessToBpRoutine,
  removeAccessToBpRoutine,
  inviteUserToBpRoutine,
  cancelInvitationRoutine,
  restoreAccessToBpRoutine,
  editUserRelationToBpRoutine,
  getInvitationsListForBpRoutine
} from 'features/accessControl/ducks/actions'
import {
  getAnalyticsTemplatesRoutine,
  createAnalyticsTemplateRoutine,
  updateAnalyticsTemplateRoutine,
  attachAnalyticsTemplateRoutine,
  detachAnalyticsTemplateRoutine,
  getBpsWithAnalyticsRoutine,
  attachAnalyticsTemplateToAllBpsRoutine
} from 'features/analytics/ducks/actions'
import {
  removeFileRoutine,
  getBpFilesRoutine,
  removeFileTagRoutine
} from 'ducks/files/actions'
import {
  addToFavouritesRoutine,
  fetchBpRoutine,
  removeFromFavouritesRoutine
} from 'features/bpDetails/ducks/actions'
import {
  fetchDashboardStatisticsRoutine,
  fetchLastViewedBpsRoutine
} from 'features/dashboard/ducks/actions'

const emptyState = {
  isFetchingAuthUser: false,
  isRegistering: false,
  isLoggingIn: false,
  isFetchingBillingList: false,
  isUploadingFile: false,
  isRemovingFile: false,
  isFetchingBps: false,
  isFetchingFiles: false,
  isFetchingFavouriteBps: false,
  isAddingToFavourites: false,
  isRemovingFromFavourites: false,
  isUpdatingBp: false,
  removeBpRoutine: false,
  isFetchingDashboardStats: false,
  isFetchingSingleBp: false,
  isFetchingInvitations: false,
  accessControlLoading: false,
  isRequestingAccess: false,
  isUpdatingUser: false,
  templatesLoading: false,
  fetchingBpsWithAnalytics: false,
  isRemovingFileTag: false
}

export default (state = emptyState, action) => {
  switch (action.type) {
    case fetchAuthUserRoutine.REQUEST:
      return {
        ...state,
        isFetchingAuthUser: true
      }
    case fetchAuthUserRoutine.SUCCESS:
    case fetchAuthUserRoutine.FAILURE:
      return {
        ...state,
        isFetchingAuthUser: false
      }
    case loginUserRoutine.REQUEST:
      return {
        ...state,
        isLoggingIn: true
      }
    case loginUserRoutine.SUCCESS:
    case loginUserRoutine.FAILURE:
      return {
        ...state,
        isLoggingIn: false
      }
    case registerUserRoutine.REQUEST:
      return {
        ...state,
        isRegistering: true
      }
    case registerUserRoutine.SUCCESS:
    case registerUserRoutine.FAILURE:
      return {
        ...state,
        isRegistering: false
      }
    case getBillingListRoutine.REQUEST:
      return {
        ...state,
        isFetchingBillingList: true
      }
    case getBillingListRoutine.SUCCESS:
    case getBillingListRoutine.FAILURE:
      return {
        ...state,
        isFetchingBillingList: false
      }
    case removeFileRoutine.REQUEST:
      return {
        ...state,
        isRemovingFile: true
      }
    case removeFileRoutine.SUCCESS:
    case removeFileRoutine.FAILURE:
      return {
        ...state,
        isRemovingFile: false
      }
    case fetchUserBpsRoutine.REQUEST:
      return {
        ...state,
        isFetchingBps: true
      }
    case fetchUserBpsRoutine.SUCCESS:
    case fetchUserBpsRoutine.FAILURE:
      return {
        ...state,
        isFetchingBps: false
      }
    case fetchFavouritesRoutine.REQUEST:
      return {
        ...state,
        isFetchingFavouriteBps: true
      }
    case fetchFavouritesRoutine.SUCCESS:
    case fetchFavouritesRoutine.FAILURE:
      return {
        ...state,
        isFetchingFavouriteBps: false
      }
    case updateUserRoutine.REQUEST:
      return {
        ...state,
        isUpdatingUser: true
      }
    case updateUserRoutine.SUCCESS:
    case updateUserRoutine.FAILURE:
      return {
        ...state,
        isUpdatingUser: false
      }
    case addToFavouritesRoutine.REQUEST:
      return {
        ...state,
        isAddingToFavourites: true
      }
    case addToFavouritesRoutine.SUCCESS:
    case addToFavouritesRoutine.FAILURE:
      return {
        ...state,
        isAddingToFavourites: false
      }
    case removeFromFavouritesRoutine.REQUEST:
      return {
        ...state,
        isRemovingFromFavourites: true
      }
    case removeFromFavouritesRoutine.SUCCESS:
    case removeFromFavouritesRoutine.FAILURE:
      return {
        ...state,
        isRemovingFromFavourites: false
      }
    case updateBpRoutine.REQUEST:
      return {
        ...state,
        isUpdatingBp: true
      }
    case updateBpRoutine.SUCCESS:
    case updateBpRoutine.FAILURE:
      return {
        ...state,
        isUpdatingBp: false
      }
    case fetchBpRoutine.REQUEST:
      return {
        ...state,
        isFetchingSingleBp: true
      }
    case fetchBpRoutine.SUCCESS:
    case fetchBpRoutine.FAILURE:
      return {
        ...state,
        isFetchingSingleBp: false
      }
    case removeBpRoutine.REQUEST:
      return {
        ...state,
        isRemovingBp: true
      }
    case removeBpRoutine.SUCCESS:
    case removeBpRoutine.FAILURE:
      return {
        ...state,
        isRemovingBp: false
      }
    case getInvitationsListForBpRoutine.REQUEST:
      return {
        ...state,
        isFetchingInvitations: true
      }
    case getInvitationsListForBpRoutine.SUCCESS:
    case getInvitationsListForBpRoutine.FAILURE:
      return {
        ...state,
        isFetchingInvitations: false
      }
    case fetchLastViewedBpsRoutine.REQUEST:
    case fetchDashboardStatisticsRoutine.REQUEST:
      return {
        ...state,
        isFetchingDashboardStats: true
      }
    case fetchDashboardStatisticsRoutine.SUCCESS:
    case fetchDashboardStatisticsRoutine.FAILURE:
    case fetchLastViewedBpsRoutine.SUCCESS:
    case fetchLastViewedBpsRoutine.FAILURE:
      return {
        ...state,
        isFetchingDashboardStats: false
      }
    case fetchAllNotificationsRoutine.REQUEST:
    case fetchUnreadNotificationsCounterRoutine.REQUEST:
    case fetchUnseenNotificationsCounterRoutine.REQUEST:
      return {
        ...state,
        isFetchingNotifications: true
      }
    case fetchUnreadNotificationsCounterRoutine.SUCCESS:
    case fetchUnreadNotificationsCounterRoutine.FAILURE:
    case fetchUnseenNotificationsCounterRoutine.SUCCESS:
    case fetchUnseenNotificationsCounterRoutine.FAILURE:
    case fetchAllNotificationsRoutine.SUCCESS:
    case fetchAllNotificationsRoutine.FAILURE:
      return {
        ...state,
        isFetchingNotifications: false
      }
    case assignUserToBpRoutine.REQUEST:
    case revokeAccessToBpRoutine.REQUEST:
    case removeAccessToBpRoutine.REQUEST:
    case inviteUserToBpRoutine.REQUEST:
    case cancelInvitationRoutine.REQUEST:
    case restoreAccessToBpRoutine.REQUEST:
    case editUserRelationToBpRoutine.REQUEST:
      return {
        ...state,
        accessControlLoading: true
      }
    case assignUserToBpRoutine.SUCCESS:
    case revokeAccessToBpRoutine.SUCCESS:
    case removeAccessToBpRoutine.SUCCESS:
    case inviteUserToBpRoutine.SUCCESS:
    case cancelInvitationRoutine.SUCCESS:
    case restoreAccessToBpRoutine.SUCCESS:
    case editUserRelationToBpRoutine.SUCCESS:
    case assignUserToBpRoutine.FAILURE:
    case revokeAccessToBpRoutine.FAILURE:
    case removeAccessToBpRoutine.FAILURE:
    case inviteUserToBpRoutine.FAILURE:
    case cancelInvitationRoutine.FAILURE:
    case restoreAccessToBpRoutine.FAILURE:
    case editUserRelationToBpRoutine.FAILURE:
      return {
        ...state,
        accessControlLoading: false
      }
    case requestAccessToBpRoutine.REQUEST:
      return {
        ...state,
        isRequestingAccess: true
      }
    case requestAccessToBpRoutine.SUCCESS:
    case requestAccessToBpRoutine.FAILURE:
      return {
        ...state,
        isRequestingAccess: false
      }
    case getAnalyticsTemplatesRoutine.REQUEST:
    case createAnalyticsTemplateRoutine.REQUEST:
    case updateAnalyticsTemplateRoutine.REQUEST:
    case attachAnalyticsTemplateRoutine.REQUEST:
    case detachAnalyticsTemplateRoutine.REQUEST:
    case attachAnalyticsTemplateToAllBpsRoutine.REQUEST:
      return {
        ...state,
        templatesLoading: true
      }
    case getAnalyticsTemplatesRoutine.SUCCESS:
    case getAnalyticsTemplatesRoutine.FAILURE:
    case createAnalyticsTemplateRoutine.SUCCESS:
    case createAnalyticsTemplateRoutine.FAILURE:
    case updateAnalyticsTemplateRoutine.SUCCESS:
    case updateAnalyticsTemplateRoutine.FAILURE:
    case attachAnalyticsTemplateRoutine.SUCCESS:
    case attachAnalyticsTemplateRoutine.FAILURE:
    case detachAnalyticsTemplateRoutine.SUCCESS:
    case detachAnalyticsTemplateRoutine.FAILURE:
    case attachAnalyticsTemplateToAllBpsRoutine.SUCCESS:
    case attachAnalyticsTemplateToAllBpsRoutine.FAILURE:
      return {
        ...state,
        templatesLoading: false
      }
    case getBpsWithAnalyticsRoutine.REQUEST:
      return {
        ...state,
        fetchingBpsWithAnalytics: true
      }
    case getBpsWithAnalyticsRoutine.SUCCESS:
    case getBpsWithAnalyticsRoutine.FAILURE:
      return {
        ...state,
        fetchingBpsWithAnalytics: false
      }
    case getBpFilesRoutine.REQUEST:
      return {
        ...state,
        isFetchingFiles: true
      }
    case getBpFilesRoutine.SUCCESS:
    case getBpFilesRoutine.FAILURE:
      return {
        ...state,
        isFetchingFiles: false
      }
    case removeFileTagRoutine.REQUEST:
      return {
        ...state,
        isRemovingFileTag: true
      }
    case removeFileTagRoutine.SUCCESS:
    case removeFileTagRoutine.FAILURE:
      return {
        ...state,
        isRemovingFileTag: false
      }
    default:
      return state
  }
}
