import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import useDebounce from 'utils/hooks/useDebounce'
import { isEmpty } from 'ramda'
import { useDispatch } from 'react-redux'
import { clearSearchedBpsListRoutine, searchInAllBpsRoutine } from 'features/AccessRequest/ducks/actions'

export const AllBpsSearchInput = ({
  disabled = false,
  setIsSearching,
  placeholder = 'Search Building Passports (at least 3 characters)',
  ...props
}) => {
  const [value, setValue] = useState('')
  const dispatch = useDispatch()
  const debouncedValue = useDebounce(value, 500)

  const handleClearValue = () => setValue('')
  const handleInputChange = e => setValue(e.target.value)

  useEffect(() => {
    if (debouncedValue.length >= 3) {
      dispatch(searchInAllBpsRoutine({
        query: debouncedValue
      }))
      setIsSearching(true)
    } else {
      dispatch(clearSearchedBpsListRoutine())
      setIsSearching(false)
    }
  }, [debouncedValue])

  return (
    <SearchInputContainer disabled={disabled}>
      <InputWrapper>
        <SearchInputIcon>
          <StyledSearchIcon />
        </SearchInputIcon>
        <StyledInput
          {...props}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onChange={handleInputChange}
        />
        {!isEmpty(value) && <StyledClearIcon onClick={handleClearValue} />}
      </InputWrapper>
    </SearchInputContainer>
  )
}

export default AllBpsSearchInput

const StyledInput = styled.input`
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  line-height: 16px;
  font-size: 16px;
  flex-grow: 2;
  padding-right: 20px;
  height: 100%;
`

const SearchInputContainer = styled.div`
  border-radius: 4px;
  height: 100%;
  box-sizing: border-box;
  opacity: ${props => (props.disabled ? '.5' : '1')};
  width: 100%;
  display: flex;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 10px 0;
`

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 15px;
`

const SearchInputIcon = styled.div`
  position: relative;
  top: 2px;
  margin-right: 15px;
  color: rgba(152, 152, 152, 0.61);
`

const StyledClearIcon = styled(ClearIcon)`
  cursor: pointer;
  position: absolute;
  right: 0;
  padding: 10px;
  font-size: 16px;
  color: rgba(152, 152, 152, 0.61);
`

const StyledSearchIcon = styled(SearchIcon)`
  font-size: 25px !important;
`
