import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Collapse from '@mui/material/Collapse'
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined'
import Flex from 'components/atoms/Flex'
import { isNil } from 'ramda'
import { useSelector } from 'react-redux'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import { canEditBp } from 'utils/user'

const DetailsSection = ({
  label,
  children,
  expandable,
  disabled,
  openEditModal,
  name,
  bp,
  hideIcon
}) => {
  const [expanded, setExpanded] = useState(!expandable)
  const currentUser = useSelector(getCurrentUser)

  const canEdit = canEditBp(currentUser, bp)

  const handleClick = () => {
    return (expandable && !disabled)
      ? setExpanded(prev => !prev)
      : {}
  }

  const handleEditModalOpen = e => {
    e.stopPropagation()
    openEditModal(name)
  }

  return (
    <Wrapper>
      <Header onClick={handleClick} expandable={expandable} disabled={disabled}>
        {label}
        <Flex>
          {
            !isNil(openEditModal) && canEdit && !hideIcon && (
              <EditIconWrapper onClick={handleEditModalOpen}>
                <span>edit</span>
                <EditIcon />
              </EditIconWrapper>
            )
          }
          {expandable && !disabled && <ExpandIcon expanded={expanded} />}
        </Flex>
      </Header>
      {
        <Collapse in={expanded && !disabled}>
          <Content>
            {children}
          </Content>
        </Collapse>
      }
    </Wrapper>
  )
}

export default DetailsSection

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  color: ${({ theme, disabled }) => disabled ? theme.colors.grey[700] : theme.colors.haiti};
  border-bottom: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: .3px;
  cursor: ${({ expandable, disabled }) => (expandable && !disabled) ? 'pointer' : 'default'};
`

const Content = styled.div`
  padding: 10px 0 10px 5px;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.haiti};
`

const ExpandIcon = styled(ExpandMoreIcon)`
  margin-left: 5px;
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.haiti};
  transition: all .3s;
  transform: rotate(0);

  ${({ expanded }) => expanded && css`
    transform: rotate(-180deg);
  `}
`

const EditIconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    transition: all .3s;
    opacity: 0;
  }

  &:hover {
    span {
      opacity: 1;
    }
  }

  span {
    display: inline-block;
    margin-right: 3px;
    font-size: 13px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.secondary};
  }
`

const EditIcon = styled(CreateOutlinedIcon)`
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.secondary};
`
