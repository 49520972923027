import React, { useState } from 'react'
import Modal from 'components/atoms/Modal'
import { MenuItem } from '@mui/material'
import { useDispatch } from 'react-redux'
import { pathOr } from 'ramda'
import { shareFileRoutine } from 'ducks/files/actions'

const UnshareFileModal = ({ bp, file }) => {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const isFloorPlan = pathOr('', ['fileType', 'name'], file) === 'Building plan'

  const handleModalOpen = e => {
    e.stopPropagation()
    e.preventDefault()
    setOpen(true)
  }
  const handleModalClose = e => {
    e.stopPropagation()
    e.preventDefault()
    setOpen(false)
  }

  const handleSubmit = e => {
    e.stopPropagation()
    e.preventDefault()

    dispatch(shareFileRoutine({
      fileId: file.id,
      buildingUnitPassportsIds: [],
      bpId: bp.id
    }))
  }

  return (
    <>
      <MenuItem
        disabled={isFloorPlan || !file.isSharedWithUnits}
        onClick={handleModalOpen}
      >
        Unshare
      </MenuItem>
      <Modal
        open={open}
        title='Unshare file'
        withSubmit
        withCancel
        onClose={handleModalClose}
        onCancel={handleModalClose}
        onSubmit={handleSubmit}
      >
        Do you really want to unshare this file from all units?
      </Modal>
    </>
  )
}

export default UnshareFileModal
