import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Modal from 'components/atoms/Modal'
import Button from 'components/atoms/Button'
import PrimaryButton from 'components/atoms/PrimaryButton'
import moment from 'moment'

const ModalDateInput = ({
  name,
  value,
  onChange,
  noMargin = false,
  disabled = false,
  title = 'Set Expiration Date',
  icon
}) => {
  const [open, setOpen] = useState(false)
  const [date, setDate] = useState(value)

  useEffect(() => {
    setDate(value)
  }, [value])

  const handleSave = () => {
    onChange(date)
    setOpen(false)
  }

  return (
    <>
      <TriggerWrapper
        onClick={() => !disabled && setOpen(true)}
        noMargin={noMargin}
      >
        {date ? (
          <DateText>{moment(date).format('DD-MM-YYYY')}</DateText>
        ) : (
          <IconWrapper disabled={disabled}>{icon}</IconWrapper>
        )}
      </TriggerWrapper>
      <Modal open={open} onClose={() => setOpen(false)} title={title}>
        <ModalContent>
          <ModalDesc>Select an expiration date:</ModalDesc>
          <DateInput
            type='date'
            value={date}
            onChange={e => setDate(e.target.value)}
            disabled={disabled}
          />
          <ButtonsWrapper>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <PrimaryButton onClick={handleSave}>Accept</PrimaryButton>
          </ButtonsWrapper>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ModalDateInput

const TriggerWrapper = styled.div`
  cursor: pointer;
  margin: ${props => (props.noMargin ? '0' : 'initial')};
  position: relative;
`

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
`
const DefaultIcon = styled.div`
  font-size: 18px;
`

const ModalContent = styled.div`
  width: 100%;
`
const ModalDesc = styled.div`
  margin-bottom: 20px;
  font-size: 18px;
`
const ButtonsWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 16px;
  & > button {
    flex: 1;
  }
`

const DateInput = styled.input`
  width: 95%;
  padding: 12px;
  font-size: 16px;
  margin-bottom: 20px;
  margin-right: 10px;
`
const DateText = styled.span`
  display: inline-block;
  min-width: 80px;
  padding: 4px 8px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text || '#000'};
  text-align: center;
  border-radius: 4px;
`
