import React, { useCallback, useState } from 'react'
import Menu from '@mui/material/Menu'
import styled from 'styled-components'
import MenuItem from '@mui/material/MenuItem'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUserRole, getUserPhotoUrl, getCurrentUser } from 'features/auth/ducks/selectors'
import InitialsAvatar from 'features/navigation/components/InitialsAvatar'
import { PRIVATE_PATHS, redirect } from 'utils/paths'
import { logoutUserRoutine } from 'features/auth/ducks/actions'
import { pathOr } from 'ramda'

const AvatarMenu = () => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const userPhotoUrl = useSelector(getUserPhotoUrl)
  const logoutUser = useCallback(() => dispatch(logoutUserRoutine()), [dispatch])
  const userRole = useSelector(getCurrentUserRole)
  const isFrsUser = userRole === 'emergency_service'
  const isResponsiblePerson = userRole === 'responsible_person'
  const user = useSelector(getCurrentUser)
  const currentUserSource = pathOr('', ['source', 'value'], user)
  const isFromInfoExchange = currentUserSource === 'info_exchange'

  const openMenu = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const handleItemClick = path => () => {
    handleClose()
    redirect(path)
  }

  return (
    <div>
      <Menu
        keepMounted
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        style={{
          marginTop: '10px'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={open}
        onClose={handleClose}
      >
        {!isFrsUser && !isResponsiblePerson && <MenuItem onClick={handleItemClick(PRIVATE_PATHS.billingList)}>Billing list</MenuItem>}
        <MenuItem onClick={handleItemClick(PRIVATE_PATHS.profileSettings)}>My Profile</MenuItem>
        <MenuItem onClick={logoutUser}>Logout</MenuItem>
      </Menu>
      <AvatarWrapper onClick={openMenu}>
        {
          userPhotoUrl && !isFromInfoExchange
            ? <ImageAvatar src={userPhotoUrl} />
            : <InitialsAvatar />
        }
      </AvatarWrapper>
    </div>
  )
}

export default AvatarMenu

const AvatarWrapper = styled.div`
  cursor: pointer;
`

const ImageAvatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
`
