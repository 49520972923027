import withStyles from '@mui/styles/withStyles';
import Switch from '@mui/material/Switch'
import theme from 'theme/theme'

const Toggle = withStyles({
  switchBase: {
    color: theme.colors.white,
    '&$checked': {
      color: theme.colors.white,
    },
    '&$checked + $track': {
      backgroundColor: theme.colors.secondary,
    },
  },
  checked: {},
  track: {}
})(Switch)

export default Toggle
