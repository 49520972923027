import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import Collapse from '@mui/material/Collapse'
import { isEmpty, pathOr, pick, propOr } from 'ramda'
import { TEMPLATE_ITEMS_NAMES } from 'utils/constants'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import {
  acceptAnalyticsSharingInvitationRoutine,
  rejectAnalyticsSharingInvitationRoutine
} from 'features/analytics/ducks/actions'
import { useDispatch } from 'react-redux'

const SharedTemplate = ({ invitation }) => {
  const [open, setOpen] = useState(false)
  const dataValues = pick(['hasUprn', 'hasAddress', 'hasBuildingTypes', 'hasAgent', 'hasOwner'], invitation)
  const dispatch = useDispatch()

  const valuesToDisplay = useMemo(() => {
    let result = []
    for (const key in dataValues) {
      if (dataValues[key]) {
        result.push(key)
      }
    }
    return result
  }, [dataValues])

  const fileValues = useMemo(() => {
    return propOr([], 'fileTypes', invitation).map(type => type.name)
  }, [invitation])

  const handleAccept = () => {
    dispatch(acceptAnalyticsSharingInvitationRoutine(invitation.id))
  }

  const handleReject = () => {
    dispatch(rejectAnalyticsSharingInvitationRoutine(invitation.id))
  }

  return (
    <SharedTemplateWrapper>
      <TemplateBasicInfo>
        <div>
          <TemplateCreator>
            {pathOr('', ['creator', 'firstName'], invitation)} {pathOr('', ['creator', 'lastName'], invitation)}
          </TemplateCreator>
          <TemplateName>
            {propOr('', 'name', invitation)}
          </TemplateName>
        </div>
        <ActionIcons>
          <CheckIcon onClick={handleAccept}>✓</CheckIcon>
          <CrossIcon onClick={handleReject}>X</CrossIcon>
          <CollapseIcon onClick={() => setOpen(prev => !prev)} />
        </ActionIcons>
      </TemplateBasicInfo>
      <Collapse in={open}>
        {
          !isEmpty(valuesToDisplay) && (
            <>
              <CollapseSectionTitle>
                DATA:
              </CollapseSectionTitle>
              {
                valuesToDisplay.map(item => {
                  return (
                    <CollapseSectionItem key={item}>
                      {TEMPLATE_ITEMS_NAMES[item]}
                    </CollapseSectionItem>
                  )
                })
              }
            </>
          )
        }
        {
          !isEmpty(fileValues) && (
            <>
              <CollapseSectionTitle>
                FILES:
              </CollapseSectionTitle>
              {
                fileValues.map(item => (
                  <CollapseSectionItem key={item}>
                    {item}
                  </CollapseSectionItem>
                ))
              }
            </>
          )
        }
      </Collapse>
    </SharedTemplateWrapper>
  )
}

export default SharedTemplate

const SharedTemplateWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  border-radius: 4px;
  margin: 5px 0;
  padding: 5px;
`

const TemplateName = styled.div`
  font-size: 14px;
`

const TemplateCreator = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.grey[700]};
`

const TemplateBasicInfo = styled.div`
  display: flex;
  justify-content: space-between;
`

const CollapseSectionTitle = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.secondary};
  text-transform: uppercase;
  margin: 5px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.grey[400]};
  padding-top: 5px;
`

const CollapseSectionItem = styled.div`
  font-size: 13px;
`

const ActionIcons = styled.div`
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CollapseIcon = styled(KeyboardArrowDownOutlinedIcon)`
  transition: all .3s;
  cursor: pointer;
  transform: rotate(${({ open }) => open ? '180deg': '0'});
`

const CheckIcon = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 16px;
  padding: 3px;
  cursor: pointer;
`

const CrossIcon = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 16px;
  padding: 3px;
  cursor: pointer;
`
