import { createRoutine } from 'redux-saga-routines'
import {
  put,
  call,
  takeLatest,
  fork,
  delay,
  select,
  race,
  take
} from '@redux-saga/core/effects'
import Echo from 'echo' // Your Echo service

import { logoutUserRoutine } from 'features/auth/ducks/actions'
import { isUserLoggedIn, getCurrentUser } from 'features/auth/ducks/selectors'
import { toast } from 'react-toastify'
import { getSessionRemainingSeconds } from './selectors'
import * as sessionService from 'services/sessionService'
import EchoService from 'echo'
import LocalStorageService from '../../../services/LocalStorageService'
import { KEYS } from '../../../utils/localStorage'

const SESSION_CHECK_INTERVAL = 60000 // Check every minute

export const checkSessionStatusRoutine = createRoutine('CHECK_SESSION_STATUS')
export const refreshSessionRoutine = createRoutine('REFRESH_SESSION')
export const sessionExpiringRoutine = createRoutine('SESSION_EXPIRING')
export const sessionExpiredRoutine = createRoutine('SESSION_EXPIRED')
export const dismissSessionWarningRoutine = createRoutine(
  'DISMISS_SESSION_WARNING'
)
export const initSessionListenerRoutine = createRoutine('INIT_SESSION_LISTENER')

function* initSessionListener() {
  yield put(initSessionListenerRoutine.request())
  try {
    const isLoggedIn = yield select(isUserLoggedIn)
    if (!isLoggedIn) return

    const user = yield select(getCurrentUser)
    const response = yield call(sessionService.checkSession)
    const data = response.data.data || response.data // Handle both formats

    // Ensure we have a valid date
    let expiresAt = null
    try {
      expiresAt = new Date(data.expiresAt)
      // Check if date is valid
      if (isNaN(expiresAt.getTime())) {
        console.error('Invalid expires_at date:', data.expiresAt)
        expiresAt = new Date(Date.now() + 1800000) // Default 30 mins
      }
    } catch (e) {
      console.error('Error parsing expires_at:', e)
      expiresAt = new Date(Date.now() + 1800000) // Default 30 mins
    }

    // Ensure remaining seconds is valid
    const remainingSeconds = Math.max(0, parseInt(data.remainingSeconds) || 0)

    // Convert warning minutes to seconds
    const warningThresholdSeconds = parseInt(data.warningMinutes) * 60 || 300

    yield put(
      initSessionListenerRoutine.success({
        expiresAt,
        remainingSeconds,
        warningThresholdSeconds
      })
    )

    const token = LocalStorageService.get(KEYS.token)

    if (window.Echo && token && user?.id) {
      if (window.echoChannel) {
        try {
          window.echoChannel.unsubscribe()
        } catch (e) {
          console.error('Error unsubscribing from previous channel:', e)
        }
      }

      // Store the channel reference
      window.echoChannel = window.Echo.private(`user.${user.id}`)

      // Listen for the session.expiring event (without dot prefix)
      window.echoChannel
        .listen('.session.expiring', event => {
          try {
            const eventExpiresAt = new Date(event.expiresAt)
            const currentTime = new Date()
            const eventRemainingSeconds = Math.max(
              0,
              Math.floor((eventExpiresAt - currentTime) / 1000)
            )
            const eventWarningThresholdSeconds =
              parseInt(event.warningMinutes) * 60 || 300

            if (eventRemainingSeconds <= eventWarningThresholdSeconds) {
              // Use store.dispatch instead of put
              window.reduxStore.dispatch(
                sessionExpiringRoutine({
                  remainingSeconds: eventRemainingSeconds
                })
              )
            }
          } catch (e) {
            console.error('Error processing session event:', e)
          }
        })
        .error(error => {
          console.error('Error listening to session expiring event:', error)
        })
    } else {
      console.error('Echo instance not found')
    }

    // Start periodic session checking
    yield fork(sessionCheckWatcher)
  } catch (error) {
    console.error('Failed to initialize session listener:', error)
    yield put(initSessionListenerRoutine.failure(error))
  }
}

function* checkSessionStatus() {
  yield put(checkSessionStatusRoutine.request())
  try {
    const isLoggedIn = yield select(isUserLoggedIn)
    if (!isLoggedIn) return

    const response = yield call(sessionService.checkSession)
    const data = response.data.data || response.data // Handle both formats

    // Ensure we have a valid date
    let expiresAt = null
    try {
      expiresAt = new Date(data.expiresAt)
      // Check if date is valid
      if (isNaN(expiresAt.getTime())) {
        console.error('Invalid expires_at date:', data.expiresAt)
        expiresAt = new Date(Date.now() + 1800000) // Default 30 mins
      }
    } catch (e) {
      console.error('Error parsing expires_at:', e)
      expiresAt = new Date(Date.now() + 1800000) // Default 30 mins
    }
    // Ensure remaining seconds is valid
    const remainingSeconds = Math.max(0, parseInt(data.remainingSeconds) || 0)

    // Convert warning minutes to seconds
    const warningThresholdSeconds = parseInt(data.warningMinutes) * 60 || 300

    yield put(
      checkSessionStatusRoutine.success({
        expiresAt,
        remainingSeconds,
        warningThresholdSeconds
      })
    )

    // Check if session is about to expire
    if (remainingSeconds <= warningThresholdSeconds && remainingSeconds > 0) {
      yield put(sessionExpiringRoutine({ remainingSeconds }))
    } else if (remainingSeconds <= 0) {
      yield put(sessionExpiredRoutine())
      yield put(logoutUserRoutine())
    }
  } catch (error) {
    console.error('Failed to check session status:', error)
    yield put(checkSessionStatusRoutine.failure(error))
  }
}

function* refreshSession() {
  yield put(refreshSessionRoutine.request())
  try {
    const isLoggedIn = yield select(isUserLoggedIn)
    if (!isLoggedIn) return

    const response = yield call(sessionService.extendSession)
    const data = response.data.data || response.data // Handle both formats

    // Ensure we have a valid date
    let expiresAt = null
    try {
      expiresAt = new Date(data.expiresAt)
      // Check if date is valid
      if (isNaN(expiresAt.getTime())) {
        console.error('Invalid expires_at date:', data.expiresAt)
        expiresAt = new Date(Date.now() + 1800000) // Default 30 mins
      }
    } catch (e) {
      console.error('Error parsing expires_at:', e)
      expiresAt = new Date(Date.now() + 1800000) // Default 30 mins
    }

    // Ensure remaining seconds is valid
    const remainingSeconds = Math.max(0, parseInt(data.remainingSeconds) || 0)

    // Convert warning minutes to seconds
    const warningThresholdSeconds = parseInt(data.warningMinutes) * 60 || 300

    yield put(
      refreshSessionRoutine.success({
        expiresAt,
        remainingSeconds,
        warningThresholdSeconds
      })
    )

    toast.success('Your session has been extended')
  } catch (error) {
    console.error('Failed to refresh session:', error)
    yield put(refreshSessionRoutine.failure(error))
    toast.error('Failed to extend your session. Please try again.')
  }
}

function* handleSessionExpiring({ payload }) {
  const { remainingSeconds } = payload

  // Wait for user action or session expiry
  const { expired, dismissed, refreshed } = yield race({
    expired: delay(remainingSeconds * 1000),
    dismissed: take(dismissSessionWarningRoutine.TRIGGER),
    refreshed: take(refreshSessionRoutine.TRIGGER)
  })

  if (expired) {
    yield put(sessionExpiredRoutine())
    yield delay(1000) // Small delay before logout
    yield put(logoutUserRoutine())
  }
}

function* sessionCheckLoop() {
  while (true) {
    yield call(checkSessionStatus)
    yield delay(SESSION_CHECK_INTERVAL)
  }
}

// WATCHERS
function* sessionCheckWatcher() {
  yield fork(sessionCheckLoop)
}

function* initSessionListenerWatcher() {
  yield takeLatest(initSessionListenerRoutine.TRIGGER, initSessionListener)
}

function* checkSessionStatusWatcher() {
  yield takeLatest(checkSessionStatusRoutine.TRIGGER, checkSessionStatus)
}

function* refreshSessionWatcher() {
  yield takeLatest(refreshSessionRoutine.TRIGGER, refreshSession)
}

function* sessionExpiringWatcher() {
  yield takeLatest(sessionExpiringRoutine.TRIGGER, handleSessionExpiring)
}

// SAGAS
export const sessionSagas = [
  fork(initSessionListenerWatcher),
  fork(checkSessionStatusWatcher),
  fork(refreshSessionWatcher),
  fork(sessionExpiringWatcher)
]
