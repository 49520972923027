import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import CloseIcon from '@mui/icons-material/Close'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import AssistantOutlinedIcon from '@mui/icons-material/AssistantOutlined'
import { useSelector } from 'react-redux'
import { getSelectedBp } from '../../ducks/selectors'

const AddMultipleFilesModal = ({
  externalBpId,
  children,
  initialCategory,
  initialType,
  unit,
  isPrivate,
  addedFilesCount
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const selectedBp = useSelector(getSelectedBp)
  const bp = useMemo(
    () => ({
      id: externalBpId || (selectedBp && selectedBp.id)
    }),
    [externalBpId, selectedBp]
  )
  const shouldBypassModal = initialCategory && initialType

  const openModal = () => {
    if (shouldBypassModal) {
      navigateToTraditional()
    } else {
      setIsOpen(true)
    }
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const navigateToTraditional = () => {
    let url = `/files/upload/${bp.id}?method=traditional`

    if (initialCategory) url += `&category=${initialCategory}`
    if (initialType) url += `&type=${initialType}`

    const newWindow = window.open(url, '_blank')
    newWindow.focus()
  }

  const handleSelectTraditional = () => {
    navigateToTraditional()
    closeModal()
  }

  const handleSelectAI = () => {
    // Open a new window and navigate to the AI upload route
    const newWindow = window.open(`/files/upload/${bp.id}?method=ai`, '_blank')
    newWindow.focus()
    // Close the modal
    closeModal()
  }

  return (
    <>
      {/* Wrap children in a clickable element that opens the modal */}
      <Trigger onClick={openModal}>{children}</Trigger>

      {/* Only render the modal when isOpen is true */}
      {isOpen && (
        <Backdrop onClick={closeModal}>
          <ModalContainer onClick={e => e.stopPropagation()}>
            <ModalHeader>
              <ModalTitle>Choose Upload Method</ModalTitle>
              <CloseIconWrapper onClick={closeModal}>
                <StyledCloseIcon />
              </CloseIconWrapper>
            </ModalHeader>

            <ModalContent>
              <OptionsContainer>
                <OptionCard onClick={handleSelectTraditional}>
                  <IconWrapper>
                    <CloudUploadIcon />
                  </IconWrapper>
                  <OptionTitle>Traditional Upload</OptionTitle>
                  <OptionDescription>
                    Standard file upload with manual categorisation and
                    organisation.
                  </OptionDescription>
                </OptionCard>

                <OptionCard onClick={handleSelectAI}>
                  <NewBadge>NEW</NewBadge>
                  <IconWrapper ai>
                    <PulsingAIIcon />
                  </IconWrapper>
                  <OptionTitle>AI Smart Upload</OptionTitle>
                  <OptionDescription>
                    Transform your disorganised building files into a clean,
                    organised and searchable repository with our AI assistant.
                  </OptionDescription>
                </OptionCard>
              </OptionsContainer>
            </ModalContent>
          </ModalContainer>
        </Backdrop>
      )}
    </>
  )
}

export default AddMultipleFilesModal
const Trigger = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`
const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div`
  width: 90%;
  max-width: 900px;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
`

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  border-bottom: 1px solid #eaecf0;
  padding: 0 24px;
`

const ModalTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
`

const CloseIconWrapper = styled.div`
  width: fit-content;
  padding: 5px;
  cursor: pointer;
`

const StyledCloseIcon = styled(CloseIcon)`
  font-size: 20px;
  color: #666;

  &:hover {
    color: #000;
  }
`

const ModalContent = styled.div`
  padding: 32px;
`

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const OptionCard = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 32px 24px;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    border-color: #ccc;
  }
`

const IconWrapper = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: ${({ ai, theme }) =>
    ai ? theme.colors.primary || '#33BECC' : '#f2f2f2'};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  svg {
    font-size: 40px;
    color: ${({ ai }) => (ai ? 'white' : '#555')};
  }
`

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.9;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`

const PulsingAIIcon = styled(AssistantOutlinedIcon)`
  font-size: 40px;
  color: white;
  animation: ${pulseAnimation} 2s infinite ease-in-out;
`

const OptionTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #333;
`

const OptionDescription = styled.p`
  font-size: 14px;
  line-height: 1.5;
  color: #666;
`

const NewBadge = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #ff5722;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
`

const UploadButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary || '#33BECC'};
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryDark || '#2986A3'};
  }
`
