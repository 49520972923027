import React, { useState } from 'react'
import Collapse from '@mui/material/Collapse'
import styled from 'styled-components'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const UnitFormPersonSection = ({ children, title }) => {
  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(prev => !prev)

  return (
    <Wrapper>
      <PanelTitle
        onClick={toggleOpen}
      >
        {title}
        <StyledExpandIcon open={open} />
      </PanelTitle>
      <Collapse in={open}>
        {children}
      </Collapse>
    </Wrapper>
  )
}

export default UnitFormPersonSection

const Wrapper = styled.div`
  margin-bottom: 20px;
`

const PanelTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.colors.haiti};
  font-size: 18px;
  letter-spacing: .15px;
  cursor: pointer;
  padding-bottom: 10px;
`

const StyledExpandIcon = styled(ExpandMoreIcon)`
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.grey[700]};
  transition: all .3s;
  transform: rotate(${({ open }) => open ? '180deg' : '0'});
`
