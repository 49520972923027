import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import { ReactComponent as FileIcon } from 'assets/images/file-icon.svg'
import InfoIcon from '@mui/icons-material/Info'
import Tooltip from '@mui/material/Tooltip'
const MarkdownContainer = styled.div`
  line-height: 1.5;

  p {
    margin-top: 0;
    margin-bottom: 10px;
  }

  code {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 2px 4px;
    border-radius: 3px;
    font-family: monospace;
  }

  pre {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 12px;
    border-radius: 5px;
    overflow-x: auto;
  }

  a {
    color: ${({ theme }) => theme.colors.primary};
  }

  ul,
  ol {
    padding-left: 20px;
  }

  /* Style for highlighted terms */
  strong,
  em {
    font-style: normal;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.secondary};
    background-color: ${({ theme }) => theme.colors.secondary + '15'};
    padding: 0 3px;
    border-radius: 3px;
  }
`

const MarkdownRenderer = ({
  content,
  sources = null,
  onDocumentClick = undefined
}) => {
  const [expandedDocument, setExpandedDocument] = useState(null)

  const handleItemClick = (source, index) => {
    setExpandedDocument(expandedDocument === index ? null : index)
  }

  const handleViewDocumentClick = (source, e) => {
    if (e) {
      e.stopPropagation()
    }

    if (onDocumentClick) {
      onDocumentClick(source)
    }
  }

  // Process and format markdown content
  const processContent = () => {
    if (!content) return null

    // Replace HTML tags with markdown formatting
    return content.replace(/<em>(.*?)<\/em>/g, '**$1**')
  }
  const isSourceClickable = source => {
    return !!(source.url || source.fileUrl || source.path)
  }
  return (
    <MarkdownContainer>
      {/* Render the content directly with ReactMarkdown */}
      <ReactMarkdown>{processContent()}</ReactMarkdown>

      {sources && sources.length > 0 && (
        <SourcesContainer>
          <SourcesHeading>Sources:</SourcesHeading>
          <SourcesList>
            {/* Filter out duplicate sources */}
            {[...new Map(sources.map(s => [s.name, s])).values()].map(
              (source, index) => (
                <React.Fragment key={index}>
                  <Tooltip
                    title={
                      isSourceClickable(source)
                        ? ''
                        : 'This document is not available for preview'
                    }
                    placement='top'
                    arrow
                  >
                    <div>
                      <SourceItem
                        onClick={() => handleItemClick(source, index)}
                        isExpanded={expandedDocument === index}
                        isClickable={isSourceClickable(source)}
                      >
                        <SourceIconContainer>
                          <FileIcon />
                        </SourceIconContainer>
                        <SourceName>{source.name}</SourceName>
                        <SourceType>{source.type || 'Document'}</SourceType>
                        {!isSourceClickable(source) && (
                          <UnavailableIcon>
                            <InfoIcon fontSize='small' />
                          </UnavailableIcon>
                        )}
                      </SourceItem>
                    </div>
                  </Tooltip>

                  {expandedDocument === index && (
                    <SourcePreview>
                      {isSourceClickable(source) ? (
                        <>
                          {/* Thumbnail container */}
                          {source.thumbnailUrl ||
                          (source.type?.toLowerCase().includes('pdf') &&
                            source.url) ? (
                            <ThumbnailContainer>
                              <FileThumbnail
                                src={source.thumbnailUrl || source.url}
                                alt={source.name}
                                objectFit={
                                  source.type?.toLowerCase().includes('pdf')
                                    ? 'contain'
                                    : 'cover'
                                }
                                onError={e => {
                                  // Create a fallback placeholder element
                                  const placeholder =
                                    document.createElement('div')
                                  placeholder.style.display = 'flex'
                                  placeholder.style.flexDirection = 'column'
                                  placeholder.style.alignItems = 'center'
                                  placeholder.style.justifyContent = 'center'
                                  placeholder.style.width = '100%'
                                  placeholder.style.height = '100%'
                                  placeholder.style.backgroundColor = '#f5f5f5'

                                  placeholder.innerHTML = `
                  <svg width="48" height="48" viewBox="0 0 24 24">
                    <path fill="#666" d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M18,20H6V4H13V9H18V20Z" />
                  </svg>
                  <div style="font-size:12px;color:#666;">${source.name}</div>
                `

                                  if (e.target && e.target.parentNode) {
                                    // Hide the original image
                                    e.target.style.display = 'none'
                                    // Insert the placeholder
                                    e.target.parentNode.appendChild(placeholder)
                                  }
                                }}
                              />
                            </ThumbnailContainer>
                          ) : (
                            <PlaceholderThumbnail>
                              <FileIcon />
                              <div>{source.name}</div>
                            </PlaceholderThumbnail>
                          )}

                          {/* Button container */}
                          <PreviewButtonContainer>
                            <PreviewButton
                              onClick={e => handleViewDocumentClick(source, e)}
                            >
                              View Full Document
                            </PreviewButton>
                          </PreviewButtonContainer>
                        </>
                      ) : (
                        <UnavailablePreview>
                          <InfoIcon />
                          <UnavailableText>
                            Document preview not available
                          </UnavailableText>
                        </UnavailablePreview>
                      )}
                    </SourcePreview>
                  )}
                </React.Fragment>
              )
            )}
          </SourcesList>
        </SourcesContainer>
      )}
    </MarkdownContainer>
  )
}

export default MarkdownRenderer

const SourcesContainer = styled.div`
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};
`

const SourcesHeading = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.haiti};
`

const SourcesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const SourceItem = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: ${({ theme, isExpanded }) =>
    isExpanded ? theme.colors.primary + '15' : theme.colors.backgroundColor};
  border: 1px solid
    ${({ theme, isExpanded }) =>
      isExpanded ? theme.colors.primary : theme.colors.lightGrey};
  border-radius: 4px;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  opacity: ${({ isClickable }) => (isClickable ? 1 : 0.7)};
  transition: all 0.2s ease;

  &:hover {
    background-color: ${({ theme, isClickable }) =>
      isClickable ? theme.colors.primary + '15' : theme.colors.backgroundColor};
    border-color: ${({ theme, isClickable }) =>
      isClickable ? theme.colors.primary : theme.colors.lightGrey};
  }
`

const UnavailableIcon = styled.span`
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.grey[300]};
  display: flex;
  align-items: center;
`
const SourceIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.colors.secondary};
  }
`

const SourceName = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  color: ${({ theme }) => theme.colors.haiti};
`

const SourceType = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey[200]};
  margin-left: auto;
  background: ${({ theme }) => theme.colors.grey[600]};
  padding: 2px 8px;
  border-radius: 10px;
`

const PreviewButton = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`
const ThumbnailContainer = styled.div`
  width: 100%;
  height: 180px;
  background-color: ${({ theme }) => theme.colors.lighterGrey || '#f8f8f8'};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  margin-bottom: 16px;
`

const FileThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: ${props => props.objectFit || 'cover'};
  transition: transform 0.3s ease;
`

const PlaceholderThumbnail = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.lighterGrey || '#f8f8f8'};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  margin-bottom: 12px;

  svg {
    width: 48px;
    height: 48px;
    margin-bottom: 8px;
    color: ${({ theme }) => theme.colors.grey[400]};
  }

  div {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey[300]};
    text-align: center;
    padding: 0 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
`

const PreviewButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const UnavailablePreview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  color: ${({ theme }) => theme.colors.grey[300]};
  width: 100%;

  svg {
    font-size: 32px;
    margin-bottom: 12px;
  }
`

const UnavailableText = styled.div`
  font-size: 14px;
  text-align: center;
`

const SourcePreview = styled.div`
  margin-top: -1px;
  margin-bottom: 12px;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-top: none;
  border-radius: 0 0 4px 4px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: calc(100% - 2px);
  box-sizing: border-box;
`
