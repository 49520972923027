import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { PATHS, PRIVATE_PATHS, setNavigate } from 'utils/paths'
import Login from 'features/auth/Login'
import SignUp from 'features/auth/SignUp'
import Dashboard from 'features/dashboard/Dashboard'
import TopBar from 'features/navigation/TopBar'
import Navigation from 'features/navigation/Navigation'
import EmailConfirmation from 'features/auth/EmailConfirmation'
import HomePage from 'features/homePage/HomePage'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { connect, useDispatch, useSelector } from 'react-redux'
import {
  fetchAuthUserRoutine,
  logoutUserRoutine
} from 'features/auth/ducks/actions'
import {
  getCurrentUserRole,
  isUserLoggedIn,
  getCurrentUser
} from 'features/auth/ducks/selectors'
import PrivateRoute from 'components/PrivateRoute'
import RequestResetPassword from 'features/auth/RequestResetPassword'
import PasswordReset from 'features/auth/PasswordReset'
import ShortlistedBps from 'features/shortlistedBps/ShortlistedBps'
import BpDetails from 'features/bpDetails/BpDetails'
import ProfileSettings from 'features/profileSettings/ProfileSettings'
import Cart from 'features/cart/Cart'
import NotFound, { RedirectToNotFound } from 'components/NotFound'
import CreateBp from 'features/createBp/CreateBp'
import Drafts from 'features/drafts/Drafts'
import PaymentSuccess from 'features/cart/components/PaymentSuccess'
import BillingList from 'features/billingList/BillingList'
import Terms from 'features/terms/terms'
import AccessRequest from 'features/AccessRequest/AccessRequest'
import storageService from 'services/LocalStorageService'
import { KEYS } from 'utils/localStorage'
import packageJson from '../package.json'
import GlobalLoader from 'components/GlobalLoader'
import { selectGlobalLoader } from 'ducks/loaderSelectors'
import Analytics from 'features/analytics/Analytics'
import AddTemplate from 'features/analytics/AddTemplate'
import EditTemplates from 'features/analytics/EditTemplates'
import SignUpConfirmation from 'features/auth/components/SignUpConfirmation'
import MainList from 'features/bpList/MainList'
import { pdfjs } from 'react-pdf'
import { pathOr, propOr } from 'ramda'
import UpdateInfoExchangeAccount from 'features/auth/UpdateInfoExchangeAccount'
import { isNotNilOrEmpty } from 'utils/ramda'
import { getFileTypesRoutine } from 'ducks/files/actions'
import { getFileCategoriesRoutine } from 'ducks/dictionaries/actions'
import { getBuildingTypesRoutine } from 'features/bpList/ducks/actions'
import LoginCallback from 'features/auth/LoginCallback'
import { useLocation } from 'react-router'
import AddMultipleFilesPage from './features/bpDetails/Components/files/AddMultipleFilesPage'
import SessionExpiryModal from './features/session/SessionExpiryModal'
import { initSessionListenerRoutine } from './features/session/ducks/actions'

function App({ userRole, isloggedin }) {
  const dispatch = useDispatch()
  const [versionChecked, setVersionChecked] = useState(false)
  const isLoading = useSelector(selectGlobalLoader)
  const [dictionariesFetched, setDictionariesFetched] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()
  const currentPath = location.pathname
  const [authFetched, setAuthFetched] = useState(false)
  const isFileUploadPage = currentPath.includes('/files/upload/')

  useEffect(() => {
    setNavigate(navigate)
  }, [navigate])

  useEffect(() => {
    if (!currentPath?.includes('callback') && !authFetched) {
      dispatch(fetchAuthUserRoutine())
      setAuthFetched(true)
    }

    const appVersion = storageService.get(KEYS.version) || ''
    const packageJsonVersion = packageJson.version
    if (appVersion !== packageJsonVersion) {
      storageService.set(KEYS.version, packageJsonVersion)
      dispatch(
        logoutUserRoutine({
          callback: () => window.location.reload(true)
        })
      )
    } else if (!versionChecked) {
      setVersionChecked(true)
    }

    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`
  }, [currentPath, authFetched, versionChecked])

  useEffect(() => {
    if (isloggedin && !dictionariesFetched) {
      const fetchDictionaries = async () => {
        try {
          dispatch(getFileTypesRoutine())
          dispatch(getFileCategoriesRoutine())
          dispatch(getBuildingTypesRoutine())
          dispatch(initSessionListenerRoutine())
          setDictionariesFetched(true)
        } catch (error) {
          console.error('Failed to fetch dictionaries:', error)
        }
      }
      fetchDictionaries()
    } else if (!isloggedin && dictionariesFetched) {
      setDictionariesFetched(false)
    }
  }, [isloggedin, dictionariesFetched])

  const currentUser = useSelector(getCurrentUser)
  const currentUserSource = pathOr('', ['source', 'value'], currentUser)
  const isFromInfoExchange = currentUserSource === 'info_exchange'
  const hasCurrentUserFirstName = isNotNilOrEmpty(
    propOr('', 'firstName', currentUser)
  )

  return (
    <AppContainer>
      {isLoading && <GlobalLoader />}
      {!versionChecked && <LoadingScreen />}
      {!isFileUploadPage && <TopBar isloggedin={isloggedin} />}
      {isloggedin && !isFileUploadPage && <Navigation />}
      {isloggedin && currentUser && <SessionExpiryModal />}
      <Content isloggedin={isloggedin} isFileUploadPage={isFileUploadPage}>
        {isFromInfoExchange && !hasCurrentUserFirstName && (
          <FakeModal>
            <UpdateInfoExchangeAccount userInfo={currentUser} />
          </FakeModal>
        )}
        <Routes>
          <Route path={PATHS.home} element={<HomePage />} />
          <Route path={PATHS.login} element={<Login />} />
          <Route path={PATHS.loginCallback} element={<LoginCallback />} />
          <Route path={PATHS.signup} element={<SignUp />} />
          <Route path={PATHS.signupConfirm} element={<SignUpConfirmation />} />
          <Route
            path={PATHS.emailVerification}
            element={<EmailConfirmation />}
          />
          <Route
            path={PATHS.requestPasswordReset}
            element={<RequestResetPassword />}
          />
          <Route path={PATHS.passwordReset} element={<PasswordReset />} />
          <Route
            path={PRIVATE_PATHS.dashboard}
            element={
              <PrivateRoute userRole={userRole} allowedRoles={['user']}>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path={PRIVATE_PATHS.bpList}
            element={
              <PrivateRoute
                userRole={userRole}
                allowedRoles={['user', 'emergency_service']}
              >
                <MainList />
              </PrivateRoute>
            }
          />
          <Route
            path={PRIVATE_PATHS.drafts}
            element={
              <PrivateRoute userRole={userRole} allowedRoles={['user']}>
                <Drafts />
              </PrivateRoute>
            }
          />
          <Route
            path={PRIVATE_PATHS.savedPassports}
            element={
              <PrivateRoute
                userRole={userRole}
                allowedRoles={['user', 'emergency_service']}
              >
                <ShortlistedBps />
              </PrivateRoute>
            }
          />
          <Route
            path={PRIVATE_PATHS.accessRequests}
            element={
              <PrivateRoute userRole={userRole} allowedRoles={['user']}>
                <AccessRequest />
              </PrivateRoute>
            }
          />
          <Route
            path={PRIVATE_PATHS.savedDetails}
            element={
              <PrivateRoute
                userRole={userRole}
                allowedRoles={['user', 'emergency_service']}
              >
                <BpDetails />
              </PrivateRoute>
            }
          />
          <Route
            path={PRIVATE_PATHS.bpDetails}
            element={
              <PrivateRoute
                userRole={userRole}
                allowedRoles={['user', 'emergency_service']}
              >
                <BpDetails />
              </PrivateRoute>
            }
          />
          <Route
            path={PRIVATE_PATHS.draftDetails}
            element={
              <PrivateRoute userRole={userRole} allowedRoles={['user']}>
                <BpDetails />
              </PrivateRoute>
            }
          />
          <Route
            path={PRIVATE_PATHS.createBp}
            element={
              <PrivateRoute userRole={userRole} allowedRoles={['user']}>
                <CreateBp />
              </PrivateRoute>
            }
          />
          <Route
            path={PRIVATE_PATHS.fileUpload}
            element={<AddMultipleFilesPage />}
          />
          <Route
            path={PRIVATE_PATHS.profileSettings}
            element={
              <PrivateRoute
                userRole={userRole}
                allowedRoles={['user', 'emergency_service']}
              >
                <ProfileSettings />
              </PrivateRoute>
            }
          />
          <Route
            path={PRIVATE_PATHS.cart}
            element={
              <PrivateRoute userRole={userRole} allowedRoles={['user']}>
                <Cart />
              </PrivateRoute>
            }
          />
          <Route
            path={PRIVATE_PATHS.terms}
            element={
              <PrivateRoute userRole={userRole} allowedRoles={['user']}>
                <Terms />
              </PrivateRoute>
            }
          />
          <Route
            path={PRIVATE_PATHS.paymentSuccess}
            element={
              <PrivateRoute userRole={userRole} allowedRoles={['user']}>
                <PaymentSuccess />
              </PrivateRoute>
            }
          />
          <Route
            path={PRIVATE_PATHS.billingList}
            element={
              <PrivateRoute userRole={userRole} allowedRoles={['user']}>
                <BillingList />
              </PrivateRoute>
            }
          />
          <Route
            path={PRIVATE_PATHS.paymentError}
            element={
              <PrivateRoute userRole={userRole} allowedRoles={['user']}>
                <Cart />
              </PrivateRoute>
            }
          />
          <Route
            path={PRIVATE_PATHS.analytics}
            element={
              <PrivateRoute userRole={userRole} allowedRoles={['user']}>
                <Analytics />
              </PrivateRoute>
            }
          />
          <Route
            path={PRIVATE_PATHS.addAnalytics}
            element={
              <PrivateRoute userRole={userRole} allowedRoles={['user']}>
                <AddTemplate />
              </PrivateRoute>
            }
          />
          <Route
            path={PRIVATE_PATHS.editAnalytics}
            element={
              <PrivateRoute userRole={userRole} allowedRoles={['user']}>
                <EditTemplates />
              </PrivateRoute>
            }
          />
          <Route path={PATHS.notFound} element={<NotFound />} />
          <Route path='*' element={<RedirectToNotFound />} />
        </Routes>
      </Content>
      <ToastContainer />
    </AppContainer>
  )
}

const mapStateToProps = state => ({
  isloggedin: isUserLoggedIn(state),
  userRole: getCurrentUserRole(state)
})

export default connect(mapStateToProps)(App)

const AppContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
`

const Content = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: ${({ isloggedin, isFileUploadPage }) =>
    isFileUploadPage
      ? '40px 50px'
      : isloggedin
        ? '40px 50px 40px 136px'
        : '40px 50px'};
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  height: ${({ isFileUploadPage }) =>
    isFileUploadPage ? '100vh' : 'calc(100vh - 88px)'};
  overflow-y: auto;
`

const LoadingScreen = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`
const FakeModal = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
`
