import emptyBpListState from 'features/bpList/ducks/state'
import {
  fetchUserBpsRoutine,
  getBuildingTypesRoutine,
  searchBpsRoutine,
  getBpsPricesRoutine,
  fetchFavouritesRoutine,
  getBpsCountRoutine,
  getUnitsCountRoutine,
  clearBpPricesRoutine,
  searchFilesRoutine
} from 'features/bpList/ducks/actions'
import { pathOr, propOr } from 'ramda'
import { getInvitationsListForBpRoutine } from 'features/accessControl/ducks/actions'
import { logoutUserRoutine } from 'features/auth/ducks/actions'
import { removeBpRoutine } from 'features/createBp/ducks/actions'

export default (state = emptyBpListState, action) => {
  switch (action.type) {
    case fetchUserBpsRoutine.SUCCESS:
      return {
        ...state,
        entries: pathOr(1, ['payload', 'data'], action),
        totalPages: pathOr(1, ['payload', 'meta', 'lastPage'], action),
        totalFound: pathOr(1, ['payload', 'meta', 'total'], action)
      }
    case removeBpRoutine.SUCCESS:
      return {
        ...state
        // entries: state.entries.filter(e => e.id !== action.payload)
      }
    case fetchFavouritesRoutine.SUCCESS:
      return {
        ...state,
        favourites: {
          ...state.favourites,
          entries: pathOr(1, ['payload', 'data'], action),
          totalPages: pathOr(1, ['payload', 'meta', 'lastPage'], action),
          totalFound: pathOr(1, ['payload', 'meta', 'total'], action)
        }
      }
    case getBpsPricesRoutine.SUCCESS:
      return {
        ...state,
        prices: propOr([], 'payload', action)
      }
    case clearBpPricesRoutine.SUCCESS:
      return {
        ...state,
        prices: []
      }
    case searchBpsRoutine.SUCCESS:
      return {
        ...state,
        searchBpResults: pathOr([], ['payload', 'data'], action)
      }
    case searchFilesRoutine.SUCCESS:
      return {
        ...state,
        searchFileResults: pathOr([], ['payload', 'data'], action)
      }
    case getBuildingTypesRoutine.SUCCESS:
      return {
        ...state,
        buildingTypes: action.payload
      }
    case getInvitationsListForBpRoutine.SUCCESS:
      return {
        ...state,
        invitations: action.payload
      }
    case logoutUserRoutine.SUCCESS:
      return emptyBpListState
    case getBpsCountRoutine.SUCCESS:
      return {
        ...state,
        bpsCount: action.payload
      }
    case getUnitsCountRoutine.SUCCESS:
      return {
        ...state,
        unitsCount: action.payload
      }
    default:
      return state
  }
}
