import React, { useEffect, useState } from 'react'
import FormControl from '@mui/material/FormControl'
import styled from 'styled-components'
import { TextField, Autocomplete } from '@mui/material'
import Checkbox from 'components/atoms/Checkbox'
import { propOr } from 'ramda'
import FloorBadges from 'features/bpDetails/Components/files/FloorBadges'
import { isNotNilOrEmpty } from 'utils/ramda'

export const ShareUnitSelect = ({
  options = [],
  onSubmit,
  onChange,
  values
}) => {
  const [selectedUnits, setSelectedUnits] = useState(values)

  useEffect(() => {
    setSelectedUnits(values)
  }, [values])

  const handleChange = (event, value) => {
    setSelectedUnits(value)
    if (onChange) {
      onChange(value)
    }
  }

  const isOptionSelected = unitId => {
    return selectedUnits.some(unit => unit.value === unitId)
  }

  const handleSelectAll = () => {
    const newSelection = selectedUnits.length === options.length ? [] : options
    setSelectedUnits(newSelection)
    if (onChange) {
      onChange(newSelection)
    }
  }

  return (
    <Wrapper>
      <LabelWrapper>
        <Label>Share file with</Label>
        <ButtonsWrapper>
          <SelectAllButton onClick={handleSelectAll}>
            {selectedUnits.length === options.length
              ? 'Deselect All'
              : 'Select All'}
          </SelectAllButton>
          <SaveButton onClick={() => onSubmit(selectedUnits)}>
            Confirm
          </SaveButton>
        </ButtonsWrapper>
      </LabelWrapper>

      <StyledFormControl variant='outlined'>
        <Autocomplete
          multiple
          options={options}
          value={selectedUnits}
          onChange={handleChange}
          limitTags={0}
          getLimitTagsText={more => `${more} selected`}
          disableCloseOnSelect
          getOptionLabel={option => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderOption={(props, option) => (
            <MenuListItem {...props}>
              <OptionDetailsWrapper>
                <div>
                  <UnitName>{option.label}</UnitName>
                  <UnitUprn>
                    UPRN: {propOr('Not yet allocated', 'uprn', option)}
                  </UnitUprn>
                </div>
                <FloorNumbersWrapper>
                  Floors:&nbsp;{' '}
                  {isNotNilOrEmpty(option.floorNumbers) ? (
                    <FloorBadges floorNumbers={option.floorNumbers} />
                  ) : (
                    '---'
                  )}
                </FloorNumbersWrapper>
              </OptionDetailsWrapper>
            </MenuListItem>
          )}
          renderInput={params => (
            <TextField {...params} placeholder='Select units' />
          )}
        />
      </StyledFormControl>
    </Wrapper>
  )
}

export default ShareUnitSelect

const Wrapper = styled.div`
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.secondary};
  }
`

const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin-bottom: ${({ noMargin }) => (noMargin ? 0 : '22px')}!important;
  box-sizing: border-box;
  min-height: 46px;

  .MuiOutlinedInput-input {
    box-sizing: border-box;
    padding: 14px 15px;

    &:focus {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }

  .MuiAutocomplete-tag {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.grey[600]};
    background-color: ${({ theme }) => theme.colors.grey[400]}!important;
  }
`

const UnitName = styled.div`
  font-size: 14px;
  font-weight: bold;
`

const UnitUprn = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey[800]};
`

const MenuListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 480px;
  height: 50px;
  padding: 0 10px;

  label {
    display: flex;
    align-items: center;
    width: 30px;
    height: 20px;
  }
`

const SelectAllButton = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  text-transform: uppercase;
  cursor: pointer;
`

const SaveButton = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  cursor: pointer;
`

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.11px;
  min-height: 17px;
`

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`

const OptionDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const FloorNumbersWrapper = styled.div`
  display: flex;
  align-items: center;
`

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
`
const TagItem = styled.div`
  display: inline-flex;
  align-items: center;
  background: ${({ theme }) => theme.colors.grey[300]};
  padding: 5px 10px;
  border-radius: 5px;
  margin: 4px;
  font-size: 14px;
`
