import React, { useEffect, useMemo, useRef, useState } from 'react'
import { convertBytes } from 'utils/files'
import Select from 'components/atoms/Select'
import { ReactComponent as DeleteIcon } from 'assets/images/delete.svg'
import { useSelector } from 'react-redux'
import { getFileTypes } from 'ducks/files/selectors'
import { selectFileCategories } from 'ducks/dictionaries/selectors'
import { selectBpUnits } from 'ducks/units/selectors'
import styled, { css } from 'styled-components'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import { getFloorOptions } from 'utils/bpData'
import FileTypesSearch from 'features/bpDetails/Components/files/FileTypesSearch'
import { propOr } from 'ramda'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import Input from 'components/atoms/Input'
import AddUsersToFile from 'features/bpDetails/Components/files/AddUsersToFile'
import { Tooltip } from '@mui/material'
import AttachTagsToUploadingFile from 'features/bpDetails/Components/files/AttachTagsToUploadingFiles'
import CircularProgress from '@mui/material/CircularProgress'
import ModalSelect from 'features/bpDetails/Components/files/ModalSelect'
import ModalDateInput from 'features/bpDetails/Components/files/ModalDateInput'
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined'
import { ApartmentOutlined, EventRepeatOutlined } from '@mui/icons-material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CloseIcon from '@mui/icons-material/Close'
import AssistantOutlinedIcon from '@mui/icons-material/AssistantOutlined'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { formatDateWithTime } from '../../../../utils/date'
const defaultValues = {
  fileCategory: '',
  fileType: '',
  expirationDate: '',
  units: [],
  floors: [],
  userIds: [],
  tagIds: []
}

const informationExchangeCategoryName = 'FRS Information Exchange'

const FileModalTableRow = ({
  file,
  onRemove,
  onSelect,
  isSubmitted,
  onValidationStateChange,
  onChange,
  bulkChangeValues,
  isSelected,
  initialType,
  initialCategory,
  unit,
  isPrivate,
  currentStep,
  certaintyLevel,
  uploadStatus,
  createdAt,
  thumbnailUrl
}) => {
  const [values, setValues] = useState(defaultValues)
  const fileExtension = file.path?.split('.')?.pop()
  const [displayName, setDisplayName] = useState(
    file.name
      ?.replace(`.${fileExtension}`, '')
      ?.replace(`.${fileExtension.toUpperCase()}`, '')
  )
  const [showPreview, setShowPreview] = useState(false)
  const previewRef = useRef(null)
  const [editNameMode, setEditNameMode] = useState(false)
  const [tempDisplayName, setTempDisplayName] = useState(displayName)
  const fileTypes = useSelector(getFileTypes)
  const fileCategories = useSelector(selectFileCategories)
  const bpUnits = useSelector(selectBpUnits)
  const bp = useSelector(getSelectedBp)
  const isValidExtensionForFloorPlan = [
    'pdf',
    'dwg',
    'jpg',
    'jpeg',
    'png',
    'svg'
  ].includes(fileExtension)
  const isValidExtensionForErp = ['pdf'].includes(fileExtension)

  const isUnit = isNotNilOrEmpty(unit)
  const isCompleted = uploadStatus === 'completed'
  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  useEffect(() => {
    if (isNotNilOrEmpty(fileTypes) && isNotNilOrEmpty(initialType)) {
      handleValueChange('fileType', initialType)
    }

    if (isNotNilOrEmpty(fileCategories) && isNotNilOrEmpty(initialCategory)) {
      handleValueChange('fileCategory', initialCategory)
    }
  }, [initialType, initialCategory, fileCategories, fileTypes])

  const handleCategoryChange = (_, value) => {
    setValues(prev => ({
      ...prev,
      fileType: '',
      fileCategory: value,
      floors: []
    }))
  }

  const handleTypeChange = (_, value) => {
    setValues(prev => ({
      ...prev,
      floors: [],
      fileType: value
    }))
  }

  const getConfidenceText = confidence => {
    if (!confidence || confidence === 0) return null

    if (confidence >= 70) return 'High'
    if (confidence >= 55) return 'Medium'
    return null
  }

  useEffect(() => {
    if (isNotNilOrEmpty(bulkChangeValues) && isSelected) {
      const {
        fileCategory,
        fileType,
        units,
        floors,
        expirationDate,
        userIds,
        tagIds
      } = bulkChangeValues
      const selectedNewType = fileTypes.find(type => type.id === fileType)
      const selectedOldType = fileTypes.find(
        type => type.id === values.fileType
      )
      const isFloorPlan =
        (selectedOldType?.name === 'Building plan' &&
          isNilOrEmpty(selectedNewType?.name)) ||
        selectedNewType?.name === 'Building plan'

      isNotNilOrEmpty(fileCategory) && handleCategoryChange('', fileCategory)
      isNotNilOrEmpty(fileType) && handleTypeChange('', fileType)
      isNotNilOrEmpty(units) && handleValueChange('units', units)
      isNotNilOrEmpty(floors) &&
        isFloorPlan &&
        handleValueChange('floors', floors)
      isNotNilOrEmpty(expirationDate) &&
        handleValueChange('expirationDate', expirationDate)
      isNotNilOrEmpty(userIds) && handleValueChange('userIds', userIds)
      isNotNilOrEmpty(tagIds) && handleValueChange('tagIds', tagIds)
    }
  }, [bulkChangeValues])

  const categoriesOptions = fileCategories
    .filter(cat => cat.name !== informationExchangeCategoryName)
    .map(type => ({
      label: type.name,
      value: type.id
    }))

  const bpFloorOptions = getFloorOptions(bp)
  const unitFloorNumbers = propOr([], 'floorNumbers', unit)

  const floorOptions = bpFloorOptions.filter(option =>
    unitFloorNumbers.includes(option.value)
  )

  const isFloorPlan = useMemo(() => {
    if (isNotNilOrEmpty(values.fileType)) {
      const selectedType = fileTypes.find(type => type.id === values.fileType)
      return selectedType?.name === 'Building plan'
    } else {
      return false
    }
  }, [values.fileType])

  const isErpFile = useMemo(() => {
    if (isNotNilOrEmpty(values.fileCategory)) {
      const selectedCategory = fileCategories.find(
        category => category.id === values.fileCategory
      )
      return selectedCategory?.name === 'Emergency Response Pack (ERP)'
    } else {
      return false
    }
  }, [values.fileCategory])

  const unitsOptions = bpUnits.map(type => ({
    label: type.name,
    value: type.id
  }))

  useEffect(() => {
    const { fileType, fileCategory, floors } = values
    const floorPlanValid = isFloorPlan
      ? isNotNilOrEmpty(floors) && isValidExtensionForFloorPlan
      : true
    const erpValid = isErpFile ? isValidExtensionForErp : true

    const isValid =
      (currentStep === 2 &&
        isNotNilOrEmpty(fileType) &&
        isNotNilOrEmpty(fileCategory) &&
        floorPlanValid &&
        erpValid) ||
      currentStep === 1

    onValidationStateChange(isValid)
  }, [values, isFloorPlan, isErpFile])

  useEffect(() => {
    if (isPrivate) {
      onChange({ file, values: { displayName } })
    } else {
      onChange({ file, values: { ...values, displayName } })
    }
  }, [values])

  const handleTypeAndCategoryChange = (category, type) => {
    category !== values.fileCategory && handleCategoryChange('', category)
    type !== values.fileType && handleTypeChange('', type)
  }

  const toggleEditNameMode = () => {
    setEditNameMode(prev => !prev)
  }

  const handleSaveDisplayName = () => {
    setDisplayName(tempDisplayName)
    toggleEditNameMode()
  }

  return (
    <Wrapper>
      <TableRow
        key={file.id}
        isUnit={isUnit}
        isPrivate={isPrivate}
        currentStep={currentStep}
        hasCertainty={
          !isUnit && currentStep === 2 && isCompleted && certaintyLevel > 0
        }
      >
        <RadioCell>
          <input type='checkbox' onChange={onSelect} checked={isSelected} />
        </RadioCell>
        <TableCell>
          <FileNameWrapper>
            <FileIconWrapper currentStep={currentStep}>
              {currentStep === 1 ? (
                <FileIcon />
              ) : uploadStatus === 'completed' ? (
                <CheckIcon />
              ) : uploadStatus === 'waiting_for_ai' ? (
                <AIIcon />
              ) : uploadStatus === 'failed' ? (
                <FailIcon />
              ) : (
                <CircularProgress size={18} />
              )}
            </FileIconWrapper>
            <div>
              <FileNameEditWrapper>
                {editNameMode ? (
                  <input
                    name='displayName'
                    value={tempDisplayName}
                    onChange={e => setTempDisplayName(e.target.value)}
                  />
                ) : (
                  <>
                    <FileName
                      isPrivate={isPrivate}
                      fullName={currentStep === 1 && !isUnit}
                    >
                      {displayName}.{fileExtension}
                    </FileName>
                    {currentStep === 2 && (
                      <PreviewContainer>
                        <Tooltip title='Preview file'>
                          <PreviewIconWrapper
                            onClick={() => setShowPreview(true)}
                          >
                            <PreviewIcon />
                          </PreviewIconWrapper>
                        </Tooltip>

                        {showPreview && (
                          <PreviewPopup
                            ref={previewRef}
                            onClick={() => setShowPreview(false)}
                          >
                            <PreviewContent onClick={e => e.stopPropagation()}>
                              <PreviewHeader>
                                <PreviewTitle>
                                  {displayName}.{fileExtension}
                                </PreviewTitle>
                                <PreviewCloseButton
                                  onClick={() => setShowPreview(false)}
                                />
                              </PreviewHeader>
                              <PreviewBody>
                                <ThumbnailWrapper>
                                  {thumbnailUrl ? (
                                    <PreviewImage
                                      src={thumbnailUrl}
                                      alt={displayName}
                                    />
                                  ) : (
                                    <PlaceholderPreview>
                                      <InsertDriveFileOutlinedIcon />
                                      <div>{fileExtension.toUpperCase()}</div>
                                    </PlaceholderPreview>
                                  )}
                                </ThumbnailWrapper>
                                <PreviewInfo>
                                  <div>
                                    <InfoRow>
                                      <InfoLabel>File Name:</InfoLabel>
                                      <InfoValue>
                                        {displayName}.{fileExtension}
                                      </InfoValue>
                                    </InfoRow>
                                    <InfoRow>
                                      <InfoLabel>Size:</InfoLabel>
                                      <InfoValue>
                                        {convertBytes(file.size)}
                                      </InfoValue>
                                    </InfoRow>
                                  </div>
                                  <div>
                                    <InfoRow>
                                      <InfoLabel>Type:</InfoLabel>
                                      <InfoValue>
                                        {fileExtension.toUpperCase()}
                                      </InfoValue>
                                    </InfoRow>
                                    {createdAt && (
                                      <InfoRow>
                                        <InfoLabel>Upload Time:</InfoLabel>
                                        <InfoValue>
                                          {formatDateWithTime(createdAt)}
                                        </InfoValue>
                                      </InfoRow>
                                    )}
                                  </div>
                                </PreviewInfo>
                              </PreviewBody>
                            </PreviewContent>
                          </PreviewPopup>
                        )}
                      </PreviewContainer>
                    )}
                  </>
                )}
                {editNameMode ? (
                  <StyledSaveIcon
                    onClick={
                      isNotNilOrEmpty(tempDisplayName)
                        ? handleSaveDisplayName
                        : () => {}
                    }
                    disabled={isNilOrEmpty(tempDisplayName)}
                  />
                ) : (
                  currentStep === 1 && (
                    <StyledEditIcon onClick={toggleEditNameMode} />
                  )
                )}
              </FileNameEditWrapper>
              <FileSize>{convertBytes(file.size)}</FileSize>
              {currentStep === 2 && (
                <UploadProgressWrapper>
                  {uploadStatus === 'new' ? (
                    <CompletedText status={uploadStatus}>
                      Processing...
                    </CompletedText>
                  ) : uploadStatus === 'failed' ? (
                    <CompletedText status={uploadStatus}>Failed</CompletedText>
                  ) : uploadStatus === 'waiting_for_ai' ? (
                    <CompletedText status={uploadStatus}>
                      Classification in progress...
                    </CompletedText>
                  ) : uploadStatus === 'pending' ? (
                    <CompletedText status={uploadStatus}>
                      Uploading...
                    </CompletedText>
                  ) : (
                    <CompletedText status={uploadStatus}>
                      Uploaded
                    </CompletedText>
                  )}
                </UploadProgressWrapper>
              )}
            </div>
          </FileNameWrapper>
        </TableCell>
        {!isPrivate && (
          <>
            {((currentStep === 2 && isCompleted) || isUnit) && (
              <>
                <TableCell>
                  <Select
                    noMargin
                    value={values.fileCategory}
                    name='fileCategory'
                    options={categoriesOptions}
                    hasError={isNilOrEmpty(values.fileCategory) && isSubmitted}
                    onChange={handleCategoryChange}
                  />
                </TableCell>
                <TableCell>
                  <FileTypesSearch
                    placeholder='Type to search'
                    category={values.fileCategory}
                    onFileTypeSelect={handleTypeAndCategoryChange}
                    value={values.fileType}
                    hasError={isNilOrEmpty(values.fileType) && isSubmitted}
                  />
                </TableCell>
              </>
            )}
            {!isUnit &&
              currentStep === 2 &&
              isCompleted &&
              certaintyLevel > 0 && (
                <TableCell>
                  {currentStep === 2 && certaintyLevel >= 55 && (
                    <CertaintyLabel certainty={certaintyLevel}>
                      {getConfidenceText(certaintyLevel)}
                    </CertaintyLabel>
                  )}
                  {currentStep === 2 &&
                    certaintyLevel > 0 &&
                    certaintyLevel < 55 && (
                      <LowConfidenceHint>
                        Please label manually
                      </LowConfidenceHint>
                    )}
                </TableCell>
              )}
            {((currentStep === 2 && isCompleted) || isUnit) && (
              <TableCell>
                <ModalSelect
                  icon={<FloorIcon />}
                  name='floors'
                  value={values.floors}
                  onChange={val => handleValueChange('floors', val)}
                  options={isUnit ? floorOptions : bpFloorOptions}
                  disabled={!isFloorPlan}
                  selectAll
                  multiple
                  hasError={
                    isNilOrEmpty(values.floors) && isFloorPlan && isSubmitted
                  }
                />
              </TableCell>
            )}
            {!isUnit && currentStep === 2 && isCompleted && (
              <TableCell>
                <ModalSelect
                  icon={<ApartmentIcon />}
                  name='units'
                  value={values.units}
                  onChange={val => handleValueChange('units', val)}
                  options={unitsOptions}
                  disabled={isNilOrEmpty(unitsOptions)}
                  selectAll
                  multiple
                />
              </TableCell>
            )}

            {!isUnit && currentStep === 2 && isCompleted && (
              <>
                <TableCell>
                  <Tooltip title='Manage permissions'>
                    <AddUsersToFile
                      isUpload
                      initiallySelected={values.userIds}
                      savedCount={values.userIds?.length || 0}
                      onSave={value => {
                        handleValueChange('userIds', value)
                      }}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title='Manage tags'>
                    <AttachTagsToUploadingFile
                      savedCount={values.tagIds?.length || 0}
                      initiallySelected={values.tagIds}
                      onSave={value => {
                        handleValueChange('tagIds', value)
                      }}
                    />
                  </Tooltip>
                </TableCell>
              </>
            )}
            {!isPrivate && ((currentStep === 2 && isCompleted) || isUnit) && (
              <TableCell>
                <ModalDateInput
                  icon={<ExpirationIcon />}
                  name='expirationDate'
                  value={values.expirationDate}
                  onChange={date => handleValueChange('expirationDate', date)}
                />
              </TableCell>
            )}
            {currentStep === 2 && !isCompleted && (
              <>
                <SpanningPlaceholderCell>
                  <PlaceholderText>Processing, please wait...</PlaceholderText>
                </SpanningPlaceholderCell>
              </>
            )}
            {currentStep !== 2 && (
              <TableCell>
                <DeleteIconWrapper onClick={onRemove}>
                  <DeleteIcon />
                </DeleteIconWrapper>
              </TableCell>
            )}
          </>
        )}
      </TableRow>
      {isFloorPlan && !isValidExtensionForFloorPlan && (
        <TypeError>Wrong file extension for Building Plan.</TypeError>
      )}
      {isErpFile && !isValidExtensionForErp && (
        <TypeError>Only PDF files are allowed in the ERP category.</TypeError>
      )}
    </Wrapper>
  )
}

export default FileModalTableRow

const SpanningPlaceholderCell = styled.div`
  grid-column: 3 / -1; // Span from column 3 to the end
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`
const PlaceholderText = styled.div`
  font-size: 14px;
  align-self: center;
  color: #999;
  text-align: center;
  padding: 1rem;
`
const FileName = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.haiti};
  max-width: 230px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ fullName }) =>
    fullName &&
    css`
      max-width: 550px;
    `}

  ${({ isPrivate }) =>
    isPrivate &&
    css`
      max-width: unset;
      width: 100%;
    `}

  @media (max-width: 1366px) {
    max-width: 200px;

    ${({ fullName }) =>
      fullName &&
      css`
        max-width: 350px;
      `}
  }

  @media (max-width: 1280px) {
    max-width: 160px;

    ${({ fullName }) =>
      fullName &&
      css`
        max-width: 280px;
      `}
  }
`

const FileSize = styled.div`
  font-size: 12px;
`

const RadioCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const FileNameWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`

const FileIconWrapper = styled.div`
  margin-right: 10px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  ${({ currentStep }) =>
    currentStep === 1 &&
    css`
      background-color: #fae6e1;
    `}

  @media (max-width: 1280px) {
    min-width: 28px;
    min-height: 28px;
    margin-right: 8px;
  }
`

const FileIcon = styled(InsertDriveFileOutlinedIcon)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 16px !important;
`

const LowConfidenceHint = styled.div`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
  margin-top: 4px;
`

const TableRow = styled.div`
  display: grid;
  /* Base columns - match the headers */
  grid-template-columns: 40px 1fr 1fr 1fr 60px 60px 60px 60px 60px;
  margin-top: -1px;
  min-height: 72px;
  position: relative;
  cursor: pointer;

  &:last-of-type {
    border-radius: 0 0 8px 8px;
  }

  ${({ isUnit }) =>
    isUnit &&
    css`
      grid-template-columns: 40px 1fr 1fr 1fr 40px;
    `}
  ${({ currentStep }) =>
    currentStep === 1 &&
    css`
      grid-template-columns: 40px 1fr 60px;
    `}

  ${({ isPrivate }) =>
    isPrivate &&
    css`
      grid-template-columns: 40px minmax(300px, 1fr) 40px;
    `}

  ${({ hasCertainty, currentStep }) =>
    !hasCertainty &&
    currentStep === 2 &&
    css`
      grid-template-columns: 40px 1fr 1fr 1fr 60px 60px 60px 60px 60px;
    `}

  ${({ hasCertainty, currentStep }) =>
    hasCertainty &&
    currentStep === 2 &&
    css`
      grid-template-columns: 40px 1fr 1fr 1fr 80px 60px 60px 60px 60px 60px;
    `}

  @media (max-width: 1366px) {
    grid-template-columns: 40px 1fr 1fr 1fr 50px 50px 50px 50px 50px;

    ${({ hasCertainty, currentStep }) =>
      hasCertainty &&
      currentStep === 2 &&
      css`
        grid-template-columns: 40px 1fr 1fr 1fr 80px 50px 50px 50px 50px 50px;
      `}
  }

  @media (max-width: 1280px) {
    grid-template-columns: 40px 1fr 1fr 1fr 45px 45px 45px 45px 45px;

    ${({ hasCertainty, currentStep }) =>
      hasCertainty &&
      currentStep === 2 &&
      css`
        grid-template-columns: 40px 1fr 1fr 1fr 70px 45px 45px 45px 45px 45px;
      `}
  }
`

const TableCell = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 13px;
  padding: 0 5px;
`

const DeleteIconWrapper = styled.div`
  cursor: pointer;
  padding: 3px;

  svg {
    cursor: pointer !important;
  }
`

const TypeError = styled.div`
  color: ${({ theme }) => theme.colors.error};
  padding-left: 45px;
  margin-top: -10px;
`

const Wrapper = styled.div`
  border: 1px solid #eaecf0;
`

const CheckIcon = styled(CheckCircleOutlineIcon)`
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.success};
`

const FailIcon = styled(CloseIcon)`
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.error};
`

const AIIcon = styled(AssistantOutlinedIcon)`
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.primary};
`

const FileNameEditWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const StyledEditIcon = styled(EditIcon)`
  font-size: 14px !important;
  cursor: pointer !important;
`

const StyledSaveIcon = styled(SaveIcon)`
  font-size: 14px !important;
  cursor: pointer !important;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.error : theme.colors.success};
`
const CertaintyLabel = styled.div`
  width: fit-content;
  padding: 3px 7px;
  background: ${({ certainty, theme }) => {
    if (certainty < 55) return theme.colors.red || '#841111'
    if (certainty < 70) return theme.colors.yellow || '#844911'
    return theme.colors.green || '#075B3E'
  }};
  color: ${({ theme }) => theme.colors.white || '#fff'};
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.mediumGrey || '#bdc3c7'};
  border-radius: 4px;
  margin-right: 6px;
`
const UploadProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 4px;
`

const CompletedText = styled.span`
  font-size: 14px;
  color: ${({ status, theme }) => {
    switch (status) {
      case 'completed':
        return theme.colors.default
      case 'failed':
        return theme.colors.error
      case 'pending':
        return theme.colors.default
      case 'new':
        return theme.colors.warning
      default:
        return theme.colors.default
    }
  }};
`

const FloorIcon = styled(RoomOutlinedIcon)`
  cursor: pointer;
`

const ApartmentIcon = styled(ApartmentOutlined)`
  cursor: pointer;
`
const ExpirationIcon = styled(EventRepeatOutlined)`
  cursor: pointer;
`
const PreviewContainer = styled.div`
  position: relative;
`

const PreviewIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`

const PreviewIcon = styled(VisibilityIcon)`
  font-size: 16px !important;
`

const PreviewPopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const PreviewContent = styled.div`
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 900px;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.3);
`

const PreviewHeader = styled.div`
  padding: 16px 24px;
  background-color: ${({ theme }) => theme.colors.lighterGrey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[400]};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const PreviewTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.haiti};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const PreviewCloseButton = styled(CloseIcon)`
  cursor: pointer;
  font-size: 24px !important;
  color: ${({ theme }) => theme.colors.grey[100]};

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`

const PreviewBody = styled.div`
  padding: 24px;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
`

const ThumbnailWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.lighterGrey};
  border-radius: 4px;
  margin-bottom: 20px;
  height: 500px;
  overflow: hidden;
`

const PreviewImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`

const PlaceholderPreview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.grey[100]};

  svg {
    margin-bottom: 16px;
    font-size: 80px !important;
  }

  div {
    font-size: 16px;
  }
`

const PreviewInfo = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`

const InfoRow = styled.div`
  display: flex;
  margin-bottom: 12px;
  font-size: 14px;
`

const InfoLabel = styled.div`
  font-weight: 600;
  width: 100px;
  color: ${({ theme }) => theme.colors.haiti};
`

const InfoValue = styled.div`
  color: ${({ theme }) => theme.colors.grey[100]};
`
