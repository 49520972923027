import React from 'react'
import { Radio as MaterialRadio } from '@mui/material'
import styled from 'styled-components'

const Radio = (props) => {
  return <RadioWrapper>
    <MaterialRadio {...props} size='small' />
  </RadioWrapper>
}

export default Radio

const RadioWrapper = styled.div`
  .MuiRadio-colorSecondary.Mui-checked {
    color: ${({ theme }) => theme.colors.secondary};
  }
  
  .Mui-disabled {
    color: ${({ theme }) => theme.colors.mediumGrey} !important;
  }
`
