import {combineReducers} from 'redux'
import {routinePromiseWatcherSaga} from 'redux-saga-routines'
import {all, fork} from '@redux-saga/core/effects'

// reducers
import authReducer from 'features/auth/ducks/reducer'
import bpReducer from 'features/bpList/ducks/reducer'
import bpDetailsReducer from 'features/bpDetails/ducks/reducer'
import bpCreationReducer from 'features/createBp/ducks/reducer'
import billingListReducer from 'features/billingList/ducks/reducer'
import notificationsReducer from 'features/notifications/ducks/reducer'
import cartReducer from 'features/cart/ducks/reducer'
import accessRequestReducer from 'features/AccessRequest/ducks/reducer'
import dictionariesReducer from 'ducks/dictionaries/reducer'
import loaderReducer from 'ducks/loaderReducer'
import analyticsReducer from 'features/analytics/ducks/reducer'
import dashboardReducer from 'features/dashboard/ducks/reducer'
import unitsReducer from 'ducks/units/reducer'
import filesReducer from 'ducks/files/reducer'
import sessionReducer from 'features/session/ducks/reducer'

// sagas
import {authSagas} from 'features/auth/ducks/actions'
import {bpSagas} from 'features/bpList/ducks/actions'
import {bpCreationSagas} from 'features/createBp/ducks/actions'
import {billingListSagas} from 'features/billingList/ducks/actions'
import {notificationSagas} from 'features/notifications/ducks/actions'
import {accessControlSagas} from 'features/accessControl/ducks/actions'
import {requestAccessSagas} from 'features/AccessRequest/ducks/actions'
import {dictionariesSagas} from 'ducks/dictionaries/actions'
import {analyticsSagas} from 'features/analytics/ducks/actions'
import {unitSagas} from 'ducks/units/actions'
import {filesSagas} from 'ducks/files/actions'
import {bpDetailsSagas} from 'features/bpDetails/ducks/actions'
import {dashboardSagas} from 'features/dashboard/ducks/actions'
import {cartSagas} from 'features/cart/ducks/actions'
import {sessionSagas} from "../features/session/ducks/actions";

// reducer
const appReducer = combineReducers({
  files: filesReducer,
  bpDetails: bpDetailsReducer,
  auth: authReducer,
  bp: bpReducer,
  bpCreation: bpCreationReducer,
  billingList: billingListReducer,
  notifications: notificationsReducer,
  accessRequest: accessRequestReducer,
  dictionaries: dictionariesReducer,
  loader: loaderReducer,
  analytics: analyticsReducer,
  units: unitsReducer,
  dashboard: dashboardReducer,
  cart: cartReducer,
  session: sessionReducer
})

export default appReducer

export function* rootSaga() {
  yield all([
    ...authSagas,
    ...bpSagas,
    ...bpDetailsSagas,
    ...bpCreationSagas,
    ...billingListSagas,
    ...notificationSagas,
    ...accessControlSagas,
    ...requestAccessSagas,
    ...dictionariesSagas,
    ...analyticsSagas,
    ...unitSagas,
    ...filesSagas,
    ...dashboardSagas,
    ...cartSagas,
    ...sessionSagas,
    fork(routinePromiseWatcherSaga)
  ])
}
