import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from 'components/atoms/Modal'
import { getFilesListForBp } from 'services/fileService'
import Pagination from 'components/atoms/Pagination'
import FileMinified from 'features/bpDetails/Components/FileMinified'
import FullWidthButton from 'features/auth/components/FullWidthButton'
import AddMultipleFilesModal from '../../bpDetails/Components/files/AddMultipleFilesModal'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

const AlreadyUploadedFilesOfTypeModal = ({
  children,
  bpId,
  type,
  categoryId
}) => {
  const [open, setOpen] = useState(false)
  const [alreadyAddedFiles, setAlreadyAddedFiles] = useState([])
  const [addedPagePagination, setAddedPagePagination] = useState({
    page: 1,
    pagesCount: 1
  })

  const handleAddedFilesFetch = page => {
    getFilesListForBp({
      id: bpId,
      query: {
        page: Math.max(1, page || addedPagePagination.page),
        limit: 10,
        filter: { type }
      }
    }).then(resp => {
      setAddedPagePagination({
        pagesCount: resp.data.meta.lastPage,
        page: resp.data.meta.currentPage
      })
      setAlreadyAddedFiles(resp.data.data)
    })
  }

  const handleOpen = () => {
    setOpen(true)
    handleAddedFilesFetch()
  }

  const handleClose = () => setOpen(false)

  const handleUploadMoreClick = () => {
    setOpen(false)
  }

  return (
    <div>
      <Trigger onClick={handleOpen}>{children}</Trigger>
      <Modal
        title='Uploaded files of this type'
        open={open}
        onClose={handleClose}
      >
        <ContentWrapper>
          <AlreadyUploadedFiles>
            {alreadyAddedFiles.map(addedFile => {
              return (
                <FileMinified
                  hideOptions
                  callback={handleAddedFilesFetch}
                  key={`file-${Math.random() * 99}`}
                  file={addedFile}
                  isShared={addedFile.isSharedWithUnits}
                />
              )
            })}
          </AlreadyUploadedFiles>

          <PaginationWrapper>
            <Pagination
              onChange={handleAddedFilesFetch}
              totalPages={addedPagePagination.pagesCount}
              currentPage={addedPagePagination.page}
            />
          </PaginationWrapper>
          <ButtonWrapper>
            <AddMultipleFilesModal
              externalBpId={bpId}
              initialCategory={categoryId}
              initialType={type}
              addedFilesCount={alreadyAddedFiles.length}
            >
              <StyledButton onClick={handleUploadMoreClick}>
                <CloudUploadIcon style={{ marginRight: '8px' }} />
                Upload More Files
              </StyledButton>
            </AddMultipleFilesModal>
          </ButtonWrapper>
        </ContentWrapper>
      </Modal>
    </div>
  )
}

export default AlreadyUploadedFilesOfTypeModal

const Trigger = styled.div`
  cursor: pointer;
`

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 16px;

  & > div {
    width: 100%; // Make the AddMultipleFilesModal take full width
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const StyledButton = styled.button`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary || '#33becc'};
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;

  &:hover {
    opacity: 0.9;
  }
`

const AlreadyUploadedFiles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 100px;
`

const PaginationWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
`
