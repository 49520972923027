import { createRoutine } from 'redux-saga-routines'
import { call, fork, put, takeLatest } from '@redux-saga/core/effects'
import * as bpService from 'services/BpService'
import { pathOr } from 'ramda'
import { toast } from 'react-toastify'
import { getApiErrors } from 'utils/errors'
import { updateCart } from 'utils/localStorage'

export const paymentInitRoutine = createRoutine('PAYMENT_INIT')
export const checkBasketAvailabilityRoutine = createRoutine(
  'CHECK_BASKET_AVAILABILITY'
)
export const clearBasketAvailabilityRoutine = createRoutine(
  'CLEAR_BASKET_AVAILABILITY'
)

function* paymentInit({ payload }) {
  yield put(paymentInitRoutine.request())
  try {
    const result = yield call(bpService.createPayment, payload)
    yield put(
      paymentInitRoutine.success(
        pathOr('', ['data', 'data', 'stripeCheckoutSessionId'], result)
      )
    )
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(paymentInitRoutine.failure(error))
  }
}

function* checkBasketAvailability({ payload }) {
  yield put(checkBasketAvailabilityRoutine.request())
  try {
    const { data } = yield call(bpService.checkBasketAvailability, payload)
    yield put(checkBasketAvailabilityRoutine.success(data.data))
    updateCart(pathOr([], ['data', 'buildingPassportIds'], data))
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(checkBasketAvailabilityRoutine.failure(error))
  }
}

function* clearBasketAvailability() {
  yield put(clearBasketAvailabilityRoutine.success())
}

export function* paymentInitWatcher() {
  yield takeLatest(paymentInitRoutine.TRIGGER, paymentInit)
}

export function* checkBasketAvailabilityWatcher() {
  yield takeLatest(
    checkBasketAvailabilityRoutine.TRIGGER,
    checkBasketAvailability
  )
}

export function* clearBasketAvailabilityWatcher() {
  yield takeLatest(
    clearBasketAvailabilityRoutine.TRIGGER,
    clearBasketAvailability
  )
}

export const cartSagas = [
  fork(paymentInitWatcher),
  fork(checkBasketAvailabilityWatcher),
  fork(clearBasketAvailabilityWatcher)
]
