import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined'
import Modal from 'components/atoms/Modal'
import PrimaryButton from 'components/atoms/PrimaryButton'
import Button from 'components/atoms/Button'
import { useSelector } from 'react-redux'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import { getAssignedUsers } from 'utils/bpData'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { AddButton } from 'features/bpDetails/BpDetails'
import { hideEmail } from 'utils/user'

const AddUsersToFile = ({ onSave, savedCount, initiallySelected, children, isUpload }) => {
  const bp = useSelector(getSelectedBp)
  const user = useSelector(getCurrentUser)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [open, setOpen] = useState(false)

  const assignedUsers = useMemo(() => {
    const assigned = getAssignedUsers(bp)
    return assigned?.filter(u => u.id !== user.id) || []
  }, [bp, user])

  useEffect(() => {
    if (isNotNilOrEmpty(initiallySelected)) {
      setSelectedUsers(initiallySelected)
    }
  }, [initiallySelected])

  const toggleUser = id => () => {
    setSelectedUsers(prev => {
      if (prev.includes(id)) {
        return prev.filter(item => item !== id)
      } else {
        return [...prev, id]
      }
    })
  }

  const checkSelection = id => {
    return selectedUsers.includes(id)
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleSave = () => {
    onSave(selectedUsers)
    handleClose()
  }

  useEffect(() => {
    if (isNotNilOrEmpty(assignedUsers) && isUpload) {
      const userIds = assignedUsers.map(u => u.id)
      setSelectedUsers(userIds)
      onSave(userIds)
    }
  }, [assignedUsers, isUpload])

  const allUsersSelected = useMemo(() =>
      selectedUsers.length === assignedUsers.length && assignedUsers.length > 0,
    [selectedUsers, assignedUsers]
  )
  const handleToggleSelectAll = () => {
    setSelectedUsers(prevSelected => {
      if (prevSelected.length === assignedUsers.length) {
        return []
      } else {
        return assignedUsers.map(user => user.id)
      }
    });
  }

  return (
    <>
      <TriggerWrapper onClick={handleOpen}>
        {children || (
          <Wrapper>
            <StyledIcon />
            {savedCount > 0 && <Badge>{savedCount}</Badge>}
          </Wrapper>
        )}
      </TriggerWrapper>
      <Modal
        open={open}
        onClose={handleClose}
        title='Manage permissions'
      >
        <ModalContent>
          {isNotNilOrEmpty(assignedUsers) ?
            <>
              <ModalDesc>Which users should have access to this file?</ModalDesc>
              <SelectAllWrapper>
                <AddButton onClick={handleToggleSelectAll}>
                  {allUsersSelected ? "DESELECT ALL USERS" : "SELECT ALL USERS"}
                </AddButton>
              </SelectAllWrapper>
              {assignedUsers.map(userEl => {
                return (
                  <UserWrapper
                    key={userEl.id}
                    onClick={toggleUser(userEl.id)}
                    isSelected={checkSelection(userEl.id)}
                  >
                    <UserDetails>
                      <UserEmail>
                        {hideEmail(userEl.email)}
                      </UserEmail>
                      <UserName>
                        {userEl.firstName}&nbsp;{userEl.lastName}
                      </UserName>
                    </UserDetails>
                    <div>
                      {checkSelection(userEl.id) && <Check>✓</Check>}
                    </div>
                  </UserWrapper>
                )
              })}
            </> : (
              <ModalDesc>No users to manage</ModalDesc>
            )
          }
          <ButtonsWrapper>
            <Button onClick={handleClose}>Cancel</Button>
            <PrimaryButton disabled={isNilOrEmpty(assignedUsers)} onClick={handleSave}>Accept</PrimaryButton>
          </ButtonsWrapper>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AddUsersToFile

const TriggerWrapper = styled.div`
  width: fit-content;
  height: fit-content;
`

const Wrapper = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
`

const StyledIcon = styled(PeopleOutlinedIcon)`
  cursor: pointer !important;
`

const ButtonsWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 16px;

  & > button {
    flex: 1;
  }
`

const Check = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.success};
`

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  border: 1px solid ${({ theme, isSelected }) => isSelected ? theme.colors.secondary : theme.colors.grey[600]};
  border-radius: 8px;
  cursor: pointer;
  transition: all .3s;
  gap: 15px;
  position: relative;

  &:hover {
    border-color: ${({ theme }) => theme.colors.secondary};
  }

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
`

const Badge = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  top: -3px;
  right: -3px;
  border-radius: 50%;
`

const UserDetails = styled.div``

const UserName = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.darkGrey};
`

const UserEmail = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey[800]};
`

const ModalDesc = styled.div`
  margin-bottom: 20px;
  font-size: 18px;
`

const ModalContent = styled.div`
  width: 100%;
`

const SelectAllWrapper = styled.div`
  width: 100%;
  margin: 10px 0px;
  text-align: right;
`
