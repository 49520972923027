import React from 'react'
import styled from 'styled-components'
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined'
import Collapse from '@mui/material/Collapse'

const Panel = ({ title, open, children, completed, step, setActiveStep, fullWidth }) => {

  const handleEditClick = () => setActiveStep(step)

  return (
    <PanelWrapper>
      <PanelTitle>
        <div>
          {title}
          <TitleLine />
        </div>
        {completed && !open && (
          <EditIconWrapper onClick={handleEditClick}>
            <span>EDIT</span>
            <EditIcon />
          </EditIconWrapper>
        )}
      </PanelTitle>
      <PanelContent in={open} fullWidth={fullWidth}>
        {children}
      </PanelContent>
    </PanelWrapper>
  )
}

export default Panel

const PanelWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 16px;
  box-shadow: 0 2px 9px rgba(0, 0, 0, .03);
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px;
  overflow: hidden;
`

const PanelTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.colors.haiti};
  font-size: 18px;
  letter-spacing: .15px;
`

const TitleLine = styled.div`
  height: 4px;
  width: 50px;
  border-radius: 2px;
  margin-top: 10px;
  background-color: ${({ theme }) => theme.colors.secondary};
`

const EditIcon = styled(CreateOutlinedIcon)`
  font-size: 20px;
`

const PanelContent = styled(Collapse)`
  box-sizing: border-box;
  margin: ${(props) => props.in ? '20px auto 0' : '0 auto'};
  width: ${({ fullWidth }) => fullWidth ? '100%' : '320px'};
`

const EditIconWrapper = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  display: flex;
  align-items: center;
  
  span {
    margin-right: 5px;
    font-size: 16px;
    transition: all .3s;
    opacity: 0;
  }
  
  &:hover {
    span {
      opacity: 1;
    }
  }
`
