import React, { useEffect, useMemo, useState } from 'react'
import { useDrop } from 'react-dnd'
import styled from 'styled-components'
import { redirect } from 'utils/paths'
import { pathOr } from 'ramda'
import { isNotNilOrEmpty } from 'utils/ramda'
import AnalyticProgressBar from 'features/analytics/compontents/AnalyticProgressBar'
import { attachAnalyticsTemplateRoutine } from 'features/analytics/ducks/actions'
import { useDispatch } from 'react-redux'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { toast } from 'react-toastify'

const BpDropContainer = ({
  currentPage,
  bp,
  bps,
  index,
  handleOpenRemoveModal
}) => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const dropdownOpen = Boolean(anchorEl)
  const isDormant = bp.status === 'dormant'
  const [{ isOver }, dropRef] = useDrop({
    accept: 'template',
    drop: (item, monitor) => {
      if (!monitor.didDrop() && !isDormant) {
        // Prevent drops on dormant BPs
        assignItem(item, bps[index])
      }
      return { containerIndex: index }
    },
    collect: monitor => ({
      isOver: !isDormant && monitor.isOver() // Only show hover state for non-dormant BPs
    })
  })

  const openMenu = e => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const assignItem = (template, buildingPassport) => {
    const findTemplate = buildingPassport.analyticsTemplates.find(
      temp => temp.id === template.id
    )

    if (!findTemplate) {
      dispatch(
        attachAnalyticsTemplateRoutine({
          page: currentPage,
          bpId: buildingPassport.id,
          templateId: template.id
        })
      )
    } else {
      toast.error('This template is already assigned.')
    }
  }

  const templatesSortedByType = useMemo(() => {
    return isNotNilOrEmpty(bp.analyticsTemplates)
      ? [
          ...bp.analyticsTemplates.filter(
            template => template.type === 'default'
          ),
          ...bp.analyticsTemplates.filter(
            template => template.type === 'custom'
          )
        ]
      : []
  }, [bp])

  return (
    <PageWrapper>
      <FormContent ref={dropRef} isHighlighted={isOver} isDormant={isDormant}>
        <Header>
          <TitleWrapper
            onClick={() => redirect(`/bp-list/${bp.id}/floorplans`)}
          >
            <Title isDormant={isDormant}>{bp.name}</Title>
            <Address>
              {bp.street}, {bp.city}, {bp.postalCode}
            </Address>
          </TitleWrapper>
          {!isDormant && (
            <IconWrapper>
              <MoreIcon onClick={openMenu} />
              <Menu
                keepMounted
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                style={{
                  marginTop: '10px'
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                open={dropdownOpen}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() =>
                    redirect(`/bp-list/${bp.id}`, { path: 'analytics' })
                  }
                >
                  See Building Passport
                </MenuItem>
              </Menu>
            </IconWrapper>
          )}
        </Header>
        <AssignedTemplatesContainer>
          {templatesSortedByType.map(template => {
            return (
              <AnalyticProgressBar
                currentPage={currentPage}
                handleOpenRemoveModal={handleOpenRemoveModal}
                key={`progress-${template.id}`}
                template={template}
                bp={bp}
              />
            )
          })}
        </AssignedTemplatesContainer>
      </FormContent>
    </PageWrapper>
  )
}

export default BpDropContainer

const PageWrapper = styled.div`
  box-sizing: border-box;
  margin-bottom: 20px;
  width: 100%;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s;
  width: 100%;
  margin-bottom: ${({ open }) => (open ? '20px' : '0')};
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: baseline;
  cursor: pointer;
  width: fit-content;
`

const Address = styled.div`
  color: ${({ theme }) => theme.colors.grey[700]};
  margin-left: 10px;
  font-size: 14px;
`

const Title = styled.div`
  font-size: 22px;
  color: ${({ isDormant, theme }) =>
    !isDormant ? '#000' : theme.colors.grey[800]};
`

const FormContent = styled.div`
  box-sizing: border-box;
  width: 100%;
  transition: all 0.3s;
  background-color: ${({ isHighlighted, isDormant, theme }) =>
    isDormant ? theme.colors.grey[400] : isHighlighted ? '#d6f5f5' : 'white'};
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.03);
  padding: 20px;
  cursor: ${({ isDormant }) => (isDormant ? 'default' : 'pointer')};
`

const AssignedTemplatesContainer = styled.div`
  margin: 20px 0;
`

const MoreIcon = styled(MoreVertIcon)`
  color: ${({ theme }) => theme.colors.grey[600]};
  cursor: pointer;
  font-size: 20px !important;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`
