import APIService from './APIService'
import { dissoc } from 'ramda'

const api = APIService()

export const getAnalyticsTemplates = () => api.get('/analytics-templates')

export const getBpsWithAnalytics = payload => api.get(`/building-passport/analytics?page=${payload}&limit=30`)

export const getAnalyticsSharingInvitations = () => api.get('/analytics-templates/sharing-invitations')

export const acceptAnalyticsSharingInvitation = payload => api.patch(`/analytics-templates/${payload}/share`)

export const rejectAnalyticsSharingInvitation = payload => api.delete(`/analytics-templates/${payload}/share`)

export const createAnalyticsTemplate = payload => api.post('/analytics-templates', payload)

export const updateAnalyticsTemplate = payload => api.patch(`/analytics-templates/${payload.id}`, dissoc('id', payload))

export const duplicateAnalyticsTemplate = payload => api.post(`/analytics-templates/${payload.id}/duplicate`)

export const shareAnalyticsTemplate = ({ templateId, userId }) =>
  api.post(`/analytics-templates/${templateId}/share`, { userId })

export const removeAnalyticsTemplate = payload => api.delete(`/analytics-templates/${payload.id}`)

export const attachAnalyticsTemplate = ({ bpId, templateId }) =>
  api.post(`/building-passport/${bpId}/analytics-template/${templateId}`)

export const detachAnalyticsTemplate = ({ bpId, templateId }) =>
  api.delete(`/building-passport/${bpId}/analytics-template/${templateId}`)

export const attachAnalyticsTemplateToAllBps = ({ templateId, building_passport_ids, attach_to_all_users }) =>
  api.post(`/analytics-templates/${templateId}/attach-to-all-my-building-passports`, {
    building_passport_ids,
    attach_to_all_users,
  });

export const getActiveBuildingPassports = () => api.get('/building-passport/active');
