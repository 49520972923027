import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import LocalStorageService from 'services/LocalStorageService'
import { KEYS } from 'utils/localStorage'
import axios from 'axios'

function getCookieValue(name) {
  const match = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')
  return match ? decodeURIComponent(match[2]) : ''
}

const xsrfToken = getCookieValue('XSRF-TOKEN')
window.Pusher = Pusher
const token = LocalStorageService.get(KEYS.token)
const authToken = token
  ? token.includes('Bearer')
    ? token
    : `Bearer ${token}`
  : ''
window.Echo = new Echo({
  broadcaster: 'reverb',
  key: process.env.REACT_APP_REVERB_APP_KEY,
  wsHost: process.env.REACT_APP_REVERB_HOST || window.location.hostname,
  wsPort: process.env.REACT_APP_REVERB_PORT || 8080,
  wssPort: process.env.REACT_APP_REVERB_PORT || 8080,
  forceTLS: process.env.REACT_APP_REVERB_SECURE === 'true',
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
  auth: {
    headers: {
      Authorization: authToken,
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-XSRF-TOKEN': xsrfToken
    }
  },
  csrfToken: xsrfToken,
  authorizer: (channel, options) => {
    return {
      authorize: (socketId, callback) => {
        // Get fresh token in case it changed
        const freshToken = LocalStorageService.get(KEYS.token)
        const freshAuthToken = freshToken
          ? freshToken.includes('Bearer')
            ? freshToken
            : `Bearer ${freshToken}`
          : ''

        // Make a standard form post to avoid CSRF issues
        const formData = new FormData()
        formData.append('socket_id', socketId)
        formData.append('channel_name', channel.name)

        // Add CSRF token to URL to bypass middleware
        const authUrl = `${process.env.REACT_APP_API_URL}/broadcasting/auth`

        axios({
          method: 'POST',
          url: authUrl,
          data: formData,
          headers: {
            Authorization: freshAuthToken,
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': xsrfToken
          },
          withCredentials: true
        })
          .then(response => {
            // Ensure data has the exact format Pusher expects
            if (response.data && !response.data.auth) {
              console.warn('Auth response missing "auth" field!')
            }
            callback(false, response.data)
          })
          .catch(error => {
            console.error(
              `Auth error for channel ${channel.name}:`,
              error.response || error
            )
            callback(true, error)
          })
      }
    }
  },
  authEndpoint: `${process.env.REACT_APP_API_URL}/broadcasting/auth`
})
