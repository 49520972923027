import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import CloseIcon from '@mui/icons-material/Close'

import {
  TransformWrapper,
  TransformComponent
} from 'react-zoom-pan-pinch'
import { pathOr } from 'ramda'
import { markFileAsViewed } from 'services/fileService'

const ImageViewer = ({ file, open, onClose, version }) => {
  const timeoutRef = useRef(null)

  useEffect(() => {
    if (open) {
      if (!timeoutRef.current) {
        timeoutRef.current = setTimeout(() => {
          const fileId = file.id
          const fileVersionId = version ? version.id : pathOr('', ['versions', 0, 'id'], file)

          markFileAsViewed({
            fileId,
            fileVersionId,
            type: 'view'
          })
        }, 4000)
      }
    } else {
      if (timeoutRef.current) {
        clearInterval(timeoutRef.current)
        timeoutRef.current = null
      }
    }

    return () => {
      if (timeoutRef.current) {
        clearInterval(timeoutRef.current)
        timeoutRef.current = null
      }
    }
  }, [open, file])

  return open ? (
    <Overlay>
      <Wrapper>
        <TransformWrapper
          initialScale={0.7}
          initialPositionX={200}
          initialPositionY={100}
          centerOnInit
          centerZoomedOut
          disablePadding
          minScale={0.1}
        >
          <>
            <Header>
              <StyledCloseIcon onClick={onClose} />
            </Header>
            <TransformComponent>
              <img src={file.url} alt={file.name} />
            </TransformComponent>
          </>
        </TransformWrapper>
      </Wrapper>
    </Overlay>
  ) : null
}

export default ImageViewer

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, .7);
  display: flex;
  justify-content: center;
`

const Wrapper = styled.div`
  height: 90vh;
  width: 100%;
  padding: 20px;
  overflow: hidden;

  .react-transform-wrapper {
    width: 100% !important;
    height: 100% !important;
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
`

const StyledCloseIcon = styled(CloseIcon)`
  transition: all .3s;
  cursor: pointer;
  font-size: 30px !important;
  color: #fff;

  &:hover {
    color: #f2f2f2;
  }
`
