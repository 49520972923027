const emptyFilesState = {
  isUploading: false,
  uploadProcess: null,
  uploadProcessFetchDetails: null,
  currentBpFiles: [],
  bpFilesList: [],
  fileTypes: [],
  fileTags: [],
  multiUploadFiles: [],
  webSocketInitialized: false,
  webSocketProcessId: null
}

export default emptyFilesState
