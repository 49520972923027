import APIService from 'services/APIService'
import qs from 'query-string'
import { dissoc } from 'ramda'
import LocalStorageService from 'services/LocalStorageService'
import { KEYS } from 'utils/localStorage'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-toastify'
import axios from 'axios'

const api = APIService()

export const assignUserToBp = ({ bpId, body }) =>
  api.post(`/building-passport/${bpId}/assigned-users`, body)
export const revokeAccessToBp = ({ bpId, relationId }) =>
  api.patch(`/building-passport/${bpId}/assigned-users/${relationId}/revoke`)
export const removeAccessToBp = ({ bpId, relationId }) =>
  api.delete(`/building-passport/${bpId}/assigned-users/${relationId}`)
export const restoreAccessToBp = ({ bpId, relationId }) =>
  api.patch(
    `/building-passport/${bpId}/assigned-users/${relationId}/reactivate`
  )
export const editUserRelationToBp = ({ bpId, relationId, body }) =>
  api.patch(`/building-passport/${bpId}/assigned-users/${relationId}`, body)
export const requestAccessToBp = ({ bpId, relationType }) =>
  api.post(`/building-passport/${bpId}/access-requests`, { relationType })
export const acceptAccessRequest = ({ bpId, requestId }) =>
  api.post(`/building-passport/${bpId}/access-requests/${requestId}/accept`)
export const denyAccessRequest = ({ bpId, requestId, reason }) =>
  api.delete(`/building-passport/${bpId}/access-requests/${requestId}`, {
    reason
  })
export const searchInAllBps = ({ query }) =>
  api.get(`/building-passport/search?filter[search]=${query}`)
export const getAccessRequests = ({ bpId }) =>
  api.get(`/building-passport/${bpId}/access-requests`)
export const getInvitationsListForBp = id =>
  api.get(`/building-passport/${id}/invitations`)

export const inviteUser = ({ bpId, body }) =>
  api.post(`/building-passport/${bpId}/invitations`, body)
export const cancelInvitation = ({ bpId, invitationId }) =>
  api.delete(`/building-passport/${bpId}/invitations/${invitationId}`)

export const fetchUserBps = ({ sort, page, filters }) =>
  api.get(
    `/building-passport?${qs.stringify({ sort })}&append=net_price&page=${page}${filters || ''}`
  )

export const fetchFavouriteBps = ({ sort, page, filters }) =>
  api.get(
    `/building-passport/favorites?limit=100&${qs.stringify({ sort })}&page=${page}${filters || ''}`
  )

export const fetchDashboardStats = () => api.get(`/me/dashboard`)

export const addToFavourites = ({ bpId }) =>
  api.post(`/building-passport/favorites/${bpId}`)

export const removeFromFavourites = ({ bpId }) =>
  api.delete(`/building-passport/favorites/${bpId}`)

export const searchBps = payload =>
  api.get(`/building-passport?sort=name&page=1&filter[search]=${payload}`)

export const searchFiles = payload =>
  api.get(`/building-passport/files/search?query=${payload}`)

export const markAsSearched = ({ bpId }) =>
  api.patch(`/building-passport/${bpId}/mark-as-searched`)

export const markFileAsSearched = ({ fileId }) =>
  api.patch(`/building-passport/files/${fileId}/mark-as-searched`)
export const markAsViewed = ({ bpId }) =>
  api.patch(`/building-passport/${bpId}/mark-as-viewed`)

export const fetchLastSearchedBps = () =>
  api.get('/building-passport/last-search')

export const fetchLastSearchedFiles = () =>
  api.get('/building-passport/files/last-search')

export const fetchLastViewedBps = () => api.get('/building-passport/last-view')

export const fetchBp = id => api.get(`/building-passport/${id}`)

export const getBuildingTypes = () => api.get('/dictionaries/building-types')

export const getBpConnectedUsers = payload =>
  api.get(`/dictionaries/users?filter[name]=${payload}`)

export const getBpConnectedUsersByEmail = payload =>
  api.get(`/dictionaries/users?filter[email]=${payload}`)

export const getBpActivityLog = payload =>
  api.get(`/building-passport/${payload.id}/audit-log`)

export const getUsers = payload =>
  api.get(`/dictionaries/users?filter[email]=${payload}`)

export const createBp = payload => api.post('/building-passport', payload)

export const deleteBp = id => api.delete(`/building-passport/${id}`)

export const updateBp = payload =>
  api.patch(`/building-passport/${payload.id}`, dissoc('id', payload))

export const addNote = payload =>
  api.post(`/building-passport/${payload.id}/note`, {
    ...dissoc('id', payload)
  })

export const deleteNote = ({ bpId, noteId }) =>
  api.delete(`/building-passport/${bpId}/note/${noteId}`)

export const updateNote = ({ bpId, noteId, message }) =>
  api.patch(`/building-passport/${bpId}/note/${noteId}`, { message })

export const getBpsPrices = payload =>
  api.post(`/building-passport/price-calculations`, payload)

export const createPayment = payload => api.post(`/subscriptions`, payload)

export const uploadFile = ({ uploadedImage, objectID, isFloorPlan, type }) => {
  const apiToken = LocalStorageService.get(KEYS.token)

  const fd = new FormData()
  fd.append('uploadedImage', uploadedImage)
  fd.append('objectID', objectID)
  fd.append('token', apiToken)
  fd.append('fileType', type)
  fd.append('private', 'true')
  isFloorPlan && fd.append('isFloorPlan', isFloorPlan)

  return axios
    .post(process.env.REACT_APP_FILE_SERVICE_URL, fd)
    .then(resp => resp.data)
    .catch(error => {
      getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    })
}

export const addFileVersion = payload =>
  api.post(
    `/building-passport/files/${payload.id}/version`,
    dissoc('id', payload)
  )
export const removeFileVersion = payload =>
  api.delete(`/building-passport/files/${payload.fileId}/${payload.versionId}`)
export const reorderFileVersions = payload =>
  api.patch(`/building-passport/files/${payload.fileId}/versions-order`, {
    versionsOrder: payload.versionsOrder
  })

export const updateFile = ({ id, body, fileVersionId }) =>
  api.patch(`/building-passport/files/${id}/${fileVersionId}`, body)

export const shareFileWithUnits = payload =>
  api.post(`/building-passport/files/${payload.fileId}/share`, payload)

export const getSharedUnitsListForFile = payload =>
  api.get(`/building-passport/files/${payload}/share`)

export const removeFile = ({ id }) =>
  api.delete(`/building-passport/files/${id}`)

export const deleteUserAccount = payload =>
  api.delete(`/me/request-account-deletion`, payload)

export const getFileTypes = () => api.get('/dictionaries/file-types')

export const checkBasketAvailability = payload =>
  api.post('/basket/check-availability', payload)

export const addBuildingTypeToBp = payload =>
  api.patch(`/building-passport/${payload.bpId}/building-types`, { ...payload })

export const getBpsCount = () =>
  api.get('/building-passport/count?filter[status]=active,dormant')
export const getUnitsCount = () => api.get('/building-passport/units/count')

export const getUserRelationToBp = payload =>
  api.get(
    `/building-passport/${payload.bpId}/assigned-users/roles?user_id=${payload.userId}`
  )

export const computeGuruMessages = payload =>
  api.post(`/building-passport/${payload.bpId}/guru`, payload)
