/* eslint-disable */
import { createRoutine } from 'redux-saga-routines'
import {
  call,
  delay,
  fork,
  put,
  select,
  takeLatest
} from '@redux-saga/core/effects'
import { dissoc, isEmpty, pathOr, propOr } from 'ramda'
import * as bpService from 'services/BpService'
import * as fileService from 'services/fileService'
import { toast } from 'react-toastify'
import * as unitService from 'services/unitsService'
import { isNotNilOrEmpty } from 'utils/ramda'
import { fileToUploadPath, thumbnailToUploadPath } from 'utils/files'
import { fetchUnitRoutine } from 'ducks/units/actions'
import { getApiErrors } from 'utils/errors'
import { fetchBpRoutine } from 'features/bpDetails/ducks/actions'

export const fileStatusUpdateRoutine = createRoutine('FILE_STATUS_UPDATE')
export const initFileWebSocketRoutine = createRoutine('INIT_FILE_WEBSOCKET')
export const updateFileStatusRoutine = createRoutine('UPDATE_FILE_STATUS')
export const cleanupFileWebSocketRoutine = createRoutine(
  'CLEANUP_FILE_WEBSOCKET'
)
export const reconnectWebSocketRoutine = createRoutine('RECONNECT_WEBSOCKET')
export const getBpFilesRoutine = createRoutine('GET_BP_FILES')
export const getFileTypesRoutine = createRoutine('GET_FILE_TYPES')
export const finalizeFileRoutine = createRoutine('FINALIZE_FILE')
export const updateFileRoutine = createRoutine('UPDATE_FILE')
export const shareFileRoutine = createRoutine('SHARE_FILE')
export const downloadFileRoutine = createRoutine('DOWNLOAD_FILE')
export const removeFileRoutine = createRoutine('REMOVE_FILE')
export const clearBpFilesRoutine = createRoutine('CLEAR_BP_FILES')
export const getSharedUnitsListForFileRoutine = createRoutine(
  'GET_SHARED_UNITS_FOR_FILE'
)
export const clearSharedUnitsListForFileRoutine = createRoutine(
  'CLEAR_SHARED_UNITS_FOR_FILE'
)
export const addFileVersionRoutine = createRoutine('ADD_FILE_VERSION')
export const getUploadProcessRoutine = createRoutine('GET_UPLOAD_PROCESS')
export const clearUploadProcessRoutine = createRoutine('CLEAR_UPLOAD_PROCESS')
export const saveUploadProcessFetchDetailsRoutine = createRoutine(
  'SAVE_UPLOAD_PROCESS'
)
export const clearUploadProcessFetchDetailsRoutine = createRoutine(
  'CLEAR_UPLOAD_PROCESS_DETAILS'
)

export const getFileTagsForBpRoutine = createRoutine('GET_FILE_TAGS_FOR_BP')
export const createFileTagRoutine = createRoutine('CREATE_FILE_TAG')
export const removeFileTagRoutine = createRoutine('REMOVE_FILE_TAG')
export const attachFileTagToFileRoutine = createRoutine('ATTACH_FILE_TAG_TO_BP')
export const syncFileTagsInFileRoutine = createRoutine('SYNC_FILE_TAGS')
export const syncFileTagsInFileVersionRoutine = createRoutine(
  'SYNC_FILE_VERSION_TAGS'
)
export const detachFileTagFromFileRoutine = createRoutine(
  'DETACH_FILE_TAG_FROM_BP'
)

function* uploadFileVersion({ payload }) {
  const {
    bpId,
    unitId,
    uploadedImage,
    objectID,
    type,
    producedBy,
    creationDate,
    isFloorPlan,
    fileTitle,
    legalOwner,
    expirationDate,
    expirationReminderDate,
    approvedBy,
    segment,
    dateOfIssue,
    fileId,
    previousVersion,
    callback
  } = payload
  yield put(addFileVersionRoutine.request())
  try {
    const result = yield call(bpService.uploadFile, {
      uploadedImage,
      isFloorPlan,
      objectID,
      type
    })
    const path = propOr('', 'universalPath', result)
    if (type === 'building_unit_passport_file') {
      yield call(unitService.addUnitFileVersion, {
        fileId,
        bpId,
        unitId,
        previousVersion,
        path: fileToUploadPath(path, isFloorPlan),
        thumbnailPath: isFloorPlan ? thumbnailToUploadPath(path, true) : null,
        name: isNotNilOrEmpty(fileTitle)
          ? fileTitle
          : pathOr('', ['original_file_name'], result),
        producedBy,
        creationDate,
        legalOwner,
        expirationDate,
        expirationReminderDate,
        approvedBy,
        segment,
        dateOfIssue
      })
      yield put(fetchUnitRoutine({ bpId: bpId, unitId: objectID }))
    } else {
      yield call(bpService.addFileVersion, {
        id: fileId,
        previousVersion,
        path: fileToUploadPath(path, isFloorPlan),
        thumbnailPath: isFloorPlan ? thumbnailToUploadPath(path, true) : null,
        name: isNotNilOrEmpty(fileTitle)
          ? fileTitle
          : pathOr('', ['original_file_name'], result),
        producedBy,
        creationDate,
        legalOwner,
        expirationDate,
        expirationReminderDate,
        approvedBy,
        segment,
        dateOfIssue
      })
      yield put(getBpFilesRoutine({ id: bpId }))
    }
    yield put(addFileVersionRoutine.success())
    toast.success('Adding a file...')
    callback()
  } catch (error) {
    yield put(addFileVersionRoutine.failure(error))
    toast.error('Something went wrong')
  }
}

function* removeFile({ payload }) {
  yield put(removeFileRoutine.request())
  const { id, bpId, callback } = payload
  try {
    yield call(bpService.removeFile, { id })
    yield put(removeFileRoutine.success(id))
    toast.success('File has been deleted')
    yield put(getBpFilesRoutine({ id: bpId }))
    yield put(fetchBpRoutine({ id: bpId }))
    if (callback) {
      callback()
    }
  } catch (error) {
    yield put(removeFileRoutine.failure(error))
  }
}

function* getBpFiles({ payload }) {
  const { callback, id } = payload
  yield put(getBpFilesRoutine.request())
  try {
    if (id) {
      let allFiles = []
      let page = 1
      let hasMoreFiles = true

      while (hasMoreFiles) {
        const { data } = yield call(fileService.getFilesListForBp, {
          ...dissoc('callback', payload),
          query: { page, limit: 250 }
        })

        const filesForPage = data?.data || []
        allFiles = [...allFiles, ...filesForPage]

        hasMoreFiles = filesForPage.length === 250
        page++
      }

      yield put(getBpFilesRoutine.success(allFiles))
      typeof callback === 'function' && callback()
    }
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(getBpFilesRoutine.failure(error))
  }
}
function* getUploadProcess({ payload }) {
  yield put(getUploadProcessRoutine.request())
  try {
    if (isNotNilOrEmpty(payload.unitId)) {
      const { data } = yield call(
        fileService.getUnitMultiuploadProcessProgress,
        payload
      )
      yield put(getUploadProcessRoutine.success(data.data))
    } else {
      const { data } = yield call(
        fileService.getMultiuploadProcessProgress,
        payload
      )
      yield put(getUploadProcessRoutine.success(data.data))
    }
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(getUploadProcessRoutine.failure(error))
  }
}

function* clearBpFiles() {
  yield put(clearBpFilesRoutine.success())
}

function* getFileTypes() {
  yield put(getFileTypesRoutine.request())
  try {
    const result = yield call(bpService.getFileTypes)
    yield put(getFileTypesRoutine.success(result.data.data))
  } catch (error) {
    yield put(getFileTypesRoutine.failure(error))
  }
}

function* updateFile({ payload }) {
  const { clearValues } = payload
  yield put(updateFileRoutine.request())
  try {
    yield call(bpService.updateFile, payload)
    yield put(updateFileRoutine.success())
    toast.success('File has been updated')
    yield put(getBpFilesRoutine({ id: payload.bpId }))
    typeof clearValues === 'function' && clearValues()
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(updateFileRoutine.failure(error))
  }
}

function* finalizeFile({ payload }) {
  yield put(finalizeFileRoutine.request())
  try {
    yield call(fileService.finalizeMultiUploadProcess, payload)
    yield put(finalizeFileRoutine.success())
    yield put(getBpFilesRoutine({ id: payload.bpId }))
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(finalizeFileRoutine.failure(error))
  }
}

function* shareFile({ payload }) {
  yield put(shareFileRoutine.request())
  try {
    yield call(bpService.shareFileWithUnits, payload)
    yield put(shareFileRoutine.success())
    yield put(getBpFilesRoutine({ id: payload.bpId }))
    isEmpty(payload.buildingUnitPassportsIds)
      ? toast.success('File has been unshared')
      : toast.success('File has been shared')
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(shareFileRoutine.failure(error))
  }
}

function* getSharedUnitsListForFile({ payload }) {
  yield put(getSharedUnitsListForFileRoutine.request())
  try {
    const { data } = yield call(bpService.getSharedUnitsListForFile, payload)
    yield put(getSharedUnitsListForFileRoutine.success(data.data))
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(getSharedUnitsListForFileRoutine.failure(error))
  }
}

function* clearSharedUnitsListForFile() {
  yield put(clearSharedUnitsListForFileRoutine.success())
}

function* downloadFile({ payload }) {
  yield put(downloadFileRoutine.request())
  try {
    const anchor = document.createElement('a')
    anchor.href =
      process.env.REACT_APP_API_URL +
      `/building-passport/files/${payload}/download`
    anchor.target = '_blank'
    anchor.download = 'file.pdf'
    anchor.click()
    yield put(downloadFileRoutine.success())
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(downloadFileRoutine.failure(error))
  }
}

function* saveUploadProcessId({ payload }) {
  yield put(saveUploadProcessFetchDetailsRoutine.success(payload))
}

function* clearUploadProcessId() {
  yield put(clearUploadProcessFetchDetailsRoutine.success())
}

function* initializeFileWebSocket({ payload }) {
  const { processId, files, bpId } = payload

  try {
    // Clear any existing channels first
    yield call(cleanupExistingWebSockets)

    if (!window.Echo) {
      console.error('Echo instance not available')
      yield put(
        initFileWebSocketRoutine.failure(new Error('Echo not available'))
      )
      return
    }

    window.fileChannels = {}
    window.fileLastActivity = {}

    // Store process and files for reconnection
    window.lastProcessId = processId
    window.lastFiles = files
    window.lastBpId = bpId

    const keepAliveInterval = setInterval(() => {
      try {
        if (pusher.connection.state === 'connected') {
          pusher.connection.ping()
        } else {
          pusher.connection.connect()
        }
      } catch (error) {
        console.error('Keep-alive ping error:', error)
      }
    }, 30000)

    window.keepAliveIntervalId = keepAliveInterval

    const pollingInterval = setInterval(() => {
      try {
        if (window.lastBpId && window.lastProcessId) {
          const currentState = window.reduxStore.getState()
          const multiUploadFiles = currentState.files.multiUploadFiles // Adjust path as needed

          // Store for reference
          window.multiUploadFiles = multiUploadFiles

          // Check if we still need to poll
          const allFilesCompleted =
            multiUploadFiles &&
            multiUploadFiles.length > 0 &&
            multiUploadFiles.every(file =>
              ['completed', 'failed'].includes(file.status)
            )

          if (allFilesCompleted) {
            // All files are already completed, no need to poll anymore
            console.log('All files are complete, stopping polling')
            clearInterval(pollingInterval)
            return
          }

          // Otherwise continue polling
          console.log('Polling for latest status updates')
          window.reduxStore.dispatch(
            getUploadProcessRoutine({
              processId: window.lastProcessId,
              bpId: window.lastBpId
            })
          )
        }
      } catch (error) {
        console.error('Polling interval error:', error)
      }
    }, 3000)

    window.monitorIntervalId = pollingInterval

    // Subscribe to each file's status channel
    if (files && files.length > 0) {
      files.forEach((file, index) => {
        try {
          if (!file.id) {
            console.error('File is missing ID:', file)
            return
          }

          const channelName = `upload-status.${file.id}`
          // console.log(`Subscribing to channel: ${channelName}`)

          // Initialize activity timestamp
          window.fileLastActivity[file.id] = Date.now()

          // Create channel
          const channel = window.Echo.private(channelName).listen(
            '.status-changed',
            data => {
              window.fileLastActivity[file.id] = Date.now()
              // console.log(`Received status update for file ${file.id}:`, data)

              window.reduxStore.dispatch(
                updateFileStatusRoutine({
                  fileId: file.id,
                  fileIndex: index,
                  status: data.status,
                  storedFile: data.stored_file || null,
                  displayName: data.data?.display_name || null
                })
              )
            }
          )

          window.fileChannels[file.id] = channel
        } catch (err) {
          console.error(`Failed to create channel for file ${file.id}:`, err)
        }
      })

      yield put(
        initFileWebSocketRoutine.success({
          initialized: true,
          processId,
          files
        })
      )
    } else {
      console.warn('No files provided for WebSocket initialization')
      yield put(
        initFileWebSocketRoutine.failure(new Error('No files provided'))
      )
    }
  } catch (error) {
    console.error('Failed to initialize WebSockets:', error)
    yield put(initFileWebSocketRoutine.failure(error))
  }
}

// Clean up existing WebSockets
function* cleanupExistingWebSockets() {
  try {
    if (window.keepAliveIntervalId) {
      clearInterval(window.keepAliveIntervalId)
      window.keepAliveIntervalId = null
    }

    if (window.pollingIntervalId) {
      clearInterval(window.pollingIntervalId)
      window.pollingIntervalId = null
    }

    if (window.fileChannels) {
      Object.keys(window.fileChannels).forEach(fileId => {
        try {
          window.fileChannels[fileId].stopListening('.status-changed')
          window.fileChannels[fileId].unsubscribe()
        } catch (e) {
          console.error(`Error cleaning up channel for file ${fileId}:`, e)
        }
      })
      window.fileChannels = {}
    }

    if (window.Echo && window.Echo.connector.pusher.connection) {
      window.Echo.connector.pusher.connection.disconnect()
    }
  } catch (error) {
    console.error('Error cleaning up WebSockets:', error)
  }
}
// Reconnect WebSockets
function* reconnectWebSocket({ payload }) {
  const { processId, files } = payload

  try {
    const currentFilesStatus = yield select(
      state => state.files.multiUploadFiles
    )
    // Clean up existing connections
    yield call(cleanupExistingWebSockets)

    // console.log('Reconnecting WebSockets for process:', processId)

    // Wait a bit before attempting to reconnect
    yield delay(2000)

    // Re-initialize channels
    yield put(
      initFileWebSocketRoutine({
        processId,
        files: files.map(file => {
          // Preserve existing status for files by matching IDs
          const existingFile = currentFilesStatus.find(f => f.id === file.id)
          if (existingFile) {
            return {
              ...file,
              status: existingFile.status || file.status,
              storedFile: existingFile.storedFile || file.storedFile,
              displayName: existingFile.displayName || file.displayName
            }
          }
          return file
        })
      })
    )

    // Reset activity timestamp to prevent multiple reconnections
    window.lastWebSocketActivity = Date.now()
  } catch (error) {
    console.error('Failed to reconnect WebSockets:', error)
  }
}

// Cleanup WebSockets
function* cleanupFileWebSocket() {
  try {
    yield call(cleanupExistingWebSockets)
    yield put(cleanupFileWebSocketRoutine.success())
  } catch (error) {
    console.error('Failed to clean up WebSockets:', error)
    yield put(cleanupFileWebSocketRoutine.failure(error))
  }
}

function* getFileTagsForBp({ payload }) {
  yield put(getFileTagsForBpRoutine.request())
  try {
    const { data } = yield call(fileService.getTagsListForBp, payload)
    yield put(getFileTagsForBpRoutine.success(data.data))
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(getFileTagsForBpRoutine.failure(error))
  }
}

function* createFileTag({ payload }) {
  const { callback } = payload
  yield put(createFileTagRoutine.request())
  try {
    const { data } = yield call(
      fileService.createFileTag,
      dissoc('callback', payload)
    )
    yield put(createFileTagRoutine.success(data.data))

    typeof callback === 'function' && callback()
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(createFileTagRoutine.failure(error))
  }
}

function* removeFileTag({ payload }) {
  yield put(removeFileTagRoutine.request())
  try {
    const { data } = yield call(fileService.removeFileTag, payload)
    yield put(removeFileTagRoutine.success(data.data))

    yield put(getFileTagsForBpRoutine({ bpId: payload.bpId }))
    yield put(getBpFilesRoutine({ id: payload.bpId }))
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(removeFileTagRoutine.failure(error))
  }
}

function* attachFileTagToFile({ payload }) {
  const { callback } = payload
  yield put(attachFileTagToFileRoutine.request())
  try {
    const { data } = yield call(fileService.attachFileTagToFile, payload)
    yield put(attachFileTagToFileRoutine.success(data.data))

    typeof callback === 'function' && callback()
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(attachFileTagToFileRoutine.failure(error))
  }
}

function* syncFileTagsInFile({ payload }) {
  const { callback, newTags } = payload
  yield put(syncFileTagsInFileRoutine.request())
  try {
    const { data } = yield call(fileService.syncFileTags, payload)
    yield put(syncFileTagsInFileRoutine.success(data.data))

    if (data.isTagDeleted || isNotNilOrEmpty(newTags)) {
      yield put(getFileTagsForBpRoutine({ bpId: payload.bpId }))
    }

    typeof callback === 'function' && callback()
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(syncFileTagsInFileRoutine.failure(error))
  }
}

function* syncFileTagsInFileVersion({ payload }) {
  const { callback, newTags } = payload
  yield put(syncFileTagsInFileVersionRoutine.request())
  try {
    const { data } = yield call(fileService.syncFileVersionTags, payload)
    yield put(syncFileTagsInFileVersionRoutine.success(data.data))

    if (data.isTagDeleted || isNotNilOrEmpty(newTags)) {
      yield put(getFileTagsForBpRoutine({ bpId: payload.bpId }))
    }

    typeof callback === 'function' && callback()
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(syncFileTagsInFileVersionRoutine.failure(error))
  }
}

function* detachFileTagFromFile({ payload }) {
  yield put(detachFileTagFromFileRoutine.request())
  try {
    const { data } = yield call(fileService.detachFileTagFromFile, payload)
    yield put(detachFileTagFromFileRoutine.success(data.data))

    if (data.isTagDeleted) {
      yield put(getFileTagsForBpRoutine({ bpId: payload.bpId }))
    }
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(detachFileTagFromFileRoutine.failure(error))
  }
}

function* clearUploadProcess() {
  // Clean up any existing listeners
  try {
    Object.keys(window).forEach(key => {
      if (key.startsWith('file-upload-listeners-') && window[key]) {
        window[key].forEach(channel => {
          try {
            channel.stopListening('.status-changed')
            channel.unsubscribe()
          } catch (e) {
            console.error('Error cleaning up channel:', e)
          }
        })
        delete window[key]
      }
    })
  } catch (e) {
    console.error('Error cleaning up file upload listeners:', e)
  }

  yield put(clearUploadProcessRoutine.success())
}

export function* getFileTagsForBpWatcher() {
  yield takeLatest(getFileTagsForBpRoutine.TRIGGER, getFileTagsForBp)
}
export function* createFileTagWatcher() {
  yield takeLatest(createFileTagRoutine.TRIGGER, createFileTag)
}
export function* removeFileTagWatcher() {
  yield takeLatest(removeFileTagRoutine.TRIGGER, removeFileTag)
}
export function* attachFileTagToFileWatcher() {
  yield takeLatest(attachFileTagToFileRoutine.TRIGGER, attachFileTagToFile)
}
export function* syncFileTagsInFileWatcher() {
  yield takeLatest(syncFileTagsInFileRoutine.TRIGGER, syncFileTagsInFile)
}

export function* syncFileTagsInFileVersionWatcher() {
  yield takeLatest(
    syncFileTagsInFileVersionRoutine.TRIGGER,
    syncFileTagsInFileVersion
  )
}
export function* detachFileTagFromFileWatcher() {
  yield takeLatest(detachFileTagFromFileRoutine.TRIGGER, detachFileTagFromFile)
}

export function* getFileTypesWatcher() {
  yield takeLatest(getFileTypesRoutine.TRIGGER, getFileTypes)
}

export function* removeFileWatcher() {
  yield takeLatest(removeFileRoutine.TRIGGER, removeFile)
}

export function* getBpFilesWatcher() {
  yield takeLatest(getBpFilesRoutine.TRIGGER, getBpFiles)
}

export function* clearBpFilesWatcher() {
  yield takeLatest(clearBpFilesRoutine.TRIGGER, clearBpFiles)
}

export function* updateFileWatcher() {
  yield takeLatest(updateFileRoutine.TRIGGER, updateFile)
}

export function* finalizeFileWatcher() {
  yield takeLatest(finalizeFileRoutine.TRIGGER, finalizeFile)
}

export function* shareFileWatcher() {
  yield takeLatest(shareFileRoutine.TRIGGER, shareFile)
}

export function* downloadFileWatcher() {
  yield takeLatest(downloadFileRoutine.TRIGGER, downloadFile)
}

export function* getSharedUnitsListForFileWatcher() {
  yield takeLatest(
    getSharedUnitsListForFileRoutine.TRIGGER,
    getSharedUnitsListForFile
  )
}

export function* clearSharedUnitsListForFileWatcher() {
  yield takeLatest(
    clearSharedUnitsListForFileRoutine.TRIGGER,
    clearSharedUnitsListForFile
  )
}

export function* uploadFileVersionWatcher() {
  yield takeLatest(addFileVersionRoutine.TRIGGER, uploadFileVersion)
}

export function* initFileWebSocketWatcher() {
  yield takeLatest(initFileWebSocketRoutine.TRIGGER, initializeFileWebSocket)
}

export function* reconnectWebSocketWatcher() {
  yield takeLatest(reconnectWebSocketRoutine.TRIGGER, reconnectWebSocket)
}

export function* cleanupFileWebSocketWatcher() {
  yield takeLatest(cleanupFileWebSocketRoutine.TRIGGER, cleanupFileWebSocket)
}

export function* saveUploadProcessIdWatcher() {
  yield takeLatest(
    saveUploadProcessFetchDetailsRoutine.TRIGGER,
    saveUploadProcessId
  )
}

export function* clearUploadProcessIdWatcher() {
  yield takeLatest(
    clearUploadProcessFetchDetailsRoutine.TRIGGER,
    clearUploadProcessId
  )
}

export function* clearUploadProcessWatcher() {
  yield takeLatest(clearUploadProcessRoutine.TRIGGER, clearUploadProcess)
}

export function* getUploadProcessWatcher() {
  yield takeLatest(getUploadProcessRoutine.TRIGGER, getUploadProcess)
}

export const filesSagas = [
  fork(getFileTagsForBpWatcher),
  fork(createFileTagWatcher),
  fork(removeFileTagWatcher),
  fork(attachFileTagToFileWatcher),
  fork(syncFileTagsInFileWatcher),
  fork(syncFileTagsInFileVersionWatcher),
  fork(detachFileTagFromFileWatcher),
  fork(updateFileWatcher),
  fork(finalizeFileWatcher),
  fork(removeFileWatcher),
  fork(getBpFilesWatcher),
  fork(clearBpFilesWatcher),
  fork(getFileTypesWatcher),
  fork(downloadFileWatcher),
  fork(getSharedUnitsListForFileWatcher),
  fork(clearSharedUnitsListForFileWatcher),
  fork(shareFileWatcher),
  fork(uploadFileVersionWatcher),
  fork(saveUploadProcessIdWatcher),
  fork(clearUploadProcessIdWatcher),
  fork(clearUploadProcessWatcher),
  fork(initFileWebSocketWatcher),
  fork(reconnectWebSocketWatcher),
  fork(cleanupFileWebSocketWatcher),
  fork(getUploadProcessWatcher)
]
