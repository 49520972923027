import React from 'react'
import styled, { css } from 'styled-components'
import { pathOr, propOr, values } from 'ramda'
import File from 'features/bpDetails/Components/File'
import { useSelector } from 'react-redux'
import { getCurrentUserRole } from 'features/auth/ducks/selectors'
import { getFileTypeName } from 'utils/bpData'
import { getFileTypes } from 'ducks/files/selectors'

const SearchFileListItem = ({ file, onClick, disabled }) => {
  const userRole = useSelector(getCurrentUserRole)
  const fileTypes = useSelector(getFileTypes)
  const isFrsUser = userRole === 'emergency_service'

  return (
    <ItemWrapper
      onClick={disabled && !isFrsUser ? () => {} : onClick}
      disabled={disabled && !isFrsUser}
    >
      <File
        key={`file-${Math.random() * 99}`}
        file={file}
        type={getFileTypeName(
          pathOr('', ['fileType', 'id'], file),
          fileTypes,
          file.isPrivate
        )}
        isSharedWithUnits={file.isSharedWithUnits}
        isInSearchBar={true}
      />
    </ItemWrapper>
  )
}

export default SearchFileListItem

const ItemWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  cursor: pointer;
  transition: all 0.3s;
`

const UprnWrapper = styled.div`
  box-sizing: border-box;
  padding: 0 5px;
  min-width: 150px;
  display: flex;
  align-items: center;
`

const UprnCode = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey[800]};
  margin-left: 15px;
`

const BuildingName = styled.div`
  box-sizing: border-box;
  padding: 0 5px;
  width: 100%;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.haiti};
`

const BuildingTypes = styled.div`
  box-sizing: border-box;
  padding: 0 5px;
  width: 100%;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey[800]};
`

const UpdateDate = styled.div`
  box-sizing: border-box;
  padding: 0 5px;
  min-width: 120px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey[800]};
`
