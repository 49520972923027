/* eslint-disable */
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useTable } from 'react-table'
import { formatDate } from 'utils/date'
import { useTranslation } from 'react-i18next'
import { redirect } from 'utils/paths'
import { getOwner } from 'utils/hooks/values'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { isEmpty, pathOr } from 'ramda'
import { useDispatch } from 'react-redux'
import { fetchUnitRoutine } from 'ducks/units/actions'

const MyUnitsTable = ({ units, sortingOptions, sortingChange }) => {
  const { t } = useTranslation()
  const data = useMemo(() => units, [units])
  const dispatch = useDispatch()

  const handleRedirectToUnit = (bpId, unitId) => () => {
    dispatch(fetchUnitRoutine({
      bpId,
      unitId
    }))
    redirect(`/bp-list/${bpId}?tab=units`)
  }

  const columns = React.useMemo(
    () => [
      {
        Header: t('bpList.table.name'),
        id: 'name',
        accessor: 'name',
        width: '130px',
        defaultCanSort: true,
        Cell: d => <PropertyName>{d.value}</PropertyName>
      },
      {
        Header: t('bpList.table.uprn'),
        accessor: 'uprn',
        defaultCanSort: true,
        Cell: d => (
          <div>
            <div>
              {pathOr('Not yet allocated', ['value'], d)}
            </div>
            <BpUprn>
              {pathOr('Not yet allocated', ['buildingPassport', 'uprn'], d)}
            </BpUprn>
          </div>
        )
      },
      {
        Header: t('bpList.table.address'),
        id: 'building_passport.city',
        accessor: d => {
          const street = pathOr('---', ['buildingPassport', 'street'], d)
          const city = pathOr('---', ['buildingPassport', 'city'], d)
          return <div>{`${street}, ${city}`}</div>
        }
      },
      {
        Header: t('bpList.table.postcode'),
        id: 'building_passport.postal_code',
        accessor: d => {
          const postalCode = pathOr('---', ['buildingPassport', 'postalCode'], d)
          return <div>{postalCode}</div>
        }
      },
      {
        Header: 'Unit owner',
        id: 'building_passport.owner',
        accessor: d => {
          const owner = pathOr(null, ['owners', 0], d)
          return (
            <div>
              {
                owner
                  ? getOwner(d.owners[0])
                  : '---'
              }
            </div>
          )
        }
      },
      {
        Header: 'Created',
        id: 'created_at',
        accessor: 'createdAt',
        Cell: d => <DateString>{formatDate(d.value)}</DateString>
      },
      {
        Header: 'Updated',
        id: 'updated_at',
        accessor: 'updatedAt',
        Cell: d => <DateString>{formatDate(d.value)}</DateString>
      }
    ],
    []
  )

  const tableInstance = useTable({
    columns,
    data
  })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = tableInstance

  return (
    <TableWrapper>
      <table {...getTableProps()}>
        <thead>
        {
          headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {
                headerGroup.headers.map(column => (
                  <th
                    style={{ cursor: 'pointer', paddingRight: '20px' }}
                    {...column.getHeaderProps()}
                    onClick={sortingChange(column.id)}
                  >
                    {column.render('Header')}
                    <span>
                      {column.id === sortingOptions.column
                        ? <SortingArrowIcon down={!sortingOptions.desc} />
                        : ''}
                  </span>
                  </th>
                ))
              }
            </tr>
          ))
        }
        </thead>
        <tbody {...getTableBodyProps()}>
        {
          rows.map(row => {
            prepareRow(row)
            const bpId = pathOr('', ['original', 'buildingPassportId'], row)
            const unitId = pathOr('', ['original', 'id'], row)
            return (
              <tr
                {...row.getRowProps()}
                onClick={handleRedirectToUnit(bpId, unitId)}
              >
                {
                  row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>
                        {
                          cell.render('Cell')
                        }
                      </td>
                    )
                  })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {
        isEmpty(data) &&
        <EmptyState>
          No Units found.
        </EmptyState>
      }
    </TableWrapper>
  )
}

export default MyUnitsTable

const TableWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-x: auto;

  table {
    width: 100%;
  }

  th {
    box-sizing: border-box;
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: 11px;
    opacity: .5;
    text-transform: uppercase;
    text-align: left;
    height: 40px;
    padding: 0 10px;
    position: relative;
  }

  td {
    box-sizing: border-box;
    padding: 0 10px;
    height: 56px;
    font-size: 14px;
  }

  tbody > tr {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};

    &:hover {
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.lighterGrey};
    }
  }
`

const PropertyName = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
`

const DateString = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  opacity: .65;
`

const SortingArrowIcon = styled(ArrowUpwardIcon)`
  position: absolute;
  top: 10px;
  transition: all .3s;
  transform: ${({ down }) => `rotate(${down ? '-180deg' : 0})`};
  padding: 0 5px;
  font-size: 20px !important;
`

const EmptyState = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`

const BpUprn = styled.div`
  color: ${({ theme }) => theme.colors.grey[800]};
  font-size: 12px;
`
