import {createSelector} from 'reselect'
import {pathOr} from 'ramda'

const getSessionState = state => state.session

export const getSessionExpiresAt = createSelector(
  getSessionState,
  pathOr(null, ['expiresAt'])
)

export const getSessionRemainingSeconds = createSelector(
  getSessionState,
  pathOr(null, ['remainingSeconds'])
)

export const getWarningThresholdSeconds = createSelector(
  getSessionState,
  pathOr(300, ['warningThresholdSeconds'])
)

export const isSessionExpiring = createSelector(
  getSessionState,
  pathOr(false, ['isExpiring'])
)

export const isSessionExpired = createSelector(
  getSessionState,
  pathOr(false, ['isExpired'])
)

export const isWarningDismissed = createSelector(
  getSessionState,
  pathOr(false, ['warningDismissed'])
)

// Format remaining time as MM:SS
export const getFormattedRemainingTime = createSelector(
  getSessionRemainingSeconds,
  (seconds) => {
    if (seconds === null) return '--'

    // Round up to nearest minute (ceiling)
    const minutes = Math.ceil(seconds / 60)

    // Return just the minutes value
    return minutes.toString()
  }
)
