import React, { useEffect, useMemo, useState } from 'react'
import DetailsSection from 'features/bpDetails/Components/BpInfo/DetailsSection'
import styled from 'styled-components'
import { isNilOrEmpty, isNotNil, isNotNilOrEmpty } from 'utils/ramda'
import { useTranslation } from 'react-i18next'
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined'
import {
  getBuildingTypes,
  selectInvitationsList
} from 'features/bpList/ducks/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { toCamelCase } from 'js-convert-case'
import { propOr } from 'ramda'
import { getOwner, handleBooleanValue } from 'utils/hooks/values'
import { formatDateWithMonthName } from 'utils/date'
import Toggle from 'components/atoms/Toggle'
import FormControlLabel from '@mui/material/FormControlLabel'
import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import Radio from 'components/atoms/Radio'
import { updateBpRoutine } from 'features/createBp/ducks/actions'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import { canEditBp, getUserRemindersPeriod, hideEmail, hidePhoneNumber } from 'utils/user'
import { getBuildingTypeValueType } from 'utils/bpData'
import { getInvitationsListForBpRoutine } from 'features/accessControl/ducks/actions'
import { getSelectedBp, selectHasAccessToBp } from 'features/bpDetails/ducks/selectors'
import { useParams } from 'react-router'
import { redirect } from 'utils/paths'

const Information = ({ openEditModal, redirectPath }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const bp = useSelector(getSelectedBp)
  const user = useSelector(getCurrentUser)
  const [wantsReminders, setWantsReminders] = useState(null)
  const [wantsFileReminders, setWantsFileReminders] = useState(null)
  const [periodicReminder, setRemindersPeriod] = useState(null)
  const invitations = useSelector(selectInvitationsList)
  const canEdit = canEditBp(user, bp)
  const hasFullAccess = useSelector(selectHasAccessToBp)
  const { folder, id } = useParams()

  const invitedOwners = useMemo(() => {
    return invitations?.filter(inv => inv.relationType.value === 'owner') || []
  }, [invitations])

  const invitedRps = useMemo(() => {
    return invitations?.filter(inv => inv.relationType.value === 'responsible_person') || []
  }, [invitations])

  const invitedPaps = useMemo(() => {
    return invitations?.filter(inv => inv.relationType.value === 'principal_accountable_person') || []
  }, [invitations])

  const invitedPds = useMemo(() => {
    return invitations?.filter(inv => inv.relationType.value === 'principal_designer') || []
  }, [invitations])

  useEffect(() => {
    if (isNotNil(user) && isNotNil(bp)) {
      const remindersPeriod = getUserRemindersPeriod(user, bp)
      const reminders = propOr([], 'userReminders', bp)
      const hasFileReminder = isNotNilOrEmpty(reminders.find(rem =>
        rem.notificationType === 'App\\Notifications\\BuildingPassport\\FileExpirationReminderNotification'))
      setRemindersPeriod(remindersPeriod)
      setWantsFileReminders(hasFileReminder)
      setWantsReminders(isNotNil(remindersPeriod))
      if (hasFullAccess && bp.id && isNilOrEmpty(invitations)) {
        dispatch(getInvitationsListForBpRoutine(bp.id))
      }
    }
  }, [bp])

  const buildingTypes = useSelector(getBuildingTypes)

  const changeRemindersPeriod = e => {
    dispatch(updateBpRoutine({
      periodicReminder: wantsReminders
        ? Number(e.target.value)
        : null,
      id: bp.id,
      isEdit: true
    }))
    setRemindersPeriod(Number(e.target.value))
  }
  const toggleReminders = (_, value) => {
    setWantsReminders(value)
    if (isNotNil(periodicReminder)) {
      dispatch(updateBpRoutine({
        periodicReminder: value
          ? Number(periodicReminder)
          : null,
        id: bp.id,
        isEdit: true
      }))
    }
  }

  const toggleFileReminders = (_, value) => {
    setWantsFileReminders(value)
      dispatch(updateBpRoutine({
        expirationDateReminder: value,
        id: bp.id,
        isEdit: true
      }))
  }

  return (
    <div>
      <DetailsSection
        label={t('bpDetails.labels.address')}
        name='address'
        openEditModal={openEditModal}
        bp={bp}
      >
        <AddressWrapper>
          {bp.street}, {bp.city}, {bp.postalCode}
          <PinIcon />
        </AddressWrapper>
      </DetailsSection>
      <DetailsSection
        label={t('bpDetails.labels.basicInfo')}
        name='buildingTypes'
        openEditModal={() => redirect(`${redirectPath}/${id}/units`)}
        bp={bp}
        hideIcon={folder === 'units'}
      >
        {
          bp.buildingTypes && bp.buildingTypes.map(el => {
            const type = propOr(
              { label: '', unit: '' },
              toCamelCase(el.type.replace('.dwelling.', '_')),
              buildingTypes)
            const value = type.unit === 'unit' ? el.units : el.area
            return (
              <div key={el.type}>
                <BuildingType>{type.label}</BuildingType>
                <ListItem>
                  <span>{getBuildingTypeValueType(el.type).replace(' (sqm)', '')}</span>
                  <div>{value}&nbsp;{type.unit}</div>
                </ListItem>
              </div>
            )
          })
        }
      </DetailsSection>
      <DetailsSection
        label={t('bpDetails.labels.floors')}
        name='floors'
        openEditModal={openEditModal}
        expandable
        bp={bp}
      >
        {
          isNotNil(bp.floorsNumber) &&
            <ListItem>
              <span>Floors (above ground)</span>
              {t('bpDetails.floors', { count: bp.floorsNumber })}
            </ListItem>
        }
        {
         isNotNil(bp.groundFloor) &&
           <ListItem>
             <span>Ground Floor</span>
             {handleBooleanValue(bp.groundFloor)}
           </ListItem>
        }
        {
         isNotNil(bp.lowerGroundFloor) &&
           <ListItem>
             <span>Lower ground Floor</span>
             {handleBooleanValue(bp.lowerGroundFloor)}
           </ListItem>
        }
        {
          isNotNil(bp.undergroundFloorsNumber) &&
            <ListItem>
              <span>Underground Floors</span>
                {t('bpDetails.floors', { count: bp.undergroundFloorsNumber })}
            </ListItem>
        }
      </DetailsSection>
      <DetailsSection
        label='Owners'
        name='owner'
        expandable
        bp={bp}
      >
        {
          isNotNilOrEmpty(bp.owners) && bp.owners.map((owner, index) => {
              return (
                <OwnerWrapper key={'owner' + index}>
                  <Bolded>{getOwner(owner)}</Bolded>
                  <Lighter>{hidePhoneNumber(propOr('', 'phoneNumber', owner))}</Lighter>
                  <Lighter>{hideEmail(propOr('', 'email', owner))}</Lighter>
                </OwnerWrapper>
              )
            })
        }
        {
          isNotNilOrEmpty(invitedOwners) && invitedOwners.map((owner, index) => {
            return (
              <OwnerWrapper key={'invitedOwner' + index}>
                <Bolded>{getOwner(owner)}</Bolded>
                <InvitationStatus>
                  {
                    bp.status === 'draft'
                      ? 'Invite not yet sent'
                      : 'Invite pending'
                  }
                </InvitationStatus>
                <Lighter>{hidePhoneNumber(propOr('', 'phoneNumber', owner))}</Lighter>
                <Lighter>{hideEmail(propOr('', 'email', owner))}</Lighter>
              </OwnerWrapper>
            )
          })
        }
        {
          !isNotNilOrEmpty(invitedOwners) && !isNotNilOrEmpty(bp.owners) && canEdit && (
            <AssignUserButton onClick={() => openEditModal('owner')}>+ Assign owner</AssignUserButton>
          )
        }
      </DetailsSection>
      <DetailsSection
        label='Responsible persons'
        name='responsiblePerson'
        expandable
        bp={bp}
      >
        {
          isNotNilOrEmpty(bp.responsiblePersons) && bp.responsiblePersons.map((rp, index) => {
            return (
              <OwnerWrapper key={'rp' + index}>
                <Bolded>{getOwner(rp)}</Bolded>
                <Lighter>{hidePhoneNumber(propOr('', 'phoneNumber', rp))}</Lighter>
                <Lighter>{hideEmail(propOr('', 'email', rp))}</Lighter>
              </OwnerWrapper>
            )
          })
        }
        {
          isNotNilOrEmpty(invitedRps) && invitedRps.map((rp, index) => {
            return (
              <OwnerWrapper key={'invitedRp' + index}>
                <Bolded>{getOwner(rp)}</Bolded>
                <InvitationStatus>
                  {
                    bp.status === 'draft'
                      ? 'Invite not yet sent'
                      : 'Invite pending'
                  }
                </InvitationStatus>
                <Lighter>{hidePhoneNumber(propOr('', 'phoneNumber', rp))}</Lighter>
                <Lighter>{hideEmail(propOr('', 'email', rp))}</Lighter>
              </OwnerWrapper>
            )
          })
        }
        {
          !isNotNilOrEmpty(invitedRps) && !isNotNilOrEmpty(bp.responsiblePersons) && canEdit && (
            <AssignUserButton onClick={() => openEditModal('responsiblePerson')}>+ Assign responsible person</AssignUserButton>
          )
        }
      </DetailsSection>
      <DetailsSection
        label='Principal Accountable Person'
        name='principalAccountablePersons'
        expandable
        bp={bp}
      >
        {
          isNotNilOrEmpty(bp.principalAccountablePersons) && bp.principalAccountablePersons.map((pap, index) => {
            return (
              <OwnerWrapper key={'pap' + index}>
                <Bolded>{getOwner(pap)}</Bolded>
                <Lighter>{hidePhoneNumber(propOr('', 'phoneNumber', pap))}</Lighter>
                <Lighter>{hideEmail(propOr('', 'email', pap))}</Lighter>
              </OwnerWrapper>
            )
          })
        }
        {
          isNotNilOrEmpty(invitedPaps) && invitedPaps.map((pap, index) => {
            return (
              <OwnerWrapper key={'invitedPap' + index}>
                <Bolded>{getOwner(pap)}</Bolded>
                <InvitationStatus>
                  {
                    bp.status === 'draft'
                      ? 'Invite not yet sent'
                      : 'Invite pending'
                  }
                </InvitationStatus>
                <Lighter>{hidePhoneNumber(propOr('', 'phoneNumber', pap))}</Lighter>
                <Lighter>{hideEmail(propOr('', 'email', pap))}</Lighter>
              </OwnerWrapper>
            )
          })
        }
        {
          !isNotNilOrEmpty(invitedPaps) && !isNotNilOrEmpty(bp.principalAccountablePersons) && canEdit && (
            <AssignUserButton onClick={() => openEditModal('principalAccountablePerson')}>
              + Assign principal accountable person
            </AssignUserButton>
          )
        }
      </DetailsSection>
      <DetailsSection
        label='Principal Designer'
        name='principalDesigners'
        expandable
        bp={bp}
      >
        {
          isNotNilOrEmpty(bp.principalDesigners) && bp.principalDesigners.map((pd, index) => {
            return (
              <OwnerWrapper key={'pd' + index}>
                <Bolded>{getOwner(pd)}</Bolded>
                <Lighter>{hidePhoneNumber(propOr('', 'phoneNumber', pd))}</Lighter>
                <Lighter>{hideEmail(propOr('', 'email', pd))}</Lighter>
              </OwnerWrapper>
            )
          })
        }
        {
          isNotNilOrEmpty(invitedPds) && invitedPds.map((pd, index) => {
            return (
              <OwnerWrapper key={'invitedPds' + index}>
                <Bolded>{getOwner(pd)}</Bolded>
                <InvitationStatus>
                  {
                    bp.status === 'draft'
                      ? 'Invite not yet sent'
                      : 'Invite pending'
                  }
                </InvitationStatus>
                <Lighter>{hidePhoneNumber(propOr('', 'phoneNumber', pd))}</Lighter>
                <Lighter>{hideEmail(propOr('', 'email', pd))}</Lighter>
              </OwnerWrapper>
            )
          })
        }
        {
          !isNotNilOrEmpty(invitedPds) && !isNotNilOrEmpty(bp.principalDesigners) && canEdit && (
            <AssignUserButton onClick={() => openEditModal('principalDesigner')}>
              + Assign principal designer
            </AssignUserButton>
          )
        }
      </DetailsSection>
      <DetailsSection
        label='Occupants'
        name='residents'
        openEditModal={openEditModal}
        expandable
        bp={bp}
      >
        <ListItem>
          <span>Occupied</span>
          {handleBooleanValue(bp.occupied)}
        </ListItem>
        <ListItem>
          <span>Expected occupants</span>
          {bp.expectedBuildingOccupantsAmount || 0}
        </ListItem>
        {
          bp.occupied
            ? (
              <>
                <ListItem>
                  <span>Elderly occupants</span>
                  {bp.elderlyOccupantsAmount || 0}
                </ListItem>
                <ListItem>
                  <span>Disabled occupants</span>
                  {bp.disabledOccupantsAmount || 0}
                </ListItem>
              </>
              )
            : bp.expectedOccupationDateInWeeks && (
              <ListItem>
                <span>Expected occupation in</span>
                {`${bp.expectedOccupationDateInWeeks} weeks`}
              </ListItem>
            )

        }
      </DetailsSection>
      {
        hasFullAccess && (
          <DetailsSection
            label={t('bpDetails.labels.issues')}
            openEditModal={openEditModal}
            name='issues'
            expandable
            bp={bp}
          >
            <ListItem>
              <span>{t('bpDetails.labels.hoarding')}</span>
              {handleBooleanValue(bp.hoardings)}
            </ListItem>
            <ListItem>
              <span>{t('bpDetails.labels.structuralDefects')}</span>
              {handleBooleanValue(bp.structuralDefects)}
            </ListItem>
            <ListItem>
              <span>{t('bpDetails.labels.hazardousSubstances')}</span>
              {handleBooleanValue(bp.hazardousSubstances)}
            </ListItem>
            <ListItem>
              <span>{t('bpDetails.labels.asbestos')}</span>
              {handleBooleanValue(bp.asbestos)}
            </ListItem>
            <ListItem>
              <span>{t('bpDetails.labels.floodRisk')}</span>
              {handleBooleanValue(bp.floodRisk)}
            </ListItem>
            <ListItem>
              <span>{t('bpDetails.labels.claddingIssue')}</span>
              {handleBooleanValue(bp.claddingIssue)}
            </ListItem>
          </DetailsSection>
        )
      }
      {
        canEdit && (
          <DetailsSection
            label={t('bpDetails.labels.settings')}
            expandable
            bp={bp}
          >
            <SettingsOption>
              <Bolded>
                General reminders
              </Bolded>
              <Toggle checked={wantsReminders} onChange={toggleReminders} />
            </SettingsOption>
            <SettingsExplanation>
              Turn on to receive a periodic reminder to update your Building Passport
            </SettingsExplanation>
            <FormControl>
              <RadioGroup
                row
                value={periodicReminder}
                onChange={changeRemindersPeriod}
              >
                <ToggleLabel
                  value={6}
                  control={<Radio color='secondary' />}
                  label={t('bpDetails.labels.every6Mo')}
                  disabled={!wantsReminders}
                />
                <ToggleLabel
                  value={12}
                  control={<Radio color='secondary' size='small' />}
                  label={t('bpDetails.labels.every12Mo')}
                  disabled={!wantsReminders}
                />
              </RadioGroup>
            </FormControl>
            <SettingsOption>
              <Bolded>
                File expiry reminders
              </Bolded>
              <Toggle checked={wantsFileReminders} onChange={toggleFileReminders} />
            </SettingsOption>
            <SettingsExplanation>
              Turn on to receive reminders that specific files are due to expire.
              Expiry dates for each file can be managed in the ‘File information’ area
            </SettingsExplanation>
          </DetailsSection>
        )
      }
      <DatesWrapper>
        <ListItem>
          <span>{t('bpDetails.labels.created')}</span>
          {formatDateWithMonthName(bp.createdAt)}
        </ListItem>
        <ListItem>
          <span>{t('bpDetails.labels.updated')}</span>
          {formatDateWithMonthName(bp.updatedAt)}
        </ListItem>
      </DatesWrapper>
    </div>
  )
}

export default Information

const Bolded = styled.div`
  font-weight: bold;
`

const Lighter = styled.div`
  opacity: .7;
`

const OwnerWrapper = styled.div`
  margin-bottom: 10px;

  div:not(:last-of-type) {
    margin-bottom: 4px;
  }
`

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.haiti};
  font-size: 13px;
  margin-bottom: 7px;

  span {
    opacity: .7;
  }
`

const BuildingType = styled.div`
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 3px;
`

const AddressWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.haiti};
  justify-content: space-between;
  opacity: .7;
`

const PinIcon = styled(RoomOutlinedIcon)`
  font-size: 25px !important;
  color: ${({ theme }) => theme.colors.haiti} !important;
`

const DatesWrapper = styled.div`
  word-spacing: 3px;
  margin-top: 40px;
`

const SettingsOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const SettingsExplanation = styled.div`
  font-size: 11px;
  opacity: .7;
  margin-bottom: 10px;
`

const ToggleLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.haiti};
    font-family: FuturaPT-book;
  }
`

const InvitationStatus = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.primary};
`

const AssignUserButton = styled.div`
  text-transform: uppercase;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
`
