import React from "react";
import styled, {css} from 'styled-components'
import UploadIcon from 'assets/images/ai-step-1.png'
import AnalyseIcon from 'assets/images/ai-step-2.png'
import CategoriseIcon from 'assets/images/ai-step-3.png'
import IndexIcon from 'assets/images/ai-step-4.png'
import {ReactComponent as AIArrow} from 'assets/images/ai-arrow.svg';
import {ReactComponent as AIArrowBlue} from 'assets/images/ai-arrow-blue.svg';

const steps = [
    {
        icon: UploadIcon,
        title: 'UPLOAD',
        description: "Drag and drop your building's files",
        isFirst: true,
    },
    {
        icon: AnalyseIcon,
        title: 'ANALYSE',
        description: 'Let our AI work its magic',
    },
    {
        icon: CategoriseIcon,
        title: 'CATEGORISE',
        description: 'Watch as your files are identified',
    },
    {
        icon: IndexIcon,
        title: 'INDEX',
        description: 'Let the system do your filing for you',
    },
];

const AssistantSteps = ({isSmall, activeStep = 1}) => {
    return (
        <StepsWrapper isSmall={isSmall}>
            {
                steps.map((step, index) => (
                    <React.Fragment key={index}>
                        <StepWrapper isSmall={isSmall}>
                            <Step isSmall={isSmall}>
                                <IconWrapper isSmall={isSmall} isActive={activeStep >= index + 1}>
                                    <img src={step.icon} alt={step.title} width={isSmall ? 40 : 80}/>
                                </IconWrapper>
                                {!isSmall && (
                                    <>
                                        <StepTitle>{step.title}</StepTitle>
                                        <StepDescription>{step.description}</StepDescription>
                                    </>)
                                }
                            </Step>
                        </StepWrapper>
                        {index < steps.length - 1 && (
                            <ArrowWrapper isSmall={isSmall} isActive={activeStep >= index + 1}>
                                {activeStep >= index + 1 ? <AIArrowBlue/> : <AIArrow/>}
                            </ArrowWrapper>
                        )}
                    </React.Fragment>
                ))
            }
        </StepsWrapper>
    )
}

export default AssistantSteps;
const StepsWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    font-size: ${({isSmall}) => isSmall ? '16px' : '19px'};
    padding: ${({isSmall}) => isSmall ? '10px 0' : '40px 0'};
    gap: ${({isSmall}) => isSmall ? '0px' : '5px'};
    `
const StepWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: ${({isSmall}) => isSmall ? '65px' : '210px'};
    @media (max-width: 768px) {
        width: auto;
        margin: ${({isSmall}) => isSmall ? '10px 0' : '20px 0'};
    }
`;
const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 1;
  margin-left: ${({isSmall}) => isSmall ? '-30px' : '-80px'};
  margin-bottom: ${({isSmall}) => isSmall ? '5px' : '30px'};

  svg {
        width: ${({isSmall}) => isSmall ? '50px' : '100px'};
        height: auto;
  }

  @media (max-width: 768px) {
    margin-left: ${({isSmall}) => isSmall ? '-60px' : '-80px'};
  }
`;


const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const IconWrapper = styled.div`
  background-color: ${({isActive, theme}) => isActive ? theme.colors.secondary : theme.colors.white};
  border: 2px solid ${({theme}) => theme.colors.lightGray};
  border-radius: 50%;
  padding: ${({isSmall}) => isSmall ? '5px' : '20px'};
  margin-bottom: ${({isSmall}) => isSmall ? '1px' : '10px'};
  width: ${({isSmall}) => isSmall ? '40px' : '80px'};
  height: ${({isSmall}) => isSmall ? '40px' : '80px'};
  display: flex;
    z-index: 2;
  align-items: center;
  justify-content: center;
    box-shadow: 0 0 20px rgba(41, 152, 163, 0.31);
`;


const StepTitle = styled.h3`
  margin: 0;
  font-size: 18px;
  font-weight: bold;
`;

const StepDescription = styled.p`
  margin: 0;
  font-size: 14px;
`;
