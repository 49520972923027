import React, { useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import ClearIcon from '@mui/icons-material/Clear'
import { useSelector } from 'react-redux'
import { Collapse } from '@mui/material'
import { TEMPLATE_ITEMS_NAMES } from 'utils/constants'
import { pick } from 'ramda'
import { selectFileCategories } from 'ducks/dictionaries/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import { checkAnalyticsCondition } from 'utils/analytics'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import EditGeneralInfo from 'features/bpDetails/Components/edit/EditGeneralInfo'
import EditAddress from 'features/bpDetails/Components/edit/EditAddress'
import EditBuildingTypes from 'features/bpDetails/Components/edit/EditBuildingTypes'
import AddAssignmentModal from 'features/accessControl/components/AddAssignmentModal'
import Modal from 'components/atoms/Modal'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import { canEditBp } from 'utils/user'
import AddMultipleFilesModal from 'features/bpDetails/Components/files/AddMultipleFilesModal'
import { selectBpFilesList } from 'ducks/files/selectors'
import AlreadyUploadedFilesOfTypeModal from 'features/analytics/compontents/AlreadyUploadedFilesOfTypeModal'

const color = percentage => {
  switch (true) {
    case percentage < 20:
      return '#cc2200'
    case percentage > 19 && percentage < 50:
      return '#ff836b'
    case percentage > 49 && percentage < 75:
      return '#ffaf7e'
    case percentage > 74 && percentage < 100:
      return '#BAEAEF'
    default:
      return '#33becc'
  }
}

const AnalyticProgressBar = ({
  template,
  bp,
  isDetailsView,
  handleOpenRemoveModal,
  currentPage
}) => {
  const isDefaultTemplate = template.type === 'default'
  const fileCategories = useSelector(selectFileCategories)
  const [open, setOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [editContentName, setEditContentName] = useState('')
  const user = useSelector(getCurrentUser)
  const canEdit = canEditBp(user, bp)
  const bpFiles = useSelector(selectBpFilesList)

  const handleTemplateDetach = e => {
    e.stopPropagation()
    handleOpenRemoveModal(bp, template)
  }

  const dataValues = useMemo(() => {
    let result = []
    const values = pick(
      ['hasUprn', 'hasAddress', 'hasOwner', 'hasAgent', 'hasBuildingTypes'],
      template
    )
    for (const key in values) {
      if (values[key]) {
        result.push(key)
      }
    }
    return result
  }, [template, bp])

  const filesValues = useMemo(() => {
    if (fileCategories && isNotNilOrEmpty(template.fileTypes)) {
      const categories = fileCategories.map(category => {
        return {
          id: category.id,
          label: category.name,
          values:
            template.fileTypes.filter(
              type => type.fileCategoryId === category.id
            ) || []
        }
      })

      return categories.map(category => {
        if (isNotNilOrEmpty(category.values)) {
          return (
            <>
              <CategoryName>{category.label}</CategoryName>
              {category.values.map((value, index) => {
                const filesCount = value?.filesCount || 0
                return filesCount > 0 ? (
                  <AlreadyUploadedFilesOfTypeModal
                    bpId={bp.id}
                    type={value.id}
                    categoryId={category.id}
                  >
                    <SingleItem>
                      <Status checked={value?.fileExists}>
                        {value?.fileExists ? '☑' : '☐'}
                      </Status>
                      {value.name} ({filesCount}{' '}
                      {filesCount === 1 ? 'file' : 'files'})
                    </SingleItem>
                  </AlreadyUploadedFilesOfTypeModal>
                ) : (
                  <>
                    <FileItemWrapper key={`${category.label}-${index}`}>
                      <AddMultipleFilesModal
                        externalBpId={bp.id}
                        initialCategory={category.id}
                        initialType={value.id}
                        key={`${category.label}-${index}`}
                        addedFilesCount={value?.filesCount || 0}
                      >
                        <SingleItem>
                          <Status checked={value?.fileExists}>
                            {value?.fileExists ? '☑' : '☐'}
                          </Status>
                          {value.name} ({filesCount}{' '}
                          {filesCount === 1 ? 'file' : 'files'})
                        </SingleItem>
                      </AddMultipleFilesModal>
                    </FileItemWrapper>
                  </>
                )
              })}
            </>
          )
        } else {
          return null
        }
      }, [])
    }
  }, [template, fileCategories, bp, bpFiles])

  const closeEditModal = () => {
    setEditContentName('')
    setModalOpen(false)
  }

  const openEditModal = name => () => {
    setEditContentName(name)
    setModalOpen(true)
  }

  const modalContent = useMemo(() => {
    switch (editContentName) {
      case 'hasUprn':
        return (
          <EditGeneralInfo
            isAnalyticsView
            currentPage={currentPage}
            closeModal={closeEditModal}
          />
        )
      case 'hasAddress':
        return (
          <EditAddress
            isAnalyticsView
            currentPage={currentPage}
            closeModal={closeEditModal}
          />
        )
      case 'hasBuildingTypes':
        return (
          <EditBuildingTypes
            isAnalyticsView
            currentPage={currentPage}
            closeModal={closeEditModal}
          />
        )
      case 'hasAgent':
        return (
          <AddAssignmentModal
            isAnalyticsView
            currentPage={currentPage}
            initialUserType='agent'
            bp={bp}
            closeModal={closeEditModal}
          />
        )
      case 'hasOwner':
        return (
          <AddAssignmentModal
            isAnalyticsView
            currentPage={currentPage}
            initialUserType='owner'
            bp={bp}
            closeModal={closeEditModal}
          />
        )
      default:
        return <div />
    }
  }, [editContentName])

  return (
    <Wrapper isDetailsView={isDetailsView}>
      <ItemWrapper>
        <TemplateName>{template.name}</TemplateName>
        <ProgressBarWrapper>
          <Percentage>{template.percentageValue}%</Percentage>
          <ProgressBar width={template.percentageValue} />
        </ProgressBarWrapper>
        <ActionsWrapper>
          {!isDetailsView && (
            <CrossIcon
              disabled={isDefaultTemplate}
              onClick={isDefaultTemplate ? () => {} : handleTemplateDetach}
            />
          )}
          <CollapseIcon
            onClick={() => setOpen(prev => !prev)}
            open={open}
            isDetailsView={isDetailsView}
          />
        </ActionsWrapper>
      </ItemWrapper>
      <Collapse in={open}>
        <DetailsWrapper open={open}>
          <SectionTitle>data</SectionTitle>
          {dataValues.map(item => {
            return (
              <SingleItem
                key={item}
                onClick={canEdit ? openEditModal(item) : () => {}}
              >
                <Status checked={checkAnalyticsCondition(item, bp, bpFiles)}>
                  {checkAnalyticsCondition(item, bp, bpFiles) ? '☑' : '☐'}
                </Status>
                {TEMPLATE_ITEMS_NAMES[item]}
              </SingleItem>
            )
          })}
          {isNotNilOrEmpty(template.fileTypes) && (
            <>
              <FileSectionTitle>files</FileSectionTitle>
              {filesValues}
            </>
          )}
        </DetailsWrapper>
      </Collapse>
      <Modal open={modalOpen} title='Edit' onClose={closeEditModal}>
        {modalContent}
      </Modal>
    </Wrapper>
  )
}

export default AnalyticProgressBar

const Wrapper = styled.div`
  margin: 7px 0;

  ${({ isDetailsView }) =>
    isDetailsView &&
    css`
      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
    `}
`

const ItemWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
`

const TemplateName = styled.div`
  margin-right: 20px;
  width: 20%;
`

const ProgressBarWrapper = styled.div`
  width: 80%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  border-radius: 4px;
`

const ProgressBar = styled.div`
  width: ${({ width }) => width}%;
  height: 5px;
  background-color: ${({ width }) => color(width)};
  border-radius: 4px;
`

const Percentage = styled.div`
  position: absolute;
  right: 0;
  font-size: 12px;
  top: -20px;
`

const DetailsWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[400]};
  padding: 20px;
  transition: all 0.3s;
  margin: ${({ open }) => (open ? '10px 0' : 0)};
`

const SingleItem = styled.div`
  margin-bottom: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const SectionTitle = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: 10px;
`

const CollapseIcon = styled(KeyboardArrowDownOutlinedIcon)`
  transition: all 0.3s;
  cursor: pointer;
  transform: rotate(${({ open }) => (open ? '180deg' : '0')});
  margin-left: ${({ isDetailsView }) => (isDetailsView ? '15px' : 0)};
`

const FileSectionTitle = styled.div`
  text-transform: uppercase;
  margin: 30px 0 10px;
`

const CategoryName = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  margin: 15px 0 10px;
`

const Status = styled.div`
  color: ${({ theme, checked }) =>
    checked ? theme.colors.secondary : theme.colors.primary};
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
  padding-bottom: 2px;
`

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`

const CrossIcon = styled(ClearIcon)`
  margin: 0 10px;
  font-size: 14px !important;
  cursor: pointer;
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.grey[600] : theme.colors.primary};
`
const FileItemWrapper = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 5px;
`
