import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Modal from 'components/atoms/Modal'
import Button from 'components/atoms/Button'
import PrimaryButton from 'components/atoms/PrimaryButton'
import { AddButton } from '../../BpDetails'
const ModalSelect = ({
  name,
  value,
  options,
  onChange,
  selectAll = false,
  multiple = true,
  disabled = false,
  hasError = false,
  noMargin = false,
  title = `Select ${name}`,
  icon
}) => {
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(value || [])
  const [originalSelected, setOriginalSelected] = useState(value || [])

  useEffect(() => {
    setSelected(value || [])
  }, [value])

  useEffect(() => {
    if (open) {
      setOriginalSelected([...selected])
    }
  }, [open])

  const toggleOption = optionValue => {
    if (!multiple) {
      setSelected([optionValue])
    } else {
      setSelected(prev =>
        prev.includes(optionValue)
          ? prev.filter(v => v !== optionValue)
          : [...prev, optionValue]
      )
    }
  }

  const handleSelectAll = () => {
    if (selected.length > 0) {
      setSelected([])
    } else {
      setSelected(options.map(opt => opt.value))
    }
  }

  const handleSave = () => {
    onChange(selected)
    setOpen(false)
  }

  const handleClose = () => {
    onChange(selected)
    setOpen(false)
  }

  return (
    <>
      <TriggerWrapper
        onClick={() => !disabled && setOpen(true)}
        noMargin={noMargin}
      >
        <IconWrapper hasError={hasError} disabled={disabled}>
          {icon || <DefaultIcon>📝</DefaultIcon>}
        </IconWrapper>
        {selected.length > 0 && <Badge>{selected.length}</Badge>}
      </TriggerWrapper>
      <Modal open={open} onClose={handleClose} title={title}>
        <ModalContent>
          {options.length ? (
            <>
              <ModalDesc>Select {name}:</ModalDesc>
              {selectAll && (
                <SelectAllWrapper>
                  <AddButton onClick={handleSelectAll}>
                    {selected.length > 0 ? 'Deselect all' : 'Select all'}
                  </AddButton>
                </SelectAllWrapper>
              )}
              {options.map(opt => (
                <OptionWrapper
                  key={opt.value}
                  onClick={() => toggleOption(opt.value)}
                  isSelected={selected.includes(opt.value)}
                >
                  <OptionLabel>{opt.label}</OptionLabel>
                  {selected.includes(opt.value) && <Check>✓</Check>}
                </OptionWrapper>
              ))}
            </>
          ) : (
            <ModalDesc>No options available</ModalDesc>
          )}
          <ButtonsWrapper>
            <Button
              onClick={() => {
                setSelected(originalSelected)
                setOpen(false)
              }}
            >
              Cancel
            </Button>
            <PrimaryButton disabled={!options.length} onClick={handleSave}>
              Accept
            </PrimaryButton>
          </ButtonsWrapper>
        </ModalContent>
      </Modal>
    </>
  )
}
export default ModalSelect
const TriggerWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  margin: ${props => (props.noMargin ? '0' : 'initial')};
  position: relative;
`
const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => (props.hasError ? 'red' : props.theme.colors.black)};
  border-radius: 4px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
`
const DefaultIcon = styled.div`
  font-size: 18px;
`
const Badge = styled.div`
  position: absolute;
  top: -3px;
  right: -3px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary};
  color: #fff;
  border-radius: 50%;
  font-size: 10px;
`
const ModalContent = styled.div`
  width: 100%;
`
const ModalDesc = styled.div`
  margin-bottom: 20px;
  font-size: 18px;
`
const ButtonsWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 16px;
  & > button {
    flex: 1;
  }
`
const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border: 1px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.colors.secondary : theme.colors.grey[600]};
  border-radius: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  &:hover {
    border-color: ${({ theme }) => theme.colors.secondary};
  }
`
const OptionLabel = styled.div`
  font-size: 16px;
`
const Check = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.success};
`
const SelectAllWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
  text-align: right;
`
