import APIService from 'services/APIService'
import { isNotNilOrEmpty } from 'utils/ramda'
import qs from 'qs'

const api = APIService()

// const truncateFileName = file => {
//   const maxLength = 255
//   const fileName = file.name
//   const extensionIndex = fileName.lastIndexOf('.')
//   const extension = extensionIndex > -1 ? fileName.slice(extensionIndex) : ''
//
//   // Calculate the maximum length the name can have, taking the extension into account
//   const maxNameLength = maxLength - extension.length
//
//   // Get the name part of the file (without extension)
//   const nameWithoutExtension = extensionIndex > -1 ? fileName.slice(0, extensionIndex) : fileName
//
//   // Truncate the name if necessary
//   const truncatedName = nameWithoutExtension.length > maxNameLength
//     ? nameWithoutExtension.slice(0, maxNameLength)
//     : nameWithoutExtension
//
//   // Combine the truncated name with the extension
//   const newFileName = truncatedName + extension
//
//   // Create a new File object with the truncated name
//   const newFile = new File([file], newFileName, {
//     type: file.type,
//     lastModified: file.lastModified,
//   })
//
//   return newFile
// }

export const startMultiUploadProcess = payload => {
  localStorage.removeItem('uploadFiles')
  return api.post(`/building-passport/${payload.bpId}/multi-upload`, {
    totalFiles: payload.totalFiles
  })
}

export const getMultiuploadProcessProgress = payload =>
  api.get(
    `/building-passport/${payload.bpId}/multi-upload/${payload.processId}`
  )

export const getUnitMultiuploadProcessProgress = payload =>
  api.get(
    `/building-passport/${payload.bpId}/units/${payload.unitId}/multi-upload/${payload.processId}`
  )

function addFileDetails(
  fileType,
  fd,
  expirationDate,
  floors,
  userIds,
  tagIds,
  units
) {
  fileType && fd.append('file_type_id', fileType)
  expirationDate && fd.append('expiration_date', expirationDate)

  if (isNotNilOrEmpty(floors)) {
    floors.forEach(item => {
      fd.append('floor_numbers[]', item)
    })
  }

  if (isNotNilOrEmpty(userIds)) {
    userIds.forEach(item => {
      fd.append('user_ids[]', item)
    })
  }

  if (isNotNilOrEmpty(tagIds)) {
    tagIds.forEach(item => {
      fd.append('file_tag_ids[]', item)
    })
  }

  if (isNotNilOrEmpty(units)) {
    units.forEach(item => {
      fd.append('building_unit_ids[]', item)
    })
  }
}

export const addFileToProcess = async payload => {
  const {
    file,
    displayName,
    fileType,
    floors,
    processId,
    bpId,
    units,
    expirationDate,
    userIds,
    tagIds,
    isAI
  } = payload

  const fd = new FormData()
  fd.append('file', file)

  const filesInLocalStorage = JSON.parse(
    localStorage.getItem('uploadFiles') || '[]'
  )
  localStorage.setItem(
    'uploadFiles',
    JSON.stringify([...filesInLocalStorage, displayName])
  )

  fd.append('display_name', displayName)
  fd.append('upload_method', isAI ? 'ai' : 'traditional')
  addFileDetails(fileType, fd, expirationDate, floors, userIds, tagIds, units)

  return api.post(
    `/building-passport/${bpId}/multi-upload/${processId}/file`,
    fd
  )
}

export const finalizeMultiUploadProcess = payload => {
  const {
    fileId,
    fileType,
    floors,
    units,
    expirationDate,
    userIds,
    tagIds,
    bpId
  } = payload

  const fd = new FormData()

  addFileDetails(fileType, fd, expirationDate, floors, userIds, tagIds, units)

  return api.post(
    `/building-passport/${bpId}/multi-upload/${fileId}/finalize`,
    fd
  )
}

export const startUnitMultiUploadProcess = payload =>
  api.post(
    `/building-passport/${payload.bpId}/units/${payload.unitId}/multi-upload`,
    { totalFiles: payload.totalFiles }
  )

export const addUnitFileToProcess = async payload => {
  const {
    file,
    displayName,
    fileType,
    floors,
    processId,
    bpId,
    isPrivate,
    expirationDate
  } = payload
  const fd = new FormData()

  fd.append('display_name', displayName)
  isPrivate && fd.append('is_private', '1')
  expirationDate && fd.append('expiration_date', expirationDate)
  isNotNilOrEmpty(fileType) && fd.append('file_type_id', fileType)
  fd.append('file', file)

  if (isNotNilOrEmpty(floors)) {
    floors.forEach(item => {
      fd.append('floor_numbers[]', item)
    })
  }
  return api.post(
    `/building-passport/${bpId}/units/${payload.unitId}/multi-upload/${processId}/file`,
    fd
  )
}

export const getFilesListForBp = payload => {
  const { id, query } = payload
  let queryValues = { page: 1, limit: 250 }

  let queryString = ''

  if (query) {
    queryValues = { ...queryValues, ...query }
  }

  queryString = qs.stringify(queryValues, { addQueryPrefix: true })
  return api.get(`/building-passport/${id}/files${queryString}`)
}

export const searchLocalFiles = payload => {
  const { bpId, query } = payload
  return api.get(`/building-passport/${bpId}/search-file?query=${query}`)
}

export const getTagsListForBp = payload => {
  return api.get(`/building-passport/${payload.bpId}/file-tags`)
}

export const createFileTag = payload => {
  return api.post(`/building-passport/${payload.bpId}/file-tags`, {
    name: payload.name
  })
}

export const removeFileTag = payload => {
  return api.delete(
    `/building-passport/${payload.bpId}/file-tags/tag/${payload.id}`
  )
}

export const attachFileTagToFile = payload => {
  return api.post(
    `/building-passport/${payload.bpId}/file-tags/${payload.id}/attach`,
    { fileId: payload.fileId }
  )
}

export const syncFileTags = payload => {
  return api.post(`/building-passport/${payload.bpId}/file-tags/sync`, {
    fileId: payload.fileId,
    tagIds: payload.tagIds,
    newTags: payload.newTags
  })
}

export const syncFileVersionTags = payload => {
  return api.post(
    `/building-passport/${payload.bpId}/file-tags/file-versions/sync`,
    {
      fileVersionId: payload.fileVersionId,
      tagIds: payload.tagIds,
      newTags: payload.newTags
    }
  )
}

export const detachFileTagFromFile = payload => {
  return api.delete(
    `/building-passport/${payload.bpId}/file-tags/${payload.id}`
  )
}

export const markFileAsViewed = payload => {
  return api.post(
    `/building-passport/files/${payload.fileId}/${payload.fileVersionId}/log-access`,
    { type: payload.type }
  )
}

export const getFileViewedLogs = payload => {
  return api.get(`/building-passport/files/${payload.fileId}/access-logs`)
}
