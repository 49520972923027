import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearSelectedBpRoutine,
  fetchUserBpsRoutine
} from 'features/bpList/ducks/actions'
import {
  getBpsCount,
  getPagesCount,
  getUserBps
} from 'features/bpList/ducks/selectors'
import styled, { keyframes } from 'styled-components'
import Table from 'features/bpList/Components/Table'
import { useTranslation } from 'react-i18next'
import Pagination from 'components/atoms/Pagination'
import Filters from 'features/filters/Filters'
import { getFiltersQuery } from 'utils/filters'
import { isNotNilOrEmpty } from 'utils/ramda'
import { not, values, equals, isEmpty } from 'ramda'
import Drawer from '@mui/material/Drawer'
import { smallScreenCss } from 'utils/responsive'
import FilterListIcon from '@mui/icons-material/FilterList'
import SearchIcon from '@mui/icons-material/Search'
import EmptyBpList from 'features/bpList/Components/EmptyBpsListState'
import { selectIsFetchingBps } from 'ducks/loaderSelectors'
import CircularProgress from '@mui/material/CircularProgress'
import { clearBpFilesRoutine } from 'ducks/files/actions'

const defaultFilters = {
  status: 'active,inactive,dormant',
  owner: [],
  responsible_person: [],
  created_at: null,
  updated_at: null,
  building_types: []
}

const BpList = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const bps = useSelector(getUserBps)
  const pagesCount = useSelector(getPagesCount)
  const totalFound = useSelector(getBpsCount)
  const [isDrawerOpen, setDrawerOpen] = useState(false)
  const [filters, setFilters] = useState(defaultFilters)
  const [sortingOptions, setSortingOptions] = useState({
    column: 'name',
    desc: false,
    page: 1
  })
  const isLoading = useSelector(selectIsFetchingBps)

  const prevRequest = useRef({ sortingOptions: null, filters: null })
  const initialLoadComplete = useRef(false)

  const filtersQuery = useMemo(() => getFiltersQuery(filters), [filters])

  useEffect(() => {
    const { page, desc, column } = sortingOptions
    const sortParam = desc ? `-${column}` : column

    const isDifferent =
      !prevRequest.current.sortingOptions ||
      !prevRequest.current.filters ||
      prevRequest.current.sortingOptions.page !== page ||
      prevRequest.current.sortingOptions.desc !== desc ||
      prevRequest.current.sortingOptions.column !== column ||
      prevRequest.current.filters !== filtersQuery

    if (isDifferent || !initialLoadComplete.current) {
      dispatch(
        fetchUserBpsRoutine({
          sort: sortParam,
          filters: filtersQuery,
          page
        })
      )

      prevRequest.current = {
        sortingOptions: { ...sortingOptions },
        filters: filtersQuery
      }
      initialLoadComplete.current = true
    }
  }, [sortingOptions, filtersQuery, dispatch])

  useEffect(() => {
    dispatch(clearSelectedBpRoutine())
    dispatch(clearBpFilesRoutine())
  }, [dispatch])

  const handleChangeSorting = useCallback(
    column => () => {
      setSortingOptions(prev => ({
        ...sortingOptions,
        column,
        desc: column === prev.column ? !prev.desc : false
      }))
    },
    [sortingOptions]
  )

  const handlePageChange = useCallback(page => {
    setSortingOptions(prev => ({
      ...prev,
      page
    }))
  }, [])

  const openDrawer = useCallback(() => setDrawerOpen(true), [])
  const closeDrawer = useCallback(() => setDrawerOpen(false), [])
  const handleClearFilters = useCallback(() => setFilters(defaultFilters), [])

  const shouldRenderClearButton = useMemo(() => {
    return (
      values(filters).some(e => isNotNilOrEmpty(e)) &&
      not(equals(filters, defaultFilters))
    )
  }, [filters])

  return (
    <PageWrapper>
      <ControlsRow>
        <ResultsInfo>
          {totalFound > 0 && !isLoading && (
            <ResultsCount>
              <SearchIcon />
              <span>{totalFound}</span> {t('bpList.countResults')}
            </ResultsCount>
          )}
        </ResultsInfo>

        <ActionsGroup>
          {shouldRenderClearButton && (
            <ClearButton onClick={handleClearFilters}>
              {t('bpList.clearFilters')}
            </ClearButton>
          )}
          <FilterButton onClick={openDrawer}>
            <FiltersIcon />
            <FilterText>Filters</FilterText>
          </FilterButton>
        </ActionsGroup>
      </ControlsRow>

      <ContentCard>
        {isLoading ? (
          <LoadingContainer>
            <LoadingSpinner />
            <LoadingText>Loading building passports...</LoadingText>
          </LoadingContainer>
        ) : isEmpty(bps) && equals(defaultFilters, filters) ? (
          <EmptyBpList />
        ) : (
          <TableContainer>
            <Table
              sortingOptions={sortingOptions}
              sortingChange={handleChangeSorting}
              bps={bps}
            />
          </TableContainer>
        )}
      </ContentCard>

      {pagesCount > 1 && (
        <PaginationWrapper>
          <Pagination onChange={handlePageChange} totalPages={pagesCount} />
        </PaginationWrapper>
      )}

      <Drawer
        anchor='right'
        variant='persistent'
        open={isDrawerOpen}
        onClose={closeDrawer}
        PaperProps={{
          sx: {
            borderTopLeftRadius: '12px',
            borderBottomLeftRadius: '12px',
            boxShadow: '-5px 0 25px rgba(0,0,0,0.1)',
            width: '350px' // Set explicit width here
          }
        }}
      >
        <FiltersWrapper>
          <FiltersHeader>
            <FiltersTitle>Filter Passports</FiltersTitle>
            <CloseButton onClick={closeDrawer}>×</CloseButton>
          </FiltersHeader>
          <FiltersContent>
            <Filters
              closeDrawer={closeDrawer}
              parentFilters={filters}
              getFilters={setFilters}
              defaultFilters={defaultFilters}
            />
          </FiltersContent>
        </FiltersWrapper>
      </Drawer>
    </PageWrapper>
  )
}

export default BpList

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
`

const PageWrapper = styled.div`
  width: 100%;
  animation: ${fadeIn} 0.3s ease-out;
`

const ControlsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  flex-wrap: wrap;
  gap: 16px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const ResultsInfo = styled.div`
  display: flex;
  align-items: center;
`

const ResultsCount = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.haiti};
  font-size: 14px;
  background-color: #f5f7fa;
  padding: 8px 16px;
  border-radius: 8px;

  svg {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 18px;
    margin-right: 8px;
  }

  span {
    font-weight: bold;
    margin: 0 4px;
  }
`

const ActionsGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const ClearButton = styled.button`
  background: none;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 8px;
  transition: all 0.2s;

  &:hover {
    background: ${({ theme }) => theme.colors.primary}10;
  }
`

const FilterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
  transition: all 0.2s;
  box-shadow: 0 4px 12px ${({ theme }) => theme.colors.primary}30;

  &:hover {
    background: ${({ theme }) => theme.colors.secondary};
    transform: translateY(-2px);
  }
`

const FilterText = styled.span`
  margin-left: 8px;
  font-weight: 500;
`

const FiltersIcon = styled(FilterListIcon)`
  font-size: 18px !important;
`

const ContentCard = styled.div`
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  margin-bottom: 20px;
`

const TableContainer = styled.div`
  padding: 5px;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.primary}40;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.primary}70;
  }
`

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 250px;
`

const LoadingSpinner = styled(CircularProgress)`
  color: ${({ theme }) => theme.colors.primary} !important;
`

const LoadingText = styled.div`
  margin-top: 15px;
  color: ${({ theme }) => theme.colors.haiti};
  font-size: 14px;
`

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 20px;
`

const FiltersHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lighterGrey};
`

const FiltersTitle = styled.h3`
  margin: 0;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.haiti};
  font-weight: 600;
`

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.haiti};
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lighterGrey};
  }
`
const FiltersContent = styled.div`
  padding: 0 20px;
`

const FiltersWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-y: auto;
  position: relative;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.primary}40;
    border-radius: 4px;
  }
`
