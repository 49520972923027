import React, { useEffect, useMemo, useState } from 'react'
import { head, last, pathOr, prop, propOr, reverse, sortBy } from 'ramda'
import styled, { css } from 'styled-components'
import { formatDateWithTime } from 'utils/date'
import AddNewFileVersionModal from 'features/bpDetails/Components/AddNewFileVersionModal'
import { AddButton } from 'features/bpDetails/BpDetails'
import DeleteFileVersionModal from 'features/bpDetails/Components/DeleteFileVersionModal'
import EditFileVersionModal from 'features/bpDetails/Components/EditFileVersionModal'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { reorderFileVersions } from 'services/BpService'
import { toast } from 'react-toastify'
import { isNotNilOrEmpty } from 'utils/ramda'
import { reorderUnitFileVersions } from 'services/unitsService'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import { useSelector } from 'react-redux'
import PdfFullScreenViewer from 'components/PdfFullScreenViewer'
import AttachTagToFile from 'features/bpDetails/Components/files/AttachTagToFile'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ImageViewer from 'components/ImageViewer'
import { markFileAsViewed } from 'services/fileService'

const ManageFileVersionsModal = ({ file, unit }) => {
  const [newVersionModalOpen, setNewVersionModalOpen] = useState(false)
  const [versions, setVersions] = useState([])
  const [selectedVersion, setSelectedVersion] = useState({})
  const bp = useSelector(getSelectedBp)
  const [pdfViewerOpen, setPdfViewerOpen] = useState(false)
  const [imageViewerOpen, setImageViewerOpen] = useState(false)
  const canBeAccessed = file?.canBeAccessed || false
  const [anchorEl, setAnchorEl] = useState(null)
  const dropdownOpen = Boolean(anchorEl)

  const handlePdfViewerClose = () => {
    setSelectedVersion({})
    setPdfViewerOpen(false)
  }

  const handleImageViewerClose = () => {
    setSelectedVersion({})
    setImageViewerOpen(false)
  }

  const firstVersion = head(versions)
  const lastVersion = last(versions)

  useEffect(() => {
    setVersions(reverse(sortBy(prop('version'), propOr([], 'versions', file))))
  }, [file])

  const handleNewVersionModalOpen = e => {
    e && e.stopPropagation()
    setNewVersionModalOpen(true)
  }

  const handleNewVersionModalClose = e => {
    e && e.stopPropagation()
    setNewVersionModalOpen(false)
  }

  const moveItem = (fromIndex, type) => () => {
    const versionsClone = [ ...versions ]
    const el = versionsClone.splice(fromIndex, 1)[0]
    const toIndex = type === 'increment' ? fromIndex - 1 : fromIndex + 1
    versionsClone.splice(toIndex, 0, el)
    const payload = reverse(versionsClone.map(v => v.id))

    if (isNotNilOrEmpty(unit)) {
      reorderUnitFileVersions({
        bpId: bp.id,
        unitId: unit.id,
        versionsOrder: payload,
        fileId: file.id
      })
        .then(resp => {
          const newVersions = pathOr([], ['data', 'data', 'versions'], resp)
          setVersions(reverse(sortBy(prop('version'), newVersions)))
          toast.success('Version order has been changed')
        })
        .catch(e => {
          console.error(e)
          toast.error('Something went wrong')
        })
    } else {
      reorderFileVersions({
        versionsOrder: payload,
        fileId: file.id
      })
        .then(resp => {
          const newVersions = pathOr([], ['data', 'data', 'versions'], resp)
          setVersions(reverse(sortBy(prop('version'), newVersions)))
          toast.success('Version order has been changed')
        })
        .catch(e => {
          console.error(e)
          toast.error('Something went wrong')
        })
    }
  }

  const handlePreviewFile = version => e => {
    setSelectedVersion(version)
    e.stopPropagation()
    e.preventDefault()
    if (/\.pdf/.test(file.url.toLowerCase())) {
      setPdfViewerOpen(true)
    } else if (/\.jpg|\.jpeg|\.png|\.svg|\.webp|\.gif/.test(file.url.toLowerCase())) {
      setImageViewerOpen(true)
      setAnchorEl(null)
    } else {
      setSelectedVersion({})
      window.open(version.url, '_blank')
    }
  }

  const handleDownloadFile = version => e => {
    e.stopPropagation()
    e.preventDefault()
    const anchor = document.createElement('a')
    anchor.href = version.url
    anchor.target = '_blank'
    anchor.download = file.displayName
    anchor.click()

    const fileId = file.id
    const fileVersionId = version.id

    markFileAsViewed({
      fileId,
      fileVersionId,
      type: 'download'
    })
  }

  const openMenu = version => e => {
    setSelectedVersion(version)
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setSelectedVersion({})
    setAnchorEl(null)
  }

  const rows = useMemo(() => {
    return versions.map((version, index) => {
      return (
        <tr key={`version-${version.id}`}>
          <td>{version.version}</td>
          <td>{version.name}</td>
          <td>{formatDateWithTime(version.createdAt)}</td>
          <td>
            <Taggings>
              {version.taggings?.map(tag => (
                <Tag key={tag.id}>{tag.tag?.name}</Tag>
              ))}
              {
                canBeAccessed && (
                  <AttachTagToFile file={version} isVersion>
                    <AddButton>+ manage</AddButton>
                  </AttachTagToFile>
                )
              }
            </Taggings>
          </td>
          {
            canBeAccessed && (
              <>
                <IconsTd>
                  <IconWrapper onClick={e => e.stopPropagation()}>
                    <MoreIcon
                      onClick={openMenu(version)}
                    />
                    <Menu
                      keepMounted
                      getContentAnchorEl={null}
                      anchorEl={anchorEl}
                      style={{
                        marginTop: '10px'
                      }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                      }}
                      open={dropdownOpen}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={handlePreviewFile(selectedVersion)}
                      >
                        Preview
                      </MenuItem>
                      <MenuItem
                        onClick={handleDownloadFile(selectedVersion)}
                      >
                        Download
                      </MenuItem>
                      <EditFileVersionModal
                        key={selectedVersion.id}
                        file={file}
                        version={selectedVersion}
                        unit={unit}
                      />
                      <DeleteFileVersionModal
                        versionId={selectedVersion.id}
                        fileId={file.id}
                        unit={unit}
                        isLastVersion={versions.length <= 1}
                      />
                    </Menu>
                  </IconWrapper>
                </IconsTd>
                <ReorderTd>
                  <ReorderVersionsWrapper>
                    <UpIcon
                      onClick={selectedVersion.version === firstVersion.version ? null : moveItem(index, 'increment')}
                      disabled={selectedVersion.version === firstVersion.version}
                    />
                    <DownIcon
                      onClick={selectedVersion.version === lastVersion.version ? null : moveItem(index, 'decrement')}
                      disabled={selectedVersion.version === lastVersion.version}
                    />
                  </ReorderVersionsWrapper>
                </ReorderTd>
              </>
            )
          }
        </tr>
      )
    })
  }, [versions, canBeAccessed, dropdownOpen, selectedVersion])

  return (
    <>
      <Wrapper>
        {
          canBeAccessed && (
            <AddButtonWrapper>
              <AddButton onClick={handleNewVersionModalOpen}>+ Add new version</AddButton>
            </AddButtonWrapper>
          )
        }
        <Table>
          <thead>
            <th>Version</th>
            <th>File name</th>
            <th>Upload date</th>
            <th>Tags</th>
            <th />
            <th />
          </thead>
          <tbody>
          {rows}
          </tbody>
        </Table>
      </Wrapper>
      <AddNewFileVersionModal
        unit={unit}
        file={file}
        open={newVersionModalOpen}
        handleClose={handleNewVersionModalClose}
      />
      <PdfFullScreenViewer open={pdfViewerOpen} onClose={handlePdfViewerClose} file={selectedVersion} />
      <ImageViewer open={imageViewerOpen} onClose={handleImageViewerClose} file={file} version={selectedVersion} />
    </>
  )
}

export default ManageFileVersionsModal

const Wrapper = styled.div`
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;

`

const Table = styled.table`
  width: 100%;

  thead {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey[700]};
  }

  tr:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey[500]};
  }

  td, th {
    text-align: left;
    font-size: 12px;
    padding: 5px;
  }

  tr td:last-of-type {
    text-align: right;
  }

  tr {
    transition: all .3s;

    &:hover {
      background-color: ${({ theme }) => theme.colors.grey[400]};
    }
  }
`

const AddButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
`

const IconsTd = styled.td`
  width: 25px;
`

const ReorderTd = styled.td`
  width: 60px;
`

const ReorderVersionsWrapper = styled.div`
  display: flex;
  gap: 5px;
  width: 60px;
`

const UpIcon = styled(ArrowDropUpIcon)`
  cursor: pointer;

  ${({ disabled }) => disabled && css`
    color: ${({ theme }) => theme.colors.grey[800]};
  `}
`

const DownIcon = styled(ArrowDropDownIcon)`
  cursor: pointer;

  ${({ disabled }) => disabled && css`
    color: ${({ theme }) => theme.colors.grey[800]};
  `}
`

const Taggings = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  align-items: center;
`

const Tag = styled.div`
  padding: 3px 7px;
  font-size: 12px;
  border-radius: 4px;
  background-color: #e6f2ff;
  color: ${({ theme }) => theme.colors.darkGrey};
  position: relative;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`

const MoreIcon = styled(MoreVertIcon)`
  color: ${({ theme }) => theme.colors.grey[600]};
  cursor: pointer;
  font-size: 20px !important;
`
