import {
  fetchDashboardStatisticsRoutine,
  fetchLastViewedBpsRoutine,
  fetchLastSearchedBpsRoutine,
  fetchLastSearchedFilesRoutine
} from 'features/dashboard/ducks/actions'
import { propOr } from 'ramda'
import emptyDashboardState from 'features/dashboard/ducks/state'
import { searchLocalFilesRoutine } from '../../bpDetails/ducks/actions'

export default (state = emptyDashboardState, action) => {
  switch (action.type) {
    case fetchLastSearchedBpsRoutine.SUCCESS:
      return {
        ...state,
        lastSearchedBps: propOr([], 'payload', action)
      }
    case fetchLastSearchedFilesRoutine.SUCCESS:
      return {
        ...state,
        lastSearchedFiles: propOr([], 'payload', action)
      }
    case fetchLastViewedBpsRoutine.SUCCESS:
      return {
        ...state,
        lastViewed: propOr([], 'payload', action)
      }
    case fetchDashboardStatisticsRoutine.SUCCESS:
      return {
        ...state,
        dashboard: propOr({}, 'payload', action)
      }
    default:
      return state
  }
}
