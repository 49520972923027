import React, { useEffect, useState } from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import styled, { css } from 'styled-components'
import { ReactComponent as DeleteIcon } from 'assets/images/delete.svg'
import { isNotNilOrEmpty } from 'utils/ramda'
import Tooltip from '@mui/material/Tooltip'

export const SelectMui = props => {
  const {
    options = [],
    name = '',
    label = '',
    onChange = () => {},
    validate = null,
    value: initialValue, //  = ''
    required = false,
    variant = 'outlined',
    disabled = false,
    placeholder = '',
    withDelete = false,
    onDelete = () => {},
    noMargin = false,
    multiple = false,
    disabledValues = null,
    hasError,
    selectAll
  } = props
  const [value, _setValue] = useState(initialValue || '')
  const [touched, _setTouched] = useState(false)
  const [{ valid, error }, _validate] = useState({
    valid: !validate,
    error: ''
  })

  useEffect(() => {
    validate && validate(name, v => {
      _validate({ valid: v.valid, error: v.errors })
    })
  }, [value])

  const renderOptions = options.map(option => {
    return isNotNilOrEmpty(option.description)
      ? (
        <MenuItem
          key={option.value}
          value={option.value}
          disabled={disabledValues && disabledValues.includes(option.label)}
        >
          <Tooltip title={option.description}>
            <div style={{ width: '100%', height: '100%' }}>{option.label}</div>
          </Tooltip>
        </MenuItem>
      )
      : (
        <MenuItem
          key={option.value}
          value={option.value}
          disabled={disabledValues && disabledValues.includes(option.label)}
        >
          {option.label}
        </MenuItem>
      )
  })

  const handleBlur = () => _setTouched(true)
  const handleChange = e => {
    const val = e.target.value || []

    if (multiple && val.includes('all')) {
      const allOptions = options.map(el => el.value)
      _setValue(allOptions)
      onChange(name, allOptions)
    } else {
      _setValue(e.target.value)
      onChange(name, e.target.value)
    }
  }

  useEffect(() => {
    _setValue(initialValue)
  }, [initialValue])

  return (
    <Wrapper>
      <LabelWrapper>
        {
          isNotNilOrEmpty(label) && (
            <Label>
              {
                valid || (!valid && !touched) || disabled
                  ? label
                  : <Error>{error}</Error>
              }
            </Label>
          )
        }
        {
          withDelete && (
            <DelIconWrapper onClick={onDelete}>
              <span>DELETE</span> <DeleteIcon />
            </DelIconWrapper>

          )
        }
      </LabelWrapper>
      <StyledFormControl
        noMargin={noMargin}
        variant={variant}
        required={required}
        error={((!valid && touched) || hasError) && !disabled}
      >
        <StyledSelect
          onBlur={handleBlur}
          value={value}
          multiple={multiple}
          onChange={handleChange}
          placeholder={placeholder}
          displayEmpty
          disabled={disabled}
        >
          {
            placeholder &&
              <MenuItem value={null} disabled>
                {placeholder}
              </MenuItem>
          }
          {
            selectAll && multiple &&
              <MenuItem value={'all'}>
                Select all
              </MenuItem>
          }
          {renderOptions}
        </StyledSelect>
      </StyledFormControl>
    </Wrapper>
  )
}

export default SelectMui

const Wrapper = styled.div`
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.secondary};
  }
`

const StyledSelect = styled(Select)`
  box-sizing: border-box;
  background-color: ${({ theme, disabled }) => disabled ? theme.colors.lightGrey : theme.colors.white};

  input {
    height: 45px;
    width: 90%;
  }

  div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

const StyledFormControl = styled(FormControl)`
  width: 100%;
  margin-bottom: ${({ noMargin }) => noMargin ? 0 : '22px'}!important;
  box-sizing: border-box;
  height: 45px !important;

  .MuiOutlinedInput-input {
    box-sizing: border-box;
    padding: 14px 15px;
    height: 45px !important;

    &:focus {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
`

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: .11px;
  min-height: 17px;
`

const Error = styled.span`
  color: ${({ theme }) => theme.colors.error};
`

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  align-items: center;

  ${({ withDelete }) => withDelete && css`
    color: ${({ theme }) => theme.colors.primary};
  `}
`

const DelIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  cursor: pointer;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};

  span {
    transition: all .3s;
    opacity: 0;
  }

  &:hover {
    span {
      opacity: 1;
    }
  }
`
