import { flatten, join, pathOr, pipe, values } from 'ramda'

export const extractMessageFromErrorResponse = pathOr('Something went wrong', [
  'response',
  'data',
  'message'
])

export const getApiErrors = error => {
  const errors = pipe(
    pathOr({}, ['response', 'data', 'errors']),
    values,
    flatten
  )(error)

  const filteredErrors = Array.isArray(errors)
    ? errors.filter(err => err !== 'Unauthenticated.')
    : errors

  const joinedErrors = Array.isArray(filteredErrors)
    ? join('\n', filteredErrors)
    : filteredErrors

  // Check for "Unauthenticated." in the message field as well
  const message = extractMessageFromErrorResponse(error)
  if (message === 'Unauthenticated.') {
    return '' // Return empty string or alternative message
  }

  return joinedErrors || message || 'An error occurred'
}
