import React, { useMemo, useState } from 'react'
import {
  SectionHeader,
  SectionHeaderWrapper
} from 'features/bpDetails/BpDetails'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { getFileTypes } from 'ducks/files/selectors'
import { isEmpty, pathOr, propOr } from 'ramda'
import { getFileTypeName } from 'utils/bpData'
import FileCategories from 'features/bpDetails/Components/files/FileCategories'
import { selectSelectedUnitFiles } from 'ducks/units/selectors'
import UnitFolders from 'features/bpDetails/Components/units/UnitFolders'
import { isResidentialUnit } from 'utils/units'
import File from 'features/bpDetails/Components/File'
import EmptyFileState from 'features/bpList/Components/EmptyFileState'

const UnitFiles = ({ unit, canEdit }) => {
  const unitFiles = useSelector(selectSelectedUnitFiles)
  const fileTypes = useSelector(getFileTypes)
  const [recentFilesVisible, setRecentFilesVisible] = useState(true)
  const isResidential = isResidentialUnit(unit.buildingType)

  const recentFiles = useMemo(() => {
    if (unitFiles && unit) {
      const shared = propOr([], 'sharedFiles', unit).map(u => ({
        ...u,
        isShared: true
      }))
      const privateFiles = propOr([], 'privateFiles', unit).map(u => ({
        ...u,
        isPrivate: true
      }))
      const allFiles = [...unitFiles, ...shared, ...privateFiles]
      const sortedFiles = allFiles.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      )
      return sortedFiles.slice(0, 3)
    }
  }, [unitFiles, unit])

  return (
    <UnitFilesWrapper>
      {recentFilesVisible && (
        <>
          <SectionHeaderWrapper>
            <SectionHeader>Recent files</SectionHeader>
          </SectionHeaderWrapper>
          <FilesWrapper>
            {isEmpty(recentFiles) ? (
              <EmptyFileState
                text={
                  canEdit && !isResidential
                    ? 'Add your first file to this Unit'
                    : 'There are no recent files'
                }
                buttonText='+ Add files'
                hideButton={!canEdit || isResidential}
                unit={unit}
              />
            ) : (
              recentFiles.map(file => (
                <File
                  isUnit
                  isShared={file.isShared}
                  key={`file-${Math.random() * 99}`}
                  file={file}
                  isPrivate={file.isPrivate}
                  unit={unit}
                  type={getFileTypeName(
                    pathOr('', ['fileType', 'id'], file),
                    fileTypes,
                    file.isPrivate
                  )}
                />
              ))
            )}
          </FilesWrapper>
        </>
      )}
      <UnitFolders unit={unit} />
      {!isResidential && (
        <FileCategories
          unit={unit}
          setRecentFilesVisible={setRecentFilesVisible}
          canEditUnit={canEdit}
        />
      )}
    </UnitFilesWrapper>
  )
}

export default UnitFiles

const UnitFilesWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: relative;
  margin-top: 30px;
`

const FilesWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  margin-bottom: 5px;
`
