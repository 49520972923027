import { useEffect, useRef, useCallback } from 'react'
import { useDispatch } from 'react-redux'

export function useApiCall(actionCreator, options = {}) {
  const { deps = [], skipCondition = false, params = {}, callbackFn } = options
  const dispatch = useDispatch()

  const hasDispatchedRef = useRef(false)
  const prevDepsRef = useRef([])
  const prevParamsRef = useRef(params)

  useEffect(() => {
    if (skipCondition) {
      hasDispatchedRef.current = false
      return
    }

    const paramsChanged =
      JSON.stringify(prevParamsRef.current) !== JSON.stringify(params)

    const depsChanged =
      deps.length === 0
        ? false
        : prevDepsRef.current.length !== deps.length ||
          deps.some((dep, i) => dep !== prevDepsRef.current[i])

    if (!hasDispatchedRef.current || depsChanged || paramsChanged) {
      if (typeof actionCreator === 'function') {
        const action = actionCreator(params)

        if (action && typeof action === 'object' && action.type) {
          dispatch(action)

          if (typeof callbackFn === 'function') {
            callbackFn()
          }

          hasDispatchedRef.current = true
          prevDepsRef.current = [...deps]
          prevParamsRef.current = { ...params }
        }
      }
    }
  }, [dispatch, actionCreator, skipCondition, JSON.stringify(params), ...deps])
}
