import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import CloseIcon from '@mui/icons-material/Close'
import MultiFileInput from 'features/bpDetails/Components/files/MultiFileInput'
import { isNilOrEmpty, isNotNil, isNotNilOrEmpty } from 'utils/ramda'
import NextButton from 'features/createBp/components/atoms/NextButton'
import FileModalTableRow from 'features/bpDetails/Components/files/FileModalTableRow'
import AssistantSteps from 'features/bpDetails/Components/files/AssistantSteps'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import AssistantOutlinedIcon from '@mui/icons-material/AssistantOutlined'
import {
  addFileToProcess,
  addUnitFileToProcess,
  getFilesListForBp,
  startMultiUploadProcess,
  startUnitMultiUploadProcess
} from 'services/fileService'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSelectedBp,
  selectHasAccessToBp
} from 'features/bpDetails/ducks/selectors'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-toastify'
import BulkEditForm from 'features/bpDetails/Components/files/BulkEditForm'
import {
  cleanupFileWebSocketRoutine,
  finalizeFileRoutine,
  getBpFilesRoutine,
  getFileTagsForBpRoutine,
  getUploadProcessRoutine,
  initFileWebSocketRoutine
} from 'ducks/files/actions'
import {
  selectCurrentUploadProcess,
  selectMultiUploadFiles
} from 'ducks/files/selectors'
import {
  clearUploadProcessFetchDetailsRoutine,
  clearUploadProcessRoutine,
  saveUploadProcessFetchDetailsRoutine
} from 'ducks/files/actions'
import FileMinified from 'features/bpDetails/Components/FileMinified'
import Pagination from 'components/atoms/Pagination'
import {
  fetchBpRoutine,
  markBpAsViewedRoutine
} from 'features/bpDetails/ducks/actions'
import {
  fetchBpUnitsRoutine,
  fetchUnitRoutine
} from '../../../../ducks/units/actions'
import { propOr } from 'ramda'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { useLocation, useParams } from 'react-router'
import LogoShort from 'assets/images/bp-logo-orange.png'
import { Dialog, DialogContent, DialogTitle, Button } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import WarningIcon from '@mui/icons-material/Warning'
const AddMultipleFilesPage = ({
  externalBpId,
  children,
  unit,
  isPrivate,
  addedFilesCount
}) => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const method = queryParams.get('method') || 'traditional'
  const isAI = method === 'ai'
  const { id } = useParams()
  const hasFullAccess = useSelector(selectHasAccessToBp)

  const initialCategory = queryParams.get('category')
  const initialType = queryParams.get('type')

  const [showCompletionSummary, setShowCompletionSummary] = useState(false)
  const [completionSummary, setCompletionSummary] = useState({
    status: 'success',
    totalFiles: 0,
    successFiles: 0,
    failedFiles: 0
  })

  const [files, setFiles] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])
  const [bulkChangeOpen, setBulkChangeOpen] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [validationState, setValidationState] = useState([])
  const [alreadyAddedFiles, setAlreadyAddedFiles] = useState([])
  const [showIntro, setShowIntro] = useState(isAI)
  const [currentStep, setCurrentStep] = useState(1)
  const [assistantStep, setAssistantStep] = useState(1)
  const [fileData, setFileData] = useState({})
  const [addedPagePagination, setAddedPagePagination] = useState({
    page: 1,
    pagesCount: 1
  })
  const [payload, setPayload] = useState([])
  const bp = useSelector(getSelectedBp)
  const [bulkChangeValues, setBulkChangeValues] = useState(null)
  const dispatch = useDispatch()
  const isUnit = isNotNilOrEmpty(unit)
  const [isLoading, setLoading] = useState(false)
  const multiUploadFiles = useSelector(selectMultiUploadFiles)
  const filesFromLocalStorage = JSON.parse(
    localStorage.getItem('uploadFiles') || '[]'
  ).map(f => f.trim())
  const usedMultiUploadFiles = new Set()
  const buildingName = bp?.name || 'Building'
  const [isFetched, setIsFetched] = useState(false)
  const [webSocketInitialized, setWebSocketInitialized] = useState(false)

  const allCompleted = useMemo(() => {
    if (isNotNilOrEmpty(multiUploadFiles)) {
      return (
        multiUploadFiles.every(file =>
          ['completed', 'failed'].includes(file.status)
        ) && filesFromLocalStorage.length === multiUploadFiles.length
      )
    } else {
      return false
    }
  }, [filesFromLocalStorage, multiUploadFiles])

  useEffect(() => {
    return () => {
      dispatch(cleanupFileWebSocketRoutine())
    }
  }, [dispatch])

  const initializeWebSockets = (processId, files) => {
    if (files && files.length > 0 && !webSocketInitialized) {
      const uniqueFiles = files.map((file, index) => ({
        ...file,
        uniqueId: `${file.id}-${index}`,
        displayName: file.data?.displayName || '',
        originalIndex: index
      }))

      dispatch(
        initFileWebSocketRoutine({
          processId,
          files: uniqueFiles,
          bpId: externalBpId || bp.id
        })
      )
      setWebSocketInitialized(true)
    }
  }

  useEffect(() => {
    if (multiUploadFiles.length > 0 && Object.keys(fileData).length > 0) {
      Object.keys(fileData).forEach(key => {
        const fileId = fileData[key].file_id
        const serverFile = multiUploadFiles.find(f => f.id === fileId)

        if (serverFile) {
          // console.log(`File ${key} status updated:`, serverFile.status)
          // Force a re-render by updating the state
          setFileData(prev => ({
            ...prev,
            [key]: {
              ...prev[key],
              status: serverFile.status
            }
          }))
        }
      })
    }
  }, [multiUploadFiles])

  useEffect(() => {
    // Handle document visibility changes
    if (typeof document !== 'undefined') {
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible' && currentStep === 2) {
          // When user returns to the tab and we're on step 2 (complete upload)
          console.log(
            'Tab visibility changed to visible, checking upload status'
          )

          // Reset loading state
          setLoading(false)

          // Check if uploads are completed
          const bpId = externalBpId || bp?.id
          if (bpId) {
            dispatch(
              getUploadProcessRoutine({
                processId: window.lastProcessId,
                bpId: bpId
              })
            )
          }
        }
      }

      // Listen for visibility change events
      document.addEventListener('visibilitychange', handleVisibilityChange)

      // Clean up
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange)
      }
    }
    return () => {}
  }, [currentStep, externalBpId, bp?.id, dispatch])

  useEffect(() => {
    if (id) {
      dispatch(getFileTagsForBpRoutine({ bpId: id }))
      if (!isFetched) {
        dispatch(
          fetchBpRoutine({
            id,
            callback: !isFetched ? () => setIsFetched(true) : null
          })
        )
        dispatch(fetchBpUnitsRoutine({ bpId: id }))
      }
    }
  }, [id, hasFullAccess])

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowIntro(false)
    }, 500)
    return () => clearTimeout(timer)
  }, [])

  const handleSelectAll = () => {
    selectedFiles.length === files.length
      ? setSelectedFiles([])
      : setSelectedFiles(files)
  }

  const handleAddedFilesFetch = page => {
    getFilesListForBp({
      id: externalBpId || bp.id,
      query: {
        page: Math.max(1, page || addedPagePagination.page),
        limit: 10,
        filter: { type: initialType }
      }
    }).then(resp => {
      setAddedPagePagination({
        pagesCount: resp.data.meta.lastPage,
        page: resp.data.meta.currentPage
      })
      setAlreadyAddedFiles(resp.data.data)
    })
  }

  const handleModalOpen = () => {
    setIsSubmitted(false)
    // setOpen(true)

    setTimeout(() => {
      setShowIntro(false)
    }, 500)

    if (addedFilesCount && addedFilesCount > 0) {
      handleAddedFilesFetch()
    }
  }

  const handleBulkChangeValues = val => {
    setBulkChangeValues(val)

    setTimeout(() => {
      setSelectedFiles([])
      setBulkChangeValues(null)
    }, 100)
  }

  const handleModalClose = () => {
    setLoading(false)
    setFiles([])
    setShowIntro(true)
    setSelectedFiles([])
    setPayload([])
    setValidationState([])
    // setOpen(false)
    setFileData({})
    setCurrentStep(1)
    setAssistantStep(1)

    if (addedFilesCount && addedFilesCount > alreadyAddedFiles.length) {
      dispatch(fetchBpRoutine({ id: externalBpId || bp.id }))
    }

    window.close()
  }

  const handleAddFiles = filesToAdd => {
    setFiles(prev => [...prev, ...filesToAdd])
    setIsSubmitted(false)
  }

  const handleRemove = index => () => {
    const file = files[index]
    setFiles(prev => prev.filter((_, i) => i !== index))
    setSelectedFiles(prev =>
      prev.filter(f => f.lastModified !== file.lastModified)
    )
    const tempValidationState = [...validationState]
    const tempPayload = [...payload]
    tempValidationState.splice(index, 1)
    tempPayload.splice(index, 1)
    setValidationState(tempValidationState)
    setPayload(tempPayload)
  }

  const toggleFileSelection = file => () => {
    const isSelected = isNotNilOrEmpty(
      selectedFiles.find(f => f.name === file.name)
    )
    setSelectedFiles(prev => {
      if (isSelected) {
        return prev.filter(f => f.name !== file.name)
      } else {
        return [...prev, file]
      }
    })
  }

  const handleValidationChange = index => value => {
    setValidationState(prev => {
      const newState = [...prev]
      newState[index] = value
      return newState
    })
  }

  const handleValuesChange = index => values => {
    setPayload(prev => {
      const newState = [...prev]
      newState[index] = {
        file: values.file,
        ...values.values
      }
      return newState
    })
  }

  const normalizeFileName = name => {
    if (!name) return ''

    // First, simplify by lowercase
    let normalized = name.toLowerCase()

    // Remove common file extensions anywhere in the string
    normalized = normalized
      .replace(/\.pdf\.png$/i, '.') // Handle the specific .pdf.png case first
      .replace(/\.pdf/gi, '.') // Remove PDF extensions anywhere
      .replace(/\.(png|jpg|jpeg|doc|docx|xlsx|xls)$/i, '.') // Remove other extensions at the end
      .replace(/\./g, '') // Remove all remaining dots
      .replace(/[_\- ]/g, '') // Remove underscores, hyphens, and spaces

    return normalized
  }

  const rows = files.map((file, index) => {
    const findInSelected = selectedFiles.find(f => f.name === file.name)
    const isSelected = isNotNilOrEmpty(findInSelected)

    const mappingKey = `file-${index}`
    const fileMapping = fileData[mappingKey]

    let uploadStatus = 'pending'
    let matchingFile = null

    if (fileMapping) {
      // Debug: log the mapping and multiUploadFiles
      // console.log(`Mapping for ${mappingKey}:`, fileMapping)
      // console.log('multiUploadFiles:', multiUploadFiles)

      // Match the multiUploadFiles by comparing the file_id
      matchingFile = multiUploadFiles.find(f => f.id === fileMapping.file_id)

      // if (!matchingFile) {
      //   console.log(
      //     `No matching multiUploadFile found for fileMapping ${mappingKey}:`,
      //     fileMapping
      //   )
      // }

      if (matchingFile && !usedMultiUploadFiles.has(matchingFile.id)) {
        usedMultiUploadFiles.add(matchingFile.id)
        uploadStatus = matchingFile.status
      }
    } else {
      console.log(`No file mapping found for ${mappingKey} and file:`, file)
    }

    // console.log('Matching file for:', file.name, matchingFile)
    // console.log('Upload status:', uploadStatus)
    // console.log('AI File', matchingFile?.storedFile)

    return (
      <FileModalTableRow
        isPrivate={isPrivate}
        unit={unit}
        currentStep={currentStep}
        initialCategory={
          matchingFile?.storedFile &&
          (matchingFile.storedFile.use_ai || matchingFile.storedFile.useAi) &&
          (matchingFile.storedFile.ai_confidence > 55 ||
            matchingFile.storedFile.aiConfidence > 55) &&
          (matchingFile.storedFile.ai_file_category_id ||
            matchingFile.storedFile.aiFileCategoryId)
            ? matchingFile.storedFile.ai_file_category_id ||
              matchingFile.storedFile.aiFileCategoryId
            : initialCategory || null
        }
        initialType={
          matchingFile?.storedFile &&
          (matchingFile.storedFile.use_ai || matchingFile.storedFile.useAi) &&
          (matchingFile.storedFile.ai_confidence > 55 ||
            matchingFile.storedFile.aiConfidence > 55) &&
          (matchingFile.storedFile.ai_file_type_id ||
            matchingFile.storedFile.aiFileTypeId)
            ? matchingFile.storedFile.ai_file_type_id ||
              matchingFile.storedFile.aiFileTypeId
            : initialType || null
        }
        certaintyLevel={
          matchingFile?.storedFile &&
          (matchingFile.storedFile.use_ai || matchingFile.storedFile.useAi) &&
          (matchingFile.storedFile.ai_confidence ||
            matchingFile.storedFile.aiConfidence)
            ? matchingFile.storedFile.ai_confidence ||
              matchingFile.storedFile.aiConfidence
            : 0
        }
        createdAt={
          matchingFile?.storedFile &&
          (matchingFile.storedFile.created_at ||
            matchingFile.storedFile.createdAt)
            ? matchingFile.storedFile.created_at ||
              matchingFile.storedFile.createdAt
            : null
        }
        thumbnailUrl={
          matchingFile?.storedFile &&
          (matchingFile.storedFile.thumbnail_url ||
            matchingFile.storedFile.thumbnailUrl)
            ? matchingFile.storedFile.thumbnail_url ||
              matchingFile.storedFile.thumbnailUrl
            : null
        }
        isSelected={isSelected}
        isSubmitted={isSubmitted}
        bulkChangeValues={bulkChangeValues}
        key={`file-table-row-${file.name}`}
        file={file}
        onRemove={handleRemove(index)}
        onSelect={toggleFileSelection(file)}
        onValidationStateChange={handleValidationChange(index)}
        onChange={handleValuesChange(index)}
        uploadStatus={uploadStatus}
      />
    )
  })

  const headers = isUnit
    ? isPrivate
      ? ['File', 'Delete']
      : ['File', 'Category', 'File type', '']
    : currentStep === 1
      ? ['File', 'Delete']
      : isAI // Only include certainty column when isAI is true
        ? ['File', 'Category', 'File type', 'Certainty', '']
        : ['File', 'Category', 'File type', '']

  const selectedFilesLength = selectedFiles.map((_, i) => i).length
  const handleBulkChangeToggle = () => setBulkChangeOpen(prev => !prev)

  const isFormValid = validationState.every(v => v)

  const handleFileUpload = () => {
    setIsSubmitted(true)
    const bpId = externalBpId || bp.id

    if (isFormValid || isPrivate) {
      setLoading(true)
      dispatch(clearUploadProcessFetchDetailsRoutine())
      dispatch(clearUploadProcessRoutine())

      if (isUnit) {
        startUnitMultiUploadProcess({
          bpId,
          unitId: unit.id,
          totalFiles: payload.length
        }).then(resp => {
          const processId = resp.data?.data?.id
          dispatch(
            saveUploadProcessFetchDetailsRoutine({
              processId,
              unitId: unit.id,
              bpId
            })
          )
          payload.forEach(file => {
            addUnitFileToProcess({
              ...file,
              bpId,
              unitId: unit.id,
              processId,
              isPrivate: isNotNil(isPrivate) ? isPrivate : false
            }).catch(e => {
              toast.error(getApiErrors(e))
            })
          })
          toast.success('Files have been added to upload process')
          handleModalClose()
        })
      } else {
        setAssistantStep(2)
        startMultiUploadProcess({ bpId, totalFiles: payload.length })
          .then(resp => {
            const processId = resp.data?.data?.id
            const processFiles = resp.data?.data?.files || []

            // Save process details to Redux
            dispatch(
              saveUploadProcessFetchDetailsRoutine({
                processId,
                bpId
              })
            )

            // Initialize WebSockets if we have files already
            if (processFiles.length > 0) {
              initializeWebSockets(processId, processFiles)
            }

            let completedCount = 0

            const uploadedFiles = []

            payload.forEach((file, index) => {
              addFileToProcess({
                ...file,
                bpId,
                processId,
                isAI
              })
                .then(response => {
                  const processData = response.data.data

                  // If file was successfully uploaded, find its ID
                  if (processData && processData.files) {
                    // Find the newly added file (usually the last one)
                    const uploadFileName = file.file
                      ? file.file.name
                      : file.name

                    let addedFile = null
                    let matchReason = ''

                    // Try to find by exact display name match
                    const exactNameMatch = processData.files.find(f => {
                      const serverFileName = f.data?.displayName
                      return serverFileName === uploadFileName
                    })

                    if (exactNameMatch) {
                      addedFile = exactNameMatch
                      matchReason = 'exact name match'
                    } else {
                      // Try to find by normalized name
                      const normalizedMatch = processData.files.find(f => {
                        const serverFileName = f.data?.displayName
                        if (uploadFileName && serverFileName) {
                          const normalized1 = normalizeFileName(serverFileName)
                          const normalized2 = normalizeFileName(uploadFileName)
                          return normalized1 === normalized2
                        }
                        return false
                      })

                      if (normalizedMatch) {
                        addedFile = normalizedMatch
                        matchReason = 'normalized name match'
                      } else {
                        // Fallback to latest file
                        addedFile =
                          processData.files[processData.files.length - 1]
                        matchReason = 'fallback to last file'
                      }
                    }

                    // console.log(`Selected file match for ${uploadFileName}:`, {
                    //   id: addedFile.id,
                    //   displayName: addedFile.data?.displayName,
                    //   matchReason: matchReason
                    // })

                    if (addedFile) {
                      const mappingData = {
                        multi_upload_id: processData.id,
                        file_id: addedFile.id,
                        file_index: index,
                        original_name: uploadFileName,
                        server_name: addedFile.data?.displayName || '',
                        match_reason: matchReason
                      }

                      setFileData(prev => ({
                        ...prev,
                        [`file-${index}`]: mappingData
                      }))

                      uploadedFiles.push({
                        ...addedFile,
                        original_index: index,
                        original_name: uploadFileName
                      })
                    }
                  }
                })
                .catch(e => {
                  toast.error(getApiErrors(e))
                })
                .finally(() => {
                  completedCount++
                  // When all files have finished processing, perform final actions.
                  if (completedCount === payload.length) {
                    if (!webSocketInitialized && uploadedFiles.length > 0) {
                      initializeWebSockets(processId, uploadedFiles)
                    }
                    setCurrentStep(2)
                    setAssistantStep(3)
                    toast.info('Files are being uploaded...')
                    setLoading(false)
                  }
                })
            })
          })
          .catch(e => {
            toast.error(getApiErrors(e))
            setLoading(false)
          })
      }
    }
  }

  const handleSubmit = async () => {
    setIsSubmitted(true)
    dispatch(clearUploadProcessFetchDetailsRoutine())
    dispatch(clearUploadProcessRoutine())
    const bpId = externalBpId || bp.id
    console.log('handleSubmit - Using bpId:', bpId)

    if (isFormValid) {
      setLoading(true)
      setAssistantStep(4)
      toast.info('Finalizing file upload - Please wait...')

      // Create array of files to process
      const filesToProcess = []

      // First gather all valid files to process
      payload.forEach((file, index) => {
        const mappingKey = `file-${index}`
        const mapping = fileData[mappingKey]

        if (!mapping || !mapping.file_id) {
          console.warn(
            `No valid mapping for file at index ${index} (${file.name})`
          )
          return
        }

        const matchingFile = multiUploadFiles.find(
          f => f.id === mapping.file_id
        )

        if (matchingFile?.storedFile?.id) {
          filesToProcess.push({
            file,
            index,
            matchingFile,
            mapping
          })
        }
      })

      if (filesToProcess.length === 0) {
        toast.error('No valid files to finalize')
        setLoading(false)
        return
      }

      // Process files sequentially instead of in parallel
      let processedCount = 0
      const finalizedFiles = []
      const failedFiles = []

      for (const item of filesToProcess) {
        try {
          const finalizePayload = {
            fileId: item.matchingFile.storedFile.id,
            fileType: item.file.fileType,
            floors: item.file.floors,
            units: item.file.units,
            expirationDate: item.file.expirationDate,
            userIds: item.file.userIds,
            tagIds: item.file.tagIds,
            bpId: bpId
          }

          console.log(
            `Finalizing file ${processedCount + 1}/${filesToProcess.length}: ${item.file.file?.name || 'unknown'}`
          )

          // Dispatch and wait (using timeout as a workaround since dispatch doesn't return a promise)
          dispatch(finalizeFileRoutine(finalizePayload))

          // Wait a bit for the request to complete
          await new Promise(resolve => setTimeout(resolve, 1500))

          finalizedFiles.push(item)
          processedCount++

          // Show progress
          if (
            processedCount % 5 === 0 ||
            processedCount === filesToProcess.length
          ) {
            toast.info(
              `Processed ${processedCount} of ${filesToProcess.length} files...`
            )
          }
        } catch (error) {
          console.error(`Error finalizing file at index ${item.index}:`, error)
          failedFiles.push(item)
        }
      }

      // Check finalization status
      if (finalizedFiles.length === filesToProcess.length) {
        // All files were processed successfully
        toast.success('All files finalized successfully')

        // Update the file list in Redux before redirecting
        dispatch(getBpFilesRoutine({ id: bpId }))

        // Wait a moment for the file list to update
        await new Promise(resolve => setTimeout(resolve, 1000))

        setCompletionSummary({
          status: 'success',
          totalFiles: filesToProcess.length,
          successFiles: finalizedFiles.length,
          failedFiles: 0
        })
        setShowCompletionSummary(true)
        setLoading(false)
      } else if (finalizedFiles.length > 0) {
        // Some files were processed
        toast.warning(
          `Finalized ${finalizedFiles.length} out of ${filesToProcess.length} files`
        )

        // Update the file list for the successful ones
        dispatch(getBpFilesRoutine({ id: bpId }))

        // Wait a moment for the file list to update
        await new Promise(resolve => setTimeout(resolve, 1000))

        // Redirect
        toast.success(
          'Upload process has been completed with some problems. You can safely close this tab.'
        )
        setCompletionSummary({
          status: 'partial',
          totalFiles: filesToProcess.length,
          successFiles: finalizedFiles.length,
          failedFiles: filesToProcess.length - finalizedFiles.length
        })
        setShowCompletionSummary(true)
        setLoading(false)
      } else {
        // No files were processed successfully
        toast.error('Failed to finalize any files')
        setCompletionSummary({
          status: 'failed',
          totalFiles: filesToProcess.length,
          successFiles: 0,
          failedFiles: filesToProcess.length
        })
        setShowCompletionSummary(true)
        setLoading(false)
      }
    }
  }

  const handleBulkDelete = () => {
    const selectedIds = selectedFiles.map(file => file.lastModified)
    setFiles(prev =>
      prev.filter(file => !selectedIds.includes(file.lastModified))
    )
    setSelectedFiles(prev =>
      prev.filter(file => !selectedIds.includes(file.lastModified))
    )
  }

  if (!isFetched) {
    return (
      <Loading>
        <img src={LogoShort} alt='bp-logo' />
        <div>Loading Building Passport</div>
      </Loading>
    )
  }

  return (
    <>
      {showIntro && isAI ? (
        <>
          <ModalHeader>
            <ModalTitle>
              <AIIcon />
              Smart Upload Assistant - {buildingName}
            </ModalTitle>
          </ModalHeader>
          <ModalContent>
            <div>
              Welcome to your building's digital librarian and smart assistant.
              Transform your disorganised, disparate building files into a
              clean, organised and searchable repository at the drag and drop of
              your cursor.
            </div>
            <AssistantStepsWrapper>
              <AssistantSteps />
            </AssistantStepsWrapper>
          </ModalContent>
        </>
      ) : (
        <>
          <ModalHeader>
            {isAI ? (
              <ModalTitle>
                <AIIcon />
                Smart Upload Assistant - {buildingName}
              </ModalTitle>
            ) : (
              <ModalTitle>
                <TraditionalIcon />
                Traditional File Upload - {buildingName}
              </ModalTitle>
            )}
            {isAI && (
              <StepsWrapper>
                <AssistantSteps isSmall={true} activeStep={assistantStep} />
              </StepsWrapper>
            )}
          </ModalHeader>
          <ModalContent>
            {isAI && currentStep === 1 && (
              <FeedbackNote>
                <FeedbackNoteHeading>AI Document Upload</FeedbackNoteHeading>
                <FeedbackNoteParagraph>
                  Welcome to your building's digital librarian and smart
                  assistant.
                </FeedbackNoteParagraph>
                <FeedbackNoteParagraph>
                  Transform your disorganised, disparate building files into a
                  clean, organised and searchable repository at the drag and
                  drop of your cursor.
                </FeedbackNoteParagraph>
              </FeedbackNote>
            )}
            {isAI && currentStep === 1 && (
              <FileCountWarning>
                <WarningIcon style={{ color: '#f57c00', marginRight: '8px' }} />
                <span>
                  For optimal AI processing during the initial roll-out, please
                  limit each upload to 25 files or fewer.
                </span>
              </FileCountWarning>
            )}
            {!isAI && (
              <FeedbackNote>
                <FeedbackNoteHeading>Traditional Upload</FeedbackNoteHeading>
                <FeedbackNoteParagraph>
                  Upload your files and manually categorise them. You'll need to
                  select the appropriate file type and category for each
                  document.
                </FeedbackNoteParagraph>
              </FeedbackNote>
            )}
            {isAI && currentStep === 2 && (
              <FeedbackNote>
                <FeedbackNoteHeading>Review & Feedback</FeedbackNoteHeading>
                <FeedbackNoteParagraph>
                  Please review the files you have uploaded and ensure that the
                  information is correct. We need your feedback—if the file type
                  is not automatically recognized or is missing, please manually
                  add it and verify that the selected category is correct.
                </FeedbackNoteParagraph>
                <FeedbackNoteParagraph>
                  You can edit multiple files at once by selecting them from the
                  checkboxes on the left.
                </FeedbackNoteParagraph>
                <FeedbackNoteSmall>
                  Note: Only a few file types are supported by our AI system at
                  the moment, so your input is very important.
                </FeedbackNoteSmall>
              </FeedbackNote>
            )}
            {(currentStep >= 2 || isUnit) &&
              isNotNilOrEmpty(alreadyAddedFiles) && (
                <AlreadyUploadedWrapper>
                  <AlreadyUploadedHeader>
                    Uploaded files of this type
                  </AlreadyUploadedHeader>
                  <AlreadyUploadedFiles>
                    {alreadyAddedFiles.map(addedFile => {
                      return (
                        <FileMinified
                          callback={handleAddedFilesFetch}
                          key={`file-${Math.random() * 99}`}
                          file={addedFile}
                          isShared={addedFile.isSharedWithUnits}
                        />
                      )
                    })}
                  </AlreadyUploadedFiles>
                  <Pagination
                    onChange={handleAddedFilesFetch}
                    totalPages={addedPagePagination.pagesCount}
                    currentPage={addedPagePagination.page}
                  />
                </AlreadyUploadedWrapper>
              )}
            {(currentStep >= 2 || isUnit) && isNotNilOrEmpty(selectedFiles) && (
              <SelectedFilesWrapper>
                <SelectedFilesHeader>
                  {`${selectedFilesLength} selected`}
                  <SelectedActions>
                    {!isPrivate && (
                      <IconWrapper
                        active={bulkChangeOpen}
                        onClick={handleBulkChangeToggle}
                      >
                        <EditIcon />
                      </IconWrapper>
                    )}
                    <IconWrapper>
                      <DeleteIcon onClick={handleBulkDelete} />
                    </IconWrapper>
                  </SelectedActions>
                </SelectedFilesHeader>
                <BulkEditForm
                  isPrivate={isPrivate}
                  isUnit={isUnit}
                  open={bulkChangeOpen && !isPrivate}
                  onChange={handleBulkChangeValues}
                />
              </SelectedFilesWrapper>
            )}
            {isNotNilOrEmpty(files) && (
              <TableContent>
                <TableHeaders
                  isUnit={isUnit}
                  isPrivate={isPrivate}
                  currentStep={currentStep}
                  isAI={isAI}
                >
                  <TableHeader key={`header-select-all`}>
                    <RadioCell>
                      <input
                        type='checkbox'
                        onChange={handleSelectAll}
                        checked={selectedFiles.length === files.length}
                      />
                    </RadioCell>
                  </TableHeader>
                  {headers.map(header => (
                    <TableHeader key={`header-${header}`}>{header}</TableHeader>
                  ))}
                </TableHeaders>
                {rows}
              </TableContent>
            )}
            {currentStep === 1 && (
              <>
                <FileInputWrapper>
                  <MultiFileInput
                    accept='.pdf,.dwg,.doc,.docx,.jpg,.jpeg,.txt,.png,.svg,.xlsx,.xls,.xlsm'
                    onAdd={handleAddFiles}
                  />
                </FileInputWrapper>
              </>
            )}
            {(currentStep === 2 || isUnit) && !isFormValid && isSubmitted && (
              <ErrorsWarning>
                Fill in the required information highlighted above before these
                files are added
              </ErrorsWarning>
            )}
            {currentStep === 2 && isNotNilOrEmpty(files) && (
              <ButtonWrapper changedMargin={!isFormValid && isSubmitted}>
                <NextButton
                  onClick={handleSubmit}
                  disabled={isLoading || !isFormValid || !allCompleted}
                  isLoading={isLoading}
                >
                  Complete Upload
                </NextButton>
              </ButtonWrapper>
            )}
            {currentStep === 1 && isNotNilOrEmpty(files) && (
              <ButtonWrapper>
                <NextButton
                  onClick={handleFileUpload}
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  Upload Files
                </NextButton>
              </ButtonWrapper>
            )}
          </ModalContent>
        </>
      )}
      {showCompletionSummary && (
        <StyledDialog
          open={showCompletionSummary}
          onClose={() => setShowCompletionSummary(false)}
          maxWidth='sm'
          fullWidth
        >
          <DialogHeader>
            {completionSummary.status === 'success' ? (
              <SuccessIcon />
            ) : (
              <WarningStatusIcon />
            )}
            <DialogHeaderText>
              Upload{' '}
              {completionSummary.status === 'success'
                ? 'Complete'
                : 'Completed with Issues'}
            </DialogHeaderText>
          </DialogHeader>
          <DialogBody>
            <UploadSummary>
              <SummaryItem>
                <SummaryLabel>Total files:</SummaryLabel>
                <SummaryValue>{completionSummary.totalFiles}</SummaryValue>
              </SummaryItem>
              <SummaryItem>
                <SummaryLabel>Successfully uploaded:</SummaryLabel>
                <SummaryValue success>
                  {completionSummary.successFiles}
                </SummaryValue>
              </SummaryItem>
              {completionSummary.failedFiles > 0 && (
                <SummaryItem>
                  <SummaryLabel>Failed to upload:</SummaryLabel>
                  <SummaryValue error>
                    {completionSummary.failedFiles}
                  </SummaryValue>
                </SummaryItem>
              )}
              <SummaryMessage>
                {completionSummary.status === 'success'
                  ? 'All files have been successfully uploaded and categorised.'
                  : completionSummary.status === 'partial'
                    ? 'Some files were uploaded successfully, but others encountered issues. You may need to retry uploading the failed files.'
                    : 'There was a problem uploading your files. Please try again or contact support if the issue persists.'}
              </SummaryMessage>
              <CloseTabMessage>
                Your files have been uploaded to the Building Passport. It's now
                safe to close this tab.
              </CloseTabMessage>
              <SummaryActions>
                <StyledButton onClick={() => window.close()}>
                  Close
                </StyledButton>
              </SummaryActions>
            </UploadSummary>
          </DialogBody>
        </StyledDialog>
      )}
    </>
  )
}

export default AddMultipleFilesPage

const Trigger = styled.div`
  width: fit-content;
`

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  padding: 30px;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`

const FakeModal = styled.div`
  width: 95vw;
  max-width: 1400px;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;

  svg {
    margin-left: 0 !important;
    cursor: default;
  }

  input,
  option,
  .MuiSelect-root {
    font-size: 12px !important;
  }
`

const CloseIconWrapper = styled.div`
  width: fit-content;
  padding: 5px;
  cursor: pointer;
`

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[600]};
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0 20px;
`

const ModalContent = styled.div`
  padding: 24px;
  // 100vh - backdrop paddings - modal padding - header height
  background-color: ${({ theme }) => theme.colors.white};
`

const ModalTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 28px;
  font-variant: all-small-caps;
`

const StyledCloseIcon = styled(CloseIcon)`
  font-size: 16px !important;
  color: ${({ theme }) => theme.colors.haiti};
  cursor: pointer !important;

  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`

const FileInputWrapper = styled.div`
  padding: 0 0px;
  margin: 33px 0 29px;
`

const TableContent = styled.div`
  border: 1px solid #eaecf0;
  border-radius: 8px;
  width: 100%;
  margin-top: 30px;
  box-sizing: border-box;
`

const FeedbackNote = styled.div`
  padding: 12px 16px;
  border: 1px solid #ddd;
  border-left: 4px solid ${({ theme }) => theme.colors.primary || '#007bff'};
  border-radius: 4px;
  margin-bottom: 16px;
  font-size: 18px; /* overall smaller font size */
  color: #555; /* overall text color */
`

const FeedbackNoteHeading = styled.h3`
  font-size: 22px;
  margin-bottom: 8px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary || '#007bff'};
`

const FeedbackNoteParagraph = styled.p`
  font-size: 18px;
  color: #555;
  line-height: 1.5;
  margin-bottom: 8px;
`

const FeedbackNoteSmall = styled.p`
  font-size: 14px;
  color: #555;
`

const TableHeaders = styled.div`
  width: 100%;
  display: grid;
  /* Base columns - prioritize file, category, and type columns */
  grid-template-columns: 40px 1fr 1fr 1fr 60px 60px 60px 60px 60px;
  height: 44px;
  background-color: #f7f8fd;
  color: ${({ theme }) => theme.colors.trout};
  border-bottom: 1px solid #eaecf0;

  ${({ currentStep }) =>
    currentStep === 1 &&
    css`
      grid-template-columns: 40px 1fr 60px;
    `}

  ${({ isPrivate }) =>
    isPrivate &&
    css`
      grid-template-columns: 40px minmax(300px, 1fr) 40px;
    `}
  ${({ isUnit }) =>
    isUnit &&
    css`
      grid-template-columns: 40px 1fr 1fr 1fr 40px;
    `}


  ${({ currentStep, isAI }) =>
    currentStep === 2 &&
    !isAI &&
    css`
      grid-template-columns: 40px 1fr 1fr 1fr 60px 60px 60px 60px 60px;
    `}

  ${({ currentStep, isAI }) =>
    currentStep === 2 &&
    isAI &&
    css`
      grid-template-columns: 40px 1fr 1fr 1fr 80px 60px 60px 60px 60px 60px;
    `}

  @media (max-width: 1366px) {
    /* Tighten icon columns at smaller sizes */
    grid-template-columns: 40px 1fr 1fr 1fr 50px 50px 50px 50px 50px;

    ${({ currentStep, isAI }) =>
      currentStep === 2 &&
      isAI &&
      css`
        grid-template-columns: 40px 1fr 1fr 1fr 80px 50px 50px 50px 50px 50px;
      `}
  }

  @media (max-width: 1280px) {
    grid-template-columns: 40px 1fr 1fr 1fr 45px 45px 45px 45px 45px;

    ${({ currentStep, isAI }) =>
      currentStep === 2 &&
      isAI &&
      css`
        grid-template-columns: 40px 1fr 1fr 1fr 70px 45px 45px 45px 45px 45px;
      `}
  }
`

const TableHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 11px;
  text-transform: uppercase;
  padding: 0 5px;
  color: ${({ theme }) => theme.colors.trout};
`

const ErrorsWarning = styled.div`
  max-width: 500px;
  margin: 20px auto;
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.error || '#e53935'};
  color: #fff;
  text-align: center;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
`

const TraditionalIcon = styled(CloudUploadIcon)`
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 10px;
  font-size: 28px;
`

const ButtonWrapper = styled.div`
  padding-top: 20px;
  margin-top: ${({ changedMargin }) => (changedMargin ? '24' : '44px;')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${({ theme }) => theme.colors.grey[600]};
`
const StepsWrapper = styled.div`
  display: flex;
  justify-content: end;
  flex-direction: row;
  gap: 25px;
  align-items: center;
`
const SelectedFilesWrapper = styled.div`
  padding: 18px 24px;
  border: 1px solid ${({ theme }) => theme.colors.grey[600]};
  border-radius: 4px;
  margin-top: 20px;
`

const SelectedFilesHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const SelectedActions = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const DeleteIcon = styled(DeleteOutlineOutlinedIcon)`
  color: ${({ theme }) => theme.colors.grey[800]};
  cursor: pointer;
`

const EditIcon = styled(EditOutlinedIcon)`
  color: ${({ theme }) => theme.colors.grey[800]};
  cursor: pointer;
`

const IconWrapper = styled.div`
  padding: 5px;
  border-radius: 4px;
  transition: all .3s;
  cursor: pointer;
  background-color: ${({ active, theme }) => (active ? theme.colors.lightGrey : 'transparent')};

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGrey}
`

const RadioCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const AlreadyUploadedWrapper = styled.div`
  margin: 30px 0;
`

const AlreadyUploadedHeader = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`

const AlreadyUploadedFiles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.9;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`

const AIIcon = styled(AssistantOutlinedIcon)`
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 10px;
  animation: ${pulseAnimation} 2s infinite ease-in-out;
`
const AssistantStepsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 19px;
  padding: 50px 50px;
`
const Loading = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 300px;

  img {
    width: 100px;
    animation: ${pulseAnimation} 2s infinite;
    margin-bottom: 20px;
  }
`
const UploadSummary = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const SummaryItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  padding: 8px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[600]};
`

const SummaryLabel = styled.div`
  font-weight: 500;
`

const SummaryValue = styled.div`
  font-weight: 600;
  color: ${({ theme, success, error }) =>
    success
      ? theme.colors.success || 'green'
      : error
        ? theme.colors.error || 'red'
        : theme.colors.haiti};
`

const SummaryMessage = styled.div`
  margin: 20px 0;
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.grey[400]};
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.5;
`

const SummaryActions = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
`
const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }
`

const DialogHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 24px;
  background-color: ${({ theme }) => theme.colors.lighterGrey || '#f8f9fa'};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[500]};
  gap: 16px;
`

const DialogHeaderText = styled.h2`
  font-size: 22px;
  font-weight: 600;
  margin: 0;
  color: ${({ theme }) => theme.colors.haiti || '#333'};
`

const DialogBody = styled.div`
  padding: 24px;
`

const SuccessIcon = styled(CheckCircleIcon)`
  color: ${({ theme }) => theme.colors.success || '#2e7d32'};
  font-size: 28px !important;
`

const WarningStatusIcon = styled(WarningIcon)`
  color: ${({ theme }) => theme.colors.warning || '#f57c00'};
  font-size: 28px !important;
`

const CloseTabMessage = styled.div`
  margin-top: 16px;
  padding: 12px 16px;
  border-left: 4px solid ${({ theme }) => theme.colors.primary || '#007bff'};
  background-color: ${({ theme }) => theme.colors.lightGrey || '#f1f3f5'};
  font-size: 14px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.haiti || '#333'};
  font-weight: 500;
`

const StyledButton = styled.button`
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.colors.primary || '#007bff'};
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary || '#0069d9'};
  }
`
const FileCountWarning = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  padding: 10px 16px;
  background-color: #fff3e0;
  border: 1px solid #ffe0b2;
  border-radius: 4px;
  font-size: 14px;
  color: #e65100;
`
