import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import InputDescription from 'features/createBp/components/atoms/InputDescription'
import Checkbox from 'components/atoms/Checkbox'
import { Link } from 'react-router-dom'
import { PRIVATE_PATHS } from 'utils/paths'
import { pathOr, propOr } from 'ramda'
import PaymentButton from 'features/cart/PaymentButton'
import CancelIcon from '@mui/icons-material/Cancel'
import { isNotNilOrEmpty } from 'utils/ramda'
import { getCart } from 'utils/localStorage'
import { cartEmitter } from 'utils/emitters'

const CartSummary = ({ prices }) => {
  const [accepted, setAccepted] = useState(false)
  const [hasItems, setHasItems] = useState(isNotNilOrEmpty(getCart()))
  const toggleCheckbox = () => setAccepted(prev => !prev)

  const checkIfHasItems = () => {
    const cartItems = getCart()
    return setHasItems(isNotNilOrEmpty(cartItems))
  }

  useEffect(() => {
    cartEmitter.on('cart-change', checkIfHasItems)

    return () => {
      cartEmitter.off('cart-change', checkIfHasItems)
    }
  }, [])

  const hasDiscount = propOr(0, 'percentageDiscount', prices) > 0

  const countVat = () => {
    const netPrice = hasDiscount
      ? pathOr(0, ['discountedNetPrice', 'float'], prices)
      : pathOr(0, ['netPrice', 'float'], prices)
    const grossPrice = pathOr(0, ['grossPrice', 'float'], prices)
    return (grossPrice - netPrice).toFixed(2)
  }

  return (
    <Wrapper>
      {
        window.location.pathname.includes('failed') && (
          <PaymentErrorWrapper>
            <ErrorIcon />
            Payment failed! Try again.
          </PaymentErrorWrapper>
        )
      }
      <ItemRow>
        <Label>Cart total</Label>
        <CartTotalPrice>
          {
            hasDiscount
              ? pathOr('--', ['discountedNetPrice', 'formatted'], prices)
              : pathOr('--', ['netPrice', 'formatted'], prices)
          }
        </CartTotalPrice>
      </ItemRow>
      <ItemRow>
        <Label>vat</Label>
        <VatValue>{countVat()}</VatValue>
      </ItemRow>
      {
        hasDiscount && (
          <DiscountWrapper>
          <ItemRow>
            <Label>discount</Label>
              <DiscountValue>
                {`-${propOr(0, 'percentageDiscount', prices)}%`}
              </DiscountValue>
          </ItemRow>
          </DiscountWrapper>
        )
      }
      <Separator />
      <ItemRow>
        <Label>
          total amount
        </Label>
        <TotalPrice>
          {pathOr('--', ['grossPrice', 'formatted'], prices)}
        </TotalPrice>
      </ItemRow>
      <InputDescription>
        Taxes calculated at checkout
      </InputDescription>
      <Separator small />
      <Checkbox
        value={accepted}
        disabled={!hasItems}
        label={
          <CheckboxLabel>
            I agree to <Link to={PRIVATE_PATHS.terms} target="_blank">Terms & Conditions</Link>
          </CheckboxLabel>
        }
        onChange={toggleCheckbox}
      />
      <PaymentButton accepted={accepted} hasItems={hasItems} />
    </Wrapper>
  )
}

export default CartSummary

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 50px 30px;
`

const Label = styled.div`
  font-size: 11px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.darkGrey};
  font-weight: bold;
  opacity: .5;
`

const ItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CartTotalPrice = styled.div`
  font-size: 19px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.darkGrey};
`

const TotalPrice = styled.div`
  font-size: 19px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
`

const VatValue = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.darkGrey};
  opacity: .6;
  font-weight: bold;
`

const DiscountWrapper = styled.div`
  margin-top: 15px;
`

const DiscountValue = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.haiti};
`

const Separator = styled.div`
  width: 100%;
  height: ${({ small }) => small ? '2px' : '4px'};
  margin: 25px 0;
  background-color: ${({ theme }) => theme.colors.grey[500]};
`

const PaymentErrorWrapper = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.error};
  align-items: center;
  justify-content: center;
  margin-bottom: 45px;
`

const CheckboxLabel = styled.div`
  font-size: 14px;
  
  a {
    color: ${({ theme }) => theme.colors.secondary};
  }
`

const ErrorIcon = styled(CancelIcon)`
  font-size: 24px !important;
  margin-right: 10px;
`
