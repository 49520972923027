import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import useDebounce from 'utils/hooks/useDebounce'
import { isEmpty } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import {
  searchBpsRoutine,
  searchFilesRoutine
} from 'features/bpList/ducks/actions'
import {
  getSearchBpResults,
  getSearchFileResults
} from 'features/bpList/ducks/selectors'
import SearchBpListItem from 'features/SearchInput/components/SearchBpListItem'
import {
  getLastSearchedBps,
  getLastSearchedFiles
} from 'features/dashboard/ducks/selectors'
import { markBpAsSearchedRoutine } from 'features/bpDetails/ducks/actions'
import {
  fetchLastSearchedBpsRoutine,
  fetchLastSearchedFilesRoutine
} from 'features/dashboard/ducks/actions'
import { isNotNilOrEmpty } from 'utils/ramda'
import { useNavigate } from 'react-router-dom'
import SearchFileListItem from 'features/SearchInput/components/SearchFileListItem'
import CloseIcon from '@mui/icons-material/Close'

export const SearchInput = ({
  disabled = false,
  icon = SearchIcon,
  placeholder = 'Search my Building Passports...',
  ...props
}) => {
  const [value, setValue] = useState('')
  const [boxOpen, setBoxOpen] = useState(false)
  const [searchType, setSearchType] = useState('buildingpassports')
  const inputBoxRef = useRef(null)
  const dispatch = useDispatch()
  const debouncedValue = useDebounce(value, 500)
  const lastSearchedBps = useSelector(getLastSearchedBps)
  const lastSearchedFiles = useSelector(getLastSearchedFiles)
  const userBps = useSelector(getSearchBpResults)
  const userFiles = useSelector(getSearchFileResults)
  const [results, setResults] = useState([])
  const hasResults = isNotNilOrEmpty(results)
  const searchIconOverrides = { fontSize: 25 }
  const handleClearValue = () => setValue('')
  const handleInputChange = e => setValue(e.target.value)
  const isSearching = debouncedValue.length >= 3
  const navigate = useNavigate()

  const handleBoxOpen = () => setBoxOpen(true)
  const handleBoxClose = () => setTimeout(() => setBoxOpen(false), 200)

  const handleSearchTypeChange = e => {
    setSearchType(e.target.value)
    setValue('') // Clear input on search type change
    setResults([])
  }

  const handleClickBp = bp => () => {
    setValue('')
    handleBoxClose()
    dispatch(markBpAsSearchedRoutine({ bpId: bp.id }))
    window.location
    setTimeout(() => {
      navigate(`/bp-list/${bp.id}`, { replace: true })
      window.location.reload()
    }, 0)
  }

  useEffect(() => {
    dispatch(fetchLastSearchedBpsRoutine())
    dispatch(fetchLastSearchedFilesRoutine())
  }, [])

  useEffect(() => {
    if (searchType === 'buildingpassports') {
      isSearching ? setResults(userBps) : setResults(lastSearchedBps)
    } else if (searchType === 'files') {
      isSearching ? setResults(userFiles) : setResults(lastSearchedFiles)
    }
  }, [
    lastSearchedBps,
    userBps,
    lastSearchedFiles,
    userFiles,
    isSearching,
    searchType
  ])

  useEffect(() => {
    if (isSearching) {
      if (searchType === 'buildingpassports') {
        dispatch(searchBpsRoutine(debouncedValue))
      } else if (searchType === 'files') {
        dispatch(searchFilesRoutine(debouncedValue))
      }
    }
  }, [debouncedValue, dispatch, searchType])

  const dynamicPlaceholder =
    searchType === 'buildingpassports'
      ? 'Search my Building Passports...'
      : 'Search my Files...'

  return (
    <SearchInputContainer disabled={disabled}>
      <InputWrapper onClick={handleBoxOpen}>
        <SearchInputIcon>
          <StyledInputIcon style={searchIconOverrides} />
        </SearchInputIcon>
        <SearchTypeDropdown
          value={searchType}
          onChange={handleSearchTypeChange}
          disabled={disabled}
          aria-label='Select search type'
        >
          <option value='buildingpassports'>Building Passports</option>
          <option value='files'>Files</option>
        </SearchTypeDropdown>
        <StyledInput
          {...props}
          placeholder={dynamicPlaceholder}
          disabled={disabled}
          value={value}
          onChange={handleInputChange}
        />
        {!isEmpty(value) && <StyledClearIcon onClick={handleClearValue} />}
      </InputWrapper>

      {boxOpen && (
        <SearchBox ref={inputBoxRef}>
          <BoxHeader>
            <BoxTitle>
              {isSearching
                ? 'Results (' + results?.length + ')'
                : 'Last searched'}
            </BoxTitle>
            <CloseButton onClick={handleBoxClose}>
              <StyledCloseIcon />
            </CloseButton>
          </BoxHeader>
          {hasResults ? (
            results.map(item =>
              searchType === 'buildingpassports' ? (
                <SearchBpListItem
                  disabled={item.status === 'dormant'}
                  onClick={handleClickBp(item)}
                  bp={item}
                  key={`bp-${item.id}`}
                />
              ) : (
                <SearchFileListItem file={item} key={`file-${item.id}`} />
              )
            )
          ) : isSearching ? (
            <NoResultsInfo>No results matching search criteria</NoResultsInfo>
          ) : (
            <NoResultsInfo>No previous search history to display</NoResultsInfo>
          )}
        </SearchBox>
      )}
    </SearchInputContainer>
  )
}

export default SearchInput

const StyledInput = styled.input`
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  line-height: 16px;
  font-size: 16px;
  flex-grow: 2;
  padding-right: 20px;
  height: 100%;
`

const SearchTypeDropdown = styled.select`
  margin-top: 2px;
  padding: 4px 12px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  font-size: 14px;
  cursor: pointer;
  margin-right: 10px;
  background-color: ${({ theme }) => theme.colors.white};
`

const SearchInputContainer = styled.div`
  border-radius: 4px;
  height: 100%;
  box-sizing: border-box;
  opacity: ${props => (props.disabled ? '.5' : '1')};
  width: 100%;
  display: flex;
  position: relative;
`

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 30px;
`

const SearchInputIcon = styled.div`
  position: relative;
  top: 2px;
  margin-right: 15px;
  color: rgba(152, 152, 152, 0.61);
  align-items: center;
`
const StyledInputIcon = styled(SearchIcon)`
  font-size: 25px !important;
`

const StyledClearIcon = styled(ClearIcon)`
  cursor: pointer;
  position: absolute;
  right: 65px;
  padding: 10px;
  font-size: 16px;
  color: rgba(152, 152, 152, 0.61);
`

const SearchBox = styled.div`
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  padding: 24px 32px;
  position: absolute;
  top: ${({ theme }) => theme.dimensions.topBarHeight};
  z-index: 3;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);
  background-color: ${({ theme }) => theme.colors.navigation};
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  border-top: 2px solid ${({ theme }) => theme.colors.secondary};
`

const BoxTitle = styled.div`
  padding-bottom: 10px;
  width: 200px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[500]};
  color: ${({ theme }) => theme.colors.haiti};
  opacity: 60%;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 7px;
`

const NoResultsInfo = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.haiti};
`
const BoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

const CloseButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
`

const StyledCloseIcon = styled(CloseIcon)`
  font-size: 16px !important;
  color: ${({ theme }) => theme.colors.haiti};
  cursor: pointer !important;

  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`
