import emptySessionState from './state'
import {
  checkSessionStatusRoutine,
  refreshSessionRoutine,
  sessionExpiringRoutine,
  sessionExpiredRoutine,
  dismissSessionWarningRoutine,
  initSessionListenerRoutine
} from './actions'
import {logoutUserRoutine} from 'features/auth/ducks/actions'

export default (state = emptySessionState, action) => {
  switch (action.type) {
    case checkSessionStatusRoutine.REQUEST:
    case refreshSessionRoutine.REQUEST:
    case initSessionListenerRoutine.REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case checkSessionStatusRoutine.SUCCESS:
      return {
        ...state,
        expiresAt: action.payload.expiresAt,
        remainingSeconds: action.payload.remainingSeconds,
        warningThresholdSeconds: action.payload.warningThresholdSeconds,
        isLoading: false
      }
    case refreshSessionRoutine.SUCCESS:
      return {
        ...state,
        expiresAt: action.payload.expiresAt,
        remainingSeconds: action.payload.remainingSeconds,
        warningThresholdSeconds: action.payload.warningThresholdSeconds,
        isExpiring: false,
        isExpired: false,
        warningDismissed: false,
        isLoading: false
      }
    case initSessionListenerRoutine.SUCCESS:
      return {
        ...state,
        expiresAt: action.payload.expiresAt,
        remainingSeconds: action.payload.remainingSeconds,
        warningThresholdSeconds: action.payload.warningThresholdSeconds,
        isLoading: false
      }
    case checkSessionStatusRoutine.FAILURE:
    case refreshSessionRoutine.FAILURE:
    case initSessionListenerRoutine.FAILURE:
      return {
        ...state,
        isLoading: false
      }
    case sessionExpiringRoutine.TRIGGER:
      return {
        ...state,
        isExpiring: true,
        remainingSeconds: action.payload.remainingSeconds
      }
    case sessionExpiredRoutine.TRIGGER:
      return {
        ...state,
        isExpiring: false,
        isExpired: true,
        remainingSeconds: 0
      }
    case dismissSessionWarningRoutine.TRIGGER:
      return {
        ...state,
        warningDismissed: true
      }
    case logoutUserRoutine.SUCCESS:
      return emptySessionState
    default:
      return state
  }
}
