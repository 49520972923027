import React, {useEffect} from 'react'
import styled from 'styled-components'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {
  refreshSessionRoutine,
  dismissSessionWarningRoutine
} from 'features/session/ducks/actions'
import {
  isSessionExpiring,
  isSessionExpired,
  getFormattedRemainingTime,
  isWarningDismissed
} from 'features/session/ducks/selectors'
import FullWidthButton from 'features/auth/components/FullWidthButton'

const SessionExpiryModal = () => {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const expiring = useSelector(isSessionExpiring)
  const expired = useSelector(isSessionExpired)
  const warningDismissed = useSelector(isWarningDismissed)
  const remainingTime = useSelector(getFormattedRemainingTime)

  const handleExtendSession = () => {
    dispatch(refreshSessionRoutine())
  }

  const handleDismiss = () => {
    dispatch(dismissSessionWarningRoutine())
  }

  // Don't show if not expiring or already dismissed
  if ((!expiring && !expired) || (expiring && warningDismissed)) {
    return null
  }

  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalHeader>
          {expired
            ? t('session.expired.title', 'Session Expired')
            : t('session.expiring.title', 'Session Expiring Soon')}
        </ModalHeader>

        <ModalBody>
          {expired ? (
            <p>
              {t('session.expired.message', 'Your session has expired. Please log in again to continue.')}
            </p>
          ) : (
            <p>
              {t('session.expiring.message',
                'Your session will expire in {{time}} {{unit}}. Would you like to extend your session?',
                {
                  time: remainingTime,
                  unit: remainingTime === '1' ? 'minute' : 'minutes'
                }
              )}
            </p>
          )}
        </ModalBody>

        <ModalFooter>
          {expired ? (
            <FullWidthButton
              type="button"
              onClick={() => window.location.href = '/login'}
            >
              {t('session.expired.button', 'Log In Again')}
            </FullWidthButton>
          ) : (
            <>
              <DismissButton type="button" onClick={handleDismiss}>
                {t('session.expiring.dismiss', 'Dismiss')}
              </DismissButton>
              <ExtendButton type="button" onClick={handleExtendSession}>
                {t('session.expiring.extend', 'Extend Session')}
              </ExtendButton>
            </>
          )}
        </ModalFooter>
      </ModalContainer>
    </ModalOverlay>
  )
}

export default SessionExpiryModal

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

const ModalContainer = styled.div`
  background-color: ${({theme}) => theme.colors.white};
  border-radius: 4px;
  width: 100%;
  max-width: 500px;
  padding: 24px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
`

const ModalHeader = styled.h2`
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 16px;
  color: ${({theme}) => theme.colors.haiti};
`

const ModalBody = styled.div`
  margin-bottom: 24px;

  p {
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
    color: ${({theme}) => theme.colors.haiti};
  }
`

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`

const DismissButton = styled.button`
  padding: 12px 24px;
  background-color: transparent;
  border: 1px solid ${({theme}) => theme.colors.mediumGrey};
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${({theme}) => theme.colors.lightGrey};
  }
`

const ExtendButton = styled.button`
  padding: 12px 24px;
  background-color: ${({theme}) => theme.colors.primary};
  color: ${({theme}) => theme.colors.white};
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${({theme}) => theme.colors.primary || '#0056b3'};
  }
`
