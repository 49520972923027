import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import Input from 'components/atoms/Input'
import { loginUserRoutine } from 'features/auth/ducks/actions'
import {
  validateLoginField,
  validateLoginValues
} from 'features/auth/ducks/schema'
import { Navigate } from 'react-router-dom'
import { PATHS, PRIVATE_PATHS, redirect } from 'utils/paths'
import { useTranslation } from 'react-i18next'
import { getCurrentUser, isUserLoggedIn } from 'features/auth/ducks/selectors'
import FullWidthButton from 'features/auth/components/FullWidthButton'
import { isNilOrEmpty } from 'utils/ramda'

import LogoShort from 'assets/images/bp-logo-orange.png'

const emptyValues = {
  email: '',
  password: ''
}

const Login = () => {
  const [values, setValues] = useState(emptyValues)
  const [valid, setIsValid] = useState(false)
  const dispatch = useDispatch()
  const user = useSelector(getCurrentUser)
  const isloggedin = useSelector(isUserLoggedIn)
  const [step, setStep] = useState('email')
  const [isAnimating, setIsAnimating] = useState(false)

  const { t } = useTranslation()

  const handleNextStep = () => {
    validateLoginField(values)('email', v => {
      if (isNilOrEmpty(v.errors)) {
        const apiUrl = process.env.REACT_APP_API_URL
        if (values.email.includes('sainsburys')) {
          const bareApiUrl = apiUrl.replace(/\/api$/, '')
          const redirectUrl = `${bareApiUrl}/auth/redirect/sainsbury`
          window.location.href = redirectUrl
        } else {
          setIsAnimating(true)
          setTimeout(() => {
            setStep('password')
            setIsAnimating(false)
          }, 300)
        }
      }
    })
  }

  const handlePrevStep = () => {
    setIsAnimating(true)
    setTimeout(() => {
      setStep('email')
      setIsAnimating(false)
    }, 300)
  }

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = () => {
    dispatch(
      loginUserRoutine({
        password: values.password,
        email: values.email.toLowerCase()
      })
    )
  }

  useEffect(() => {
    const formElement = document.getElementById('login-form')

    const handleEnterPress = event => {
      if (event.code === 'Enter') {
        event.preventDefault()

        if (step === 'email') {
          handleNextStep()
        } else {
          handleSubmit()
        }
      }
    }

    formElement.addEventListener('keydown', handleEnterPress)

    return () => {
      formElement.removeEventListener('keydown', handleEnterPress)
    }
  }, [step, values])

  useEffect(() => {
    validateLoginValues(values, setIsValid)
  }, [values])

  return isloggedin && user.type !== 'admin' ? (
    <Navigate
      to={
        ['emergency_service', 'responsible_person'].includes(user.type)
          ? PRIVATE_PATHS.bpList
          : PRIVATE_PATHS.dashboard
      }
    />
  ) : (
    <PageWrapper>
      <BackgroundElements>
        <BuildingIcon className='building-1' />
        <BuildingIcon className='building-2' />
        <BuildingIcon className='building-3' />
        <DataStreamLine className='stream-1' />
        <DataStreamLine className='stream-2' />
      </BackgroundElements>

      <LoginCard>
        <LogoSection>
          <img src={LogoShort} alt='company logo' />
        </LogoSection>

        <HeaderText>
          {t('login.header')}
          <span>{t('login.company')}</span>
        </HeaderText>

        <LoginForm
          id='login-form'
          onSubmit={e => {
            e.preventDefault()
            handleSubmit()
          }}
        >
          <FormContent className={isAnimating ? 'animating' : ''}>
            {step === 'email' && (
              <EmailStep>
                <StyledInput
                  required
                  label={t('login.labels.email')}
                  name='email'
                  value={values.email}
                  onChange={handleOnChange}
                  validate={validateLoginField(values)}
                />
                <ActionButton
                  type='button'
                  disabled={isNilOrEmpty(values.email)}
                  onClick={handleNextStep}
                >
                  {t('login.labels.submitButton')}
                </ActionButton>
              </EmailStep>
            )}

            {step === 'password' && (
              <PasswordStep>
                <StyledInput
                  required
                  label={t('login.labels.password')}
                  type='password'
                  name='password'
                  value={values.password}
                  onChange={handleOnChange}
                  validate={validateLoginField(values)}
                  additionalLabel={
                    <ForgotPasswordLink
                      onClick={() => redirect(PATHS.requestPasswordReset)}
                    >
                      {t('login.forgotPassword')}
                    </ForgotPasswordLink>
                  }
                />
                <ButtonGroup>
                  <BackButton onClick={handlePrevStep}>
                    <ArrowIcon>←</ArrowIcon>
                    Back
                  </BackButton>
                  <ActionButton type='submit' disabled={!valid}>
                    {t('login.labels.submitButton')}
                  </ActionButton>
                </ButtonGroup>
              </PasswordStep>
            )}
          </FormContent>
        </LoginForm>

        <Divider>
          <span>{t('login.noAccount')}</span>
        </Divider>

        <SignUpButton onClick={() => redirect(PATHS.signup)}>
          {t('login.labels.createAccount')}
        </SignUpButton>
      </LoginCard>
    </PageWrapper>
  )
}

export default Login

// Animations
const float = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-15px); }
  100% { transform: translateY(0); }
`

const slideIn = keyframes`
  from { transform: translateX(-100%); opacity: 0; }
  to { transform: translateX(0); opacity: 0.5; }
`

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
`

const fadeOut = keyframes`
  from { opacity: 1; transform: translateY(0); }
  to { opacity: 0; transform: translateY(-10px); }
`

const dataPulse = keyframes`
  0% { transform: scaleX(0.1); opacity: 0.5; }
  50% { transform: scaleX(1); opacity: 0.8; }
  100% { transform: scaleX(0.1); opacity: 0.5; }
`

const logoRotate = keyframes`
  0% { transform: rotate(0deg); }
  25% { transform: rotate(90deg); }
  50% { transform: rotate(180deg); }
  75% { transform: rotate(270deg); }
  100% { transform: rotate(360deg); }
`

const scan = keyframes`
  0%, 100% { height: 0%; opacity: 0.6; }
  50% { height: 100%; opacity: 0.8; }
`

// Styled Components
const PageWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  position: relative;
  overflow: hidden;
`

const BackgroundElements = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
`

// Building Icon - SVG representation of a building
const BuildingIcon = styled.div`
  position: absolute;
  width: 100px;
  height: 180px;
  background-color: ${({ theme }) => theme.colors.primary}10;
  border: 1px solid ${({ theme }) => theme.colors.primary}20;
  opacity: 0.3;

  &:before {
    content: '';
    position: absolute;
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
    background: repeating-linear-gradient(
      to bottom,
      ${({ theme }) => theme.colors.primary}20 0px,
      ${({ theme }) => theme.colors.primary}20 10px,
      transparent 10px,
      transparent 20px
    );
  }

  &.building-1 {
    bottom: -20px;
    left: 10%;
    height: 220px;
    animation: ${float} 10s infinite ease-in-out;
    animation-delay: 0s;
  }

  &.building-2 {
    bottom: -20px;
    left: 25%;
    height: 180px;
    animation: ${float} 14s infinite ease-in-out;
    animation-delay: 2s;
  }

  &.building-3 {
    bottom: -20px;
    right: 15%;
    height: 250px;
    animation: ${float} 12s infinite ease-in-out;
    animation-delay: 1s;
  }
`

const DataStreamLine = styled.div`
  position: absolute;
  height: 3px;
  background: linear-gradient(
    to right,
    transparent,
    ${({ theme }) => theme.colors.primary}60,
    ${({ theme }) => theme.colors.secondary}60,
    transparent
  );
  opacity: 0.4;
  transform-origin: left;
  animation: ${dataPulse} 5s infinite ease-in-out;

  &.stream-1 {
    top: 40%;
    left: 0;
    width: 100%;
    animation-delay: 0s;
    animation-duration: 6s;
  }

  &.stream-2 {
    bottom: 30%;
    left: 0;
    width: 100%;
    animation-delay: 2s;
    animation-duration: 8s;
  }
`

const LoginCard = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 480px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 40px;
  border-radius: 12px;
  box-shadow:
    0 10px 40px rgba(0, 0, 0, 0.1),
    0 0 0 1px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 1;
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow:
      0 15px 50px rgba(0, 0, 0, 0.15),
      0 0 0 1px rgba(0, 0, 0, 0.05);
  }
`

const LogoSection = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  img {
    width: auto;
    height: 34px;
    cursor: pointer;
  }
`

// Animated building logo - replace this with your actual logo component
const AnimatedBuildingLogo = styled.div`
  width: 60px;
  height: 60px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    position: absolute;
    width: 40px;
    height: 50px;
    background-color: ${({ theme }) => theme.colors.primary};
    clip-path: polygon(0% 100%, 100% 100%, 100% 30%, 70% 0%, 30% 0%, 0% 30%);
  }

  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 25px;
    background-color: rgba(255, 255, 255, 0.9);
    clip-path: polygon(0% 100%, 100% 100%, 100% 30%, 50% 0%, 0% 30%);
    transform: translateY(-5px);
  }

  /* Scanning effect */
  &:hover:before {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0)
      );
      animation: ${scan} 1.5s ease-in-out;
    }
  }
`

const HeaderText = styled.h1`
  font-size: 32px;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 40px;
  text-align: center;
  animation: ${fadeIn} 0.6s ease;

  span {
    display: block;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.haiti};
    margin-top: 8px;
  }
`

const LoginForm = styled.form`
  box-sizing: border-box;
  width: 100%;
  position: relative;
`

const FormContent = styled.div`
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
  animation: ${fadeIn} 0.3s ease;

  &.animating {
    animation: ${fadeOut} 0.3s ease forwards;
  }
`

const EmailStep = styled.div`
  margin-bottom: 24px;
`

const PasswordStep = styled.div`
  margin-bottom: 24px;
`

const StyledInput = styled(Input)`
  margin-bottom: 20px;

  input {
    border-radius: 8px;
    padding: 14px;
    font-size: 15px;
    background-color: ${({ theme }) => theme.colors.lighterGrey};
    transition: all 0.2s;
    border: 1px solid transparent;

    &:focus {
      border-color: ${({ theme }) => theme.colors.primary};
      box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.primary}20;
      background-color: white;
    }
  }
`

const ActionButton = styled(FullWidthButton)`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.primary};
  transition: all 0.2s;
  height: 48px;
  font-weight: 600;
  letter-spacing: 0.5px;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transition: left 0.4s;
  }

  &:not(:disabled):hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    transform: translateY(-2px);
    box-shadow: 0 4px 12px ${({ theme }) => theme.colors.primary}30;

    &:before {
      left: 100%;
    }
  }

  &:disabled {
    opacity: 0.6;
  }
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`

const BackButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.haiti};
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  font-size: 14px;
  transition: all 0.2s;
  border-radius: 6px;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.lighterGrey};
  }
`

const ArrowIcon = styled.span`
  font-size: 18px;
`

const ForgotPasswordLink = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
  transition: color 0.2s;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
    text-decoration: underline;
  }
`

const Divider = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  margin: 32px 0;

  span {
    position: relative;
    z-index: 2;
    padding: 0 16px;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.haiti};
    font-size: 14px;
  }

  &::before {
    content: '';
    width: 100%;
    height: 1px;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.lighterGrey},
      ${({ theme }) => theme.colors.primary}40,
      ${({ theme }) => theme.colors.lighterGrey}
    );
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
  }
`

const SignUpButton = styled(FullWidthButton)`
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primary};
  height: 48px;
  font-weight: 600;
  transition: all 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lighterGrey};
    border-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.secondary};
    transform: translateY(-2px);
  }
`
