import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { getApiErrors } from 'utils/errors'
import { toast } from 'react-toastify'
import Modal from 'components/atoms/Modal'
import { CircularProgress } from '@mui/material'
import APIService from 'services/APIService'
import MenuItem from '@mui/material/MenuItem'
import styled from 'styled-components'
import Button from 'components/atoms/Button'
import PrimaryButton from 'components/atoms/PrimaryButton'
import { isNotNilOrEmpty } from 'utils/ramda'
import { flatten } from 'ramda'
import { useSelector } from 'react-redux'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import { redirect } from 'utils/paths'

const api = APIService()

const ManageFilesPermissions = ({ user, bp }) => {
  const { id, unitId: userToOpenId } = useParams()
  const userId = user.id
  const isOwner = user.relationType === 'owner'
  const [filesTree, setFilesTree] = useState([])
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState([])
  const [changeMade, setChangeMade] = useState(false)
  const currentUser = useSelector(getCurrentUser)
  const [warningModalOpen, setWarningModalOpen] = useState(false)
  const isCurrentUserBpCreator = bp.creatorId === currentUser.id
  const isUserSelectedByUrl = userToOpenId === user.id
  const location = useLocation()
  const { pathname } = location
  useEffect(() => {
    if (!open && isUserSelectedByUrl) {
      setOpen(true)
    }
  }, [open, isUserSelectedByUrl])

  useEffect(() => {
    if (isNotNilOrEmpty(filesTree)) {
      const result = filesTree.reduce((acc, category) => {
        const fileTypes = Object.values(category.fileTypes).reduce((files, currentType) => {
          const typeFiles = currentType.files.filter(el => el.isAccessGranted).map(f => f.id)
          const merged = [...files, ...typeFiles]

          return [...new Set(merged)]
        }, [])

        return [...new Set([...acc, ...fileTypes])]
      }, [])

      setSelected(result)
    }
  }, [filesTree])

  const checkCategorySelected = categoryId => {
    const category = filesTree.find(c => c.id === categoryId)

    const fileTypes = Object.values(category.fileTypes).reduce((files, currentType) => {
      const typeFiles = currentType.files.map(f => f.id)
      const merged = [...files, ...typeFiles]

      return [...new Set(merged)]
    }, [])

    return fileTypes.every(el => selected.includes(el))
  }

  const checkTypeSelected = (categoryId, typeId) => {
    const category = filesTree.find(c => c.id === categoryId)

    const fileType = Object.values(category.fileTypes).find(t => t.id === typeId)
    const ids = fileType.files.map(f => f.id)

    return ids.every(el => selected.includes(el))
  }

  const handleCategoryCheckboxClick = categoryId => () => {
    const isChecked = checkCategorySelected(categoryId)
    const category = filesTree.find(c => c.id === categoryId)
    const fileTypes = Object.values(category.fileTypes).reduce((files, currentType) => {
      const typeFiles = currentType.files.filter(file => file.isAccessEditable).map(f => f.id)

      return [...files, ...typeFiles]
    }, [])

    if (isChecked) {
      setSelected(prev => prev.filter(el => !fileTypes.includes(el)))
    } else {
      setSelected(prev => [...new Set([...prev, ...fileTypes])])
    }

    setChangeMade(true)
  }

  const handleFileCheckboxClick = (fileId) => () => {
    setChangeMade(true)
    setSelected(prev => {
      if (prev.includes(fileId)) {
        return prev.filter(fId => fId !== fileId)
      } else {
        return [...prev, fileId]
      }
    })
  }

  const handleTypeCheckboxClick = (categoryId, typeId) => () => {
    setChangeMade(true)
    const isChecked = checkTypeSelected(categoryId, typeId)
    const category = filesTree.find(c => c.id === categoryId)
    const selectedType = Object.values(category.fileTypes).find(el => el.id === typeId)
    const fileIds = selectedType.files.filter(file => file.isAccessEditable).map(el => el.id)

    if (isChecked) {
      setSelected(prev => prev.filter(el => !fileIds.includes(el)))
    } else {
      setSelected(prev => [...new Set([...prev, ...fileIds])])
    }
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    if (changeMade) {
      setWarningModalOpen(true)
    } else {
      if (isUserSelectedByUrl) {
        redirect(pathname.replace(`/${user.id}`, ''))
      }
      setOpen(false)
      setChangeMade(false)
    }
  }

  const handleSubmit = () => {
    const files = filesTree.reduce((acc, category) => {
      const fileTypes = Object.values(category.fileTypes)
      const files = flatten(fileTypes.map(type => type.files))

      return [...acc, ...files.map(file => ({ id: file.id, permission: selected.includes(file.id) }))]
    }, [])

    api.patch(`/building-passport/${id}/files-access`, { userId, files })
      .then(() => {
        if (isUserSelectedByUrl) {
          redirect(pathname.replace(`/${user.id}`, ''))
        }
        setOpen(false)
        setWarningModalOpen(false)
        setChangeMade(false)
        toast.success('Permissions saved')
      })
      .catch(err => {
        toast.error(getApiErrors(err))
      })
  }

  useEffect(() => {
    if (userId && open) {
      setLoading(true)
      api.get(`/building-passport/${id}/files-tree?user_id=${userId}`)
        .then(resp => {
          const fileCategories = Object.values(resp?.data?.data || {})
          setFilesTree(fileCategories)
          setLoading(false)
        })
        .catch(err => {
          toast.error(getApiErrors(err))
          setLoading(false)
        })
    } else {
      setFilesTree([])
      setSelected([])
    }
  }, [open, userId])

  const handleWarningModalClose = () => {
    if (isUserSelectedByUrl) {
      redirect(pathname.replace(`/${user.id}`, ''))
    }
    setWarningModalOpen(false)
    setOpen(false)
    setChangeMade(false)
  }

  return (
    <div>
      <MenuItem
        onClick={() => {
          if (userId === currentUser.id) {
            toast.info('You cannot change your own access to files.')
          } else if (isOwner && !isCurrentUserBpCreator) {
            toast.info('You do not have permission to change file access for Owner users.')
          } else {
            handleOpen()
          }
        }}
      >
        Manage permissions</MenuItem>
      <Modal
        onClose={handleClose}
        open={open}
        title='Manage file permissions'
      >
        {loading
          ? (
            <LoaderWrapper>
              <CircularProgress/>
            </LoaderWrapper>
          ) : (
            <ModalContentWrapper>
              <Wrapper>
                {
                  isNotNilOrEmpty(filesTree)
                    ? filesTree.map(category => {
                      return (
                        <CategoryWrapper key={category.id}>
                          <CategoryName>
                            <input
                              type='checkbox'
                              onClick={handleCategoryCheckboxClick(category.id)}
                              checked={checkCategorySelected(category.id)}
                            />
                            {category.name}
                          </CategoryName>
                          {Object.values(category.fileTypes).map(type => {
                            return (
                              <TypeWrapper key={type.id}>
                                <TypeName>
                                  <input
                                    type='checkbox'
                                    checked={checkTypeSelected(category.id, type.id)}
                                    onClick={handleTypeCheckboxClick(category.id, type.id)}
                                  />
                                  {type.name}
                                </TypeName>
                                <FileWrapper>
                                  {type.files.map(file => {
                                    return (
                                      <FileName key={file.id}>
                                        <input
                                          disabled={!file.isAccessEditable}
                                          onClick={handleFileCheckboxClick(file.id)}
                                          type='checkbox'
                                          checked={selected.includes(file.id)}
                                        />
                                        <a rel='noreferrer' href={file.latestVersion.url}
                                           target='_blank'>{file.displayName}</a>
                                      </FileName>
                                    )
                                  })}
                                </FileWrapper>
                              </TypeWrapper>
                            )
                          })}
                        </CategoryWrapper>
                      )
                    })
                    : (
                      <EmptyState>
                        You currently have access to no files on this Building Passport.
                      </EmptyState>
                    )
                }
              </Wrapper>
              <ButtonsWrapper>
                <Button onClick={handleClose}>Close without saving</Button>
                <PrimaryButton onClick={handleSubmit}>Save</PrimaryButton>
              </ButtonsWrapper>
            </ModalContentWrapper>
          )
        }
        <Modal
          open={warningModalOpen}
          title='Are you sure?'
          onClose={handleWarningModalClose}
        >
          Warning: your changes have not been saved. Would you like to
          save before closing this window?
          <ButtonsWrapper>
            <Button onClick={handleWarningModalClose}>Close without saving</Button>
            <PrimaryButton onClick={handleSubmit}>Save</PrimaryButton>
          </ButtonsWrapper>
        </Modal>
      </Modal>
    </div>
  )
}

export default ManageFilesPermissions

const Wrapper = styled.div`
  width: 600px;
  overflow-y: auto;
  height: 60vh;

  input {
    border: none !important;
    outline: none !important;
    cursor: pointer;
  }
`

const CategoryWrapper = styled.div`
  margin-bottom: 10px;
`

const CategoryName = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 5px 15px;
  background-color: ${({ theme }) => theme.colors.secondary};
  color: #fff;
`

const TypeWrapper = styled.div`
  padding-left: 8px;
`

const TypeName = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 5px 15px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
`

const FileWrapper = styled.div`
  padding-left: 16px;
`

const FileName = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 5px 15px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
`

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
`

const ModalContentWrapper = styled.div`
  max-height: 70vh;
  overflow: hidden;
`

const EmptyState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
`
