import React, { useCallback, useEffect, useState } from 'react'
import { pdfjs, Document, Page } from 'react-pdf'
import styled, { css } from 'styled-components'
import CircularProgress from '@mui/material/CircularProgress'
import { pathOr, propOr } from 'ramda'
import { formatDate } from 'utils/date'
import { isNilOrEmpty, isNotNilOrEmpty, renderPropIfExists } from 'utils/ramda'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import MenuItem from '@mui/material/MenuItem'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'components/atoms/Modal'
import Menu from '@mui/material/Menu'
import FloorBadges from 'features/bpDetails/Components/files/FloorBadges'
import Tooltip from '@mui/material/Tooltip'
import PdfFullScreenViewer from 'components/PdfFullScreenViewer'
import { canEditBp } from 'utils/user'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import { removeFileRoutine } from 'ducks/files/actions'
import imagePlaceholder from 'assets/picture.png'
import { markFileAsViewed } from 'services/fileService'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

export const PDFDocument = ({
  url,
  pageScale,
  onLoad,
  pageNumber,
  rotation = 0,
  height
}) => {
  return (
    <Wrapper>
      <Document
        file={url}
        loading={
          <Loading>
            <CircularProgress />
          </Loading>
        }
        renderMode='canvas'
        options={{
          cMapPacked: false
        }}
        onLoadSuccess={onLoad}
      >
        <Page
          scale={pageScale}
          pageNumber={pageNumber}
          rotate={rotation}
          height={height}
        />
      </Document>
    </Wrapper>
  )
}

export const PDFViewer = ({ file, openModal, simple }) => {
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const menuOpen = Boolean(anchorEl)
  const dispatch = useDispatch()
  const currentUser = useSelector(getCurrentUser)
  const bp = useSelector(getSelectedBp)

  const canEdit = canEditBp(currentUser, bp)

  const handlePreviewOpen = e => {
    e.preventDefault()
    e.stopPropagation()
    setOpen(true)
  }
  const handlePreviewClose = () => {
    setOpen(false)
  }

  const openMenu = e => {
    e.stopPropagation()
    e.preventDefault()
    setAnchorEl(e.currentTarget)
  }
  const handleClose = e => {
    e.preventDefault()
    e.stopPropagation()
    setAnchorEl(null)
  }

  const handleModalOpen = e => {
    e.preventDefault()
    e.stopPropagation()
    setModalOpen(true)
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  const handleEditModalOpen = e => {
    e.preventDefault()
    e.stopPropagation()
    openModal()
  }

  const handleRemoveFile = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      dispatch(removeFileRoutine({ id: file.id, bpId: bp.id }))
      handleModalClose()
    },
    [file]
  )

  const handleDownloadFile = url => e => {
    e.stopPropagation()
    e.preventDefault()
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.target = '_blank'
    anchor.download = file.displayName
    anchor.click()

    const fileId = file.id
    const fileVersionId = pathOr('', ['versions', 0, 'id'], file)

    markFileAsViewed({
      fileId,
      fileVersionId,
      type: 'download'
    })
  }

  const originalUrl = propOr('', 'originalUrl', file)
  const hasOriginalUrl = isNotNilOrEmpty(originalUrl)

  const handleEscape = e => {
    return e.key === 'Escape' && handlePreviewClose()
  }

  useEffect(() => {
    document.addEventListener('keydown', e => handleEscape(e))
    return () => document.removeEventListener('keydown', e => handleEscape(e))
  }, [])

  return (
    <ViewerWrapper disabled={isNilOrEmpty(file.url)}>
      <ThumbnailWrapper onClick={handlePreviewOpen}>
        <div className='overlay'>
          {canEdit && !simple && <MoreIcon onClick={openMenu} />}
          {renderPropIfExists(['producedBy'], file, value => (
            <FileDetail>
              <span>Produced by:</span> {value}
            </FileDetail>
          ))}
          {renderPropIfExists(['creationDate'], file, value => (
            <FileDetail>
              <span>Creation date:</span> {formatDate(value)}
            </FileDetail>
          ))}
          <FileDetail>
            <span>Uploaded by:</span>{' '}
            {pathOr('', ['uploader', 'firstName'], file) +
              ' ' +
              pathOr('', ['uploader', 'lastName'], file)}
          </FileDetail>
        </div>
        {file.thumbnailUrl ? (
          <ThumbnailImageWrapper fileUrl={file.thumbnailUrl} />
        ) : (
          <ThumbnailImageWrapper fileUrl={imagePlaceholder} />
        )}
        <Menu
          keepMounted
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          style={{
            marginTop: '10px'
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={menuOpen}
          onClose={handleClose}
        >
          <MenuItem onClick={handleDownloadFile(file.url)}>
            {hasOriginalUrl ? 'Download PDF' : 'Download'}
          </MenuItem>
          {hasOriginalUrl && (
            <MenuItem onClick={handleDownloadFile(originalUrl)}>
              Download original file
            </MenuItem>
          )}
          <MenuItem onClick={handleEditModalOpen}>Edit file details</MenuItem>
          <MenuItem onClick={handleModalOpen}>Remove file</MenuItem>
        </Menu>
        <Modal
          open={modalOpen}
          title='Remove file'
          onClose={handleModalClose}
          onCancel={handleModalClose}
          onSubmit={handleRemoveFile}
          withSubmit
          withCancel
        >
          Do you really want to remove this file?
        </Modal>
      </ThumbnailWrapper>
      <FileCaption>
        <Tooltip title={propOr('', 'displayName', file)}>
          <FileTitle>{propOr('', 'displayName', file)}</FileTitle>
        </Tooltip>
        {!simple && (
          <>
            <FloorsTitle>Floors</FloorsTitle>
            {isNotNilOrEmpty(file.floorNumbers) && (
              <FloorBadges floorNumbers={file.floorNumbers} />
            )}
          </>
        )}
      </FileCaption>
      <PdfFullScreenViewer
        open={open}
        onClose={handlePreviewClose}
        file={file}
      />
    </ViewerWrapper>
  )
}

const Wrapper = styled.div``

const ViewerWrapper = styled.div`
  display: inline-block;
  overflow: hidden;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  transition: all 0.3s;
  min-width: 160px;
  max-width: 100%;
  margin: 0 20px 20px 0;

  @media (min-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    min-width: 180px;
  }

  &:hover {
    cursor: pointer;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.3);

    .overlay {
      opacity: 1;
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.4;
      cursor: default;
    `}
`

const ThumbnailWrapper = styled.div`
  overflow: hidden;
  width: auto;
  display: flex;
  justify-content: center;
  max-height: 150px;
  min-width: 100%;
  max-width: 100%;
  position: relative;

  .overlay {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2;
    transition: all 0.3s;
    padding: 10px;
  }

  svg {
    width: inherit;
  }
`

const ThumbnailImageWrapper = styled.div`
  width: 100%;
  height: 150px;
  overflow: hidden;
  background: ${({ fileUrl }) =>
    `transparent url(${fileUrl}) no-repeat center/contain`};
`

const Loading = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  min-width: 180px;
  max-width: 180px;
`

const FileCaption = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 200px;
  padding: 10px;
  font-size: 12px;
  min-height: 52px;
`

const FloorsTitle = styled.div`
  font-size: 11px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grey[800]};
  letter-spacing: 0.3px;
  margin: 7px 0 4px;
`

const FileDetail = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 5px;
  span {
    font-weight: bold;
    display: block;
  }
`

const MoreIcon = styled(MoreVertIcon)`
  color: ${({ theme }) => theme.colors.grey[600]};
  cursor: pointer;
  font-size: 35px !important;
  position: absolute;
  right: 0;
  top: 5px;
  z-index: 999;
`

const FileTitle = styled.div`
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
