import { call, fork, put, takeLatest } from '@redux-saga/core/effects'
import * as bpService from 'services/BpService'
import { toast } from 'react-toastify'
import { getApiErrors } from 'utils/errors'
import { createRoutine } from 'redux-saga-routines'
import { getBpsWithAnalyticsRoutine } from 'features/analytics/ducks/actions'
import { fetchBpRoutine } from 'features/bpDetails/ducks/actions'

export const assignUserToBpRoutine = createRoutine('ASSIGN_USER_TO_BP')
export const revokeAccessToBpRoutine = createRoutine('REVOKE_ACCESS_TO_BP')
export const removeAccessToBpRoutine = createRoutine('REMOVE_ACCESS_TO_BP')
export const inviteUserToBpRoutine = createRoutine('SEND_INVITATION')
export const cancelInvitationRoutine = createRoutine('CANCEL_INVITATION')
export const getInvitationsListForBpRoutine = createRoutine(
  'GET_INVITATIONS_LIST'
)
export const restoreAccessToBpRoutine = createRoutine('RESTORE_ACCESS_TO_BP')
export const editUserRelationToBpRoutine = createRoutine('EDIT_USER_RELATION')

function* assignUserToBp({ payload }) {
  const { isAnalyticsView, currentPage } = payload
  yield put(assignUserToBpRoutine.request())
  try {
    yield call(bpService.assignUserToBp, payload)
    yield put(assignUserToBpRoutine.success())
    if (payload.isEdit) {
      toast.success('User has been assigned to your Building Passport')
      yield put(fetchBpRoutine({ id: payload.bpId }))
    }
    if (isAnalyticsView && currentPage) {
      yield put(getBpsWithAnalyticsRoutine(currentPage))
    }
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(assignUserToBpRoutine.failure(error))
  }
}

function* revokeAccessToBp({ payload }) {
  yield put(revokeAccessToBpRoutine.request())
  try {
    yield call(bpService.revokeAccessToBp, payload)
    yield put(revokeAccessToBpRoutine.success())
    toast.success('Assignment has been revoked')
    yield put(fetchBpRoutine({ id: payload.bpId }))
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(revokeAccessToBpRoutine.failure(error))
  }
}

function* editUserRelationToBp({ payload }) {
  yield put(editUserRelationToBpRoutine.request())
  try {
    yield call(bpService.editUserRelationToBp, payload)
    yield put(editUserRelationToBpRoutine.success())
    toast.success('Assignment has been updated')
    yield put(fetchBpRoutine({ id: payload.bpId }))
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(editUserRelationToBpRoutine.failure(error))
  }
}

function* restoreAccessToBp({ payload }) {
  yield put(restoreAccessToBpRoutine.request())
  try {
    yield call(bpService.restoreAccessToBp, payload)
    yield put(restoreAccessToBpRoutine.success())
    toast.success('Assignment has been reactivated')
    yield put(fetchBpRoutine({ id: payload.bpId }))
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(restoreAccessToBpRoutine.failure(error))
  }
}

function* removeAccessToBp({ payload }) {
  yield put(removeAccessToBpRoutine.request())
  try {
    yield call(bpService.removeAccessToBp, payload)
    yield put(removeAccessToBpRoutine.success())
    toast.success('Assignment has been removed')
    yield put(fetchBpRoutine({ id: payload.bpId }))
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(removeAccessToBpRoutine.failure(error))
  }
}

function* inviteUserToBp({ payload }) {
  yield put(inviteUserToBpRoutine.request())
  try {
    yield call(bpService.inviteUser, payload)
    yield put(inviteUserToBpRoutine.success())
    if (payload.isEdit) {
      toast.success(
        payload.isDraft
          ? 'Invitation to be sent once Building Passport is purchased'
          : 'Invitation has been sent'
      )
      yield put(fetchBpRoutine({ id: payload.bpId }))
    }
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(inviteUserToBpRoutine.failure(error))
  }
}

function* getInvitationsListForBp({ payload }) {
  yield put(getInvitationsListForBpRoutine.request())
  try {
    const { data } = yield call(bpService.getInvitationsListForBp, payload)
    yield put(getInvitationsListForBpRoutine.success(data.data))
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(getInvitationsListForBpRoutine.failure(error))
  }
}

function* cancelInvitation({ payload }) {
  yield put(cancelInvitationRoutine.request())
  try {
    yield call(bpService.cancelInvitation, payload)
    yield put(cancelInvitationRoutine.success())
    toast.success('Invitation has been cancelled')
    yield put(fetchBpRoutine({ id: payload.bpId }))
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(cancelInvitationRoutine.failure(error))
  }
}

export function* assignUserToBpWatcher() {
  yield takeLatest(assignUserToBpRoutine.TRIGGER, assignUserToBp)
}

export function* revokeAccessToBpWatcher() {
  yield takeLatest(revokeAccessToBpRoutine.TRIGGER, revokeAccessToBp)
}

export function* inviteUserToBpWatcher() {
  yield takeLatest(inviteUserToBpRoutine.TRIGGER, inviteUserToBp)
}

export function* cancelInvitationWatcher() {
  yield takeLatest(cancelInvitationRoutine.TRIGGER, cancelInvitation)
}

export function* removeAccessToBpWatcher() {
  yield takeLatest(removeAccessToBpRoutine.TRIGGER, removeAccessToBp)
}

export function* getInvitationsListForBpWatcher() {
  yield takeLatest(
    getInvitationsListForBpRoutine.TRIGGER,
    getInvitationsListForBp
  )
}

export function* restoreAccessToBpWatcher() {
  yield takeLatest(restoreAccessToBpRoutine.TRIGGER, restoreAccessToBp)
}

export function* editUserRelationToBpWatcher() {
  yield takeLatest(editUserRelationToBpRoutine.TRIGGER, editUserRelationToBp)
}

export const accessControlSagas = [
  fork(inviteUserToBpWatcher),
  fork(cancelInvitationWatcher),
  fork(assignUserToBpWatcher),
  fork(revokeAccessToBpWatcher),
  fork(getInvitationsListForBpWatcher),
  fork(restoreAccessToBpWatcher),
  fork(editUserRelationToBpWatcher),
  fork(removeAccessToBpWatcher)
]
