import React, { useEffect } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import styled from 'styled-components'
import NextButton from 'features/createBp/components/atoms/NextButton'
import { PRIVATE_PATHS, redirect } from 'utils/paths'
import { clearCart } from 'utils/localStorage'

const PaymentSuccess = () => {
  useEffect(() => {
    clearCart()
  }, [])

  return (
    <Wrapper>
      <CheckIcon />
      <Text>Payment Successful!</Text>
      <NextButton onClick={() => redirect(PRIVATE_PATHS.dashboard)}>Back to dashboard</NextButton>
    </Wrapper>
  )
}

export default PaymentSuccess

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  flex-direction: column;
`

const CheckIcon = styled(CheckCircleIcon)`
  font-size: 200px !important;
  color: ${({ theme }) => theme.colors.secondary};
`

const Text = styled.div`
  font-size: 34px;
  text-align: center;
  margin: 30px 0 40px;
`
