/* eslint-disable */
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useExpanded, useTable } from 'react-table'
import { isEmpty } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { getBuildingTypes } from 'features/bpList/ducks/selectors'
import { formatDate } from 'utils/date'
import SubscriptionsTable from 'features/billingList/components/SubscriptionsTable'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { isInvoiceLoading } from 'features/billingList/ducks/selectors'
import CircularProgress from '@mui/material/CircularProgress'

const BillingListTable = ({ transactions }) => {
  const buildingTypes = useSelector(getBuildingTypes)
  const data = useMemo(() => transactions, [transactions])
  const user = useSelector(getCurrentUser)
  const isLoading = useSelector(isInvoiceLoading)

  const getInvoice = id => e => {
    e.preventDefault()
    e.stopPropagation()
    window.open(`${process.env.REACT_APP_API_URL}/transactions/${id}/show-invoice`, '_blank')
  }

  const discountColumn = user.discount > 0
    ? [
      {
        Header: 'Discount',
        id: 'discount',
        Cell: () => (
          <CommonHeader>{user.discount}%</CommonHeader>
        )
      }
    ]
    : []

  const columns = React.useMemo(
    () => [
      {
        Header: 'Invoice number',
        id: 'invoiceNumber',
        accessor: 'invoiceNumber',
        Cell: d => {
          return (
            <InvoiceNumber>{d.value}</InvoiceNumber>
          )
        }
      },
      {
        Header: 'Quantity',
        accessor: 'subscriptions',
        id: 'expander',
        Cell: d => {
          return (
            <Quantity>
              {d.value.length}
              <ChevronIcon expanded={d.row.isExpanded} />
            </Quantity>
          )
        }
      },
      ...discountColumn,
      {
        Header: 'Payment',
        accessor: 'createdAt',
        Cell: d => {
          return (
            <CommonHeader>
              {formatDate(d.value)}
            </CommonHeader>
          )
        }
      },
      {
        Header: 'Card number',
        accessor: 'cardLastDigits',
        Cell: d => <CommonHeader>...{d.value}</CommonHeader>
      },
      {
        Header: 'Total amount',
        accessor: 'amount',
        Cell: d => {
          return <TotalAmount>{d.value.formatted}</TotalAmount>
        }
      },
      {
        Header: '',
        id: 'invoice',
        Cell: d => {
          return isLoading
            ? <CircularProgress size={24} />
            : <AttachFileIcon onClick={getInvoice(d.row.original.id)} />
        }
      }
    ],
    [buildingTypes, isLoading]
  )

  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
      <SubscriptionsTable subscriptions={row.original.subscriptions} />
    ),
    []
  )

  const tableInstance = useTable({
    columns,
    data
  }, useExpanded)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state: { expanded }
  } = tableInstance

  return (
    <TableWrapper>
      <table {...getTableProps()}>
        <thead>
        {
          headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {
                headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps()}
                  >
                    {column.render('Header')}
                    <span>
                  </span>
                  </th>
                ))
              }
            </tr>
          ))
        }
        </thead>
        <tbody {...getTableBodyProps()}>
        {
          rows.map(row => {
            prepareRow(row)
            return (
              <>
                <tr
                  {...row.getRowProps()}
                  {...row.getToggleRowExpandedProps()}
                >
                  {
                    row.cells.map(cell => {
                      return (
                        <td {...cell.getCellProps()}>
                          {
                            cell.render('Cell')
                          }
                        </td>
                      )
                    })}
                </tr>
                {row.isExpanded ? (
                  <SubTableWrapper>
                    <td colSpan={visibleColumns.length} style={{ padding: '10px 20px' }}>
                      {renderRowSubComponent({ row })}
                    </td>
                  </SubTableWrapper>
                ) : null}

              </>
            )
          })}
        </tbody>
      </table>
      {
        isEmpty(data) &&
        <EmptyState>
          No BPs found.
        </EmptyState>
      }
    </TableWrapper>
  )
}

export default BillingListTable

const TableWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-x: auto;
  
  table {
    width: 100%;
  }
  
  th {
    box-sizing: border-box;
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: 11px;
    opacity: .5;
    text-transform: uppercase;
    text-align: left;
    height: 40px;
    padding: 0 10px;
    position: relative;
  }

  td {
    box-sizing: border-box;
    padding: 20px 10px;
    height: 56px;
    font-size: 14px;
  }
  
  tbody > tr {
    border-bottom: 2px solid ${({ theme }) => theme.colors.backgroundColor};
    background-color: ${({ theme }) => theme.colors.lighterGrey};
  }
`

const InvoiceNumber = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.haiti};
  font-weight: bold;
`

const Quantity = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.haiti};
  font-weight: bold;
`

const EmptyState = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`

const IconWrapper = styled.div`
  min-width: 43px;
  min-height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fbede9;
  margin-right: 15px;
  border-radius: 4px;
`

const Bold = styled.div`
  font-weight: bold;
`

const DeleteIconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.error};
  
  span {
    transition: all .3s;
    opacity: 0;
    margin-right: 3px;
  }
  
  &:hover {
    span {
      opacity: 1;
    }
  }
`

const SubTableWrapper = styled.tr`
  background-color: ${({ theme }) => theme.colors.backgroundColor} !important;
  padding: 0;
  width: 100%;
`

const ChevronIcon = styled(KeyboardArrowDownIcon)`
  font-size: 11px;
  padding: ${({ expanded }) => expanded ? '0 15px 0 0' : '0 0 0 15px'};
  color: ${({ theme }) => theme.colors.haiti};
  transform: rotate(${({ expanded }) => expanded ? '0deg' : '180deg' });
`

const CommonHeader = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.darkGrey};
  opacity: .6;
`

const TotalAmount = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.haiti};
`

const Loading = styled(CircularProgress)`
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
`
