import {values} from 'ramda'

export const PATHS = {
  home: '/',
  login: '/login',
  loginCallback: '/login/callback',
  signup: '/sign-up',
  signupConfirm: '/sign-up/confirm',
  emailVerification: '/email-verification',
  requestPasswordReset: '/request-password-reset',
  passwordReset: '/password-reset',
  notFound: '/404'
}

export const PRIVATE_PATHS = {
  dashboard: '/dashboard',
  bpList: '/bp-list',
  createBp: '/create-bp',
  editBp: '/edit-bp/:id',
  bpDetails: '/bp-list/:id/:folder?/:unitId?',
  draftDetails: '/drafts/:id/:folder?/:unitId?',
  savedPassports: '/favourites',
  savedDetails: '/favourites/:id/:folder?/:unitId?',
  profileSettings: '/profile',
  notificationsCenter: '/notifications-center',
  cart: '/cart',
  terms: '/terms',
  drafts: '/drafts',
  paymentSuccess: '/cart/success',
  paymentError: '/cart/failed',
  billingList: '/billing-list',
  accessRequests: '/access-requests',
  analytics: '/analytics',
  addAnalytics: '/analytics/add',
  editAnalytics: '/analytics/edit',
  fileUpload: '/files/upload/:id',
}

let navigateFunction

/**
 * Sets the navigate function from React Router.
 * This should be called once inside a component.
 * @param {Function} navigate - The navigate function from React Router.
 */
export const setNavigate = navigate => {
  navigateFunction = navigate
}

/**
 * Redirects to the specified path with optional state.
 * Can be used anywhere in your application.
 * @param {string} path - The path to navigate to.
 * @param {object} [state] - Optional state to pass.
 */
export const redirect = (path, state) => {
  if (navigateFunction) {
    navigateFunction(path, {state})
  } else {
    console.error(
      'Navigate function has not been set. Ensure that setNavigate is called inside a component.'
    )
  }
}

export const isExcludedFromFetchRedirect = path => {
  return values(PATHS).includes(path)
}
