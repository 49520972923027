import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork } from '@redux-saga/core/effects'
import * as bpService from 'services/BpService'
import * as fileService from 'services/fileService'
import { pathOr } from 'ramda'
import { toast } from 'react-toastify'
import { getApiErrors } from 'utils/errors'
import { PRIVATE_PATHS, redirect } from 'utils/paths'
import { fetchBpUnitsRoutine } from 'ducks/units/actions'
import { createBpRoutine } from 'features/createBp/ducks/actions'
import { fetchFavouritesRoutine } from 'features/bpList/ducks/actions'
import { fetchLastSearchedBpsRoutine } from 'features/dashboard/ducks/actions'

export const fetchBpRoutine = createRoutine('FETCH_BP')

export const computeGuruMessagesRoutine = createRoutine('COMPUTE_GURU_MESSAGES')

export const searchLocalFilesRoutine = createRoutine('SEARCH_LOCAL_FILES')
export const addBuildingTypeToBpRoutine = createRoutine(
  'ADD_BUILDING_TYPE_TO_BP'
)
export const markBpAsSearchedRoutine = createRoutine('MARK_BP_AS_SEARCHED')
export const markFileAsSearchedRoutine = createRoutine('MARK_FILE_AS_SEARCHED')
export const markBpAsViewedRoutine = createRoutine('MARK_BP_AS_VIEWED')
export const addToFavouritesRoutine = createRoutine('ADD_TO_FAVOURITES')
export const removeFromFavouritesRoutine = createRoutine(
  'REMOVE_FROM_FAVOURITES'
)
export const clearSelectedBpRoutine = createRoutine('CLEAR_SELECTED_BP')
export const getUserRelationToBpRoutine = createRoutine(
  'GET_USER_RELATION_TO_BP'
)
export const getBpConnectedUsersRoutine = createRoutine(
  'GET_BP_CONNECTED_USERS'
)
export const getBpConnectedUsersByEmailRoutine = createRoutine(
  'GET_BP_CONNECTED_USERS_BY_EMAIL'
)
export const getUsersByEmailRoutine = createRoutine('GET_USERS')
export const clearConnectedUsersRoutine = createRoutine('CLEAR_CONNECTED_USERS')
export const clearUsersFoundByEmailRoutine = createRoutine('CLEAR_FOUND_USERS')

function* addBuildingTypeToBp({ payload }) {
  const { isWizard } = payload
  yield put(addBuildingTypeToBpRoutine.request())
  try {
    const { data } = yield call(bpService.addBuildingTypeToBp, payload)
    yield put(addBuildingTypeToBpRoutine.success(data.data))
    toast.success('Building type has been added')
    if (isWizard) {
      yield put(createBpRoutine.success(data.data))
    } else {
      yield put(fetchBpRoutine({ id: payload.bpId }))
    }
    yield put(fetchBpUnitsRoutine({ bpId: payload.bpId }))
  } catch (error) {
    yield put(addBuildingTypeToBpRoutine.failure(error))
  }
}

function* addToFavourites({ payload }) {
  yield put(addToFavouritesRoutine.request())
  try {
    yield call(bpService.addToFavourites, payload)
    yield put(addToFavouritesRoutine.success(payload.bpId))
    yield put(fetchFavouritesRoutine({ page: 1 }))
    toast.success('Building Passport has been added to favourites')
  } catch (error) {
    yield put(addToFavouritesRoutine.failure(error))
  }
}

function* removeFromFavourites({ payload }) {
  yield put(removeFromFavouritesRoutine.request())
  try {
    yield call(bpService.removeFromFavourites, payload)
    yield put(removeFromFavouritesRoutine.success(payload.bpId))
    yield put(fetchFavouritesRoutine({ page: 1 }))
    toast.success('Building Passport has been removed from favourites')
  } catch (error) {
    yield put(removeFromFavouritesRoutine.failure(error))
  }
}

function* markBpAsSearched({ payload }) {
  yield put(markBpAsSearchedRoutine.request())
  try {
    yield call(bpService.markAsSearched, payload)
    yield put(markBpAsSearchedRoutine.success())
    yield put(fetchLastSearchedBpsRoutine())
  } catch (error) {
    yield put(markBpAsSearchedRoutine.failure(error))
  }
}

function* markFileAsSearched({ payload }) {
  yield put(markFileAsSearchedRoutine.request())
  try {
    yield call(bpService.markFileAsSearched, payload)
    yield put(markFileAsSearchedRoutine.success())
  } catch (error) {
    yield put(markFileAsSearchedRoutine.failure(error))
  }
}

function* searchLocalFiles({ payload }) {
  yield put(searchLocalFilesRoutine.request())
  try {
    const { data } = yield call(fileService.searchLocalFiles, payload)
    yield put(searchLocalFilesRoutine.success(data.data))
  } catch (error) {
    yield put(searchLocalFilesRoutine.failure(error))
  }
}
function* markBpAsViewed({ payload }) {
  yield put(markBpAsViewedRoutine.request())
  try {
    yield call(bpService.markAsViewed, payload)
    yield put(markBpAsViewedRoutine.success())
  } catch (error) {
    yield put(markBpAsViewedRoutine.failure(error))
  }
}

function* fetchBp({ payload }) {
  yield put(fetchBpRoutine.request())
  const { id, callback } = payload
  try {
    const result = yield call(bpService.fetchBp, id)
    yield put(fetchBpRoutine.success(result.data.data))
    typeof callback === 'function' && callback()
  } catch (error) {
    if (pathOr(0, ['response', 'status'], error) === 403) {
      redirect(PRIVATE_PATHS.bpList)
    }
    yield put(fetchBpRoutine.failure(error))
  }
}

function* getBpConnectedUsers({ payload }) {
  yield put(getBpConnectedUsersRoutine.request())
  try {
    if (payload.length >= 3) {
      const result = yield call(bpService.getBpConnectedUsers, payload)
      yield put(getBpConnectedUsersRoutine.success(result.data.data))
    } else {
      yield put(getBpConnectedUsersRoutine.success([]))
    }
  } catch (error) {
    yield put(getBpConnectedUsersRoutine.failure(error))
  }
}

function* getBpConnectedUsersByEmail({ payload }) {
  yield put(getBpConnectedUsersByEmailRoutine.request())
  try {
    const { data } = yield call(
      bpService.getBpConnectedUsersByEmail,
      payload.replace('+', '%2B').toLowerCase()
    )
    yield put(getBpConnectedUsersByEmailRoutine.success(data.data))
  } catch (error) {
    yield put(getBpConnectedUsersByEmailRoutine.failure(error))
  }
}

function* clearConnectedUsers() {
  yield put(clearConnectedUsersRoutine.success())
}

function* getUsersByEmail({ payload }) {
  const { email, type } = payload
  const codedEmail = email.replace('+', '%2B').toLowerCase()
  yield put(getUsersByEmailRoutine.request())
  try {
    if (payload.email.length >= 3) {
      const result = yield call(bpService.getUsers, codedEmail)
      yield put(
        getUsersByEmailRoutine.success({ data: result.data.data, type })
      )
    } else {
      yield put(getUsersByEmailRoutine.success([]))
    }
  } catch (error) {
    yield put(getUsersByEmailRoutine.failure(error))
  }
}

function* clearUsersFoudByEmail() {
  yield put(clearUsersFoundByEmailRoutine.success())
}

function* getUserRelationToBp({ payload }) {
  yield put(getUserRelationToBpRoutine.request())
  try {
    const { data } = yield call(bpService.getUserRelationToBp, payload)
    yield put(getUserRelationToBpRoutine.success(data.data.roles))
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(getUserRelationToBpRoutine.failure(error))
  }
}

function* computeGuruMessages({ payload }) {
  yield put(computeGuruMessagesRoutine.request())
  try {
    const { data } = yield call(bpService.computeGuruMessages, payload)
    yield put(computeGuruMessagesRoutine.success(data.data))
  } catch (error) {
    yield put(computeGuruMessagesRoutine.failure(error))
  }
}

function* clearSelectedBp() {
  yield put(clearSelectedBpRoutine.success())
}

// WATCHERS

export function* computeGuruMessagesWatcher() {
  yield takeLatest(computeGuruMessagesRoutine.TRIGGER, computeGuruMessages)
}
export function* markBpAsSearchedWatcher() {
  yield takeLatest(markBpAsSearchedRoutine.TRIGGER, markBpAsSearched)
}

export function* markFileAsSearchedWatcher() {
  yield takeLatest(markFileAsSearchedRoutine.TRIGGER, markFileAsSearched)
}
export function* fetchBpWatcher() {
  yield takeLatest(fetchBpRoutine.TRIGGER, fetchBp)
}

export function* getBpConnectedUsersWatcher() {
  yield takeLatest(getBpConnectedUsersRoutine.TRIGGER, getBpConnectedUsers)
}

export function* getBpConnectedUsersByEmailWatcher() {
  yield takeLatest(
    getBpConnectedUsersByEmailRoutine.TRIGGER,
    getBpConnectedUsersByEmail
  )
}

export function* searchLocalFilesWatcher() {
  yield takeLatest(searchLocalFilesRoutine.TRIGGER, searchLocalFiles)
}

export function* addToFavouritesWatcher() {
  yield takeLatest(addToFavouritesRoutine.TRIGGER, addToFavourites)
}

export function* removeFromFavouritesWatcher() {
  yield takeLatest(removeFromFavouritesRoutine.TRIGGER, removeFromFavourites)
}

export function* markBpAsViewedWatcher() {
  yield takeLatest(markBpAsViewedRoutine.TRIGGER, markBpAsViewed)
}

export function* getUsersByEmailWatcher() {
  yield takeLatest(getUsersByEmailRoutine.TRIGGER, getUsersByEmail)
}

export function* clearConnectedUsersWatcher() {
  yield takeLatest(clearConnectedUsersRoutine.TRIGGER, clearConnectedUsers)
}

export function* clearUsersFoudByEmailWatcher() {
  yield takeLatest(clearUsersFoundByEmailRoutine.TRIGGER, clearUsersFoudByEmail)
}

export function* addBuildingTypeToBpWatcher() {
  yield takeLatest(addBuildingTypeToBpRoutine.TRIGGER, addBuildingTypeToBp)
}

export function* clearSelectedBpWatcher() {
  yield takeLatest(clearSelectedBpRoutine.TRIGGER, clearSelectedBp)
}

export function* getUserRelationToBpWatcher() {
  yield takeLatest(getUserRelationToBpRoutine.TRIGGER, getUserRelationToBp)
}

// SAGAS
export const bpDetailsSagas = [
  fork(markBpAsSearchedWatcher),
  fork(markFileAsSearchedWatcher),
  fork(searchLocalFilesWatcher),
  fork(fetchBpWatcher),
  fork(getBpConnectedUsersWatcher),
  fork(getBpConnectedUsersByEmailWatcher),
  fork(addToFavouritesWatcher),
  fork(removeFromFavouritesWatcher),
  fork(markBpAsViewedWatcher),
  fork(getUsersByEmailWatcher),
  fork(clearConnectedUsersWatcher),
  fork(clearUsersFoudByEmailWatcher),
  fork(clearUsersFoudByEmailWatcher),
  fork(addBuildingTypeToBpWatcher),
  fork(clearSelectedBpWatcher),
  fork(getUserRelationToBpWatcher),
  fork(computeGuruMessagesWatcher)
]
