/* eslint-disable */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { useTable } from 'react-table'
import { formatDate } from 'utils/date'
import { useTranslation } from 'react-i18next'
import { redirect } from 'utils/paths'
import { getOwner } from 'utils/hooks/values'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { isEmpty, pathOr } from 'ramda'
import { addToCart, getCart, removeFromCart } from 'utils/localStorage'
import NextButton from 'features/createBp/components/atoms/NextButton'
import { cartEmitter } from 'utils/emitters'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { isNotEmpty, isNotNil, isNotNilOrEmpty } from 'utils/ramda'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser, getCurrentUserRole } from 'features/auth/ducks/selectors'
import { ReactComponent as DeleteIcon } from 'assets/images/delete.svg'
import { removeBpRoutine } from 'features/createBp/ducks/actions'
import Modal from 'components/atoms/Modal'

const Table = ({ bps, sortingOptions, sortingChange, isDraftTable, isFavouritesTable, handleRefetch }) => {
  const { t } = useTranslation()
  const [refreshHelper, setRefreshHelper] = useState(0)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [deleteBpId, setDeleteBpId] = useState(null)
  const data = useMemo(() => bps, [bps, refreshHelper])
  const currentUser = useSelector(getCurrentUser)
  const currentUserRole = useSelector(getCurrentUserRole)
  const isResponsiblePerson = currentUserRole === 'responsible_person'
  const isFrs = currentUserRole === 'emergency_service'
  const dispatch = useDispatch()

  const isInCart = id => {
    const cartItems = getCart()
    return cartItems.includes(id)
  }

  const refreshItems = () => {
    setRefreshHelper(Math.random() * 100)
  }

  const handleDelete = useCallback(() => {
    dispatch(removeBpRoutine({ id: deleteBpId }))
    removeFromCart(deleteBpId)

    setTimeout(() => {
      handleRefetch()
    }, 500)

    handleDeleteModalClose()
  }, [deleteBpId])

  const handleDeleteModalOpen = id => e => {
    e.stopPropagation()
    e.preventDefault()
    setDeleteBpId(id)
    setDeleteModalOpen(true)
  }
  const handleDeleteModalClose = () => {
    setDeleteBpId(null)
    setDeleteModalOpen(false)
  }

  useEffect(() => {
    cartEmitter.on('item-added', refreshItems)
    cartEmitter.on('draft-removed', refreshItems)

    return () => {
      cartEmitter.off('item-added', refreshItems)
      cartEmitter.off('draft-removed', refreshItems)
    }
  }, [])

  const handleAddToBasket = id => e => {
    e.preventDefault()
    e.stopPropagation()
    addToCart(id)
  }

  const redirectLink = id => {
    if (isDraftTable) return `/drafts/${id}`
    if (isFavouritesTable) return `/favourites/${id}`
    return `/bp-list/${id}`
  }

  const subscriptionColumns = bps.some(bp => bp.status === 'dormant')
    ? [
      {
        Header: 'Status',
        id: 'status',
        accessor: 'status',
        Cell: d => <div>{d.value}</div>
      },
      {
        Header: 'Subscription',
        id: 'reactivateButton',
        Cell: d => {
          const status = pathOr('', ['row', 'original', 'status'], d)
          const id = pathOr('', ['row', 'original', 'id'], d)
          return status === 'dormant'
            ? (
              <div>
                {
                  isInCart(id)
                    ? <>
                      <InBasket dormant>
                        <CheckIcon />
                        in basket
                      </InBasket>
                    </>
                    : (
                      <div style={{ textAlign: 'left' }}>
                        <AddButton onClick={handleAddToBasket(id)}>
                          Reactivate
                        </AddButton>
                      </div>
                    )
                }
              </div>
            )
            : <div />
        }
      }
    ]
    : []

  const basketColumn = isResponsiblePerson
    ? []
    : [
        {
          Header: '',
          accessor: 'id',
          Cell: d => {
            const isOwner = pathOr('', ['row', 'original', 'creatorId'], d) === currentUser.id
            return (
              <div>
                {
                  isOwner && (
                    <>
                      {
                        isInCart(d.value)
                          ? <>
                            <InBasket>
                              <CheckIcon />
                              in basket
                            </InBasket>
                          </>
                          : (
                            <div>
                              <AddButton onClick={handleAddToBasket(d.value)}>
                                Add to basket
                              </AddButton>
                            </div>
                          )
                      }
                    </>
                  )
                }
              </div>
            )
          }
        },
      {
        Header: '',
        id: 'remove',
        Cell: d => {
          const isOwner = d.row.original.creatorId === currentUser.id
          return (
            <div>
              {
                isOwner && (
                  <DeleteIconWrapper onClick={handleDeleteModalOpen(d.row.original.id)}>
                    <span>DELETE</span>
                    <DeleteIcon />
                  </DeleteIconWrapper>
                )
              }
            </div>
          )
        }
      }
      ]

  const draftColumns = isDraftTable
    ? [
        {
          Header: 'Price',
          accessor: 'netPrice',
          Cell: d => {
            const price = pathOr(null, ['value', 'formatted'], d)
            const buildingTypes = pathOr([], ['row', 'original', 'buildingTypes'], d)
            const isDwellingSole = isNotEmpty(
              buildingTypes.filter(type =>
                type.type === 'residential.dwelling.sole_unit')
            )
            return (
              <Price>
                {
                  isNotNil(price)
                    ? <div>
                      {price.replace(' ', '')}
                      <span>
                        {isDwellingSole ? '/one-time' : '/year'}
                      </span>
                    </div>
                    : '---'
                }
              </Price>
            )
          }
        },
      ...basketColumn
      ]
    : []

  const columns = React.useMemo(
    () => [
      {
        Header: t('bpList.table.name'),
        id: 'name',
        accessor: 'name',
        width: '130px',
        defaultCanSort: true,
        Cell: d => <PropertyName>{d.value}</PropertyName>
      },
      {
        Header: t('bpList.table.uprn'),
        accessor: 'uprn',
        defaultCanSort: true,
        Cell: d => {
          return isNotNilOrEmpty(d.value)
            ? d.value
            : 'Not yet allocated'
        }
      },
      {
        Header: t('bpList.table.address'),
        id: 'city',
        accessor: d =>
          <div>{`${d.street}, ${d.city}`}</div>
      },
      {
        Header: t('bpList.table.postcode'),
        id: 'postal_code',
        accessor: 'postalCode'
      },
      {
        Header: t('bpList.table.landlord'),
        id: 'owner',
        accessor: d => {
          const owner = pathOr(null, ['owners', 0], d)
          return (
            <div>
              {
                owner
                  ? getOwner(d.owners[0])
                  : '---'
              }
            </div>
          )
        }
      },
      {
        Header: 'Created',
        id: 'created_at',
        accessor: 'createdAt',
        Cell: d => <DateString>{formatDate(d.value)}</DateString>
      },
      {
        Header: 'Updated',
        id: 'updated_at',
        accessor: 'updatedAt',
        Cell: d => <DateString>{formatDate(d.value)}</DateString>
      },
      ...subscriptionColumns,
      ...draftColumns
    ],
    []
  )

  const tableInstance = useTable({
    columns,
    data
  })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = tableInstance

  return (
    <TableWrapper>
      <table {...getTableProps()}>
        <thead>
        {
          headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {
                headerGroup.headers.map(column => (
                  <th
                    style={{ cursor: 'pointer', paddingRight: '20px' }}
                    {...column.getHeaderProps()}
                    onClick={sortingChange(column.id)}
                  >
                    {column.render('Header')}
                    <span>
                      {column.id === sortingOptions.column
                        ? <SortingArrowIcon down={!sortingOptions.desc} />
                        : ''}
                  </span>
                  </th>
                ))
              }
            </tr>
          ))
        }
        </thead>
        <tbody {...getTableBodyProps()}>
        {
          rows.map(row => {
            prepareRow(row)
            const status = pathOr('', ['original', 'status'], row)
            return (
              <BpRow
                dormant={status === 'dormant' && !isFrs}
                {...row.getRowProps()}
                onClick={status === 'dormant' && !isFrs
                  ? () => {}
                  : () => redirect(redirectLink(row.original.id))}
              >
                {
                  row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>
                        {
                          cell.render('Cell')
                        }
                      </td>
                    )
                  })}
              </BpRow>
            )
          })}
        </tbody>
      </table>
      {
        isEmpty(data) &&
          <EmptyState>
            No BPs found.
          </EmptyState>
      }
      <Modal
        open={deleteModalOpen}
        title='Remove drat'
        onClose={handleDeleteModalClose}
        onCancel={handleDeleteModalClose}
        onSubmit={handleDelete}
        withSubmit
        withCancel
      >
        Do you really want to remove this draft?
      </Modal>
    </TableWrapper>
  )
}

export default Table

const TableWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-x: auto;

  table {
    width: 100%;
  }

  th {
    box-sizing: border-box;
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: 11px;
    opacity: .5;
    text-transform: uppercase;
    text-align: left;
    height: 40px;
    padding: 0 10px;
    position: relative;
  }

  td {
    box-sizing: border-box;
    padding: 0 10px;
    height: 56px;
    font-size: 14px;
  }

  tbody > tr {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};

    &:hover {
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.lighterGrey};
    }
  }
`

const PropertyName = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
`

const DateString = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  opacity: .65;
`

const SortingArrowIcon = styled(ArrowUpwardIcon)`
  position: absolute;
  top: 10px;
  transition: all .3s;
  transform: ${({ down }) => `rotate(${down ? '-180deg' : 0})`};
  padding: 0 5px;
  font-size: 20px !important;
`

const EmptyState = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`

const AddButton = styled(NextButton)`
  width: 150px;
  height: 30px;
  font-weight: normal;
  margin: 0 auto;
  border-radius: 4px;
`

const InBasket = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary} !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  ${({ dormant }) => dormant && css`
    justify-content: flex-start;
  `}
`

const CheckIcon = styled(CheckCircleIcon)`
  font-size: 16px !important;
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 5px;
  position: relative;
  top: 1px;
`

const Price = styled.div`
  font-weight: bold;

  span {
    font-size: 11px;
    font-weight: normal;
  }
`

const DeleteIconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.error};

  span {
    transition: all .3s;
    opacity: 0;
    margin-right: 3px;
  }

  &:hover {
    span {
      opacity: 1;
    }
  }
`

const BpRow = styled.tr`
  ${({ dormant }) => dormant && css`
    background-color: ${({ theme }) => theme.colors.grey[400]} !important;
    color: ${({ theme }) => theme.colors.grey[800]} !important;

    & div {
      color: ${({ theme }) => theme.colors.grey[800]};
    }

    &:hover {
      cursor: default !important;
      background-color: ${({ theme }) => theme.colors.grey[400]} !important;
    }
  `}
`
