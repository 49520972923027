import React from 'react'
import styled from 'styled-components'
import { Collapse } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const ActivityExpandablePanel = ({ title, children, expanded, onToggle }) => {
  return (
    <Wrapper>
      <Header onClick={onToggle}>
        {title}
        {expanded
          ? <KeyboardArrowUpIcon />
          : <KeyboardArrowDownIcon />}
      </Header>
      <Collapse in={expanded}>
        {children}
      </Collapse>
    </Wrapper>
  )
}

export default ActivityExpandablePanel

const Wrapper = styled.div`

`

const Header = styled.div`
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[500]};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
