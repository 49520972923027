import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as FileIcon } from 'assets/images/file-icon.svg'
import { isNotNilOrEmpty } from 'utils/ramda'
import { toCamelCase } from 'js-convert-case'
import { redirect } from 'utils/paths'
import { pathOr, propOr } from 'ramda'
import { Tooltip } from '@mui/material'

const BpBox = ({ bp, buildingTypes, noAction }) => {
  const { name, uprn, isUnit } = bp

  const buildingTypesString = useMemo(() => {
    let result = ''
    if (isNotNilOrEmpty(bp.buildingTypes)) {
      bp.buildingTypes.forEach(e => {
        const type = toCamelCase(e.type)
        result += `${pathOr('', [type, 'label'], buildingTypes)};`
      })
    }
    return result.substring(0, result.length - 1)
  }, [bp, buildingTypes])

  return (
    (<BpBoxWrapper
      onClick={
        noAction
          ? () => {}
          : () => redirect(`bp-list/${bp.id}`)
      }
      noAction={noAction}
    >
      {
        propOr('', 'status', bp) === 'draft' && !isUnit && (
          // eslint-disable-next-line
          (<Tooltip title='This Building Passport is still in draft and therefore cannot be accessed by anyone other than the initial creator, including emergency services. Please add to basket and pay the subscription to enable the full suite of platform features.'>
            <DraftLabel>
              Draft
            </DraftLabel>
          </Tooltip>)
        )
      }
      {
        isUnit && (
          <UnitLabel>
            Unit
          </UnitLabel>
        )
      }
      <DetailsWrapper>
        <IconWrapper>
          <FileIcon />
        </IconWrapper>
        <div>
          <BpName>
            {name}
          </BpName>
          <Uprn>
            UPRN: {
              isNotNilOrEmpty(uprn)
                ? uprn
                : 'Not yet allocated'
            }
          </Uprn>
        </div>
      </DetailsWrapper>
      <div>
        <DetailsItem>
          <DetailsLabel>Type</DetailsLabel>
          <DetailsValue>{buildingTypesString}</DetailsValue>
        </DetailsItem>
        <DetailsItem>
          <DetailsLabel>Address</DetailsLabel>
          <DetailsValue>{`${bp.street}, ${bp.city}, ${bp.postalCode}`}</DetailsValue>
        </DetailsItem>
      </div>
    </BpBoxWrapper>)
  );
}

export default BpBox

const BpBoxWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  min-width: 300px;
  max-width: 300px;
  padding: 25px;
  height: 150px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  margin-bottom: 20px;
  margin-right: 20px;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[400]};
  }

  ${({ noAction }) => noAction && css`
    cursor: default;

    &:hover {
      background-color: ${({ theme }) => theme.colors.white};
    }
  `}
`

const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
`

const IconWrapper = styled.div`
  background-color: #fbede9;
  min-width: 55px;
  min-height: 55px;
  max-width: 55px;
  max-height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
`

const BpName = styled.div`
  font-size: 19px;
  line-height: 20px;
  ${({ theme }) => theme.colors.haiti};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const Uprn = styled.div`
  font-size: 13px;
  margin-top: 2px;
  color: ${({ theme }) => theme.colors.grey[800]};
`

const DetailsItem = styled.div`
  display: flex;
  margin-top: 8px;
`

const DetailsLabel = styled.div`
  box-sizing: border-box;
  padding-left: 10px;
  min-width: 55px;
  margin-right: 15px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey[800]};
`

const DetailsValue = styled.div`
  box-sizing: border-box;
  font-size: 14px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.haiti};
`

const DraftLabel = styled.div`
  padding: 5px 10px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.secondary};
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 12px;
  opacity: .7;
`

const UnitLabel = styled.div`
  padding: 5px 10px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary};
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 12px;
  opacity: .7;
`
