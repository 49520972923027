import React, { useEffect, useMemo, useState } from 'react'
import {
  AddButton,
  MainContentPanel,
  SectionHeader,
  SectionHeaderWrapper
} from 'features/bpDetails/BpDetails'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { isEmpty, pathOr } from 'ramda'
import { getFileTypeName } from 'utils/bpData'
import FileCategories from 'features/bpDetails/Components/files/FileCategories'
import FilesSearchInput from 'features/bpDetails/Components/FilesSearchInput'
import { canEditBp } from 'utils/user'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import {
  getSelectedBp,
  localSearchFileResults
} from 'features/bpDetails/ducks/selectors'
import { getFileTypes, selectBpFilesList } from 'ducks/files/selectors'
import File from 'features/bpDetails/Components/File'
import AddMultipleFilesModal from 'features/bpDetails/Components/files/AddMultipleFilesModal'
import EmptyFileState from 'features/bpList/Components/EmptyFileState'
import { redirect } from 'utils/paths'
import FileTagsPanel from 'features/bpDetails/Components/files/FileTagsPanel'
import { selectIsFetchingFiles } from 'ducks/loaderSelectors'
import {
  CircularProgress,
  Chip,
  Tooltip,
  IconButton,
  Menu,
  MenuItem
} from '@mui/material'
import GridViewIcon from '@mui/icons-material/GridView'
import ListIcon from '@mui/icons-material/List'
import SortIcon from '@mui/icons-material/Sort'
const OtherFiles = () => {
  const files = useSelector(selectBpFilesList)
  const fileTypes = useSelector(getFileTypes)
  const [viewMode, setViewMode] = useState('grid')
  const [recentFilesVisible, setRecentFilesVisible] = useState(true)
  const [foundFiles, setFoundFiles] = useState([])
  const [isSearching, setIsSearching] = useState(false)
  const bp = useSelector(getSelectedBp)
  const currentUser = useSelector(getCurrentUser)
  const isFetchingFiles = useSelector(selectIsFetchingFiles)
  const [sortBy, setSortBy] = useState('newest')
  const [sortMenuAnchorEl, setSortMenuAnchorEl] = useState(null)
  const canEdit = canEditBp(currentUser, bp)

  const recentFiles = useMemo(() => {
    if (files) {
      const sortedFiles = files.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      )
      return sortedFiles.slice(0, 6)
    }
  }, [files])

  const openSortMenu = event => {
    setSortMenuAnchorEl(event.currentTarget)
  }

  const closeSortMenu = () => {
    setSortMenuAnchorEl(null)
  }

  const sortFiles = filesToSort => {
    if (!filesToSort) return []

    switch (sortBy) {
      case 'newest':
        return [...filesToSort].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
      case 'oldest':
        return [...filesToSort].sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        )
      case 'name-asc':
        return [...filesToSort].sort((a, b) =>
          a.displayName.localeCompare(b.displayName)
        )
      case 'name-desc':
        return [...filesToSort].sort((a, b) =>
          b.displayName.localeCompare(a.displayName)
        )
      case 'type':
        return [...filesToSort].sort((a, b) => {
          const typeA = getFileTypeName(
            pathOr('', ['fileType', 'id'], a),
            fileTypes,
            a.isPrivate
          )
          const typeB = getFileTypeName(
            pathOr('', ['fileType', 'id'], b),
            fileTypes,
            b.isPrivate
          )
          return typeA.localeCompare(typeB)
        })
      case 'expiry-asc':
        return [...filesToSort].sort((a, b) => {
          // Handle null expiration dates (files with no expiry come last)
          if (!a.expirationDate && !b.expirationDate) return 0
          if (!a.expirationDate) return 1
          if (!b.expirationDate) return -1
          return new Date(a.expirationDate) - new Date(b.expirationDate)
        })
      case 'expiry-desc':
        return [...filesToSort].sort((a, b) => {
          // Handle null expiration dates (files with no expiry come last)
          if (!a.expirationDate && !b.expirationDate) return 0
          if (!a.expirationDate) return 1
          if (!b.expirationDate) return -1
          return new Date(b.expirationDate) - new Date(a.expirationDate)
        })
      default:
        return filesToSort
    }
  }

  const sortedRecentFiles = useMemo(() => {
    return sortFiles(recentFiles)
  }, [recentFiles, sortBy])

  const sortedSearchFiles = useMemo(() => {
    return sortFiles(foundFiles)
  }, [foundFiles, sortBy])

  const getSortLabel = sortBy => {
    switch (sortBy) {
      case 'newest':
        return 'Newest first'
      case 'oldest':
        return 'Oldest first'
      case 'name-asc':
        return 'Name (A-Z)'
      case 'name-desc':
        return 'Name (Z-A)'
      case 'type':
        return 'File type'
      case 'expiry-asc':
        return 'Expiry date (soonest)'
      case 'expiry-desc':
        return 'Expiry date (latest)'
      default:
        return 'Newest first'
    }
  }

  const searchFiles = useSelector(localSearchFileResults)

  useEffect(() => {
    if (isSearching) {
      setFoundFiles(searchFiles)
    }
  }, [searchFiles, isSearching])

  return (
    <OtherFilesWrapper>
      <MainContentPanel>
        <PanelHeaderWrapper>
          <TitleWrapper>
            <PageTitle>Documents</PageTitle>
            {recentFilesVisible && (
              <>
                <Chip
                  label={`${files?.length || 0} files`}
                  variant='outlined'
                  size='small'
                />
                <AddButton onClick={() => redirect(`/bp-list/${bp.id}/files/`)}>
                  View all
                </AddButton>
              </>
            )}
          </TitleWrapper>
        </PanelHeaderWrapper>
        {recentFilesVisible && (
          <FileTagsPanel
            onTagClick={tagId => {
              redirect(`/bp-list/${bp.id}/files/`, { selectedTag: tagId })
            }}
          />
        )}

        <SearchInputWrapper>
          <FilesSearchInput setIsSearching={setIsSearching} />
          <SearchInfo>
            <NewFunctionalityLabel active={true}>New!</NewFunctionalityLabel>{' '}
            You can search by uploader name, file category, file type, or
            specific words/phrases within the documents themselves.
          </SearchInfo>
        </SearchInputWrapper>

        {(recentFilesVisible || isSearching) && (
          <ViewToggle>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title='Grid view'>
                <StyledIconButton
                  isActive={viewMode === 'grid'}
                  onClick={() => setViewMode('grid')}
                >
                  <GridViewIcon />
                </StyledIconButton>
              </Tooltip>
              <Tooltip title='List view'>
                <StyledIconButton
                  isActive={viewMode === 'list'}
                  onClick={() => setViewMode('list')}
                >
                  <ListIcon />
                </StyledIconButton>
              </Tooltip>
            </div>

            <SortButton onClick={openSortMenu}>
              <SortIcon fontSize='small' />
              <span>Sort: {getSortLabel(sortBy)}</span>
            </SortButton>

            <Menu
              anchorEl={sortMenuAnchorEl}
              open={Boolean(sortMenuAnchorEl)}
              onClose={closeSortMenu}
            >
              <MenuItem
                selected={sortBy === 'newest'}
                onClick={() => {
                  setSortBy('newest')
                  closeSortMenu()
                }}
              >
                Newest first
              </MenuItem>
              <MenuItem
                selected={sortBy === 'oldest'}
                onClick={() => {
                  setSortBy('oldest')
                  closeSortMenu()
                }}
              >
                Oldest first
              </MenuItem>
              <MenuItem
                selected={sortBy === 'name-asc'}
                onClick={() => {
                  setSortBy('name-asc')
                  closeSortMenu()
                }}
              >
                Name (A-Z)
              </MenuItem>
              <MenuItem
                selected={sortBy === 'name-desc'}
                onClick={() => {
                  setSortBy('name-desc')
                  closeSortMenu()
                }}
              >
                Name (Z-A)
              </MenuItem>
              <MenuItem
                selected={sortBy === 'type'}
                onClick={() => {
                  setSortBy('type')
                  closeSortMenu()
                }}
              >
                File type
              </MenuItem>
              <MenuItem
                selected={sortBy === 'expiry-asc'}
                onClick={() => {
                  setSortBy('expiry-asc')
                  closeSortMenu()
                }}
              >
                Expiry date (soonest)
              </MenuItem>
              <MenuItem
                selected={sortBy === 'expiry-desc'}
                onClick={() => {
                  setSortBy('expiry-desc')
                  closeSortMenu()
                }}
              >
                Expiry date (latest)
              </MenuItem>
            </Menu>
          </ViewToggle>
        )}

        {isSearching && (
          <>
            <SectionHeaderWrapper>
              <SectionHeader>
                Search results{' '}
                {!isEmpty(foundFiles) ? '(' + foundFiles.length + ')' : ''}
              </SectionHeader>
            </SectionHeaderWrapper>
            <FilesWrapper viewMode={viewMode}>
              {isEmpty(sortedSearchFiles) ? (
                <div>No files found</div>
              ) : (
                sortedSearchFiles.map(file => (
                  <File
                    key={`file-${file.id || Math.random() * 99}`}
                    file={file}
                    type={getFileTypeName(
                      pathOr('', ['fileType', 'id'], file),
                      fileTypes,
                      file.isPrivate
                    )}
                    showThumbnail={viewMode === 'grid'}
                    isSharedWithUnits={file.isSharedWithUnits}
                    viewMode={viewMode}
                  />
                ))
              )}
            </FilesWrapper>
          </>
        )}

        {!isSearching && (
          <>
            {recentFilesVisible && (
              <>
                <SectionHeaderWrapper>
                  <SectionHeader>Recent files</SectionHeader>
                  {canEdit && (
                    <AddMultipleFilesModal>
                      <AddButton>+ Add new files</AddButton>
                    </AddMultipleFilesModal>
                  )}
                </SectionHeaderWrapper>
                <FilesWrapper viewMode={viewMode}>
                  {isFetchingFiles ? (
                    <FilesFetchingBox>
                      <CircularProgress />
                      Fetching files...
                    </FilesFetchingBox>
                  ) : isEmpty(sortedRecentFiles) ? (
                    <EmptyFileState
                      text='Add your first file to this Building Passport'
                      buttonText='+ Add files'
                      hideButton={!canEdit}
                    />
                  ) : (
                    sortedRecentFiles.map(file => (
                      <File
                        key={`file-${file.id || Math.random() * 99}`}
                        file={file}
                        type={getFileTypeName(
                          pathOr('', ['fileType', 'id'], file),
                          fileTypes,
                          file.isPrivate
                        )}
                        isSharedWithUnits={file.isSharedWithUnits}
                        viewMode={viewMode}
                        showThumbnail={viewMode === 'grid'}
                      />
                    ))
                  )}
                </FilesWrapper>
              </>
            )}
            <FileCategories setRecentFilesVisible={setRecentFilesVisible} />
          </>
        )}
      </MainContentPanel>
    </OtherFilesWrapper>
  )
}

export default OtherFiles

const OtherFilesWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: relative;
`

const FilesWrapper = styled.div`
  box-sizing: border-box;
  max-width: 100%;
  display: ${props => (props.viewMode === 'grid' ? 'grid' : 'flex')};
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 16px;
  flex-direction: column;
  gap: 12px;
  align-items: ${props =>
    props.viewMode === 'grid' ? 'stretch' : 'flex-start'};
`

const PanelHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  text-transform: uppercase;
  font-size: 14px;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const SearchInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 30px 0;
`

const SearchInfo = styled.div`
  font-size: 14px;
  margin-top: 5px;
  line-height: 1.4;
  display: flex;
  align-items: center;
`

const NewFunctionalityLabel = styled.div`
  width: fit-content;
  padding: 3px 7px;
  background: ${({ active, theme }) =>
    active ? theme.colors.primary : theme.colors.backgroundColor};
  color: ${({ active, theme }) => (active ? '#fff' : theme.colors.darkgrey)};
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  border-radius: 4px;
  margin-right: 6px;
`

const FilesFetchingBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  align-self: center;
  grid-column: 1 / -1;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`

const PageTitle = styled.h1`
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: #202124;
`
const ViewToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  border-radius: 8px;
  padding: 2px;
  margin-bottom: 20px;
`
const StyledIconButton = styled(IconButton)`
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.primary : 'inherit'} !important;

  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`
const SortButton = styled.button`
  display: flex;
  align-items: center;
  gap: 6px;
  background: none;
  border: none;
  padding: 6px 12px;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.grey[200]};
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[100]};
  }
`
