import React, { useMemo } from 'react'
import styled from 'styled-components'
import { PieChart } from 'react-minimal-pie-chart';
import { flatten, pathOr, propOr, values as ramdaValues } from 'ramda'
import { Tooltip } from '@mui/material'
import { useSelector } from 'react-redux'
import { getDashboardStats } from 'features/dashboard/ducks/selectors'

const TemplateBox = ({ template }) => {
  const dashboardStats = useSelector(getDashboardStats)

  const total = useMemo(() => {
    return flatten(ramdaValues(propOr([], 'percentageValues', template))).length
  }, [template])

  const completed = useMemo(() => {
    return pathOr([], ['percentageValues', 5], template).length
  }, [template])

  const bpsCount = useMemo(() => {
    return {
      1: pathOr([], ['percentageValues', 1], template).length,
      2: pathOr([], ['percentageValues', 2], template).length,
      3: pathOr([], ['percentageValues', 3], template).length,
      4: pathOr([], ['percentageValues', 4], template).length,
      5: pathOr([], ['percentageValues', 5], template).length
    }
  }, [template])

  const values = useMemo(() => {
    return [
      {
        title: `100% - ${bpsCount[5]}`,
        value: pathOr([], ['percentageValues', 5], template).length,
        color: '#33becc'
      },
      {
        title: `75 - 99% - ${bpsCount[4]}`,
        value: pathOr([], ['percentageValues', 4], template).length,
        color: '#BAEAEF'
      },
      {
        title: `50 - 74% - ${bpsCount[3]}`,
        value: pathOr([], ['percentageValues', 3], template).length,
        color: '#ffaf7e'
      },
      {
        title: `20 - 49% - ${bpsCount[2]}`,
        value: pathOr([], ['percentageValues', 2], template).length,
        color: '#ff836b'
      },
      {
        title: `0 - 19% - ${bpsCount[1]}`,
        value: pathOr([], ['percentageValues', 1], template).length,
        color: '#cc2200'
      }
    ]
  }, [template])

  return (
    <Tooltip
      placement='top'
      title={`This template is applied to ${total} of your ${dashboardStats.activeBuildingPassportsCount} live
      Building Passports. ${completed} of these have 100% of the required information`}
    >
      <TemplateBoxWrapper>
        <TemplateName>{template.name}</TemplateName>
        <BoxContent>
          <BoxChart>
            <PieChart
              animate
              lineWidth={15}
              data={values}
            />
            <ProgressCount>
              <CompletedCount>{completed}/{total}</CompletedCount>
              <div>complete</div>
            </ProgressCount>
          </BoxChart>
        </BoxContent>
      </TemplateBoxWrapper>
    </Tooltip>
  )
}

export default TemplateBox

const TemplateBoxWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  min-width: 220px;
  max-width: 220px;
  padding: 15px 25px;
  height: 210px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  margin-bottom: 20px;
  margin-right: 20px;
`

const TemplateName = styled.div`
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.darkGrey};
`

const BoxContent = styled.div`
  display: flex;
  align-items: center;
  height: 150px;
`

const BoxChart = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 120px;
  position: relative;
`

const ProgressCount = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkGrey};
  flex-direction: column;
`

const CompletedCount = styled.div`
  font-size: 24px;
  font-weight: bold;
`
