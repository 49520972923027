import React, { useMemo, useState } from 'react'
import { Collapse, Tooltip } from '@mui/material'
import styled, { css } from 'styled-components'
import Select from 'components/atoms/Select'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'
import { useSelector } from 'react-redux'
import { getFileTypes } from 'ducks/files/selectors'
import { selectFileCategories } from 'ducks/dictionaries/selectors'
import { selectBpUnits } from 'ducks/units/selectors'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import { getFloorOptions } from 'utils/bpData'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import FileTypesSearch from 'features/bpDetails/Components/files/FileTypesSearch'
import Input from 'components/atoms/Input'
import AddUsersToFile from 'features/bpDetails/Components/files/AddUsersToFile'
import AttachTagsToUploadingFile from 'features/bpDetails/Components/files/AttachTagsToUploadingFiles'

const defaultValues = {
  fileCategory: '',
  fileType: '',
  units: [],
  floors: [],
  expirationDate: '',
  userIds: [],
  tagIds: []
}

const informationExchangeCategoryName = 'FRS Information Exchange'

const BulkEditForm = ({ open, onChange, isUnit, isPrivate }) => {
  const [values, setValues] = useState(defaultValues)
  const fileTypes = useSelector(getFileTypes)
  const fileCategories = useSelector(selectFileCategories)
  const bpUnits = useSelector(selectBpUnits)
  const bp = useSelector(getSelectedBp)

  const isFloorPlan = useMemo(() => {
    if (isNotNilOrEmpty(values.fileType)) {
      const selectedType = fileTypes.find(type => type.id === values.fileType)
      return selectedType?.name === 'Building plan'
    } else {
      return false
    }
  }, [values.fileType])

  const isValid = useMemo(() => {
    // Check if ANY field has a value, not just specific combinations
    return Object.values(values).some(value =>
      Array.isArray(value) ? value.length > 0 : Boolean(value)
    )
  }, [values])

  const handleValueChange = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }))
  }

  const handleCategoryChange = (_, value) => {
    setValues(prev => ({
      ...prev,
      fileType: '',
      floors: []
    }))
    setTimeout(() => {
      setValues(prev => ({
        ...prev,
        fileCategory: value
      }))
    }, 0)
  }

  const handleTypeChange = (_, value) => {
    setValues(prev => ({
      ...prev,
      floors: [],
      fileType: value
    }))
  }

  const categoriesOptions = fileCategories
    .filter(cat => cat.name !== informationExchangeCategoryName)
    .map(type => ({
      label: type.name,
      value: type.id
    }))

  const floorOptions = getFloorOptions(bp)

  const unitsOptions = bpUnits
    // .filter(unit => !isResidentialUnit(unit.buildingType))
    .map(type => ({
      label: type.name,
      value: type.id
    }))

  const handleSaveChanges = () => {
    onChange(values)
  }

  const handleTypeAndCategoryChange = (category, type) => {
    category !== values.fileCategory && handleCategoryChange('', category)
    type !== values.fileType && handleTypeChange('', type)
  }

  return (
    <Collapse in={open}>
      <Wrapper>
        {isFloorPlan ? (
          <TableRow isUnit={isUnit}>
            <TableCell>
              <FileNameWrapper />
            </TableCell>
            <TableCell>
              <Select
                noMargin
                label='Category'
                onChange={handleCategoryChange}
                value={values.fileCategory}
                name='fileCategory'
                options={categoriesOptions}
              />
            </TableCell>
            <TableCell>
              <FileTypesSearch
                label='File type'
                placeholder='Type to search'
                category={values.fileCategory}
                onFileTypeSelect={handleTypeAndCategoryChange}
                value={values.fileType}
              />
            </TableCell>
            <TableCell>
              <Select
                noMargin
                multiple
                onChange={handleValueChange}
                label='Applicable floors'
                name='floors'
                selectAll
                value={values.floors}
                options={floorOptions}
                hasError={isNilOrEmpty(values.floors) && isFloorPlan}
              />
            </TableCell>
            {/* Rest of your conditional cells for isFloorPlan=true */}
            {!isUnit && (
              <TableCell>
                <Select
                  noMargin
                  label='Share with'
                  multiple
                  onChange={handleValueChange}
                  value={values.units}
                  selectAll
                  name='units'
                  disabled={isNilOrEmpty(unitsOptions)}
                  options={unitsOptions}
                />
              </TableCell>
            )}
            {!isPrivate && (
              <TableCell>
                <Input
                  nomarginbottom
                  label='Expiration date'
                  value={values.expirationDate}
                  name='expirationDate'
                  type='date'
                  onChange={handleValueChange}
                />
              </TableCell>
            )}
            {!isUnit && (
              <>
                <TableCell style={{ paddingTop: '20px' }}>
                  <Tooltip title='Manage permissions'>
                    <AddUsersToFile
                      isUpload
                      value={values.userIds}
                      savedCount={values.userIds?.length || 0}
                      onSave={value => {
                        handleValueChange('userIds', value)
                      }}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell style={{ paddingTop: '20px' }}>
                  <Tooltip title='Manage tags'>
                    <AttachTagsToUploadingFile
                      savedCount={values.tagIds?.length || 0}
                      onSave={value => {
                        handleValueChange('tagIds', value)
                      }}
                    />
                  </Tooltip>
                </TableCell>
              </>
            )}
            <TableCell>
              <IconWrapper
                onClick={isValid ? handleSaveChanges : () => {}}
                disabled={!isValid}
              >
                <CheckOutlinedIcon />
              </IconWrapper>
            </TableCell>
          </TableRow>
        ) : (
          <TableRowWithoutFloorPlan isUnit={isUnit}>
            <TableCell>
              <FileNameWrapper />
            </TableCell>
            <TableCell>
              <Select
                noMargin
                label='Category'
                onChange={handleCategoryChange}
                value={values.fileCategory}
                name='fileCategory'
                options={categoriesOptions}
              />
            </TableCell>
            <TableCellWide>
              <FileTypesSearch
                label='File type'
                placeholder='Type to search'
                category={values.fileCategory}
                onFileTypeSelect={handleTypeAndCategoryChange}
                value={values.fileType}
              />
            </TableCellWide>
            {/* Rest of your conditional cells for isFloorPlan=false */}
            {!isUnit && (
              <TableCell>
                <Select
                  noMargin
                  label='Share with'
                  multiple
                  onChange={handleValueChange}
                  value={values.units}
                  selectAll
                  name='units'
                  disabled={isNilOrEmpty(unitsOptions)}
                  options={unitsOptions}
                />
              </TableCell>
            )}
            {!isPrivate && (
              <TableCell>
                <Input
                  nomarginbottom
                  label='Expiration date'
                  value={values.expirationDate}
                  name='expirationDate'
                  type='date'
                  onChange={handleValueChange}
                />
              </TableCell>
            )}
            {!isUnit && (
              <>
                <TableCell style={{ paddingTop: '20px' }}>
                  <Tooltip title='Manage permissions'>
                    <AddUsersToFile
                      isUpload
                      value={values.userIds}
                      savedCount={values.userIds?.length || 0}
                      onSave={value => {
                        handleValueChange('userIds', value)
                      }}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell style={{ paddingTop: '20px' }}>
                  <Tooltip title='Manage tags'>
                    <AttachTagsToUploadingFile
                      savedCount={values.tagIds?.length || 0}
                      onSave={value => {
                        handleValueChange('tagIds', value)
                      }}
                    />
                  </Tooltip>
                </TableCell>
              </>
            )}
            <TableCell>
              <IconWrapper
                onClick={isValid ? handleSaveChanges : () => {}}
                disabled={!isValid}
              >
                <CheckOutlinedIcon />
              </IconWrapper>
            </TableCell>
          </TableRowWithoutFloorPlan>
        )}
      </Wrapper>
    </Collapse>
  )
}

export default BulkEditForm

const FileNameWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 250px;
  overflow: hidden;
`

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr repeat(5, 180px) repeat(3, 40px);
  height: 72px;
  margin-top: -1px;
  position: relative;
  cursor: pointer;

  ${({ isUnit }) =>
    isUnit &&
    css`
      grid-template-columns: 1fr repeat(3, 180px) 40px;
    `}
`

const TableCell = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 13px;
  padding: 0 5px;
  max-width: 160px;
  &.hidden {
    padding: 0;
    width: 0;
    max-width: 0;
    overflow: hidden;
    opacity: 0;
  }
`
const TableRowWithoutFloorPlan = styled.div`
  display: grid;
  grid-template-columns: 1fr repeat(4, 180px) repeat(3, 40px);
  height: 72px;
  margin-top: -1px;
  position: relative;
  cursor: pointer;

  ${({ isUnit }) =>
    isUnit &&
    css`
      grid-template-columns: 1fr repeat(3, 180px) 40px;
    `}
`

const TableCellWide = styled(TableCell)`
  max-width: 320px;
`

const IconWrapper = styled.div`
  cursor: pointer;
  padding: 3px;
  background-color: ${({ theme }) => theme.colors.haiti};
  color: #fff;
  max-height: 40px;
  min-height: 40px;
  max-width: 40px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-top: 24px;
  transition: all 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.colors.grey[500]};
      color: ${({ theme }) => theme.colors.grey[700]};
      cursor: default;

      &:hover {
        background-color: ${({ theme }) => theme.colors.grey[500]};
      }
    `}
`

const Wrapper = styled.div``
