import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import Input from 'components/atoms/Input'
import { useDispatch, useSelector } from 'react-redux'
import {
  validateAccreditationField,
  validateRegistrationField,
  validateRegistrationValues
} from 'features/auth/ducks/schema'
import {
  clearLatestInvitationRoutine,
  getAccreditationTypesRoutine,
  getInvitationRoutine,
  registerUserRoutine
} from 'features/auth/ducks/actions'
import Checkbox from 'components/atoms/Checkbox'
import {
  isUserLoggedIn,
  selectAccreditationTypes,
  selectCurrentInvitation
} from 'features/auth/ducks/selectors'
import { PATHS, PRIVATE_PATHS } from 'utils/paths'
import BackButton from 'components/atoms/BackButton'
import FullWidthButton from 'features/auth/components/FullWidthButton'
import qs from 'qs'
import { propOr, update, dissoc, head, remove, isEmpty } from 'ramda'
import { isNotNil, isNotNilOrEmpty } from 'utils/ramda'
import PhoneField from 'components/atoms/PhoneInput'
import AutocompleteInput from 'components/atoms/AutocompleteInput'
import Collapse from '@mui/material/Collapse'
import InputDescription from 'features/createBp/components/atoms/InputDescription'
import { getRoleDescription } from 'utils/userRoles'
import ExpandableAccreditations from 'features/auth/components/ExpandableAccreditations'
import { getUnitInvitationRoutine } from 'ducks/units/actions'
import { Navigate } from 'react-router-dom'

const emptyValues = {
  type: 'user',
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  passwordConfirmation: '',
  phoneNumber: '',
  company: '',
  postalCode: '',
  street: '',
  city: '',
  disclaimer: false,
  updates: false,
  reminders: false,
  periodicReminder: 6,
  accreditations: [
    {
      id: '',
      number: ''
    }
  ]
}

export const AccreditationForm = ({
  values,
  options,
  index,
  onChange = () => {},
  onRemove = () => {},
  withDelete
}) => {
  const selectedOption = options.find(option => option.value === values.id)
  return (
    <AccreditationFormWrapper>
      <AutocompleteInput
        name='id'
        options={options}
        withDelete={withDelete}
        onDelete={onRemove(index)}
        onChange={onChange(index)}
        inputValue={values.id}
        value={selectedOption}
        label='Type'
        validate={validateAccreditationField(values)}
      />
      <Collapse in={!isEmpty(values.id)}>
        {!isEmpty(values.id) && (
          <AccreditationNumberWrapper>
            <Input
              optional
              label='Number'
              name='number'
              value={values.number}
              onChange={onChange(index)}
            />
          </AccreditationNumberWrapper>
        )}
      </Collapse>
    </AccreditationFormWrapper>
  )
}

const SignUp = () => {
  const [values, setValues] = useState(emptyValues)
  const [valid, setIsValid] = useState(false)
  const [accreditationsValid, setAccreditationsValid] = useState(false)
  const [phoneValid, setPhoneValid] = useState(false)
  const dispatch = useDispatch()
  const isloggedin = useSelector(isUserLoggedIn)
  const [referral, setReferral] = useState(null)
  const isFrs = useMemo(() => values.type === 'emergency_service', [values])
  const referralToAdd = isNotNil(referral) ? { referral } : {}
  const accreditationTypes = useSelector(selectAccreditationTypes)
  const currentInvitation = useSelector(selectCurrentInvitation)

  const options = useMemo(() => {
    return accreditationTypes
      ? [
          {
            label: '',
            value: ''
          },
          ...accreditationTypes.map(type => ({
            label: type.name,
            value: type.id
          }))
        ]
      : []
  }, [accreditationTypes])

  useEffect(() => {
    const queryParams = qs.parse(window.location.search.substr(1))
    if (queryParams.invitation_id) {
      queryParams.type === 'building_passport'
        ? dispatch(
            getInvitationRoutine({
              id: queryParams.invitation_id,
              email: queryParams.email.replace('+', '%2B')
            })
          )
        : dispatch(
            getUnitInvitationRoutine({
              id: queryParams.invitation_id,
              email: queryParams.email.replace('+', '%2B')
            })
          )
    }
    setReferral(propOr(null, 'referral', queryParams))
    setTimeout(
      () => window.history.replaceState(null, null, window.location.pathname),
      0
    )
    dispatch(getAccreditationTypesRoutine())

    return () => dispatch(clearLatestInvitationRoutine())
  }, [])

  useEffect(() => {
    !isEmpty(currentInvitation) &&
      setValues(prev => ({
        ...prev,
        firstName: currentInvitation.firstName,
        lastName: currentInvitation.lastName,
        email: currentInvitation.email,
        phoneNumber: currentInvitation.phoneNumber || '',
        type: 'user'
      }))
  }, [currentInvitation])

  useEffect(() => {
    const length = values.accreditations.length
    switch (true) {
      case length === 1:
        setAccreditationsValid(true)
        break
      case length > 1:
        setAccreditationsValid(
          values.accreditations.every(item => !isEmpty(item.id))
        )
        break
      default:
        return false
    }
  }, [values.accreditations])

  const handleOnChange = (name, value) =>
    setValues({ ...values, [name]: value })

  const handleSubmit = async e => {
    e.preventDefault()
    await dispatch(
      registerUserRoutine({
        ...values,
        accreditations: values.accreditations.filter(item =>
          isNotNilOrEmpty(item.id)
        ),
        email: values.email.toLowerCase(),
        passwordConfirmation: values.password,
        ...referralToAdd
      })
    )
  }

  useEffect(() => {
    validateRegistrationValues(dissoc('phoneNumber', values), setIsValid)
  }, [values])

  const handleAccreditationChange = index => (name, value) => {
    const accreditation = values.accreditations[index]
    const newValue = {
      ...accreditation,
      [name]: value
    }
    setValues({
      ...values,
      accreditations: update(index, newValue, values.accreditations)
    })
  }

  const handleAccreditationRemove = index => () => {
    if (values.accreditations.length > 1) {
      setValues({
        ...values,
        accreditations: remove(index, 1, values.accreditations)
      })
    } else {
      setValues({
        ...values,
        accreditations: [
          {
            id: '',
            number: ''
          }
        ]
      })
    }
  }

  const handleAccreditationAdd = () => {
    setValues({
      ...values,
      accreditations: [
        ...values.accreditations,
        {
          id: '',
          number: ''
        }
      ]
    })
  }

  const roleDescription = useMemo(
    () => getRoleDescription(values.type),
    [values.type]
  )

  return isloggedin ? (
    <Navigate to={PRIVATE_PATHS.dashboard} />
  ) : (
    <PageWrapper>
      <BackButtonWrapper>
        <BackButton to={PATHS.login}>Back to Log in</BackButton>
      </BackButtonWrapper>
      <SignupWrapper>
        <HeaderText>
          Let’s create a safer
          <span>Built Environment</span>
        </HeaderText>
        <FormDescription>
          Join Building Passport now and let’s start making the world a safer
          place together.
        </FormDescription>
        <SignupForm onSubmit={handleSubmit}>
          {!isNotNilOrEmpty(currentInvitation) && (
            <>
              <Checkbox
                label='I am an emergency service worker'
                name='type'
                onChange={(name, value) =>
                  handleOnChange(name, value ? 'emergency_service' : 'user')
                }
              />
              {roleDescription && (
                <DescriptionWrapper>
                  <InputDescription>{roleDescription}</InputDescription>
                </DescriptionWrapper>
              )}
            </>
          )}
          <Input
            required
            value={values.email}
            label='Email'
            disabled={isNotNilOrEmpty(currentInvitation.email)}
            name='email'
            onChange={handleOnChange}
            validate={validateRegistrationField(values)}
          />
          <Input
            required
            withPasswordHint
            value={values.password}
            label='Password'
            type='password'
            name='password'
            onChange={handleOnChange}
            validate={validateRegistrationField(values)}
          />
          <SectionName>personal details</SectionName>
          <SectionDescription>
            After logging in you can change Privacy Settings in &quot;My
            Account&quot; section and choose whether your personal details are
            shown for the public in the Building Passport or not.
          </SectionDescription>
          <Input
            required
            value={values.firstName}
            label='First name'
            name='firstName'
            onChange={handleOnChange}
            validate={validateRegistrationField(values)}
          />
          <Input
            required
            value={values.lastName}
            label='Last name'
            name='lastName'
            onChange={handleOnChange}
            validate={validateRegistrationField(values)}
          />
          <PhoneField
            name='phoneNumber'
            label='Phone number'
            value={values.phoneNumber}
            onChange={handleOnChange}
            validate={valid => setPhoneValid(valid)}
          />
          {!isFrs && !isEmpty(values.type) && (
            <ExpandableAccreditations
              title='Accreditations / Qualifications'
              optional
            >
              <SectionDescription>
                Please add details of any professional accreditations or
                qualifications you have, along with corresponding membership
                numbers if appropriate. The quality and accuracy of the
                information you add to a Building Passport should be appropriate
                to the professional qualifications you hold.
              </SectionDescription>
              {values.accreditations && (
                <>
                  <InputDescription>
                    If you don&apos;t see your accreditation type on the list,
                    please{' '}
                    <a href='mailto:info@buildingpassport.com'>
                      contact the administrator.
                    </a>
                  </InputDescription>
                  <AccreditationsFormsWrapper>
                    {values.accreditations.map((item, index) => {
                      return (
                        <AccreditationForm
                          index={index}
                          key={`accreditation-form-${index}`}
                          values={values.accreditations[index]}
                          onChange={handleAccreditationChange}
                          options={options}
                          onRemove={handleAccreditationRemove}
                          withDelete={
                            !isEmpty(head(values.accreditations).id) ||
                            values.accreditations.length > 1
                          }
                        />
                      )
                    })}
                  </AccreditationsFormsWrapper>
                  <AddAnotherTypeButton onClick={handleAccreditationAdd}>
                    + Add another accreditation
                  </AddAnotherTypeButton>
                </>
              )}
            </ExpandableAccreditations>
          )}
          <SectionName>contact details</SectionName>
          <Input
            required={isFrs}
            optional={!isFrs}
            label={isFrs ? 'FRS Station' : 'Company'}
            name='company'
            value={values.company}
            onChange={handleOnChange}
            validate={validateRegistrationField(values)}
          />
          <Input
            required
            value={values.postalCode}
            label='Postal code'
            name='postalCode'
            onChange={handleOnChange}
            validate={validateRegistrationField(values)}
          />
          <Input
            required
            label='Address'
            value={values.street}
            name='street'
            onChange={handleOnChange}
            validate={validateRegistrationField(values)}
          />
          <Input
            required
            label='City'
            name='city'
            value={values.city}
            onChange={handleOnChange}
            validate={validateRegistrationField(values)}
          />
          <CheckboxWrapper>
            <Checkbox
              label='I agree to Building Passport Policy & Terms of use'
              name='disclaimer'
              onChange={handleOnChange}
            />
          </CheckboxWrapper>
          <CheckboxWrapper>
            <Checkbox
              label='I want to receive marketing promotions, special offers, inspiration and policy updates via email.'
              name='updates'
              onChange={handleOnChange}
            />
          </CheckboxWrapper>
          <ButtonWrapper>
            <FullWidthButton
              type='submit'
              disabled={!valid || !phoneValid || !accreditationsValid}
            >
              Create account
            </FullWidthButton>
          </ButtonWrapper>
        </SignupForm>
      </SignupWrapper>
    </PageWrapper>
  )
}

export default SignUp

const PageWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const SignupWrapper = styled.div`
  box-sizing: border-box;
  width: 645px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 50px 160px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(93, 117, 208, 0.1);
`

const FormDescription = styled.div`
  font-size: 16px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.haiti};
  opacity: 0.7;
  margin: 25px 0 30px;
`

const HeaderText = styled.h1`
  font-size: 38px;
  margin-bottom: 30px;

  span {
    display: block;
    font-weight: bold;
  }
`

const SignupForm = styled.form`
  box-sizing: border-box;
  width: 100%;
`

const ButtonWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  text-align: right;
  margin-top: 30px;
`

const CheckboxWrapper = styled.div`
  width: 100%;
  padding: 5px 0;
  line-height: 30px;

  a {
    text-decoration: underline;
  }
`

const BackButtonWrapper = styled.div`
  padding: 0 0 15px;
  width: 645px;
  margin: 0 auto;
`

const SectionName = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
  letter-spacing: 0.1px;
  margin: 15px 0;

  span {
    color: ${({ theme }) => theme.colors.grey[700]};
    text-transform: lowercase;
  }
`

const SectionDescription = styled.div`
  font-size: 12px;
  margin-bottom: 15px;
`

const AccreditationFormWrapper = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 15px;
  }
`

const AccreditationNumberWrapper = styled.div`
  background-color: rgba(247, 248, 253, 0.51);
  border: 1px solid #e9eff4;
  padding: 15px 15px 0;
`

const AddAnotherTypeButton = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 12px;
  font-weight: bold;
  width: fit-content;
  cursor: pointer;
  margin: 10px 0 20px auto;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.colors.grey[600]};
      cursor: default;
    `}
`

const AccreditationsFormsWrapper = styled.div`
  margin-top: 10px;
`

const DescriptionWrapper = styled.div`
  margin: 20px 0;
`
