const emptySessionState = {
  expiresAt: null,
  isExpiring: false,
  isExpired: false,
  warningDismissed: false,
  warningThresholdSeconds: 300, // Default value, will be overridden by server
  remainingSeconds: null,
  isLoading: false
}

export default emptySessionState
