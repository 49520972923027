import React, { useEffect, useMemo, useState } from 'react'
import { Collapse } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import styled from 'styled-components'
import UnitsTable from 'features/createBp/components/atoms/UnitsTable'
import { useDispatch, useSelector } from 'react-redux'
import { selectCreatedBp } from 'features/createBp/ducks/selectors'
import {
  addBpUnitsRoutine,
  clearSelectedUnitRoutine,
  removeBpUnitRoutine,
  updateBpUnitRoutine
} from 'ducks/units/actions'
import { selectBpUnits } from 'ducks/units/selectors'
import { hasMultipleUnits } from 'utils/units'

const UnitSection = ({ open, section, toggleOpen, changeSectionValues }) => {
  const [units, setUnits] = useState([])
  const createdBp = useSelector(selectCreatedBp)
  const dispatch = useDispatch()
  const bpUnits = useSelector(selectBpUnits)
  const isSoleResidential = section.buildingType === 'residential.dwelling.sole_unit'

  useEffect(() => {
    changeSectionValues(units)
  }, [units])

  const isSoleUnit = useMemo(() => {
    if (section) {
      return !hasMultipleUnits(section.buildingType)
    } else {
      return false
    }
  }, [section])

  useEffect(() => {
    const typeUnits = bpUnits.filter(unit => unit.buildingType === section.buildingType)
    setUnits(typeUnits)
  }, [bpUnits])

  const handleUnitEdit = newUnit => {
    dispatch(updateBpUnitRoutine({
      ...newUnit,
      bpId: newUnit.buildingPassportId,
      isCreationFlow: true
    }))
    dispatch(clearSelectedUnitRoutine())
  }

  const handleUnitRemove = values => {
    dispatch(removeBpUnitRoutine({
      ...values,
      bpId: createdBp.id,
      isWizard: true
    }))
  }

  const handleAddUnit = () => {
    dispatch(addBpUnitsRoutine({
      buildingType: section.buildingType,
      amount: 1,
      bpId: createdBp.id,
      isWizard: true
    }))
  }

  return (
    <UnitSectionWrapper>
      <UnitSectionHeader onClick={toggleOpen}>
        <UnitSectionTitle>
          <div>
            {section.name}
            {
              section.multi && (
                <UnitSectionUnitsNumber>
                  ({units.length})
                </UnitSectionUnitsNumber>
              )
            }
          </div>
          {
            open
              ? <KeyboardArrowUpIcon />
              : <KeyboardArrowDownIcon />
          }
        </UnitSectionTitle>
      </UnitSectionHeader>
      <Collapse in={open}>
        <UnitSectionUnitsHeader>
          <div>{units.length} {units.length === 1 ? 'unit' : 'units'}</div>
        </UnitSectionUnitsHeader>
        {units && (
          <UnitsTable
            bp={createdBp}
            units={units}
            handleUnitEdit={handleUnitEdit}
            handleUnitRemove={handleUnitRemove}
            isSoleUnit={isSoleUnit}
            isSoleResidential={isSoleResidential}
          />
        )}
        {
          !isSoleResidential && (
            <AddIconWrapper onClick={handleAddUnit}>
              <AddUnitIcon>
                +
              </AddUnitIcon>
              <span>Add one unit</span>
            </AddIconWrapper>
          )
        }
      </Collapse>
    </UnitSectionWrapper>
  )
}

export default UnitSection

const UnitSectionWrapper = styled.div`
  margin-bottom: 30px;
`

const UnitSectionHeader = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[500]};
  margin-bottom: 10px;
  cursor: pointer;
`

const UnitSectionTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const UnitSectionUnitsNumber = styled.span`
  color: ${({ theme }) => theme.colors.grey[700]};
  font-size: 18px;
  display: inline-block;
  margin-left: 5px;
  font-weight: normal;
`

const UnitSectionUnitsHeader = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grey[800]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin-bottom: 10px;
`

const AddIconWrapper = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.secondary};
  width: fit-content;

  span {
    transition: all .3s;
    display: block;
    margin-left: 5px;
    opacity: 0;
    text-transform: uppercase;
    font-size: 14px;
  }

  &:hover {
    span {
      opacity: 1;
    }
  }
`

const AddUnitIcon = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 30px;
  font-weight: bold;
  width: fit-content;
  cursor: pointer;
`
