import React from 'react'
import ReactDOM from 'react-dom/client'
import { mergeDeepRight } from 'ramda'
import axios from 'axios'
import applyConverters from 'axios-case-converter'
import LocalStorageService from 'services/LocalStorageService'
import { KEYS } from 'utils/localStorage'
import { PATHS } from 'utils/paths'
import styled from 'styled-components'

const apiUrl = process.env.REACT_APP_API_URL

export const mergeConfig = config => {
  const authToken = LocalStorageService.get(KEYS.token)
  const authHeader = authToken
    ? {
        Authorization: authToken.includes('Bearer')
          ? authToken
          : `Bearer ${authToken}`
      }
    : {}

  const defaultConfig = {
    headers: {
      ...authHeader
    }
  }
  return mergeDeepRight(defaultConfig)(config)
}

const get =
  instance =>
  async (url, config = {}) => {
    return instance.get(url, mergeConfig(config))
  }

const post =
  instance =>
  async (url, data = {}, config = {}) => {
    return instance.post(url, data, mergeConfig(config))
  }

const put =
  instance =>
  async (url, data = {}, config = {}) => {
    return instance.put(url, data, mergeConfig(config))
  }

const patch =
  instance =>
  async (url, data = {}, config = {}) => {
    return instance.patch(url, data, mergeConfig(config))
  }

const handleDelete =
  instance =>
  async (url, data = {}, config = {}) => {
    return instance.delete(
      url,
      mergeConfig(data ? { ...config, data } : config)
    )
  }

export default (baseURL = apiUrl) => {
  const instance = applyConverters(
    axios.create({
      baseURL,
      withCredentials: true,
      withXSRFToken: true,
      xsrfCookieName: 'XSRF-TOKEN',
      xsrfHeaderName: 'X-XSRF-TOKEN'
    }),
    {
      preservedKeys: ['_method']
    }
  )

  instance.interceptors.response.use(
    response => {
      return response
    },
    error => {
      const { response } = error

      const isAuthenticationRequest =
        error.config &&
        (error.config.url.includes('/login') ||
          error.config.url.includes('/broadcasting/auth'))

      if (
        response &&
        [401, 419].includes(response.status) &&
        !isAuthenticationRequest
      ) {
        const currentPathname = window.location.pathname

        const allowedPaths = [
          PATHS.login,
          PATHS.loginCallback,
          PATHS.requestPasswordReset,
          PATHS.passwordReset,
          PATHS.emailVerification,
          PATHS.signup,
          PATHS.signupConfirm
        ]

        if (!allowedPaths.some(el => el.includes(currentPathname))) {
          LocalStorageService.set(KEYS.redirectPath, currentPathname)
          LocalStorageService.remove(KEYS.token)
        }
      }

      return Promise.reject(error)
    }
  )

  return {
    get: get(instance),
    post: post(instance),
    put: put(instance),
    patch: patch(instance),
    delete: handleDelete(instance)
  }
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999999999999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f2f2f2;
`

const InfoWrapper = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`

const Header = styled.div`
  color: black;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
`

const GoBackButton = styled.button`
  background: #33becc;
  padding: 10px 20px;
  color: #fff;
  outline: none;
  border: none;
  border-radius: 4px;
`
