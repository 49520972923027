import React, {useCallback, useEffect, useMemo, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isNil, pathOr, propOr, last } from 'ramda'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import { canEditBp } from 'utils/user'
import moment from 'moment'
import { removeUnitFileRoutine } from 'ducks/units/actions'
import { removeFileRoutine, updateFileRoutine } from 'ducks/files/actions'
import { ReactComponent as FileIcon } from 'assets/images/file-icon.svg'
import { formatDate } from 'utils/date'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import UnshareFileModal from 'features/bpDetails/Components/UnshareFileModal'
import { Collapse } from '@mui/material'
import ManageFileVersionsModal from 'features/bpDetails/Components/ManageFileVersionsModal'
import Modal from 'components/atoms/Modal'
import EditPrivateFileModal from 'features/bpDetails/Components/EditPrivateFileModal'
import EditFileModal from 'features/bpDetails/Components/EditFileModal'
import { getFloorOptions } from 'utils/bpData'
import ShareFileModal from 'features/bpDetails/Components/ShareFileModal'
import PdfFullScreenViewer from 'components/PdfFullScreenViewer'
import styled, { css } from 'styled-components'
import ShareIcon from '@mui/icons-material/Share'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import AddNewFileVersionModal from 'features/bpDetails/Components/AddNewFileVersionModal'
import { isNotNilOrEmpty } from 'utils/ramda'
import AddUsersToFile from 'features/bpDetails/Components/files/AddUsersToFile'
import AttachTagToFile from 'features/bpDetails/Components/files/AttachTagToFile'
import { AddButton } from 'features/bpDetails/BpDetails'
import FileItemTag from 'features/bpDetails/Components/files/FileItemTag'
import ImageViewer from 'components/ImageViewer'
import { markFileAsViewed } from 'services/fileService'
import ViewedStatusModal from 'features/bpDetails/Components/files/ViewedStatusModal'
import { markFileAsSearchedRoutine } from 'features/bpDetails/ducks/actions'

const File = ({
  file,
  type,
  isUnit,
  isShared,
  unit,
  isSharedWithUnits,
  canEditUnit,
  isPrivate,
  isInSearchBar,
  viewMode = 'list',
  showThumbnail
}) => {
  const { uploader, expirationDate, displayName } = file
  const [anchorEl, setAnchorEl] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [shareModalOpen, setShareModalOpen] = useState(false)
  const [newVersionModalOpen, setNewVersionModalOpen] = useState(false)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()
  const hasExpirationDate = useMemo(
    () => !isNil(expirationDate),
    [expirationDate]
  )
  const [pdfViewerOpen, setPdfViewerOpen] = useState(false)
  const [imageViewerOpen, setImageViewerOpen] = useState(false)
  const bp = useSelector(getSelectedBp)
  const currentUser = useSelector(getCurrentUser)
  const isFloorPlan = pathOr('', ['fileType', 'name'], file) === 'Building plan'
  const canBeAccessed = file?.canBeAccessed || false

  const latestVersion = useMemo(() => {
    return propOr([], 'versions', file).length
  }, [file])

  const latestVersionCreationDate = useMemo(() => {
    const versions = propOr([], 'versions', file).sort((a, b) =>
      a.createdAt.localeCompare(b.createdAt)
    )

    return last(versions)?.createdAt || null
  }, [file])

  const [collapsed, setCollapsed] = useState(false)
  const toggleCollapse = () => setCollapsed(prev => !prev)

  const handlePdfViewerClose = () => setPdfViewerOpen(false)
  const handleImageViewerClose = () => setImageViewerOpen(false)
  const canEdit =
    canEditBp(currentUser, bp) || (isUnit && canEditUnit) || isPrivate

  const isExpired = useMemo(() => {
    return hasExpirationDate
      ? moment(expirationDate).isSameOrBefore(moment())
      : false
  }, [hasExpirationDate])

  // useEffect(() => {
  //   if (modalOpen || editModalOpen || shareModalOpen || newVersionModalOpen) {
  //     const timeout = setTimeout(() => {
  //       setAnchorEl(null)
  //     }, 10) // Așteaptă 100ms înainte să închidă meniul
  //
  //     return () => clearTimeout(timeout) // Curăță timeout-ul la unmount
  //   }
  // }, [modalOpen, editModalOpen, shareModalOpen, newVersionModalOpen])

  const openMenu = e => {
    e.stopPropagation()
    e.preventDefault()
    setAnchorEl(null)
    setAnchorEl(e.currentTarget)
  }
  const handleClose = e => {
    e.stopPropagation()
    e.preventDefault()
    setAnchorEl(null)
  }

  const handleModalOpen = e => {
    e.stopPropagation()
    e.preventDefault()
    setAnchorEl(null)
    setModalOpen(true)
  }
  const handleModalClose = () => setModalOpen(false)

  const handleEditModalOpen = e => {
    e.stopPropagation()
    e.preventDefault()
    setAnchorEl(null)
    setEditModalOpen(true)
  }
  const handleEditModalClose = () => setEditModalOpen(false)

  const handleShareModalOpen = e => {
    e.stopPropagation()
    e.preventDefault()
    setAnchorEl(null)
    setShareModalOpen(true)
  }
  const handleShareModalClose = () => setShareModalOpen(false)

  const handleRemoveFile = useCallback(() => {
    if (isUnit) {
      dispatch(
        removeUnitFileRoutine({
          bpId: bp.id,
          unitId: unit.id,
          fileId: file.id
        })
      )
    } else {
      dispatch(removeFileRoutine({ id: file.id, bpId: bp.id }))
    }
    handleModalClose()
  }, [file])

  const handlePreviewFile = e => {
    e.stopPropagation()
    e.preventDefault()
    setAnchorEl(null)
    if (isInSearchBar) {
      dispatch(markFileAsSearchedRoutine({ fileId: file.id }))
    }
    if (/\.pdf/.test(file.url.toLowerCase())) {
      setPdfViewerOpen(true)
    } else if (
      /\.jpg|\.jpeg|\.png|\.svg|\.webp|\.gif/.test(file.url.toLowerCase())
    ) {
      setImageViewerOpen(true)
    } else {
      window.open(file.url, '_blank')
    }
  }

  const handleDownloadFile = url => e => {
    e.stopPropagation()
    e.preventDefault()
    setAnchorEl(null)
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.target = '_blank'
    anchor.download = file.displayName
    anchor.click()

    const fileId = file.id
    const fileVersionId = pathOr('', ['versions', 0, 'id'], file)

    markFileAsViewed({
      fileId,
      fileVersionId,
      type: 'download'
    })
  }

  const handleNewVersionModalOpen = () => {
    setAnchorEl(null)
    setNewVersionModalOpen(true)
  }

  const handleNewVersionModalClose = () => {
    setAnchorEl(null)
    setNewVersionModalOpen(false)
  }

  const originalUrl = propOr('', 'originalUrl', file)
  const hasOriginalUrl = isNotNilOrEmpty(originalUrl)

  const handleSavePermissions = selectedUsers => {
    if (isNotNilOrEmpty(selectedUsers)) {
      const fileVersionId = pathOr('', ['versions', 0, 'id'], file)
      dispatch(
        updateFileRoutine({
          id: file.id,
          bpId: bp.id,
          fileVersionId,
          body: { userIds: selectedUsers }
        })
      )
    }
  }

  // Rendering the file menu options
  const renderFileMenu = () => (
    <Menu
      keepMounted
      getContentAnchorEl={null}
      anchorEl={anchorEl}
      style={{
        marginTop: '10px'
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      open={open}
      onClose={handleClose}
    >
      <MenuItem onClick={handlePreviewFile}>Preview</MenuItem>
      <MenuItem onClick={handleDownloadFile(file.url)}>
        {hasOriginalUrl ? 'Download PDF' : 'Download'}
      </MenuItem>
      {hasOriginalUrl && (
        <MenuItem onClick={handleDownloadFile(originalUrl)}>
          Download original file
        </MenuItem>
      )}
      {!isShared && canEdit && (
        <MenuItem onClick={handleEditModalOpen}>Edit file details</MenuItem>
      )}
      {canEdit && (
        <MenuItem onClick={handleNewVersionModalOpen}>Add new version</MenuItem>
      )}
      {canEdit && (
        <AddUsersToFile
          initiallySelected={file?.users || []}
          onSave={handleSavePermissions}
          savedCount={file?.users?.length || 0}
        >
          <MenuItem>Manage permissions</MenuItem>
        </AddUsersToFile>
      )}
      {canEdit && (
        <ViewedStatusModal file={file}>
          <MenuItem>Viewed status</MenuItem>
        </ViewedStatusModal>
      )}
      {!isUnit && (
        <MenuItem disabled={isFloorPlan} onClick={handleShareModalOpen}>
          Share
        </MenuItem>
      )}
      {!isUnit && <UnshareFileModal bp={bp} file={file} />}
      {!isShared && <MenuItem onClick={handleModalOpen}>Remove file</MenuItem>}
    </Menu>
  )

  // Rendering the file tags
  const renderFileTags = () => (
    <FileTagsBox viewMode={viewMode} onClick={e => e.stopPropagation()}>
      {isNotNilOrEmpty(file.taggings) ? (
        <>
          {file.taggings.map(tag => (
            <FileItemTag
              key={`${file.id}-${tag.id}`}
              tag={tag}
              disabled={!canBeAccessed}
            />
          ))}
          {canBeAccessed && (
            <AttachTagToFile file={file}>
              <PlusButton>+</PlusButton>
            </AttachTagToFile>
          )}
        </>
      ) : (
        <TagsEmptyState>
          {canBeAccessed ? (
            <AttachTagToFile file={file}>
              <AddTagButton>+ Add tags</AddTagButton>
            </AttachTagToFile>
          ) : (
            <NoTagsLabel>No tags</NoTagsLabel>
          )}
        </TagsEmptyState>
      )}
    </FileTagsBox>
  )

  // Rendering the file versions section
  const renderFileVersions = () => (
    <FileVersionsBox viewMode={viewMode} onClick={e => e.stopPropagation()}>
      <FileVersionsBoxHeader onClick={toggleCollapse}>
        {collapsed ? 'Hide file versions' : 'Show file versions'}
        <ChevronIcon collapsed={collapsed} />
      </FileVersionsBoxHeader>
      <Collapse in={collapsed} onClick={e => e.stopPropagation()}>
        <ManageFileVersionsModal file={file} unit={unit} />
      </Collapse>
    </FileVersionsBox>
  )

  // Main render method
  return (
    <>
      <FileContainer viewMode={viewMode}>
        {viewMode === 'grid' ? (
          // Grid view layout
          <>
            <FileCardWrapper
              canBeAccessed={canBeAccessed}
              isExpired={isExpired}
              hasThumbnail={showThumbnail}
              onClick={canBeAccessed ? handlePreviewFile : () => {}}
            >
              <ActionIconsContainer>
                {isSharedWithUnits && <SharedIcon />}
                <IconWrapper onClick={e => e.stopPropagation()}>
                  <MoreIcon onClick={canBeAccessed ? openMenu : () => {}} />
                </IconWrapper>
              </ActionIconsContainer>

              <FileCardContent>
                {showThumbnail && (
                  <ThumbnailContainer>
                    {file.thumbnailUrl || file.url ? (
                      <FileThumbnail
                        src={file.thumbnailUrl || file.url}
                        alt={displayName}
                        objectFit={
                          file.contentType?.includes('pdf')
                            ? 'contain'
                            : 'cover'
                        }
                        onError={e => {
                          // Create a fallback placeholder element
                          const placeholder = document.createElement('div')
                          placeholder.style.display = 'flex'
                          placeholder.style.flexDirection = 'column'
                          placeholder.style.alignItems = 'center'
                          placeholder.style.justifyContent = 'center'
                          placeholder.style.width = '100%'
                          placeholder.style.height = '100%'
                          placeholder.style.backgroundColor = '#f5f5f5'

                          placeholder.innerHTML = `
                            <svg width="48" height="48" viewBox="0 0 24 24">
                              <path fill="#666" d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M18,20H6V4H13V9H18V20Z" />
                            </svg>
                            <div style="font-size:12px;color:#666;">${displayName}</div>
                          `

                          if (e.target && e.target.parentNode) {
                            // Hide the original image
                            e.target.style.display = 'none'
                            // Insert the placeholder
                            e.target.parentNode.appendChild(placeholder)
                          }
                        }}
                      />
                    ) : (
                      <PlaceholderThumbnail>
                        <FileIcon />
                        <div>{displayName}</div>
                      </PlaceholderThumbnail>
                    )}
                  </ThumbnailContainer>
                )}
                <CardFileName>{displayName}</CardFileName>
                <FileTypeLabel>{type}</FileTypeLabel>

                {hasExpirationDate && (
                  <ExpiryDate isExpired={isExpired}>
                    {isExpired ? 'Expired' : 'Expires'}:{' '}
                    {formatDate(expirationDate)}
                  </ExpiryDate>
                )}

                <UploaderInfo>
                  {uploader && (
                    <>
                      By: {propOr('', 'firstName', uploader)}{' '}
                      {propOr('', 'lastName', uploader)}
                    </>
                  )}
                </UploaderInfo>

                <VersionInfo>
                  Version {latestVersion} •{' '}
                  {formatDate(latestVersionCreationDate || file.createdAt)}
                </VersionInfo>
              </FileCardContent>

              {!isInSearchBar && (
                <FileCardFooter>
                  {renderFileTags()}
                  {renderFileVersions()}
                </FileCardFooter>
              )}
            </FileCardWrapper>
          </>
        ) : (
          // List view layout
          <>
            <FileWrapper
              key={file.id}
              id={file.id}
              canBeAccessed={canBeAccessed}
              isExpired={isExpired}
              onClick={canBeAccessed ? handlePreviewFile : () => {}}
            >
              <NameWrapper>
                <FileIconWrapper>
                  <FileIcon />
                </FileIconWrapper>
                <FileName>{displayName}</FileName>
                {isInSearchBar ? (
                  <TopTag>{file.buildingPassport?.name}</TopTag>
                ) : (
                  <TopTag>Version {latestVersion}</TopTag>
                )}
              </NameWrapper>
              {isSharedWithUnits && <SharedIcon />}
              <FileDetailsWrapper>
                <DetailsWrapper>
                  <Details>
                    {type && <FileType>{type}</FileType>}
                    <p>
                      {hasExpirationDate && (
                        <ExpiryDate isExpired={isExpired}>
                          {isExpired ? `Expired at:` : `Expiry date:`}&nbsp;
                          {formatDate(expirationDate)}
                        </ExpiryDate>
                      )}
                      {latestVersionCreationDate && !isUnit && (
                        <span>
                          Upload&nbsp;date:&nbsp;
                          {formatDate(latestVersionCreationDate)}
                        </span>
                      )}
                      {isUnit && (
                        <span>
                          Upload&nbsp;date:&nbsp;{formatDate(file.createdAt)}
                        </span>
                      )}
                    </p>
                    {uploader && (
                      <p>
                        Upload by:{' '}
                        {propOr('', 'firstName', uploader) +
                          ' ' +
                          propOr('', 'lastName', uploader)}
                      </p>
                    )}
                  </Details>
                </DetailsWrapper>
                <IconWrapper onClick={e => e.stopPropagation()}>
                  <MoreIcon onClick={canBeAccessed ? openMenu : () => {}} />
                </IconWrapper>
              </FileDetailsWrapper>
            </FileWrapper>

            {!isInSearchBar && (
              <>
                {renderFileTags()}
                {renderFileVersions()}
              </>
            )}
          </>
        )}

        {renderFileMenu()}
      </FileContainer>

      {/* Modals */}
      <AddNewFileVersionModal
        unit={unit}
        file={file}
        open={newVersionModalOpen}
        handleClose={handleNewVersionModalClose}
      />
      <Modal
        open={modalOpen}
        title='Remove file'
        onClose={handleModalClose}
        onCancel={handleModalClose}
        onSubmit={handleRemoveFile}
        withSubmit
        withCancel
      >
        Do you really want to remove this file?
      </Modal>
      {file.isPrivate ? (
        <EditPrivateFileModal
          open={editModalOpen}
          unit={unit}
          onClose={handleEditModalClose}
          file={file}
        />
      ) : (
        <EditFileModal
          open={editModalOpen}
          isUnit={isUnit}
          unit={unit}
          onClose={handleEditModalClose}
          floorOptions={getFloorOptions(bp)}
          file={file}
        />
      )}
      <ShareFileModal
        open={shareModalOpen}
        onClose={handleShareModalClose}
        file={file}
      />
      <PdfFullScreenViewer
        open={pdfViewerOpen}
        onClose={handlePdfViewerClose}
        file={file}
      />
      <ImageViewer
        open={imageViewerOpen}
        onClose={handleImageViewerClose}
        file={file}
      />
    </>
  )
}

export default File

// Container to wrap both view modes
const FileContainer = styled.div`
  margin-bottom: ${props => (props.viewMode === 'grid' ? '0' : '15px')};
  width: 100%;
`

// Grid view styled components
const FileCardWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  border-radius: 4px;
  overflow: hidden;
  height: 100%;
  cursor: ${({ canBeAccessed }) => (canBeAccessed ? 'pointer' : 'default')};
  opacity: ${({ isExpired }) => (isExpired ? 0.7 : 1)};
  background-color: white;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);

  &:hover {
    border-color: ${({ theme, canBeAccessed }) =>
      canBeAccessed ? theme.colors.secondary : theme.colors.grey[400]};
    box-shadow: ${({ canBeAccessed }) =>
      canBeAccessed
        ? '0 4px 12px rgba(0, 0, 0, 0.1)'
        : '0 1px 3px rgba(0, 0, 0, 0.08)'};
    transform: ${({ canBeAccessed }) =>
      canBeAccessed ? 'translateY(-2px)' : 'none'};
  }

  ${({ canBeAccessed }) =>
    !canBeAccessed &&
    css`
      color: ${({ theme }) => theme.colors.grey[600]};
      svg {
        cursor: default;
      }
      & div {
        color: ${({ theme }) => theme.colors.grey[600]};
      }
    `}
`

const FileCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: ${({ theme }) => theme.colors.lighterGrey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[500]};
`

const FileCardContent = styled.div`
  padding: 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const CardFileName = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.haiti};
  margin-bottom: 8px;
  line-height: 1.3;
  word-break: break-word;
  height: 42px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const FileTypeLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 6px;
  padding: 3px 0;
`

const UploaderInfo = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.grey[100]};
  margin-top: 12px;
  display: flex;
  align-items: center;
`

const VersionInfo = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.grey[100]};
  padding-top: 12px;
  border-top: 1px solid ${({ theme }) => theme.colors.grey[600]};
  margin-top: 8px;
`

const FileCardFooter = styled.div`
  background-color: ${({ theme }) => theme.colors.lighterGrey};
  border-top: 1px solid ${({ theme }) => theme.colors.grey[500]};
`

const ExpiryDate = styled.div`
  display: inline-block;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: ${({ theme, isExpired }) =>
    isExpired ? theme.colors.error + '18' : theme.colors.secondary + '15'};
  color: ${({ theme, isExpired }) =>
    isExpired ? theme.colors.error : theme.colors.secondary};
  font-weight: 500;
`

const SharedIcon = styled(ShareIcon)`
  color: ${({ theme }) => theme.colors.secondary};
  top: 1px;
  right: 1px;
  font-size: 10px;
`

const FileVersionsBox = styled.div`
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.lighterGrey};
  margin-bottom: ${props => (props.viewMode === 'grid' ? '0' : '15px')};
  border: ${props =>
    props.viewMode === 'grid'
      ? 'none'
      : `1px solid ${props.theme.colors.grey[500]}`};
  border-radius: ${props =>
    props.viewMode === 'grid' ? '0 0 8px 8px' : '0 0 4px 4px'};
`

const FileVersionsBoxHeader = styled.div`
  width: 100%;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ChevronIcon = styled(KeyboardArrowDownIcon)`
  font-size: 14px !important;
  margin-left: 3px;
  color: ${({ theme }) => theme.colors.haiti};
  transform: rotate(${({ collapsed }) => (collapsed ? '180deg' : '0')});
`

const TopTag = styled.div`
  position: absolute;
  top: -10px;
  left: 5px;
  padding: 2px 5px;
  font-size: 11px;
  background-color: #fff;
  color: ${({ theme }) => theme.colors.darkGrey};
`

const DetailsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  box-sizing: border-box;
`

const Details = styled.div`
  font-size: 11px;
  text-align: right;
  color: ${({ theme }) => theme.colors.black};
`

const FileType = styled.div`
  font-weight: bold;
`

const MoreIcon = styled(MoreVertIcon)`
  color: ${({ theme }) => theme.colors.grey[600]};
  cursor: pointer;
  font-size: 24px !important;
  transition: color 0.2s;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`

const FileIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;

  svg {
    width: 24px;
    height: 24px;
  }
`

const FileDetailsWrapper = styled.div`
  box-sizing: border-box;
  justify-content: flex-end;
  display: flex;
  max-width: 60%;
`

const FileName = styled.div`
  margin-left: 15px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.haiti};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
`

const FileWrapper = styled.div`
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
  height: 65px;
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px 4px 0 0;
  transition: all 0.3s;
  opacity: ${({ isExpired }) => (isExpired ? 0.6 : 1)};
  background-color: ${({ isExpired, theme }) =>
    isExpired ? theme.colors.grey[400] : 'transparent'};
  position: relative;

  &:hover {
    border-color: ${({ theme }) => theme.colors.secondary};
  }

  ${({ canBeAccessed }) =>
    !canBeAccessed &&
    css`
      color: ${({ theme }) => theme.colors.grey[700]} !important;
      cursor: default !important;

      svg {
        cursor: default !important;
      }

      & div {
        color: ${({ theme }) => theme.colors.grey[700]} !important;
      }
      &:hover {
        border-color: ${({ theme }) => theme.colors.grey[700]};
      }
    `}
`

const NameWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 15px;
  max-width: 300px;
  overflow: hidden;

  @media (min-width: 1300px) {
    max-width: 500px;
  }
`

const FileTagsBox = styled.div`
  padding: ${props => (props.viewMode === 'grid' ? '10px 12px' : '5px 10px')};
  display: flex;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
  border-bottom: ${props =>
    props.viewMode === 'grid'
      ? `1px solid ${props.theme.colors.grey[500]}`
      : 'none'};
  border-left: ${props =>
    props.viewMode === 'grid'
      ? 'none'
      : `1px solid ${props.theme.colors.grey[500]}`};
  border-right: ${props =>
    props.viewMode === 'grid'
      ? 'none'
      : `1px solid ${props.theme.colors.grey[500]}`};
  background-color: ${props =>
    props.viewMode === 'grid' ? props.theme.colors.lighterGrey : 'transparent'};
  min-height: ${props => (props.viewMode === 'grid' ? '32px' : '20px')};
`

const TagsLabel = styled.div`
  font-size: 12px;
  text-transform: uppercase;
`
const TagsEmptyState = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4px 0;
`

const NoTagsLabel = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.secondary};
  font-style: italic;
`

const AddTagButton = styled.button`
  background: none;
  border: 1px solid ${({ theme }) => theme.colors.grey[400]};
  border-radius: 4px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[200]};
    color: ${({ theme }) => theme.colors.secondary};
    border-color: ${({ theme }) => theme.colors.grey[500]};
  }
`

const PlusButton = styled.button`
  background: none;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  padding: 0;
  line-height: 1;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[200]};
    color: ${({ theme }) => theme.colors.haiti};
  }
`
const ThumbnailContainer = styled.div`
  width: 100%;
  height: 160px;
  background-color: ${({ theme }) => theme.colors.lighterGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[500]};
`

const FileThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: ${props => props.objectFit || 'cover'};
  transition: transform 0.3s ease;
`

const PlaceholderThumbnail = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.grey[100]};
  color: white;

  svg {
    width: 48px;
    height: 48px;
    margin-bottom: 8px;
  }
`
const ActionIconsContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 2;

  ${SharedIcon} {
    margin-right: 4px;
  }
`
