import React, { useEffect, useMemo, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserFirstName } from 'features/auth/ducks/selectors'
import PageHeader from 'components/atoms/PageHeader'
import styled from 'styled-components'
import ProfileInfo from 'features/profileSettings/components/ProfileInfo'
import { isNotNilOrEmpty } from 'utils/ramda'
import BpBox from 'features/dashboard/components/BpBox'
import { getBuildingTypes, getUserBps } from 'features/bpList/ducks/selectors'
import { fetchUserBpsRoutine } from 'features/bpList/ducks/actions'
import { isEmpty, propOr, values } from 'ramda'
import TemplateBox from 'features/dashboard/components/TemplateBox'
import DashboardEmptyState from 'features/dashboard/components/DashboardEmptyState'
import {
  getDashboardStats,
  getLastViewedBps
} from 'features/dashboard/ducks/selectors'
import {
  fetchDashboardStatisticsRoutine,
  fetchLastViewedBpsRoutine
} from 'features/dashboard/ducks/actions'

const SectionTitle = ({ children }) => {
  return (
    <SectionTitleWrapper>
      <SectionTitleText>{children}</SectionTitleText>
      <SectionUnderline />
    </SectionTitleWrapper>
  )
}

const Dashboard = () => {
  const currentUserName = useSelector(getUserFirstName)
  const dashboardStats = useSelector(getDashboardStats)
  const bps = useSelector(getUserBps)
  const lastViewed = useSelector(getLastViewedBps)
  const buildingTypes = useSelector(getBuildingTypes)
  const dispatch = useDispatch()
  const dataFetchedRef = useRef(false)

  // Improved template calculation with better categorization logic
  const templatesStats = useMemo(() => {
    const templates = propOr({}, 'analyticsTemplates', dashboardStats)
    if (isNotNilOrEmpty(templates)) {
      return values(templates).map(template => {
        const ranges = { 1: [], 2: [], 3: [], 4: [], 5: [] }

        propOr([], 'percentageValues', template).forEach(percentage => {
          // More efficient range checking
          if (percentage < 20) ranges[1].push(percentage)
          else if (percentage < 50) ranges[2].push(percentage)
          else if (percentage < 75) ranges[3].push(percentage)
          else if (percentage < 100) ranges[4].push(percentage)
          else ranges[5].push(percentage)
        })

        return { ...template, percentageValues: ranges }
      })
    }
    return []
  }, [dashboardStats])

  // Only fetch data once when component mounts
  useEffect(() => {
    if (!dataFetchedRef.current) {
      // Fetch data in parallel
      Promise.all([
        dispatch(fetchLastViewedBpsRoutine()),
        dispatch(fetchDashboardStatisticsRoutine()),
        dispatch(
          fetchUserBpsRoutine({
            page: 1,
            sort: '-created_at'
          })
        )
      ])

      dataFetchedRef.current = true
    }
  }, [])

  // Memoize BpBox components to prevent unnecessary re-renders
  const renderBpBoxes = useCallback(
    bpList => {
      return bpList.map(bp => (
        <BpBox key={bp.id} bp={bp} buildingTypes={buildingTypes} />
      ))
    },
    [buildingTypes]
  )

  const recentlyAddedBps = useMemo(
    () =>
      isNotNilOrEmpty(bps) ? (
        renderBpBoxes(bps)
      ) : (
        <DashboardEmptyState style={{ margin: '0 auto' }} />
      ),
    [bps, renderBpBoxes]
  )

  const lastViewedBps = useMemo(
    () => (isNotNilOrEmpty(lastViewed) ? renderBpBoxes(lastViewed) : null),
    [lastViewed, renderBpBoxes]
  )

  return (
    <PageWrapper>
      <ContentWrapper>
        <PageHeaderWrapper>
          <WelcomeHeader>
            <PageHeader>Welcome back, {currentUserName}!</PageHeader>
            <StatusIndicator />
          </WelcomeHeader>
        </PageHeaderWrapper>

        {!isEmpty(templatesStats) && (
          <Section>
            <SectionTitle>Analytics summary</SectionTitle>
            <BpsList>
              <LegendCard>
                <LegendLabel>Legend</LegendLabel>
                <LegendItems>
                  <ColorItem>
                    <ColorBox color='#33becc' /> 100%
                  </ColorItem>
                  <ColorItem>
                    <ColorBox color='#BAEAEF' /> 75 - 99%
                  </ColorItem>
                  <ColorItem>
                    <ColorBox color='#ffaf7e' /> 50 - 74%
                  </ColorItem>
                  <ColorItem>
                    <ColorBox color='#ff836b' /> 20 - 49%
                  </ColorItem>
                  <ColorItem>
                    <ColorBox color='#cc2200' /> 0 - 20%
                  </ColorItem>
                </LegendItems>
              </LegendCard>

              {templatesStats.map((template, index) => (
                <TemplateBox key={`template-${index}`} template={template} />
              ))}
            </BpsList>
          </Section>
        )}

        <Section>
          <SectionTitle>Recently added</SectionTitle>
          <BpsList>{recentlyAddedBps}</BpsList>
        </Section>

        {isNotNilOrEmpty(lastViewed) && (
          <Section>
            <SectionTitle>Last viewed</SectionTitle>
            <BpsList>{lastViewedBps}</BpsList>
          </Section>
        )}
      </ContentWrapper>

      <DetailsWrapper>
        <ProfileInfo />
      </DetailsWrapper>
    </PageWrapper>
  )
}

export default Dashboard

const PageWrapper = styled.div`
  display: flex;
`

const ContentWrapper = styled.div`
  width: 100%;
  padding-right: 300px;
  overflow: hidden;
`

const Section = styled.div`
  margin-bottom: 40px;
  animation: fadeInUp 0.5s ease-out;
  animation-fill-mode: both;

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &:nth-child(2) {
    animation-delay: 0.1s;
  }
  &:nth-child(3) {
    animation-delay: 0.2s;
  }
  &:nth-child(4) {
    animation-delay: 0.3s;
  }
`

const LegendCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 25px;
  height: 210px;
  margin-right: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition:
    transform 0.2s,
    box-shadow 0.2s;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  }
`

const LegendItems = styled.div`
  display: flex;
  flex-direction: column;
`

const DetailsWrapper = styled.div`
  width: 280px;
  position: fixed;
  top: ${({ theme }) => theme.dimensions.topBarHeight};
  background-color: ${({ theme }) => theme.colors.white};
  right: 0;
  bottom: 0;
  backdrop-filter: blur(10px);
  padding: 25px 20px;
  overflow-y: auto;
  box-shadow: -4px 0 15px rgba(0, 0, 0, 0.05);
`

const SectionTitleWrapper = styled.div`
  margin: 30px 0 20px;
  display: flex;
  flex-direction: column;
`

const SectionTitleText = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.haiti};
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  &:before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 18px;
    background: ${({ theme }) => theme.colors.primary};
    margin-right: 10px;
    border-radius: 2px;
  }
`

const SectionUnderline = styled.div`
  width: 70px;
  height: 3px;
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.primary},
    ${({ theme }) => theme.colors.secondary}
  );
  border-radius: 3px;
  margin-left: 14px;
`

const BpsList = styled.div`
  box-sizing: border-box;
  display: flex;
  align-content: flex-start;
  min-height: 170px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 5px;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.primary}60;
    border-radius: 3px;
  }
`

const WelcomeHeader = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

const StatusIndicator = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary};
  box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.primary}30;
  margin-left: 15px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.primary};
    animation: pulse 2s infinite;

    @keyframes pulse {
      0% {
        transform: scale(1);
        opacity: 1;
      }
      70% {
        transform: scale(2.5);
        opacity: 0;
      }
      100% {
        transform: scale(1);
        opacity: 0;
      }
    }
  }
`

const PageHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`

const ColorItem = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 0;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.grey[800]};
  width: 100px;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey[200]};
  }
`

const ColorBox = styled.div`
  height: 8px;
  width: 8px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  margin-right: 10px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.05);
`

const LegendLabel = styled.div`
  font-size: 15px;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.colors.haiti};
  font-weight: 600;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[200]};
  padding-bottom: 8px;
`
