/* eslint-disable */
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useTable } from 'react-table'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { isEmpty } from 'ramda'
import NextButton from 'features/createBp/components/atoms/NextButton'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { isNotNilOrEmpty } from 'utils/ramda'
import { useSelector } from 'react-redux'
import { selectPendingRequests } from 'features/AccessRequest/ducks/selectors'

const SearchedBpsTable = ({ bps, openRequestModal, isSearching }) => {
  const data = useMemo(() => bps, [bps])
  const pendingRequests = useSelector(selectPendingRequests)

  const requestsIds = useMemo(() => {
    return pendingRequests && pendingRequests.map(request => {
      return request.buildingPassportId
    })
  }, [pendingRequests])

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        id: 'name',
        accessor: 'name',
        width: '130px',
        defaultCanSort: true,
        Cell: d => <PropertyName>{d.value}</PropertyName>
      },
      {
        Header: 'UPRN',
        accessor: 'uprn',
        defaultCanSort: true,
        Cell: d => {
          return isNotNilOrEmpty(d.value)
            ? d.value
            : 'Not yet allocated'
        }
      },
      {
        Header: 'Address',
        id: 'city',
        accessor: d =>
          <div>{`${d.street}, ${d.city}`}</div>
      },
      {
        Header: 'Postcode',
        id: 'postal_code',
        accessor: 'postalCode'
      },
      {
        Header: '',
        accessor: 'id',
        Cell: d => {
          return requestsIds.includes(d.value)
            ? (
              <>
                <InBasket>
                  <CheckIcon />
                  Already requested
                </InBasket>
              </>
            ) : (
              <AddButton
                onClick={openRequestModal(d.row.original)}
              >
                Request access
              </AddButton>
            )
        }
      }
    ],
    [requestsIds]
  )

  const tableInstance = useTable({
    columns,
    data
  })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = tableInstance

  return (
    <TableWrapper>
      <table {...getTableProps()}>
        <thead>
        {
          headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {
                headerGroup.headers.map(column => (
                  <th
                    style={{ cursor: 'pointer', paddingRight: '20px' }}
                    {...column.getHeaderProps()}
                  >
                    {column.render('Header')}
                  </th>
                ))
              }
            </tr>
          ))
        }
        </thead>
        <tbody {...getTableBodyProps()}>
        {
          rows.map(row => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
              >
                {
                  row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>
                        {
                          cell.render('Cell')
                        }
                      </td>
                    )
                  })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {
        isEmpty(data)
          ? isSearching
            ? (
                <EmptyState>
                  There is currently no Building Passport for this building. Please contact the landlord/building owner or their representative and request that they create one.
                </EmptyState>
              )
            : (
                <EmptyState>
                  Use input above to search for Building Passports
                </EmptyState>
              )
          : null
      }
    </TableWrapper>
  )
}

export default SearchedBpsTable

const TableWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-x: auto;
  
  table {
    width: 100%;
  }
  
  th {
    box-sizing: border-box;
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: 11px;
    opacity: .5;
    text-transform: uppercase;
    text-align: left;
    height: 40px;
    padding: 0 10px;
    position: relative;
  }

  td {
    box-sizing: border-box;
    padding: 0 10px;
    height: 56px;
    font-size: 14px;
  }
  
  tbody > tr {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
    
    &:hover {
      cursor: pointer;
      background-color: ${({ theme }) => theme.colors.lighterGrey};
    }
  }
`

const PropertyName = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
`

const SortingArrowIcon = styled(ArrowUpwardIcon)`
  position: absolute;
  top: 10px;
  transition: all .3s;
  transform: ${({ down }) => `rotate(${down ? '-180deg' : 0})`};
  padding: 0 5px;
  font-size: 20px !important;
`

const EmptyState = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`

const AddButton = styled(NextButton)`
  width: 150px;
  height: 30px;
  font-weight: normal;
  margin: 0 auto;
  border-radius: 4px;
`

const InBasket = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const CheckIcon = styled(CheckCircleIcon)`
  font-size: 16px !important;
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 5px;
  position: relative;
  top: 1px;
`
