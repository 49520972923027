import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import DataDropContainer from 'features/analytics/compontents/DataDropContainer'
import FilesDropContainer from 'features/analytics/compontents/FilesDropContainer'
import PrimaryButton from 'components/atoms/PrimaryButton'
import Modal from 'components/atoms/Modal'
import Input from 'components/atoms/Input'
import FullWidthButton from 'features/auth/components/FullWidthButton'
import { isEmpty, values } from 'ramda'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import {
  duplicateAnalyticsTemplateRoutine,
  updateAnalyticsTemplateRoutine
} from 'features/analytics/ducks/actions'
import Collapse from '@mui/material/Collapse'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'

const EditTemplateForm = ({ isLoading, template, openAttachToAllModal, openRemoveModal, openShareModal, initialOpen, id }) => {
  const [open, setOpen] = useState(initialOpen)
  const [anchorEl, setAnchorEl] = useState(null)
  const [dataValues, setDataValues] = useState({
    hasUprn: false,
    hasAddress: false,
    hasBuildingTypes: false,
    hasOwner: false,
    hasAgent: false
  })
  const [fileTypesValues, setFileTypesValues] = useState([])
  const dispatch = useDispatch()
  const [name, setName] = useState({
    main: 'New template',
    temp: 'New template'
  })
  const [nameModalOpen, setNameModalOpen] = useState(false)
  const isDefaultTemplate = template.type === 'default'
  const dropdownOpen = Boolean(anchorEl)

  const openMenu = e => {
    setAnchorEl(e.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const openNameModal = () => setNameModalOpen(true)
  const closeNameModal = () => {
    setNameModalOpen(false)
    setName(prev => ({ ...prev, temp: name.main }))
  }

  useEffect(() => {
    setDataValues({
      hasUprn: template.hasUprn,
      hasAddress: template.hasAddress,
      hasBuildingTypes: template.hasBuildingTypes,
      hasOwner: template.hasOwner,
      hasAgent: template.hasAgent
    })
    setFileTypesValues(template.fileTypes)
    setName({
      main: template.name,
      temp: template.name
    })
  }, [template])

  const handleSubmit = () => {
    dispatch(updateAnalyticsTemplateRoutine({
      id: template.id,
      fileTypes: fileTypesValues.map(v => v.id),
      ...dataValues
    }))
  }

  const handleTitleChange = () => {
    dispatch(updateAnalyticsTemplateRoutine({
      id: template.id,
      name: name.temp
    }))
  }

  const shouldDisabledButton = () => {
    return values(dataValues).every(val => val === false) && isEmpty(fileTypesValues)
  }

  const countGivenFields = useMemo(() => {
    return {
      data: values(dataValues).filter(v => v === true).length,
      files: fileTypesValues.length
    }
  }, [dataValues, fileTypesValues])

  const handleDuplicateTemple = () => {
    dispatch(duplicateAnalyticsTemplateRoutine({
      id: template.id
    }))
    setAnchorEl(null)
  }

  const handleRemoveModalOpen = () => {
    openRemoveModal(template)
    setAnchorEl(null)
  }

  const handleAttachToAllModalOpen = () => {
    openAttachToAllModal(template)
    setAnchorEl(null)
  }

  return (
    <PageWrapper id={id}>
      <FormContent>
        <Header open={open}>
          <Title>
            <div>
              {name.main}
              <TemplateType>{template.type}</TemplateType>
            </div>
            {!isDefaultTemplate && <EditIcon onClick={openNameModal} />}
          </Title>
          <OptionsWrapper>
            <TagsWrapper>
              {isDefaultTemplate && <Tag>UNEDITABLE</Tag>}
              <Tag>
                {countGivenFields.data} data field{countGivenFields.data === 1 ? '' : 's'},&nbsp;
                {countGivenFields.files} file{countGivenFields.files === 1 ? '' : 's'}
              </Tag>
            </TagsWrapper>
            <IconWrapper>
              <MoreIcon disabled={isLoading} onClick={openMenu} />
              <Menu
                keepMounted
                disabled={isLoading}
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                style={{
                  marginTop: '10px'
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                open={dropdownOpen}
                onClose={handleClose}
              >
                <MenuItem onClick={handleDuplicateTemple}>Duplicate</MenuItem>
                  {!isDefaultTemplate && <MenuItem onClick={openShareModal(template)}>Share template</MenuItem>}
                  {!isDefaultTemplate && <MenuItem onClick={handleAttachToAllModalOpen}>Attach to all Building Passports</MenuItem>}
                  {!isDefaultTemplate && <MenuItem onClick={handleRemoveModalOpen}>Remove</MenuItem>}
              </Menu>
            </IconWrapper>
            <CollapseIcon
              onClick={() => setOpen(prev => !prev)}
              open={open}
            />
          </OptionsWrapper>
        </Header>
        <Collapse in={open}>
          <DataDropContainer
            isEdit
            isDefaultTemplate={isDefaultTemplate}
            dataValues={dataValues}
            setDataValues={setDataValues}
          />
          {
            isEmpty(fileTypesValues) && isDefaultTemplate
              ? null
              : <FilesDropContainer
                  isEdit
                  isDefaultTemplate={isDefaultTemplate}
                  fileTypesValues={fileTypesValues}
                  setFileTypesValues={setFileTypesValues}
                />
          }
          <ButtonWrapper>
            {!isDefaultTemplate && (
              <PrimaryButton
                onClick={handleSubmit}
                disabled={shouldDisabledButton()}
              >
                Save changes
              </PrimaryButton>
            )}
          </ButtonWrapper>
        </Collapse>
      </FormContent>
      <Modal
        open={nameModalOpen}
        onClose={closeNameModal}
        title='Set template name'
      >
        <Input
          name='name'
          value={name.temp}
          onChange={(_, value) => setName(prev => ({ ...prev, temp: value }))}
        />
        <FullWidthButton
          disabled={isEmpty(name.temp)}
          onClick={() => {
            setName(prev => ({ ...prev, main: name.temp }))
            setNameModalOpen(false)
            handleTitleChange()
          }}
        >
          Save
        </FullWidthButton>
      </Modal>
    </PageWrapper>
  )
}

export default EditTemplateForm

const PageWrapper = styled.div`
  box-sizing: border-box;
  margin-bottom: 20px;
  width: 100%;
`

const FormContent = styled.div`
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.03);
  padding: 20px;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  transition: all .3s;
  width: 100%;
  margin-bottom: ${({ open }) => open ? '20px' : '0'};
`

const Title = styled.div`
  display: flex;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`

const EditIcon = styled(EditOutlinedIcon)`
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  transition: all .3s;
  margin-left: 10px;

  &:hover {
    opacity: .8;
  }
`

const TemplateType = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey[600]};
  text-transform: uppercase;
  font-weight: normal;
`

const TagsWrapper = styled.div`
  display: flex;
  margin-right: 15px;
`

const OptionsWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Tag = styled.div`
  padding: 5px 10px;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  color: ${({ theme }) => theme.colors.darkGrey};
  border-radius: 4px;
  font-size: 13px;
  font-weight: lighter;
  margin-left: 10px;
`

const CollapseIcon = styled(KeyboardArrowDownOutlinedIcon)`
  transition: all .3s;
  cursor: pointer;
  transform: rotate(${({ open }) => open ? '180deg': '0'});
`

const MoreIcon = styled(MoreVertIcon)`
  color: ${({ theme }) => theme.colors.grey[600]};
  cursor: pointer;
  font-size: 20px !important;

  ${({ disabled }) => disabled && css`
    cursor: not-allowed !important;
    pointer-events: none !important;
  `}
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`
