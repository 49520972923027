import React, { useEffect, useState, useRef, useCallback } from 'react'
import styled, { keyframes, css } from 'styled-components'
import PageHeader from 'components/atoms/PageHeader'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUserRole } from 'features/auth/ducks/selectors'
import BpList from 'features/bpList/BpList'
import UnitsList from 'features/bpList/UnitsList'
import { ReactComponent as MyBpsIcon } from 'assets/images/Menu2.svg'
import { ReactComponent as LockIcon } from 'assets/images/lock-unlocked.svg'
import {
  clearSelectedBpRoutine,
  getBpsCountRoutine,
  getUnitsCountRoutine
} from 'features/bpList/ducks/actions'
import {
  selectBpsCount,
  selectUnitsCount
} from 'features/bpList/ducks/selectors'

const MainList = () => {
  const currentUserRole = useSelector(getCurrentUserRole)
  const [activeTab, setActiveTab] = useState('bp')
  const dispatch = useDispatch()
  const bpsCount = useSelector(selectBpsCount)
  const unitsCount = useSelector(selectUnitsCount)
  const initialLoadRef = useRef(false)

  const handleTabChange = useCallback(
    tabName => () => {
      setActiveTab(tabName)
    },
    []
  )

  useEffect(() => {
    if (!initialLoadRef.current) {
      dispatch(getBpsCountRoutine())
      dispatch(getUnitsCountRoutine())
      dispatch(clearSelectedBpRoutine())
      initialLoadRef.current = true
    }
  }, [dispatch])

  useEffect(() => {
    if (bpsCount === 0 && unitsCount > 0) {
      setActiveTab('unit')
    } else if (!activeTab) {
      setActiveTab('bp')
    }
  }, [bpsCount, unitsCount, activeTab])

  return (
    <PageWrapper>
      <HeaderSection>
        <StyledPageHeader>
          {currentUserRole === 'emergency_service'
            ? 'Building Passports'
            : 'My Building Passports'}
        </StyledPageHeader>

        <PageDescription>
          Access and manage your building passports and units
        </PageDescription>
      </HeaderSection>

      <TabsSection>
        <TabsContainer>
          <TabItem active={activeTab === 'bp'} onClick={handleTabChange('bp')}>
            <TabIcon active={activeTab === 'bp'}>
              <MyBpsIcon />
            </TabIcon>
            <TabText>Building Passports</TabText>
            <TabBadge>{bpsCount}</TabBadge>
          </TabItem>

          <TabItem
            active={activeTab === 'unit'}
            onClick={handleTabChange('unit')}
          >
            <TabIcon active={activeTab === 'unit'}>
              <LockIcon />
            </TabIcon>
            <TabText>Units</TabText>
            <TabBadge>{unitsCount}</TabBadge>
          </TabItem>
        </TabsContainer>
      </TabsSection>

      <ContentSection>
        {activeTab === 'bp' && <BpList />}
        {activeTab === 'unit' && <UnitsList />}
      </ContentSection>
    </PageWrapper>
  )
}

export default MainList

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
`

const PageWrapper = styled.div`
  width: 100%;
  animation: ${fadeIn} 0.4s ease-out;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
`

const HeaderSection = styled.div`
  width: 100%;
  padding: 0 24px;
  margin-bottom: 30px;
`

const StyledPageHeader = styled(PageHeader)`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.haiti};
  font-size: 32px;
`

const PageDescription = styled.p`
  color: ${({ theme }) => theme.colors.haiti}90;
  font-size: 16px;
  margin: 0;
`

const TabsSection = styled.div`
  width: 100%;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 24px;
`

const TabsContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
`

const TabItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 24px;
  height: 60px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  ${({ active, theme }) =>
    active &&
    css`
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: ${theme.colors.secondary};
      }
    `}

  &:hover {
    background-color: ${({ theme }) => theme.colors.lighterGrey};
  }
`

const TabIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  margin-right: 12px;
  border-radius: 8px;
  background-color: ${({ active, theme }) =>
    active ? `${theme.colors.primary}15` : 'transparent'};
  transition: background-color 0.2s ease;

  svg {
    width: 20px;
    height: 20px;
    fill: ${({ active, theme }) =>
      active ? theme.colors.primary : theme.colors.haiti + '80'};
    transition: fill 0.2s ease;
  }
`

const TabText = styled.span`
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
`

const TabBadge = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  min-width: 24px;
  height: 24px;
  padding: 0 8px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.lighterGrey};
  color: ${({ theme }) => theme.colors.haiti}90;
  font-size: 12px;
  font-weight: 600;
`

const ContentSection = styled.div`
  width: 100%;
  padding: 0 24px;
  max-width: 1400px;
  margin: 0 auto;
`
