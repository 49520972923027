import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { PRIVATE_PATHS } from 'utils/paths'
import { ReactComponent as DashboardIcon } from 'assets/images/menu1.svg'
import { ReactComponent as MyBpsIcon } from 'assets/images/Menu2.svg'
import { ReactComponent as ShortlistedIcon } from 'assets/images/manu3.svg'
import { ReactComponent as DraftsIcon } from 'assets/images/pencil.svg'
import { ReactComponent as HelpIcon } from 'assets/images/faq.svg'
import { ReactComponent as LockIcon } from 'assets/images/lock-unlocked.svg'
import { ReactComponent as AnalyticsIcon } from 'assets/images/analytics.svg'
import { useSelector } from 'react-redux'
import { getCurrentUserRole } from 'features/auth/ducks/selectors'
import Tooltip from '@mui/material/Tooltip'
import {useLocation} from "react-router";

const Navigation = () => {
  const [rerender, setRerender] = useState(0)
  const location = useLocation()
  const currentPath = location.pathname
  const currentUserRole = useSelector(getCurrentUserRole)

  const isFrsUser = currentUserRole === 'emergency_service'
  const isResponsiblePerson = currentUserRole === 'responsible_person'

  return (
    <NavigationWrapper key={rerender}>
      <LinksContainer>
        {
          !isFrsUser && !isResponsiblePerson &&
            <IconLink
              isactive={(currentPath.startsWith(PRIVATE_PATHS.dashboard)).toString()}
              to={PRIVATE_PATHS.dashboard}
            >
              <Tooltip title='Dashboard'>
                <DashboardIcon />
              </Tooltip>
              <div className='link-label'>Dashboard</div>
            </IconLink>
        }
        <IconLink
          isactive={(currentPath.startsWith(PRIVATE_PATHS.bpList)).toString()}
          to={PRIVATE_PATHS.bpList}
        >
          <Tooltip title='Building Passports list'>
            <MyBpsIcon />
          </Tooltip>
          <div className='link-label'>{isFrsUser ? 'Building Passports List' : 'My Building Passports'}</div>
        </IconLink>
        <IconLink
          isactive={(currentPath.startsWith(PRIVATE_PATHS.savedPassports)).toString()}
          to={PRIVATE_PATHS.savedPassports}
        >
          <Tooltip title='Favourite Building Passports'>
            <ShortlistedIcon />
          </Tooltip>
          <div className='link-label'>Favourites</div>
        </IconLink>
        {
          !isFrsUser &&
            <>
              <IconLink
                isactive={(currentPath.startsWith(PRIVATE_PATHS.drafts)).toString()}
                to={PRIVATE_PATHS.drafts}
              >
                <Tooltip title='Drats list'>
                  <DraftsIcon />
                </Tooltip>
                <div className='link-label'>Drafts</div>
              </IconLink>
              <IconLink
                isactive={(currentPath.startsWith(PRIVATE_PATHS.accessRequests)).toString()}
                to={PRIVATE_PATHS.accessRequests}
              >
                <Tooltip title='Search for and request access to Building Passports'>
                  <LockIcon />
                </Tooltip>
                <div className='link-label'>Request Access</div>
              </IconLink>
              <IconLink
                isactive={(currentPath.startsWith(PRIVATE_PATHS.analytics)).toString()}
                to={PRIVATE_PATHS.analytics}
              >
                <AnalyticsIcon />
                <div className='link-label'>Analytics Overview</div>
              </IconLink>
            </>
        }
      </LinksContainer>
      <HelpLink
        href='https://www.buildingpassport.com/faqs'
        target='_blank'
      >
        <HelpIcon />
        <div className='link-label'>Help</div>
      </HelpLink>
    </NavigationWrapper>
  )
}

export default Navigation

const NavigationWrapper = styled.div`
  box-sizing: border-box;
  width: ${({ theme }) => theme.dimensions.navWidth};
  background-color: ${({ theme }) => theme.colors.navigation};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 1;
  border-right: 2px solid ${({ theme }) => theme.colors.lightGrey};
  transition: all .3s;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .link-label {
    opacity: 0;
    font-size: 16px;
    left: 80px;
    position: absolute;
    white-space: nowrap;
  }

  &:hover {
    width: 240px;
    z-index: 99;

    .link-label {
      opacity: 1;
    }
  }
`

const LinksContainer = styled.div`
  width: 100%;
  margin-top: 130px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`

const IconLink = styled(Link)`
  box-sizing: border-box;
  padding-left: 40px;
  position: relative;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 30px;
  transition: all .2s;
  width: 100%;
  height: 44px;

  svg {
    width: 24px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  &::before {
    content: '';
    display: ${({ isactive }) => isactive === "true" ? 'block' : 'none'};
    background-color: ${({ theme }) => theme.colors.primary};
    left: 0;
    position: absolute;
    height: 100%;
    width: 6px;
    border-radius: 0 4px 4px 0;
    box-shadow: 0 2px 12px rgba(209, 73, 37, 0.74);
  }
`

const HelpLink = styled.a`
  box-sizing: border-box;
  padding-left: 40px;
  position: relative;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 30px;
  transition: all .2s;
  width: 100%;
  height: 44px;

  svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`
