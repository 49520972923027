import React from 'react'
import styled from 'styled-components'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { redirect } from 'utils/paths'

const BackButton = ({ to, children }) => {
  const redirectFn = path => () => redirect(path)
  return (
    <Wrapper onClick={redirectFn(to)}>
      <Arrow />
      <Text>{children}</Text>
    </Wrapper>
  )
}

export default BackButton

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
`

const Arrow = styled(ArrowBackIcon)`
  font-size: 20px !important;
`

const Text = styled.div`
  color: ${({ theme }) => theme.colors.haiti};
  font-size: 14px;
  margin-left: 8px;
  transition: all .3s;
  
  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`
