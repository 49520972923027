import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import SearchIcon from '@mui/icons-material/Search'
import useDebounce from 'utils/hooks/useDebounce'
import { isEmpty, pathOr, propOr } from 'ramda'
import { FindInPageOutlined } from '@mui/icons-material'
import { searchLocalFilesRoutine } from 'features/bpDetails/ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import ClearIcon from '@mui/icons-material/Clear'

export const FilesSearchInput = ({
  disabled = false,
  setIsSearching,
  placeholder = 'Search files by keywords or sentences, as you would on Google...',
  onChange = () => {},
  ...props
}) => {
  const [value, setValue] = useState('')
  const debouncedValue = useDebounce(value, 500)
  const dispatch = useDispatch()
  const bp = useSelector(getSelectedBp)

  const handleClearValue = () => setValue('')
  const handleInputChange = e => setValue(e.target.value)

  useEffect(() => {
    if (debouncedValue.length >= 3) {
      dispatch(
        searchLocalFilesRoutine({
          query: debouncedValue,
          bpId: bp.id
        })
      )
      setIsSearching(true)
    } else {
      setIsSearching(false)
    }
  }, [debouncedValue])

  return (
    <SearchInputContainer disabled={disabled}>
      <InputWrapper>
        <SearchInputIcon>
          <StyledSearchIcon />
        </SearchInputIcon>
        <TextareaSearch
          {...props}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onChange={handleInputChange}
        />
        {!isEmpty(value) && <StyledClearIcon onClick={handleClearValue} />}
      </InputWrapper>
    </SearchInputContainer>
  )
}

export default FilesSearchInput

const TextareaSearch = styled.textarea`
  width: 100%;
  min-height: 80px;
  font-size: 16px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  outline: none;
  resize: none;
  box-sizing: border-box;

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
    box-shadow: 0 0 4px ${({ theme }) => theme.colors.primary};
  }
`

const SearchInputContainer = styled.div`
  border-radius: 4px;
  height: 100%;
  box-sizing: border-box;
  opacity: ${props => (props.disabled ? '.5' : '1')};
  width: 100%;
  display: flex;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
`

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  input {
    padding: 0;
  }
`

const SearchInputIcon = styled.div`
  position: relative;
  top: 2px;
  margin-right: 15px;
  color: ${({ theme }) => theme.colors.primary};
`

const StyledClearIcon = styled(ClearIcon)`
  cursor: pointer;
  position: absolute;
  right: 0;
  padding: 10px;
  font-size: 16px;
  color: rgba(152, 152, 152, 0.61);
`

const StyledSearchIcon = styled(FindInPageOutlined)`
  font-size: 25px !important;
`
