import {
  getFileTypesRoutine,
  removeFileRoutine,
  addFileVersionRoutine,
  getUploadProcessRoutine,
  saveUploadProcessFetchDetailsRoutine,
  clearUploadProcessFetchDetailsRoutine,
  clearUploadProcessRoutine,
  getBpFilesRoutine,
  getFileTagsForBpRoutine,
  createFileTagRoutine,
  attachFileTagToFileRoutine,
  detachFileTagFromFileRoutine,
  syncFileTagsInFileRoutine,
  syncFileTagsInFileVersionRoutine,
  clearBpFilesRoutine,
  fileStatusUpdateRoutine,
  initFileWebSocketRoutine,
  cleanupFileWebSocketRoutine,
  updateFileStatusRoutine
} from 'ducks/files/actions'
import { pathOr } from 'ramda'
import emptyFilesState from 'ducks/files/state'
import { fetchBpRoutine } from 'features/bpDetails/ducks/actions'

const normalizeFileName = name => {
  if (!name) return ''

  // First, simplify by lowercase
  let normalized = name.toLowerCase()

  // Remove common file extensions anywhere in the string
  normalized = normalized
    .replace(/\.pdf\.png$/i, '.') // Handle the specific .pdf.png case first
    .replace(/\.pdf/gi, '.') // Remove PDF extensions anywhere
    .replace(/\.(png|jpg|jpeg|doc|docx|xlsx|xls)$/i, '.') // Remove other extensions at the end
    .replace(/\./g, '') // Remove all remaining dots
    .replace(/[_\- ]/g, '') // Remove underscores, hyphens, and spaces

  return normalized
}
export default (state = emptyFilesState, action) => {
  switch (action.type) {
    case addFileVersionRoutine.REQUEST:
      return {
        ...state,
        isUploading: true
      }
    case addFileVersionRoutine.FAILURE:
      return {
        ...state,
        isUploading: false
      }
    case addFileVersionRoutine.SUCCESS:
      return {
        ...state,
        isUploading: false
      }

    case removeFileRoutine.SUCCESS:
      return {
        ...state,
        currentBpFiles: state.currentBpFiles.filter(
          e => e.id !== action.payload
        )
      }
    case getFileTypesRoutine.SUCCESS:
      return {
        ...state,
        fileTypes: action.payload
      }
    case fetchBpRoutine.SUCCESS:
      return {
        ...state,
        currentBpFiles: pathOr([], ['payload', 'files'], action)
      }
    case updateFileStatusRoutine.TRIGGER:
      console.log(
        'Receiving update, updating multiUploadFiles:',
        action.payload
      )
      const updateDisplayName =
        action.payload.displayName ||
        action.payload.data?.displayName ||
        action.payload.data?.display_name ||
        ''

      return {
        ...state,
        multiUploadFiles: state.multiUploadFiles.map(file => {
          // First try direct ID match
          if (file.id === action.payload.fileId) {
            return {
              ...file,
              status: action.payload.status,
              storedFile: action.payload.storedFile || file.storedFile,
              displayName: updateDisplayName || file.displayName
            }
          }

          // Then try name matching if we have display names
          else if (updateDisplayName && file.displayName) {
            const normalizedUpdate = normalizeFileName(updateDisplayName)
            const normalizedFile = normalizeFileName(file.displayName)

            if (normalizedFile === normalizedUpdate) {
              return {
                ...file,
                status: action.payload.status,
                storedFile: action.payload.storedFile || file.storedFile
              }
            }
          }

          return file
        })
      }

    case initFileWebSocketRoutine.SUCCESS:
      return {
        ...state,
        webSocketInitialized: true,
        webSocketProcessId: action.payload.processId,
        multiUploadFiles: action.payload.files.map((file, index) => ({
          id: file.id,
          uniqueId: `${file.id}-${index}`,
          fileId: file.fileId || null,
          status: file.status || 'pending',
          storedFile: file.storedFile || null,
          displayName: file.data?.displayName || null
        }))
      }

    case cleanupFileWebSocketRoutine.SUCCESS:
      return {
        ...state,
        webSocketInitialized: false,
        webSocketProcessId: null,
        multiUploadFiles: []
      }
    case getUploadProcessRoutine.SUCCESS:
      // console.log('Processing upload process data:', action.payload)

      // Extract files from upload process and transform them to match multiUploadFiles structure
      const processFiles = action.payload.files || []

      if (processFiles.length > 0) {
        return {
          ...state,
          uploadProcess: action.payload,
          // Add or update files in multiUploadFiles
          multiUploadFiles: processFiles.map((file, index) => {
            // Try to find existing file in current multiUploadFiles
            const existingFile = state.multiUploadFiles.find(
              f => f.id === file.id
            )

            return {
              id: file.id,
              uniqueId: existingFile?.uniqueId || `${file.id}-${index}`,
              fileId: file.fileId || existingFile?.fileId || null,
              status: file.status || existingFile?.status || 'pending',
              storedFile: file.storedFile || existingFile?.storedFile || null,
              displayName:
                file.data?.display_name ||
                file.data?.displayName ||
                existingFile?.displayName ||
                null
            }
          })
        }
      }

      // If no files in the process, just update the uploadProcess
      return {
        ...state,
        uploadProcess: action.payload
      }
    case clearUploadProcessRoutine.SUCCESS:
      return {
        ...state,
        uploadProcess: null
      }
    case saveUploadProcessFetchDetailsRoutine.SUCCESS:
      return {
        ...state,
        uploadProcessFetchDetails: action.payload
      }
    case clearUploadProcessFetchDetailsRoutine.SUCCESS:
      return {
        ...state,
        uploadProcessFetchDetails: null
      }
    case getBpFilesRoutine.SUCCESS:
      return {
        ...state,
        bpFilesList: action.payload
      }
    case clearBpFilesRoutine.SUCCESS:
      return {
        ...state,
        bpFilesList: []
      }
    case getBpFilesRoutine.FAILURE:
      return {
        ...state,
        bpFilesList: []
      }
    case getFileTagsForBpRoutine.SUCCESS:
      return {
        ...state,
        fileTags: action.payload
      }
    case createFileTagRoutine.SUCCESS:
      return {
        ...state,
        fileTags: [...state.fileTags, action.payload]
      }
    case attachFileTagToFileRoutine.SUCCESS:
    case detachFileTagFromFileRoutine.SUCCESS:
    case syncFileTagsInFileRoutine.SUCCESS:
      return {
        ...state,
        bpFilesList: state.bpFilesList.map(file => {
          if (file.id === action.payload.id) {
            return action.payload
          } else {
            return file
          }
        })
      }
    case syncFileTagsInFileVersionRoutine.SUCCESS:
      return {
        ...state,
        bpFilesList: state.bpFilesList.map(file => {
          if (file.id === action.payload.fileId) {
            const version = file?.versions || []
            return {
              ...file,
              versions: version.map(v => {
                if (v.id === action.payload.id) {
                  return action.payload
                } else {
                  return v
                }
              })
            }
          } else {
            return file
          }
        })
      }
    default:
      return state
  }
}
