import React, { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import { isNotNil, isNotNilOrEmpty } from 'utils/ramda'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { isNil, pathOr, propOr } from 'ramda'
import { AddButton } from 'features/bpDetails/BpDetails'
import { useSelector } from 'react-redux'
import { selectFileCategories } from 'ducks/dictionaries/selectors'
import { getFileTypeName } from 'utils/bpData'
import { sortFilesByExpirationStatus } from 'utils/files'
import { getCurrentUser } from 'features/auth/ducks/selectors'
import { canEditBp } from 'utils/user'
import { selectSelectedUnitFiles } from 'ducks/units/selectors'
import { getFileTypes, selectBpFilesList } from 'ducks/files/selectors'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import File from 'features/bpDetails/Components/File'
import AddMultipleFilesModal from 'features/bpDetails/Components/files/AddMultipleFilesModal'
import { Tooltip, IconButton, Menu, MenuItem } from '@mui/material'
import GridViewIcon from '@mui/icons-material/GridView'
import ListIcon from '@mui/icons-material/List'
import SortIcon from '@mui/icons-material/Sort'
const FileCategories = ({ setRecentFilesVisible, unit, canEditUnit }) => {
  const [currentFolder, setCurrentFolder] = useState(null)
  const [allFolders, setAllFolders] = useState([])
  const hasSelectedFolder = isNotNil(currentFolder)
  const fileTypes = useSelector(getFileTypes)
  const fileCategories = useSelector(selectFileCategories)
  const bpFiles = useSelector(selectBpFilesList)
  const unitFiles = useSelector(selectSelectedUnitFiles)
  const bp = useSelector(getSelectedBp)
  const currentUser = useSelector(getCurrentUser)
  const isUnit = isNotNilOrEmpty(unit)
  const [viewMode, setViewMode] = useState('grid')
  const [sortBy, setSortBy] = useState('newest')
  const [sortMenuAnchorEl, setSortMenuAnchorEl] = useState(null)
  const files = useMemo(() => {
    return isUnit ? unitFiles : bpFiles
  }, [bpFiles, unitFiles, isUnit])

  const canEdit = isUnit ? canEditUnit : canEditBp(currentUser, bp)

  useEffect(() => {
    if (isNotNil(files) && isNotNil(fileCategories)) {
      // Create an array for uncategorized files
      const uncategorizedFiles = files.filter(file =>
        isNil(pathOr(null, ['fileType', 'fileCategoryId'], file))
      )

      // Create an uncategorized folder if there are uncategorized files
      const uncategorizedFolder = {
        id: 'uncategorised',
        name: 'Uncategorised',
        files: uncategorizedFiles,
        isCustom: true
      }

      // Map the regular categories
      const categorizedFolders = fileCategories.map(category => {
        return {
          ...category,
          files: files.filter(
            file =>
              pathOr('', ['fileType', 'fileCategoryId'], file) === category.id
          )
        }
      })

      // Combine them, adding uncategorized only if it has files
      setAllFolders([
        ...categorizedFolders,
        ...(uncategorizedFiles.length > 0 ? [uncategorizedFolder] : [])
      ])
    }
  }, [files, fileCategories])

  const handleCategoryChange = category => {
    const folder = allFolders.find(folder => folder.id === category.id)
    setCurrentFolder(folder)
  }

  useEffect(() => {
    if (allFolders && currentFolder) {
      handleCategoryChange(currentFolder)
    }
  }, [allFolders])

  useEffect(() => {
    !isUnit && setRecentFilesVisible(isNil(currentFolder))
  }, [currentFolder])

  const openSortMenu = event => {
    setSortMenuAnchorEl(event.currentTarget)
  }

  const closeSortMenu = () => {
    setSortMenuAnchorEl(null)
  }

  const sortFiles = filesToSort => {
    if (!filesToSort) return []

    switch (sortBy) {
      case 'newest':
        return [...filesToSort].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
      case 'oldest':
        return [...filesToSort].sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        )
      case 'name-asc':
        return [...filesToSort].sort((a, b) =>
          a.displayName.localeCompare(b.displayName)
        )
      case 'name-desc':
        return [...filesToSort].sort((a, b) =>
          b.displayName.localeCompare(a.displayName)
        )
      case 'type':
        return [...filesToSort].sort((a, b) => {
          const typeA = getFileTypeName(
            pathOr('', ['fileType', 'id'], a),
            fileTypes,
            a.isPrivate
          )
          const typeB = getFileTypeName(
            pathOr('', ['fileType', 'id'], b),
            fileTypes,
            b.isPrivate
          )
          return typeA.localeCompare(typeB)
        })
      case 'expiry-asc':
        return [...filesToSort].sort((a, b) => {
          if (!a.expirationDate && !b.expirationDate) return 0
          if (!a.expirationDate) return 1
          if (!b.expirationDate) return -1
          return new Date(a.expirationDate) - new Date(b.expirationDate)
        })
      case 'expiry-desc':
        return [...filesToSort].sort((a, b) => {
          if (!a.expirationDate && !b.expirationDate) return 0
          if (!a.expirationDate) return 1
          if (!b.expirationDate) return -1
          return new Date(b.expirationDate) - new Date(a.expirationDate)
        })
      default:
        return filesToSort
    }
  }

  const sortedFolderFiles = useMemo(() => {
    return sortFiles(propOr([], 'files', currentFolder))
  }, [currentFolder, sortBy])

  const getSortLabel = sortBy => {
    switch (sortBy) {
      case 'newest':
        return 'Newest first'
      case 'oldest':
        return 'Oldest first'
      case 'name-asc':
        return 'Name (A-Z)'
      case 'name-desc':
        return 'Name (Z-A)'
      case 'type':
        return 'File type'
      case 'expiry-asc':
        return 'Expiry date (soonest)'
      case 'expiry-desc':
        return 'Expiry date (latest)'
      default:
        return 'Newest first'
    }
  }

  return (
    <SectionWrapper withMargin={isUnit || isNil(currentFolder)}>
      <HeaderWrapper>
        <HeaderTitleWrapper>
          <SectionHeader
            open={isNotNil(currentFolder)}
            onClick={() => setCurrentFolder(null)}
          >
            File category
          </SectionHeader>
          {isNotNil(currentFolder) && (
            <>
              <BreadcrumbsSign>&gt;</BreadcrumbsSign>
              <CurrentFolderName>{currentFolder.name}</CurrentFolderName>
            </>
          )}
        </HeaderTitleWrapper>
      </HeaderWrapper>
      {hasSelectedFolder && (
        <ViewToggle>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title='Grid view'>
              <StyledIconButton
                isActive={viewMode === 'grid'}
                onClick={() => setViewMode('grid')}
              >
                <GridViewIcon />
              </StyledIconButton>
            </Tooltip>
            <Tooltip title='List view'>
              <StyledIconButton
                isActive={viewMode === 'list'}
                onClick={() => setViewMode('list')}
              >
                <ListIcon />
              </StyledIconButton>
            </Tooltip>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SortButton onClick={openSortMenu}>
              <SortIcon fontSize='small' />
              <span>Sort: {getSortLabel(sortBy)}</span>
            </SortButton>

            <Menu
              anchorEl={sortMenuAnchorEl}
              open={Boolean(sortMenuAnchorEl)}
              onClose={closeSortMenu}
            >
              <MenuItem
                selected={sortBy === 'newest'}
                onClick={() => {
                  setSortBy('newest')
                  closeSortMenu()
                }}
              >
                Newest first
              </MenuItem>
              <MenuItem
                selected={sortBy === 'oldest'}
                onClick={() => {
                  setSortBy('oldest')
                  closeSortMenu()
                }}
              >
                Oldest first
              </MenuItem>
              <MenuItem
                selected={sortBy === 'name-asc'}
                onClick={() => {
                  setSortBy('name-asc')
                  closeSortMenu()
                }}
              >
                Name (A-Z)
              </MenuItem>
              <MenuItem
                selected={sortBy === 'name-desc'}
                onClick={() => {
                  setSortBy('name-desc')
                  closeSortMenu()
                }}
              >
                Name (Z-A)
              </MenuItem>
              <MenuItem
                selected={sortBy === 'type'}
                onClick={() => {
                  setSortBy('type')
                  closeSortMenu()
                }}
              >
                File type
              </MenuItem>
              <MenuItem
                selected={sortBy === 'expiry-asc'}
                onClick={() => {
                  setSortBy('expiry-asc')
                  closeSortMenu()
                }}
              >
                Expiry date (soonest)
              </MenuItem>
              <MenuItem
                selected={sortBy === 'expiry-desc'}
                onClick={() => {
                  setSortBy('expiry-desc')
                  closeSortMenu()
                }}
              >
                Expiry date (latest)
              </MenuItem>
            </Menu>
            {canEdit && (
              <AddMultipleFilesModal
                unit={unit}
                initialCategory={currentFolder?.id || ''}
              >
                <AddButton>+ Add new files</AddButton>
              </AddMultipleFilesModal>
            )}
          </div>
        </ViewToggle>
      )}

      {hasSelectedFolder ? (
        <FilesWrapper viewMode={viewMode}>
          {sortedFolderFiles.map(file => (
            <File
              isUnit={isUnit}
              key={`file-${file.id || Math.random() * 99}`}
              canEditUnit={canEditUnit}
              file={file}
              type={getFileTypeName(
                pathOr('', ['fileType', 'id'], file),
                fileTypes,
                file.isPrivate
              )}
              isSharedWithUnits={file.isSharedWithUnits}
              viewMode={viewMode}
              showThumbnail={viewMode === 'grid'}
            />
          ))}
        </FilesWrapper>
      ) : (
        <FoldersList>
          {allFolders &&
            allFolders.map(category => {
              const filesCount = propOr([], 'files', category).length
              return (
                <FolderWrapper
                  key={category.id}
                  onClick={
                    filesCount > 0
                      ? () => handleCategoryChange(category)
                      : () => {}
                  }
                  disabled={filesCount === 0}
                >
                  <StyledFolderIcon />
                  <FolderNameWrapper>
                    <FolderName>{category.name}</FolderName>
                    <FilesCounter>({filesCount})</FilesCounter>
                  </FolderNameWrapper>
                </FolderWrapper>
              )
            })}
        </FoldersList>
      )}
    </SectionWrapper>
  )
}

export default FileCategories

const FilesWrapper = styled.div`
  box-sizing: border-box;
  max-width: 100%;
  display: ${props => (props.viewMode === 'grid' ? 'grid' : 'flex')};
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 16px;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
`

const SectionWrapper = styled.div`
  margin-top: ${({ withMargin }) => (withMargin ? '30px' : 0)};
`

const FoldersList = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 16px;
  margin-top: 20px;
  padding: 8px;
`

const FolderWrapper = styled.div`
  box-sizing: border-box;
  height: 72px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.grey[500]};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: all 0.2s ease;
  cursor: pointer;
  position: relative;

  &:hover {
    border-color: ${({ theme, disabled }) =>
      disabled ? theme.colors.grey[400] : theme.colors.secondary};
    box-shadow: ${({ disabled }) =>
      disabled
        ? '0 1px 3px rgba(0, 0, 0, 0.08)'
        : '0 4px 12px rgba(0, 0, 0, 0.1)'};
    transform: ${({ disabled }) => (disabled ? 'none' : 'translateY(-2px)')};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      opacity: 0.5;
      color: ${({ theme }) => theme.colors.grey[100]};

      & div,
      & span {
        color: ${({ theme }) => theme.colors.grey[300]};
      }
    `}
`

const FolderName = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.haiti};
  margin-left: 15px;
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const HeaderTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`
const StyledFolderIcon = styled(FolderOpenIcon)`
  color: ${({ theme }) => theme.colors.grey[600]};
  transition: color 0.2s ease;
  ${FolderWrapper}:hover:not([disabled]) & {
    color: ${({ theme }) => theme.colors.secondary};
  }
`
const SectionHeader = styled.div`
  font-size: 11px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.haiti};
  letter-spacing: 0.3px;

  ${({ open }) =>
    open &&
    css`
      cursor: pointer;
    `}
`

const BreadcrumbsSign = styled(ChevronRightIcon)`
  color: ${({ theme }) => theme.colors.grey[600]};
  font-size: 18px !important;
  margin: 0 5px;
`

const CurrentFolderName = styled(SectionHeader)`
  color: ${({ theme }) => theme.colors.primary};
`

const FilesCounter = styled.span`
  display: inline-block;
  font-weight: bold;
  margin-left: 5px;
  color: ${({ theme }) => theme.colors.grey[700]};
`

const FolderNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

// Add the view toggle styling from OtherFiles
const ViewToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  border-radius: 8px;
  padding: 2px 6px;
`

const StyledIconButton = styled(IconButton)`
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.primary : 'inherit'} !important;

  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`

const SortButton = styled.button`
  display: flex;
  align-items: center;
  gap: 6px;
  background: none;
  border: none;
  padding: 6px 12px;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.grey[200]};
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[100]};
  }
`
