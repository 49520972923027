import React, { useState, useRef, useEffect } from 'react'
import { MainContentPanel } from 'features/bpDetails/BpDetails'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import { Avatar, Button, CircularProgress, IconButton } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import DeleteIcon from '@mui/icons-material/Delete'
import { getCurrentUser, getUserPhotoUrl } from 'features/auth/ducks/selectors'
import LogoShort from 'assets/images/bp-logo-orange.png'
import MarkdownRenderer from 'components/MarkdownRenderer'
import InitialsAvatar from 'features/navigation/components/InitialsAvatar'
import Tooltip from '@mui/material/Tooltip'
import { computeGuruMessagesRoutine } from 'features/bpDetails/ducks/actions'
import * as bpService from 'services/BpService'
import PdfFullScreenViewer from 'components/PdfFullScreenViewer'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
const BPGuru = () => {
  const bp = useSelector(getSelectedBp)
  const currentUser = useSelector(getCurrentUser)
  const dispatch = useDispatch()
  const [messages, setMessages] = useState([
    {
      id: 1,
      type: 'assistant',
      content: `Hello! I'm Guru, your Building Passport assistant. I can help you find information about ${bp.name}. What would you like to know?`,
      timestamp: new Date()
    }
  ])
  const [inputValue, setInputValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const messagesEndRef = useRef(null)
  const userPhotoUrl = useSelector(getUserPhotoUrl)
  const [pdfViewerOpen, setPdfViewerOpen] = useState(false)
  const [currentFile, setCurrentFile] = useState(null)

  const suggestedQuestions = [
    'What are the findings from the latest fire risk assessment?',
    'What grade of concrete was used in construction?',
    'Show me all the lift service records',
    'Has this building ever had an issue with legionella?',
    "What's the EPC/DEC rating of this building?",
    'Have there been any insurance claims in the last 5 years?',
    'Tell me all the warranty expiry dates from uploaded documents'
  ]

  useEffect(() => {
    const savedMessages = localStorage.getItem(`bp-guru-chat-${bp.id}`)
    if (savedMessages) {
      try {
        const parsedMessages = JSON.parse(savedMessages)
        const messagesWithDates = parsedMessages.map(msg => ({
          ...msg,
          timestamp: new Date(msg.timestamp)
        }))
        setMessages(messagesWithDates)
      } catch (error) {
        console.error('Error parsing saved messages:', error)
      }
    }
  }, [bp.id])

  useEffect(() => {
    localStorage.setItem(`bp-guru-chat-${bp.id}`, JSON.stringify(messages))
  }, [messages, bp.id])

  const getBPGuruResponse = async query => {
    setIsLoading(true)

    try {
      const response = await bpService.computeGuruMessages({
        query,
        bpId: bp.id
      })

      dispatch(computeGuruMessagesRoutine.success(response.data))

      setIsLoading(false)
      return {
        content: response.data.answer,
        sources: response.data.sources
      }
    } catch (error) {
      console.error('Error getting Guru response:', error)
      dispatch(computeGuruMessagesRoutine.failure(error))
      setIsLoading(false)
      return {
        content: `I'm sorry, I encountered an error while processing your request. Please try again later.`,
        sources: []
      }
    }
  }

  const handleClearChat = () => {
    if (window.confirm('Are you sure you want to clear this chat history?')) {
      setMessages([
        {
          id: 1,
          type: 'assistant',
          content: `Hello! I'm Guru, your Building Passport assistant. I can help you find information about ${bp.name}. What would you like to know?`,
          timestamp: new Date()
        }
      ])
    }
  }

  const handleDocumentClick = source => {
    if (!source) return

    // Check all possible URL properties
    const fileUrl =
      source.fileUrl || (source.file && source.file.url) || source.file_url

    if (!fileUrl) {
      return
    }

    setCurrentFile({
      url: fileUrl,
      displayName: source.name,
      id: source.id || 'source-file',
      contentType: source.type?.toLowerCase().includes('pdf')
        ? 'application/pdf'
        : fileUrl.toLowerCase().endsWith('.pdf')
          ? 'application/pdf'
          : null
    })

    setPdfViewerOpen(true)
  }

  const handlePdfViewerClose = () => {
    setPdfViewerOpen(false)
  }

  const handleSendMessage = async (messageText = inputValue) => {
    if (!messageText.trim()) return

    const userMessage = {
      id: messages.length + 1,
      type: 'user',
      content: messageText,
      timestamp: new Date()
    }

    setMessages(prevMessages => [...prevMessages, userMessage])
    setInputValue('') // Clear input field

    const response = await getBPGuruResponse(messageText)
    const assistantMessage = {
      id: messages.length + 2,
      type: 'assistant',
      content: response.content,
      sources: response.sources,
      timestamp: new Date(),
      feedback: null
    }

    setMessages(prevMessages => [...prevMessages, assistantMessage])
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSendMessage()
    }
  }

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  const handleSuggestedQuestion = question => {
    handleSendMessage(question)
  }

  const handleFeedback = (messageId, feedbackType) => {
    setMessages(prevMessages =>
      prevMessages.map(msg =>
        msg.id === messageId ? { ...msg, feedback: feedbackType } : msg
      )
    )

    try {
      // await bpService.saveMessageFeedback({
      //   messageId,
      //   feedback: feedbackType,
      //   buildingId: bp.id,
      //   userId: currentUser.id
      // })
      console.log(`Feedback ${feedbackType} recorded for message ${messageId}`)
    } catch (error) {
      console.error('Error saving feedback:', error)
    }
  }

  return (
    <GuruWrapper>
      <MainContentPanel>
        <ChatHeader>
          <HeaderAvatar src={LogoShort} alt='Guru' />
          <HeaderTitle>
            <h1>Building Passport Guru</h1>
            <HeaderSubtitle>Your Building Passport Assistant</HeaderSubtitle>
          </HeaderTitle>
        </ChatHeader>

        <ChatWindow>
          {messages.map(message => (
            <MessageContainer key={message.id} type={message.type}>
              {message.type === 'assistant' && (
                <HeaderAvatar src={LogoShort} alt='Guru' />
              )}
              <MessageContent type={message.type}>
                <MessageSender type={message.type}>
                  {message.type === 'assistant' ? 'Guru' : 'You'}
                </MessageSender>
                {message.type === 'assistant' && message.sources ? (
                  <MarkdownRenderer
                    content={message.content}
                    sources={message.sources}
                    onDocumentClick={handleDocumentClick}
                  />
                ) : (
                  <MarkdownRenderer content={message.content} />
                )}
                <MessageFooter>
                  <MessageTime>
                    {message.timestamp.toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit'
                    })}
                  </MessageTime>

                  {message.type === 'assistant' && (
                    <FeedbackContainer>
                      <Tooltip title='Helpful'>
                        <FeedbackButton
                          size='small'
                          selected={message.feedback === 'positive'}
                          onClick={() => handleFeedback(message.id, 'positive')}
                        >
                          <ThumbUpIcon fontSize='small' />
                        </FeedbackButton>
                      </Tooltip>
                      <Tooltip title='Not helpful'>
                        <FeedbackButton
                          size='small'
                          selected={message.feedback === 'negative'}
                          onClick={() => handleFeedback(message.id, 'negative')}
                        >
                          <ThumbDownIcon fontSize='small' />
                        </FeedbackButton>
                      </Tooltip>
                    </FeedbackContainer>
                  )}
                </MessageFooter>
              </MessageContent>
              {message.type === 'user' && (
                <AvatarWrapper>
                  {userPhotoUrl ? (
                    <ImageAvatar src={userPhotoUrl} />
                  ) : (
                    <InitialsAvatar></InitialsAvatar>
                  )}
                </AvatarWrapper>
              )}
            </MessageContainer>
          ))}

          {isLoading && (
            <MessageContainer type='assistant'>
              <MessageAvatar type='assistant'>
                <Avatar src={LogoShort} alt='Guru' />
              </MessageAvatar>
              <MessageContent type='assistant'>
                <TypingIndicator>
                  <CircularProgress size={20} />
                  <span>Guru is typing...</span>
                </TypingIndicator>
              </MessageContent>
            </MessageContainer>
          )}

          <div ref={messagesEndRef} />
        </ChatWindow>

        <InputContainer>
          {messages.length === 1 && (
            <SuggestedQuestionsContainer>
              <SuggestedQuestionsTitle>
                Try asking about:
              </SuggestedQuestionsTitle>
              <SuggestedQuestionsList>
                {suggestedQuestions.map((question, index) => (
                  <SuggestedQuestionBubble
                    key={index}
                    onClick={() => handleSuggestedQuestion(question)}
                  >
                    {question}
                  </SuggestedQuestionBubble>
                ))}
              </SuggestedQuestionsList>
            </SuggestedQuestionsContainer>
          )}
          <InputWrapper>
            <Tooltip title='Clear chat history'>
              <ClearButton onClick={handleClearChat}>
                <DeleteIcon />
              </ClearButton>
            </Tooltip>
            <ChatInput
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder='Ask Guru about this building...'
              rows={1}
              maxRows={5}
            />
            <SendButton
              onClick={handleSendMessage}
              disabled={!inputValue.trim() || isLoading}
            >
              <SendIcon />
            </SendButton>
          </InputWrapper>
          <InputFooter>
            Building Passport Guru can help you find information about the
            building, documents, floorplans, and more.
          </InputFooter>
        </InputContainer>
      </MainContentPanel>
      <PdfFullScreenViewer
        open={pdfViewerOpen}
        onClose={handlePdfViewerClose}
        file={currentFile}
      />
    </GuruWrapper>
  )
}

export default BPGuru

const GuruWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: relative;
`

const ChatHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
`

const HeaderAvatar = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
`

const HeaderTitle = styled.div`
  h1 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    color: ${({ theme }) => theme.colors.haiti};
  }
`

const HeaderSubtitle = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey[200]};
`

const ChatWindow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 60vh;
  min-height: 400px;
  overflow-y: auto;
  padding: 16px 0;
  margin-bottom: 16px;
`

const MessageContainer = styled.div`
  display: flex;
  gap: 16px;
  max-width: 100%;
  margin-bottom: 8px;
  justify-content: ${({ type }) =>
    type === 'user' ? 'flex-end' : 'flex-start'};
`

const MessageAvatar = styled.div`
  margin-top: 4px;
`

const MessageContent = styled.div`
  background-color: ${({ type, theme }) =>
    type === 'assistant'
      ? theme.colors.backgroundColor
      : theme.colors.primary + '15'};
  border-radius: 8px;
  padding: 12px 16px;
  max-width: 70%;
  color: ${({ theme }) => theme.colors.haiti};
  position: relative;
  align-self: ${({ type }) => (type === 'user' ? 'flex-end' : 'flex-start')};
`

const MessageSender = styled.div`
  font-weight: 600;
  margin-bottom: 4px;
  color: ${({ type, theme }) =>
    type === 'assistant' ? theme.colors.primary : theme.colors.haiti};
`

const MessageTime = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey[200]};
  text-align: right;
  margin-top: 8px;
`

const MessageFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
`

const ImageAvatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
`

const AvatarWrapper = styled.div`
  cursor: pointer;
`

const TypingIndicator = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.colors.grey[200]};
  padding: 8px 0;

  span {
    font-size: 14px;
  }
`

const InputContainer = styled.div`
  margin-top: 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};
  padding-top: 16px;
`

const InputWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 12px;
  background: ${({ theme }) => theme.colors.backgroundColor};
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  border-radius: 8px;
  padding: 8px 12px;
`

const ChatInput = styled.textarea`
  flex: 1;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 8px 0;
  resize: none;
  background: transparent;
  font-family: inherit;

  &::placeholder {
    color: ${({ theme }) => theme.colors.grey[200]};
  }
`

const AttachButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.grey[200]} !important;
  padding: 8px !important;
`

const SendButton = styled(IconButton)`
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.grey[200] : theme.colors.primary} !important;
  padding: 8px !important;
`

const InputFooter = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey[200]};
  margin-top: 8px;
  text-align: center;
`
const ClearButton = styled(IconButton)`
  color: ${({ theme }) => theme.colors.grey[200]} !important;
  padding: 8px !important;

  &:hover {
    color: ${({ theme }) => theme.colors.error} !important;
  }
`

const SuggestedQuestionsContainer = styled.div`
  margin-bottom: 24px;
`

const SuggestedQuestionsTitle = styled.h3`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.haiti};
  margin-bottom: 12px;
`

const SuggestedQuestionsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

const SuggestedQuestionBubble = styled.button`
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  border-radius: 18px;
  padding: 8px 16px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.haiti};
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary}15;
    border-color: ${({ theme }) => theme.colors.primary};
  }
`
const FeedbackContainer = styled.div`
  display: flex;
  gap: 4px;
`

const FeedbackButton = styled(IconButton)`
  padding: 4px !important;
  color: ${({ selected, theme }) =>
    selected ? theme.colors.primary : theme.colors.grey[200]} !important;

  &:hover {
    color: ${({ theme, selected }) =>
      selected ? theme.colors.primary : theme.colors.haiti} !important;
    background-color: ${({ theme }) => theme.colors.lightGrey}20 !important;
  }
`
