import React, { useState } from 'react'
import Modal from 'components/atoms/Modal'
import { removeFileVersion } from 'services/BpService'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedBp } from 'features/bpDetails/ducks/selectors'
import { isNotNilOrEmpty } from 'utils/ramda'
import { removeUnitFileVersion } from 'services/unitsService'
import { fetchUnitRoutine } from 'ducks/units/actions'
import { getBpFilesRoutine } from 'ducks/files/actions'
import { MenuItem } from '@mui/material'

const DeleteFileVersionModal = ({ fileId, versionId, unit, isLastVersion }) => {
  const [open, setOpen] = useState(false)
  const currentBp = useSelector(getSelectedBp)
  const dispatch = useDispatch()
  const isUnit = isNotNilOrEmpty(unit)

  const handleOpen = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleClose = () => setOpen(false)

  const handleSubmit = () => {
    const handleSuccess = () => {
      toast.success('Removing file version...')
      isUnit
        ? dispatch(fetchUnitRoutine({
            bpId: currentBp.id,
            unitId: unit.id
          }))
        : dispatch(getBpFilesRoutine({
          id: currentBp.id
        }))
      handleClose()
    }

    const handleError = () => {
      toast.error('Something went wrong')
      handleClose()
    }

    if (isUnit) {
      removeUnitFileVersion({
        bpId: currentBp.id,
        unitId: unit.id,
        fileId,
        fileVersionId: versionId
      })
        .then(handleSuccess)
        .catch(handleError)
    } else {
      removeFileVersion({
        fileId,
        versionId
      })
        .then(handleSuccess)
        .catch(handleError)
    }
  }

  return (
    <>
      <MenuItem disabled={isLastVersion} onClick={isLastVersion ? () => {} : handleOpen}>Remove</MenuItem>
      <Modal
        open={open}
        onClose={handleClose}
        title='Delete file version'
        withCancel
        onCancel={handleClose}
        withSubmit
        onSubmit={handleSubmit}
        submitButtonText='Remove version'
      >
        Do you really want to delete this file version?
      </Modal>
    </>
  )
}

export default DeleteFileVersionModal
