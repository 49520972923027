import React, { useEffect, useState } from 'react'
import { getFloorsIntervals } from 'utils/bpData'
import { isNotNilOrEmpty } from 'utils/ramda'
import styled from 'styled-components'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import { useSelector } from 'react-redux'
import { pathOr } from 'ramda'
import { selectBpUnits } from 'ducks/units/selectors'
import { redirect } from 'utils/paths'

const DetailsUnitTableItem = ({
  unit,
  unitsCount,
  openEditModal,
  openDeleteModal,
  closeDropdownTrigger,
  isSoleUnit,
  isSoleResidential,
  hasFullAccess,
  redirectPath
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const dropdownOpen = Boolean(anchorEl)
  const bpUnits = useSelector(selectBpUnits)

  const floorNumbers = floors => {
    const sorted = floors
      .map(num => num === 'lower_ground' ? -0.5 : Number(num))
      .sort((a, b) => b - a)

    return getFloorsIntervals(sorted)
  }

  useEffect(() => {
    setAnchorEl(null)
  }, [closeDropdownTrigger])

  const openMenu = e => {
    e.stopPropagation()
    e.preventDefault()
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleItemClick = () => {
    if (!anchorEl) {
      redirect(`${redirectPath}/${unit.id}`)
    }
  }

  return (
    <TableRow onClick={handleItemClick}>
      <td>{unit.name}</td>
      <td>{unit.uprn || 'Not yet allocated'}</td>
      <td>{isNotNilOrEmpty(unit.floorNumbers) ? floorNumbers(unit.floorNumbers).join(', ') : '---'}</td>
      <td>{unit.area || '---'}</td>
      <td>{
        pathOr(false, ['owners', 0], unit)
          ? `${unit.owners[0].firstName} ${unit.owners[0].lastName}`
          : 'Unassigned'
      }</td>
      <td>
        {
          hasFullAccess && (
            <IconWrapper>
              <MoreIcon onClick={openMenu} />
              <Menu
                keepMounted
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                style={{
                  marginTop: '10px'
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                open={dropdownOpen}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={openEditModal(unit)}
                  disabled={
                    isSoleResidential ||
                    (unitsCount === 1 && isSoleUnit)
                  }
                >
                  Edit
                </MenuItem>
                <MenuItem
                  disabled={
                    bpUnits.length <= 1 ||
                    isSoleResidential
                  }
                  onClick={openDeleteModal(unit)}
                >
                  Remove Unit
                </MenuItem>
              </Menu>
            </IconWrapper>
          )
        }
      </td>
    </TableRow>
  )
}

export default DetailsUnitTableItem

const MoreIcon = styled(MoreVertIcon)`
  color: ${({ theme }) => theme.colors.grey[600]};
  cursor: pointer;
  font-size: 20px !important;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
`

const TableRow = styled.tr``
