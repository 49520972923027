import { createRoutine } from 'redux-saga-routines'
import { put, call, takeLatest, fork } from '@redux-saga/core/effects'
import * as authService from 'services/AuthService'
import {
  isExcludedFromFetchRedirect,
  PATHS,
  PRIVATE_PATHS,
  redirect
} from 'utils/paths'
import { toast } from 'react-toastify'
import { extractMessageFromErrorResponse, getApiErrors } from 'utils/errors'
import i18n from 'i18n'
import { dissoc, isNil } from 'ramda'
import LocalStorageService from 'services/LocalStorageService'
import { clearCart, KEYS } from 'utils/localStorage'
import * as bpService from 'services/BpService'
import localStorageService from 'services/LocalStorageService'

export const registerUserRoutine = createRoutine('REGISTER_USER')
export const loginUserRoutine = createRoutine('LOGIN_USER')
export const logoutUserRoutine = createRoutine('LOGOUT_USER')
export const verifyEmailRoutine = createRoutine('VERIFY_EMAIL')
export const requestResetPasswordRoutine = createRoutine(
  'REQUEST_RESET_PASSWORD'
)
export const resetPasswordRoutine = createRoutine('RESET_PASSWORD')
export const fetchAuthUserRoutine = createRoutine('FETCH_AUTH_DETAILS')
export const changePasswordRoutine = createRoutine('CHANGE_PASSWORD')
export const clearAuthUserRoutine = createRoutine('CLEAR_AUTH_USER')
export const getAccreditationTypesRoutine = createRoutine(
  'GET_ACCREDITATION_TYPES'
)
export const getInvitationRoutine = createRoutine('GET_INVITATION')
export const clearLatestInvitationRoutine = createRoutine(
  'CLEAR_LATEST_INVITATION'
)
export const updateUserRoutine = createRoutine('UPDATE_USER')
export const getPublicApiTokenRoutine = createRoutine('GET_PUBLIC_API_TOKEN')
export const clearPublicApiTokenRoutine = createRoutine(
  'CLEAR_PUBLIC_API_TOKEN'
)
export const deleteAccountRoutine = createRoutine('DELETE_ACCOUNT')

function* registerUser({ payload }) {
  yield put(registerUserRoutine.request())
  try {
    yield call(authService.registerUser, payload)
    yield put(registerUserRoutine.success())
    redirect(PATHS.signupConfirm)
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(registerUserRoutine.failure(error))
  }
}

function* deleteAccount({ payload }) {
  yield put(deleteAccountRoutine.request())
  try {
    yield call(bpService.deleteUserAccount, payload)
    yield put(logoutUserRoutine())
    yield put(deleteAccountRoutine.success())
    toast.success(
      'Your request to delete account has been sent to the administrator'
    )
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(deleteAccountRoutine.failure(error))
  }
}

function* updateUser({ payload }) {
  yield put(updateUserRoutine.request())
  try {
    const { data } = yield call(authService.updateUser, payload)
    yield put(updateUserRoutine.success(data.data))
    toast.success('User profile has been updated')
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(updateUserRoutine.failure(error))
  }
}

function* getInvitation({ payload }) {
  yield put(getInvitationRoutine.request())
  try {
    const result = yield call(authService.getUserInvitation, payload)
    yield put(getInvitationRoutine.success(result.data.data))
  } catch (error) {
    toast.error('Invitation not found')
    yield put(getInvitationRoutine.failure(error))
  }
}

function* clearLatestInvitation() {
  yield put(clearLatestInvitationRoutine.success())
}

function* loginUser({ payload }) {
  yield put(loginUserRoutine.request())
  try {
    const result = yield call(authService.loginUser, payload)
    const authToken = yield call(authService.getApiToken)
    const userData = result.data.data
    const lastLoggedUserId = LocalStorageService.get(KEYS.userId) || ''
    if (lastLoggedUserId !== result.data.data.id) {
      clearCart()
      LocalStorageService.set(KEYS.userId, result.data.data.id)
    }
    const redirectPath = LocalStorageService.get(KEYS.redirectPath) || null
    switch (userData.type) {
      case 'emergency_service':
        LocalStorageService.set(KEYS.token, authToken.data.data.token)
        if (!isNil(redirectPath)) {
          redirect(redirectPath)
          LocalStorageService.remove(KEYS.redirectPath)
        } else {
          redirect(PRIVATE_PATHS.bpList)
        }
        yield put(loginUserRoutine.success(userData))
        break
      case 'admin':
        yield put(logoutUserRoutine())
        break
      default:
        LocalStorageService.set(KEYS.token, authToken.data.data.token)
        yield put(loginUserRoutine.success(userData))
        if (!isNil(redirectPath)) {
          redirect(redirectPath)
          LocalStorageService.remove(KEYS.redirectPath)
        } else {
          redirect(PRIVATE_PATHS.dashboard)
        }
    }
  } catch (error) {
    toast.error(extractMessageFromErrorResponse(error))
    yield put(loginUserRoutine.failure(error))
  }
}

function* getPublicApiToken() {
  yield put(getPublicApiTokenRoutine.request())
  try {
    const { data } = yield call(authService.getPublicApiToken)
    yield put(getPublicApiTokenRoutine.success(data.data.token))
  } catch (e) {
    console.error(e)
    yield put(getPublicApiTokenRoutine.failure())
  }
}

function* clearPublicApiToken() {
  yield put(clearPublicApiTokenRoutine.success())
}

function* logoutUser({ payload }) {
  yield put(logoutUserRoutine.request())
  try {
    yield call(authService.logoutUser)
    yield put(logoutUserRoutine.success())
    redirect(PATHS.login)
    localStorageService.remove(KEYS.token)
    payload && typeof payload.callback === 'function' && payload.callback()
  } catch (err) {
    console.error(err)
    payload && typeof payload.callback === 'function' && payload.callback()
    yield put(logoutUserRoutine.failure())
  }
}

function* clearAuthUser() {
  yield put(clearAuthUserRoutine.request())
  try {
    yield put(clearAuthUserRoutine.success())
    setTimeout(() => redirect(PATHS.login), 100)
  } catch (err) {
    console.error(err)
    yield put(clearAuthUserRoutine.failure())
  }
}

function* fetchAuthUser() {
  yield put(fetchAuthUserRoutine.request())
  try {
    const result = yield call(authService.fetchAuthUser)
    yield put(fetchAuthUserRoutine.success(result.data.data))
  } catch (error) {
    if (!isExcludedFromFetchRedirect(window.location.pathname)) {
      yield put(clearAuthUserRoutine())
      yield put(logoutUserRoutine())
      LocalStorageService.set(KEYS.redirectPath, window.location.pathname)
    }
    console.error(error)
    yield put(fetchAuthUserRoutine.failure(error))
  }
}

function* getAccreditationTypes() {
  yield put(getAccreditationTypesRoutine.request())
  try {
    const result = yield call(authService.getAccreditationTypes)
    yield put(getAccreditationTypesRoutine.success(result.data.data))
  } catch (error) {
    console.error(error)
    yield put(getAccreditationTypesRoutine.failure(error))
  }
}

function* changePassword({ payload }) {
  yield put(changePasswordRoutine.request())
  const { clearValues } = payload

  try {
    yield call(authService.changePassword, dissoc('clearValues', payload))
    yield put(changePasswordRoutine.success())
    toast.success(i18n.t('passwordReset.alerts.passwordChanged'))
    typeof clearValues === 'function' && clearValues()
  } catch (error) {
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(changePasswordRoutine.failure(error))
  }
}

function* requestResetPassword({ payload }) {
  yield put(requestResetPasswordRoutine.request())
  try {
    yield call(authService.requestResetPassword, payload)
    yield put(requestResetPasswordRoutine.success())
    redirect(PATHS.login)
    toast.success(i18n.t('passwordReset.alerts.requestResetSuccess'))
  } catch (error) {
    console.error(error)
    getApiErrors(error)?.trim() && toast.error(getApiErrors(error))
    yield put(requestResetPasswordRoutine.failure(error))
  }
}

function* resetPassword({ payload }) {
  yield put(resetPasswordRoutine.request())
  try {
    yield call(authService.resetPassword, payload)
    yield put(resetPasswordRoutine.success())
    toast.success(i18n.t('passwordReset.alerts.passwordChanged'))
    redirect(PATHS.login)
  } catch (error) {
    console.error(error)
    toast.error(extractMessageFromErrorResponse(error))
    yield put(resetPasswordRoutine.failure(error))
  }
}

function* verifyEmail({ payload }) {
  const { queryParams, setStatus } = payload
  yield put(verifyEmailRoutine.request())
  try {
    yield call(authService.verifyEmail, queryParams)
    yield put(verifyEmailRoutine.success())
    setStatus('success')
    setTimeout(() => redirect(PATHS.login), 5000)
  } catch (error) {
    setStatus('error')
    setTimeout(() => redirect(PATHS.login), 5000)
    console.error(error)
    yield put(verifyEmailRoutine.failure(error))
  }
}

// WATCHERS

export function* registerUserWatcher() {
  yield takeLatest(registerUserRoutine.TRIGGER, registerUser)
}

export function* updateUserWatcher() {
  yield takeLatest(updateUserRoutine.TRIGGER, updateUser)
}

export function* loginUserWatcher() {
  yield takeLatest(loginUserRoutine.TRIGGER, loginUser)
}

export function* logoutUserWatcher() {
  yield takeLatest(logoutUserRoutine.TRIGGER, logoutUser)
}

export function* fetchAuthUserWatcher() {
  yield takeLatest(fetchAuthUserRoutine.TRIGGER, fetchAuthUser)
}

export function* verifyEmailWatcher() {
  yield takeLatest(verifyEmailRoutine.TRIGGER, verifyEmail)
}

export function* requestResetPasswordWatcher() {
  yield takeLatest(requestResetPasswordRoutine.TRIGGER, requestResetPassword)
}

export function* resetPasswordWatcher() {
  yield takeLatest(resetPasswordRoutine.TRIGGER, resetPassword)
}

export function* changePasswordWatcher() {
  yield takeLatest(changePasswordRoutine.TRIGGER, changePassword)
}

export function* clearAuthUserWatcher() {
  yield takeLatest(clearAuthUserRoutine.TRIGGER, clearAuthUser)
}

export function* getAccreditationTypesWatcher() {
  yield takeLatest(getAccreditationTypesRoutine.TRIGGER, getAccreditationTypes)
}

export function* getInvitationWatcher() {
  yield takeLatest(getInvitationRoutine.TRIGGER, getInvitation)
}

export function* clearLatestInvitationWatcher() {
  yield takeLatest(clearLatestInvitationRoutine.TRIGGER, clearLatestInvitation)
}

export function* getPublicApiTokenWatcher() {
  yield takeLatest(getPublicApiTokenRoutine.TRIGGER, getPublicApiToken)
}

export function* clearPublicApiTokenWatcher() {
  yield takeLatest(clearPublicApiTokenRoutine.TRIGGER, clearPublicApiToken)
}

export function* deleteAccountWatcher() {
  yield takeLatest(deleteAccountRoutine.TRIGGER, deleteAccount)
}

// SAGAS
export const authSagas = [
  fork(loginUserWatcher),
  fork(logoutUserWatcher),
  fork(clearLatestInvitationWatcher),
  fork(registerUserWatcher),
  fork(updateUserWatcher),
  fork(getPublicApiTokenWatcher),
  fork(clearPublicApiTokenWatcher),
  fork(fetchAuthUserWatcher),
  fork(getInvitationWatcher),
  fork(verifyEmailWatcher),
  fork(requestResetPasswordWatcher),
  fork(resetPasswordWatcher),
  fork(changePasswordWatcher),
  fork(getAccreditationTypesWatcher),
  fork(clearAuthUserWatcher),
  fork(deleteAccountWatcher)
]
